interface PathInfo {
  title?: string;
  path: string;
}

export interface PageCategory {
  name: string;
  paths: PathInfo[];
}

export const categoryMatches = (category: PageCategory, path: string): boolean =>
  category.paths.some((pathInfo) => path.includes(pathInfo.path));

export const getMatchingPathInfo = (category: PageCategory, path: string): PathInfo | undefined =>
  category.paths.find((pathInfo) => path.includes(pathInfo.path));
