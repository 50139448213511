import { Divider, LinearProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { ProjectsTable } from "./ProjectsTable";
import { CreateProjectForm } from "dashboard/components/CreateProjectForm";
import { PageContent } from "dashboard/components/Page";
import { useCreateProject, useProjects } from "data/queries/projects";
import { useImpression } from "hooks/use-impression";
import { useLogInteraction } from "hooks/use-log-interaction";

export const Services = () => {
  const logInteraction = useLogInteraction();
  const [projectName, setProjectName] = useState("");
  const { data: projects, isLoading: isLoadingProjects, refetch } = useProjects();

  const {
    mutate: createProject,
    isLoading: isCreatingProject,
    error,
  } = useCreateProject({
    onSuccess: (newProject) => {
      logInteraction("Created Project", { projectId: newProject.id });
      setProjectName("");
    },
  });

  useImpression("Settings - Projects Tab", { projectCount: projects?.length });

  return (
    <div>
      {isLoadingProjects && <LinearProgress />}
      <PageContent>
        <Typography variant="h2" marginBottom={2}>
          Create a Project
        </Typography>

        <CreateProjectForm
          errorCode={error?.response?.status}
          isLoading={isCreatingProject}
          onChange={setProjectName}
          onSubmit={createProject}
          projectName={projectName}
        />

        <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

        <Typography variant="h2" gutterBottom>
          Projects
        </Typography>

        {!isLoadingProjects && !projects?.length && (
          <div>
            <Typography variant="h2">You don&rsquo;t have any projects</Typography>
            <Typography variant="body2">Click &ldquo;New Project&rdquo; to make one!</Typography>
          </div>
        )}

        <ProjectsTable projects={projects} refetch={refetch} />
      </PageContent>
    </div>
  );
};
