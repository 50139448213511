import { useLDClient } from "launchdarkly-react-client-sdk";
import React, { ReactNode } from "react";
import { FeatureFlag } from "../feature-flags";

interface FeatureToggleProps {
  flag: FeatureFlag;
  children: ReactNode;
}

export const FeatureToggle = ({ flag, children }: FeatureToggleProps) => {
  const ldClient = useLDClient();

  if (ldClient?.variation(flag)) return <>{children}</>;
  return null;
};
