export function prettifyTimeDelta(d: Date, msDelta: number): string {
  const msPerDay = 24 * 60 * 60 * 1000;
  if (msDelta < 60 * 1000) {
    // Silently ignore dates in the future, they get folded into this case
    return "<1 minute ago";
  }
  if (msDelta < msPerDay) {
    const now = new Date();
    if (now.getDate() == d.getDate()) {
      return `${d.toLocaleTimeString()} today`;
    } else {
      return `${d.toLocaleTimeString()} yesterday`;
    }
  }
  return d.toLocaleDateString();
}

export const roundToNearestUTCHours = (
  d: Date | number,
  hourInterval: number,
  roundFunc: "floor" | "ceil" | "round" = "ceil"
) => {
  // Ensure we have a date object (just creates a copy if d is already a Date)
  const date = new Date(d);

  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();

  // Get the
  const fractionalHours = utcHours + utcMinutes / 60;

  // If this runs over 24 hours, that's fine; new Date will correctly tick over to the next day/month/yeah/etc.
  let newUTCHours = Math.ceil(fractionalHours / hourInterval) * hourInterval;

  if (roundFunc === "floor") {
    newUTCHours = Math.floor(fractionalHours / hourInterval) * hourInterval;
  } else if (roundFunc === "round") {
    newUTCHours = Math.round(fractionalHours / hourInterval) * hourInterval;
  }

  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), newUTCHours)
  );
};
