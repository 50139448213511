import { Settings } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { LinkBehavior } from "dashboard/components/LinkBehavior";
import { PageHeader, PageTitle } from "dashboard/components/Page";
import { SpecDownloadButton } from "dashboard/components/SpecDownloadButton";
import { getQueryLocation } from "dashboard/utils/routes";
import { useLogInteraction } from "hooks/use-log-interaction";

type ModelPageHeaderProps = {
  projectID?: string;
  modelID?: string;
  isSettingsDisabled?: boolean;
  children?: ReactNode;
};

export const ModelPageHeader = ({
  projectID,
  modelID,
  isSettingsDisabled,
  children,
}: ModelPageHeaderProps) => {
  const logInteraction = useLogInteraction({ projectID, modelID });
  const location = useLocation();

  return (
    <PageHeader
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: { flexDirection: "column", alignItems: "flex-start" },
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("sm")]: { marginBottom: 1 },
        })}
      >
        <PageTitle>API Model</PageTitle>

        <Tooltip title="Model Settings">
          <IconButton
            component={LinkBehavior}
            disabled={isSettingsDisabled}
            onClick={() => logInteraction("Clicked to open API model settings modal")}
            size="small-outlined"
            sx={{ marginLeft: 1.5 }}
            to={getQueryLocation(
              location,
              "modal",
              `/project/${projectID}/model/${modelID}/settings`
            )}
          >
            <Settings />
          </IconButton>
        </Tooltip>

        {children}
      </Box>

      <SpecDownloadButton
        projectID={projectID}
        modelID={modelID}
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: { alignItems: "flex-start", width: "100%" },
        })}
        sxButton={(theme) => ({ [theme.breakpoints.down("sm")]: { width: "100%" } })}
      />
    </PageHeader>
  );
};
