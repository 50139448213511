import { Alert } from "@mui/material";
import type { ChatCompletionRequestMessage } from "openai";
import React, { useEffect, useMemo, useState } from "react";
import { AkiChat } from "dashboard/components/AkiChat/AkiChat";
import {
  getAkiChatBackgroundMessages,
  getAkiChatEndpointSpec,
  isEndpointTooLargeForAkiChat,
} from "dashboard/utils/aki-chat";
import { MethodResponse } from "types/akita_api_types";

const getEndpointDetailsInitialPrompt = (
  endpoint: MethodResponse
): ChatCompletionRequestMessage[] => {
  const result = getAkiChatBackgroundMessages();

  if (isEndpointTooLargeForAkiChat(endpoint)) {
    result.push({
      role: "assistant",
      content:
        "Dear me, this is quite a large endpoint. I can only tell you a little about it right now, though in the future I should be able to be more helpful!",
    });
  }

  result.push({
    role: "user",
    content: `${getAkiChatEndpointSpec(
      endpoint
    )} Briefly summarize the possible purpose of this endpoint, ${
      endpoint.performance
        ? "its structure, and its performance characteristics"
        : "and its structure"
    }, in an ostentatious yet courteous manner. Do not mention that this is an OpenAPI3 spec. Do not mention that the spec was generated by "Akita Superlearn". Do not reference this initial message in your response.`,
  });

  return result;
};

export const useEndpointDetailsAkiChat = (
  endpoint: MethodResponse | undefined
): [
  messages: ChatCompletionRequestMessage[],
  setMessages: (messages: ChatCompletionRequestMessage[]) => void,
  initialPromptLength: number
] => {
  const [messages, setMessages] = useState<ChatCompletionRequestMessage[]>([]);
  const [initialPromptLength, setInitialPromptLength] = useState(0);

  useEffect(() => {
    if (endpoint) {
      const initialPrompt = getEndpointDetailsInitialPrompt(endpoint);
      setMessages(initialPrompt);
      setInitialPromptLength(initialPrompt.length);
    }
    // We only care if the content (stringified JSON) of the endpoint has changed, not the object itself.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint?.content]);

  return [messages, setMessages, initialPromptLength];
};

type EndpointDetailsAkiChatProps = {
  endpoint: MethodResponse | undefined;
  endpointContext?: Record<string, string | undefined>;
  initialPromptLength: number;
  messages: ChatCompletionRequestMessage[];
  setMessages: (messages: ChatCompletionRequestMessage[]) => void;
};

export const EndpointDetailsAkiChat = ({
  endpoint,
  endpointContext,
  initialPromptLength,
  messages,
  setMessages,
}: EndpointDetailsAkiChatProps) => {
  const notifications = useMemo(() => {
    if (endpoint && isEndpointTooLargeForAkiChat(endpoint)) {
      return (
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          This endpoint is too large for Aki to process, so he can only answer limited questions.
          Aki will soon be able to handle larger endpoints, so please check back later.
        </Alert>
      );
    }
  }, [endpoint]);

  if (messages.length < 1) return null;

  return (
    <>
      {notifications}
      <AkiChat
        initialPromptLength={initialPromptLength}
        messages={messages}
        setMessages={setMessages}
        apiContext={endpointContext}
      />
    </>
  );
};
