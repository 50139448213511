import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import React, { FormEvent, useCallback } from "react";
import { Page, PageContent, PageHeader, PageTitle } from "dashboard/components/Page";
import { ReactivatedUsersTable } from "dashboard/pages/InternalUserReactivation/ReactivatedUsersTable";
import { useReactivateUsers } from "data/queries/reactivate-users";
import { ReactivateUsersResponse } from "types/akita_api_types";

export const InternalUserReactivationPage = () => {
  const [badEmails, setBadEmails] = React.useState<string[]>([]);
  const [response, setResponse] = React.useState<ReactivateUsersResponse | undefined>();
  const [value, setValue] = React.useState("");

  const {
    mutate: reactivateUsers,
    isLoading,
    error,
  } = useReactivateUsers({ onSuccess: (response) => setResponse(response) });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const { emails, errors } = parseEmails(value);
      setBadEmails(errors);

      if (errors.length === 0 && emails.length > 0) {
        reactivateUsers(emails);
      }
    },
    [value]
  );

  return (
    <Page>
      <PageHeader
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <PageTitle>Reactivate Users</PageTitle>

        <Typography component="div" variant="h2" color="secondary">
          STAFF ONLY
        </Typography>
      </PageHeader>

      <PageContent>
        {error && <Alert>Error: {error}</Alert>}
        <p>Enter user email addresses, one per line.</p>

        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <TextField
                id="outlined-multiline-flexible"
                label="User email addresses"
                multiline
                rows={20}
                value={value}
                onChange={handleChange}
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" type="submit">
                Get Reactivation Links
              </Button>
            </Grid>
          </Grid>
        </Box>

        {badEmails.length > 0 && (
          <>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            <p>The following email addresses look invalid:</p>
            <ol>
              {badEmails.map((email) => (
                <li key={email}>{email}</li>
              ))}
            </ol>
          </>
        )}

        {isLoading && <LinearProgress sx={{ marginTop: 1, marginBottom: 1 }} />}

        {response && (
          <>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            <ReactivatedUsersTable responses={response.activation_urls} />
          </>
        )}
      </PageContent>
    </Page>
  );
};

const emailRegexp = /^[\w.%+-]+@[\w.-]+\.[a-zA-Z][a-zA-Z]+$/i;

const parseEmails = (input: string): { emails: string[]; errors: string[] } => {
  const all = input
    .trim()
    .split("\n")
    .map((email) => email.trim());
  const emails: string[] = [];
  const errors: string[] = [];

  all.forEach((v) => {
    if (v.match(emailRegexp)) {
      emails.push(v);
    } else {
      errors.push(v);
    }
  });

  return { emails, errors };
};
