import queryString from "query-string";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { mapToQueryString, queryStringToMap, updateQueryParams } from "dashboard/utils/querystring";

// Maps filter names to the potential values of that filter.
type Filters = Map<FilterName, FilterValues>;

// The name of a filter.  Filter names are generally supplied by the backend.
type FilterName = string;

// The domain of a FilterValues map indicates potential values that can
// be filtered on.  The range indicates whether the value is currently
// being filtered on.
//
// For example, suppose we're filtering by status code and have the following
// FilterValues:
//   "200" -> true
//   "201" -> false
//
// This indicates there are two possible values that can be filtered on, "200"
// and "201".  However, only "200" is currently being applied.
export type FilterValues = Map<string, boolean>;

type QueryString = string;

// This is a custom React hook that creates a Filters object populated with keys
// and values from the current query parameters.  Updates to the filters are
// reflected in the URL, and updates to the URL (e.g. clicking the "back" button)
// are reflected in the filters.
//
// Returns the filters, the query string built from filters with values mapped to
// true, and a function to update the filters.
export const useQueryFilters = (
  maybeInitialFilters?: Filters
): [Filters, QueryString, Dispatch<SetStateAction<Filters>>] => {
  const location = useLocation();
  const navigate = useHistory();

  // Initialize filters by combining any filters in the query with any
  // initial filters passed into the hook.
  const initialFilters: Filters = maybeInitialFilters || new Map();
  updateQueryParams(initialFilters, queryStringToMap(queryString.parse(location.search)));

  // This is the core filters object.
  const [filters, setFilters] = useState<Filters>(initialFilters);

  // This reflects the query string built from the filter values that map to true.
  const urlQuery = queryString.stringify(mapToQueryString(filters));

  // Update the URL when the query params change.
  useEffect(() => {
    // Only change the location if the query string changed!  Otherwise, this
    // pushes a duplicate state in the browser's history.
    const locationSearch = location.search.replace("?", "");
    if (locationSearch !== urlQuery) {
      const loc = {
        pathname: location.pathname,
        search: urlQuery,
        hash: location.hash,
      };
      navigate.push(loc);
    }
  }, [urlQuery]);

  // Update the filters when the query params change.
  useEffect(() => {
    setFilters((prev) => {
      const updatedFilters = new Map(prev);
      updateQueryParams(updatedFilters, queryStringToMap(queryString.parse(location.search)));
      return updatedFilters;
    });
  }, [location.search]);

  /*
  // When the user hits the "back" button, recompute filters from
  // the query string.
  useEffect(() => {
    // TODO: only do this on back button
    const newFilters = queryStringToMap(queryString.parse(location.search));
    // Update the existing map, otherwise the donut checkboxes won't pick up
    // the new state.
    setFilters((prev) => {
      const updatedFilters = new Map(prev);
      updateQueryParams(updatedFilters, newFilters);
      return updatedFilters;
    });
  }, [location.pathname]);
*/

  return [filters, urlQuery, setFilters];
};

// Update filter params when a checkbox is selected/unselected.
export const updateFilters = (
  setFilters: Dispatch<SetStateAction<Filters>>,
  name: string,
  selection: string,
  selected: boolean
) => {
  setFilters((prev) => {
    const newFilters = new Map(prev);

    let m = newFilters.get(name);
    if (!m) {
      m = new Map<string, boolean>();
      newFilters.set(name, m);
    }

    m.set(selection, selected);

    return newFilters;
  });
};

export const updateMultipleFilters = (
  setFilters: Dispatch<SetStateAction<Filters>>,
  name: string,
  selection: string[],
  selected: boolean,
  toDelete?: string[]
) => {
  setFilters((prev) => {
    const newFilters = new Map(prev);

    let m = newFilters.get(name);
    if (!m) {
      m = new Map<string, boolean>();
      newFilters.set(name, m);
    }

    selection.forEach((s) => m?.set(s, selected));
    if (toDelete) {
      toDelete.forEach((s) => m?.delete(s));
    }
    return newFilters;
  });
};

// Delete selection from filter entirely.  If selection is not present, delete
// entire filter.
export const deleteFilter = (
  setFilters: Dispatch<SetStateAction<Filters>>,
  name: string,
  selection?: string
) => {
  setFilters((prev) => {
    const newFilters = new Map(prev);

    if (selection === undefined) {
      newFilters.delete(name);
    } else {
      const m = newFilters.get(name);
      if (m) {
        m.delete(selection);
      }
    }

    return newFilters;
  });
};
