import { ChevronRight } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { OrganizationDetails } from "types/akita_api_types";

interface InternalOrgsTableRowProps {
  organization: OrganizationDetails;
}

export const InternalOrgsTableRow = ({ organization }: InternalOrgsTableRowProps) => (
  <TableRow hover key={organization.organization_id}>
    <TableCell>{organization.organization_id}</TableCell>
    <TableCell>{organization.inferred_name}</TableCell>
    <TableCell align="right">{organization.services.length}</TableCell>
    <TableCell align="right">{organization.users.length}</TableCell>
    <TableCell align="right">
      <IconButton
        href={`/internal/org/${organization.organization_id}`}
        aria-label="View detailed information"
      >
        <ChevronRight />
      </IconButton>
    </TableCell>
  </TableRow>
);
