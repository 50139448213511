import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { resendInvite } from "dashboard/pages/Settings/Organization/backend";
import { AkitaError } from "dashboard/utils/error";

const useStyles = makeStyles(() => ({
  container: {},
}));

type ResendInviteProps = {
  accessToken: string;
  inviteID: string;
  setAlert: (a: any) => void;
};

export const ResendInvite = (props: ResendInviteProps) => {
  const classes = useStyles();
  const [isSent, setSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClick = () => {
    props.setAlert(null);
    setIsLoading(true);
    resendInvite(props.accessToken, props.inviteID).then(
      () => {
        setSent(true);
        setIsLoading(false);
      },
      (akErrPromise) => {
        akErrPromise.then((akErr: AkitaError) => {
          setSent(false);
          setIsLoading(false);
          handleFailure(akErr);
        });
      }
    );
  };

  const handleFailure = (err: AkitaError) => {
    props.setAlert(<Alert severity="error">{err.customerMessage}</Alert>);
  };

  return (
    <div className={classes.container}>
      <>
        <Button color="primary" disabled={isSent || isLoading} onClick={onClick}>
          {isSent ? "Sent" : "Resend Invite"}
        </Button>
        {alert}
      </>
    </div>
  );
};
