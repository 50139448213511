import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { ServiceSummary } from "types/akita_api_types";

interface SelectProjectProps {
  projects: ServiceSummary[];
  selectedProject: ServiceSummary | undefined;
  onSelect: (project: ServiceSummary | undefined) => void;
}

export const SelectProject = ({ projects, selectedProject, onSelect }: SelectProjectProps) => {
  const projectItems = useMemo(
    () =>
      projects.map((p) => (
        <MenuItem key={p.id} value={p.id}>
          {p.name}
        </MenuItem>
      )),
    [projects]
  );

  const handleSelect = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedId = event?.target?.value;
      const selectedProject = projects.find((p) => p.id === selectedId);

      onSelect(selectedProject);
    },
    [projects, onSelect]
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="project-select-label">Project</InputLabel>
      <Select
        label="Project"
        labelId="project-select-label"
        id="project-select"
        value={selectedProject?.id ?? ""}
        onChange={handleSelect}
      >
        {projectItems}
      </Select>
    </FormControl>
  );
};
