import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { akitaFetch, retry } from "data/akita-fetch";
import { GetAlertHistoryQueryParams, GetAlertHistoryResponse } from "types/akita_api_types";

const getAlertHistory = (
  projectID: string,
  deploymentID: string,
  monitorID: string,
  queryParams?: GetAlertHistoryQueryParams
) =>
  akitaFetch<GetAlertHistoryResponse>(
    `/services/${projectID}/monitors/${deploymentID}/alert-history/${monitorID}`,
    {
      queryParams,
    }
  );

export const useAlertHistory = ({
  projectID,
  deploymentID,
  monitorID,
  queryParams,
}: {
  projectID?: string;
  deploymentID?: string;
  monitorID?: string;
  queryParams?: GetAlertHistoryQueryParams;
}) =>
  useQuery(
    ["alert-history", projectID, deploymentID, monitorID, queryParams],
    () => getAlertHistory(projectID!, deploymentID!, monitorID!, queryParams),
    { enabled: !!projectID && !!deploymentID && !!monitorID, retry }
  );

// Returns a function that, when called, invalidates the cache and forces React Query
// to refetch this monitor's alert history.
export const useRefreshAlertHistory = (
  projectID: string,
  deploymentID: string,
  monitorID: string
) => {
  const queryClient = useQueryClient();

  return useCallback(
    () => void queryClient.invalidateQueries(["alert-history", projectID, deploymentID, monitorID]),
    [deploymentID, monitorID, queryClient, projectID]
  );
};
