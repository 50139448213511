/**
 * buildURL returns either a URL object built from the given string, or undefined if one can't
 * be constructed.
 */
export const buildURL = (str: string) => {
  try {
    return new URL(str);
  } catch {
    return undefined;
  }
};
