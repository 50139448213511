import { Box, SxProps, Theme, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Percentile } from "dashboard/utils/percentiles";

type PercentilePickerProps = {
  percentile: Percentile;
  setPercentile: (newPercentile: Percentile) => void;
  sx?: SxProps<Theme>;
};

export const PercentilePicker = ({ percentile, setPercentile, sx }: PercentilePickerProps) => {
  const onChangePercentile = useCallback(
    (_event, value: Percentile | null) => {
      // MUI seems to have a bug where this value can be nullable even when a ToggleButtonGroup
      // has the `exclusive` prop, in spite of its typing. Let's make sure this is not nullable,
      // to guard against this breaking our assumptions upstream.
      if (!value) return;
      setPercentile(value);
    },
    [setPercentile]
  );

  return (
    <Box sx={sx}>
      <Typography
        variant="h6"
        sx={(theme) => ({
          marginBottom: 1,
          textAlign: "right",
          height: theme.spacing(3),
          [theme.breakpoints.down("md")]: { textAlign: "left" },
        })}
      >
        Percentile
      </Typography>

      <ToggleButtonGroup
        color="primary"
        size="small"
        value={percentile}
        exclusive
        onChange={onChangePercentile}
      >
        <ToggleButton value="p90">p90</ToggleButton>
        <ToggleButton value="p95">p95</ToggleButton>
        <ToggleButton value="p99">p99</ToggleButton>
        <ToggleButton value="p99.9">p99.9</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
