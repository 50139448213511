import { List, ListItem, ListItemIcon, Typography } from "@mui/material";
import React from "react";
import { TrafficCheckIcon } from "./TrafficCheckIcon";
import { TrafficCheckStatus } from "./types";

interface TrafficCheckProps {
  statusClientStarted: TrafficCheckStatus;
  statusReceivingData: TrafficCheckStatus;
  statusModelReady: TrafficCheckStatus;
}

export const TrafficCheck = ({
  statusClientStarted,
  statusReceivingData,
  statusModelReady,
}: TrafficCheckProps) => (
  <List component="ol">
    <ListItem sx={{ paddingLeft: 0 }}>
      <ListItemIcon sx={{ minWidth: 30 }}>
        <TrafficCheckIcon status={statusClientStarted} />
      </ListItemIcon>

      <Typography
        component="span"
        fontWeight="bold"
        color={statusClientStarted === "incomplete" ? "text.secondary" : "text.primary"}
      >
        Akita client started
      </Typography>
    </ListItem>

    <ListItem sx={{ paddingLeft: 0 }}>
      <ListItemIcon sx={{ minWidth: 30 }}>
        <TrafficCheckIcon status={statusReceivingData} />
      </ListItemIcon>

      <Typography
        component="span"
        fontWeight="bold"
        color={statusReceivingData === "incomplete" ? "text.secondary" : "text.primary"}
      >
        Receiving and processing your data
      </Typography>
    </ListItem>

    <ListItem sx={{ paddingLeft: 0 }}>
      <ListItemIcon sx={{ minWidth: 30 }}>
        <TrafficCheckIcon status={statusModelReady} />
      </ListItemIcon>

      <Typography
        component="span"
        fontWeight="bold"
        color={statusModelReady === "incomplete" ? "text.secondary" : "text.primary"}
      >
        Preparing your API model
      </Typography>
    </ListItem>
  </List>
);
