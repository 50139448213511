import { Box, BoxProps, Breakpoint } from "@mui/material";
import React, { useMemo } from "react";
import { mergeSxProps } from "dashboard/utils/sx";

type AkiGridContainerProps = BoxProps & {
  /** The minimum height of a row, in MUI spacing units. Defaults to 8. */
  minHeightRow?: number;
  /**
   * Number (or record from Breakpoint name to number) of columns per row.
   * - When this is a number, it is the number of columns displayed starting at the "md" breakpoint
   * (1 column will be displayed below that breakpoint).
   * - When this is an object, it represents the number of columns to be displayed at each breakpoint.
   * - Each entry in the object gets its own mobile-first media query.
   * - Example: { sm: 2, lg: 4 } will result in a 1-column grid by default, a 2-column grid starting
   * at the "sm" breakpoint, and a 4-column grid from the "lg" breakpoint.
   * - Defaults to 6. (6-column grid starting at "md" breakpoint, 1-column below that.)
   */
  columns?: number | Partial<Record<Breakpoint, number>>;
  /** Spacing (aka gutter) between grid tracks, in MUI spacing units. Defaults to 2. */
  gap?: number;
};

export const AkiGridContainer = ({
  children,
  sx,
  columns = 6,
  minHeightRow = 8,
  gap = 2,
  ...rest
}: AkiGridContainerProps) => {
  // As an optimization to keep from recomputing the gridTemplateColumns value on every render, we'll
  // take the keys and values of `columns` and flatten them into an array to be used as the dependency
  // array for useMemo.
  const columnsMemoKey = typeof columns === "number" ? [columns] : Object.entries(columns).flat();

  const gridTemplateColumns = useMemo(() => {
    const defaultColumns: Partial<Record<Breakpoint, string>> = { xs: "repeat(1, 1fr)" };

    if (typeof columns === "number") {
      return { ...defaultColumns, md: `repeat(${columns}, 1fr)` };
    }

    return Object.entries(columns).reduce((result, [breakpoint, value]) => {
      result[breakpoint as Breakpoint] = `repeat(${value}, 1fr)`;

      return result;
    }, defaultColumns);
    // The linter doesn't like this kind of thing, but it's fine.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, columnsMemoKey);

  return (
    <Box
      gridTemplateColumns={gridTemplateColumns}
      sx={mergeSxProps(
        (theme) => ({
          display: "grid",
          gridAutoRows: `minmax(${theme.spacing(minHeightRow)}, auto)`,
          gap,
        }),
        sx
      )}
      {...rest}
    >
      {children}
    </Box>
  );
};
