import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { AkiDialog } from "dashboard/components/AkiDialog/AkiDialog";
import { AkiDialogContent } from "dashboard/components/AkiDialog/AkiDialogContent";
import { AkiDialogHeader } from "dashboard/components/AkiDialog/AkiDialogHeader";
import { QueryError } from "dashboard/components/QueryError";
import { getMonitorUpdateFromMonitor } from "dashboard/utils/monitors";
import { useUpdateMonitors } from "data/queries/monitors";
import { useLogInteraction } from "hooks/use-log-interaction";
import { Monitor } from "types/akita_api_types";

interface MonitorDeleteButtonProps {
  projectID: string;
  deploymentID: string;
  monitor?: Monitor;
  onDelete: () => void;
}

export const MonitorDeleteButton = ({
  projectID,
  deploymentID,
  monitor,
  onDelete,
}: MonitorDeleteButtonProps) => {
  const logInteraction = useLogInteraction();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {
    mutateAsync: updateMonitors,
    isLoading: isLoadingUpdateMonitors,
    error: errorUpdateMonitors,
  } = useUpdateMonitors(projectID, deploymentID);
  const onCancel = useCallback(() => setIsDialogOpen(false), []);

  const onClickDelete = useCallback(() => {
    if (!monitor) return;

    setIsDialogOpen(true);
  }, [monitor]);

  const handleDelete = useCallback(async () => {
    if (!monitor) return;

    const monitorUpdate = getMonitorUpdateFromMonitor(monitor);
    monitorUpdate.hidden = true;

    try {
      await updateMonitors([monitorUpdate]);
      logInteraction("Deleted monitor");
      setIsDialogOpen(false);
      onDelete();
    } catch (error) {
      console.error(error);
    }
  }, [logInteraction, monitor, onDelete, updateMonitors]);

  return (
    <>
      <Tooltip title="Delete monitor">
        <IconButton onClick={onClickDelete} disabled={!monitor} size="small-outlined">
          <Delete />
        </IconButton>
      </Tooltip>

      {isDialogOpen && (
        <AkiDialog onClose={onCancel}>
          <AkiDialogHeader title="Delete Monitor" />
          <AkiDialogContent>
            <QueryError error={errorUpdateMonitors} label="We had trouble removing your monitor." />

            <Typography>Are you sure? This can’t be undone.</Typography>

            <Stack direction="row-reverse" spacing={1} paddingTop={2}>
              <LoadingButton
                variant="contained"
                color="error"
                loading={isLoadingUpdateMonitors}
                onClick={handleDelete}
              >
                Delete
              </LoadingButton>

              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
            </Stack>
          </AkiDialogContent>
        </AkiDialog>
      )}
    </>
  );
};
