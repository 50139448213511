import { Alert, Box, Button, Link, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { OnboardingStepButtons } from "dashboard/components/OnboardingStep/OnboardingStepButtons";
import { OnboardingStepContext } from "dashboard/components/OnboardingStep/context";
import { TrafficCheck } from "dashboard/components/TrafficCheck/TrafficCheck";
import { TrafficCheckStatus } from "dashboard/components/TrafficCheck/types";
import { useClientTelemetryCountRunning } from "data/queries/client-telemetry";
import { useLatestModelSummaryForProject } from "data/queries/models";
import { SpecSummary } from "types/akita_api_types";

/** Returns the appropriate tuple of TrafficCheckStatus values for the given booleans. */
const getStepStatuses = (
  isClientRunning: boolean,
  isModelInitialized: boolean,
  isModelReady: boolean
): [TrafficCheckStatus, TrafficCheckStatus, TrafficCheckStatus] => {
  if (isClientRunning && !isModelInitialized && !isModelReady) {
    return ["done", "pending", "incomplete"];
  }

  if (isModelInitialized && !isModelReady) {
    return ["done", "done", "pending"];
  }

  if (isModelReady) {
    return ["done", "done", "done"];
  }

  return ["pending", "incomplete", "incomplete"];
};

interface StepTrafficCheckProps {
  projectID: string | undefined;
}

export const StepTrafficCheck = ({ projectID }: StepTrafficCheckProps) => {
  const { markCompleted, trackOnboardingEvent } = useContext(OnboardingStepContext);
  const navigate = useHistory();

  // Determines whether an Akita Agent is running from the data returned by
  // useClientTelemetryCountRunning.
  const haveClientRunning = (countRunning?: number) => !!countRunning;

  // Determines whether a model has been initialized for the project from the data returned by
  // useLatestModelSummaryForProject.
  const haveModelInitialized = (modelCreatedSummary?: SpecSummary) =>
    ["INITIALIZED", "COMPUTING", "DONE"].includes(modelCreatedSummary?.state ?? "");

  // Determines whether a model is completed for the project from the data returned by
  // useLatestModelSummaryForProject.
  const haveModelReady = (modelReadySummary?: SpecSummary) => modelReadySummary?.state === "DONE";

  // CALLBACKS
  const onExploreModelClick = useCallback(() => {
    trackOnboardingEvent(`"Explore Model" Clicked`);
    navigate.push(`/service/${projectID}/model`);
  }, [projectID, navigate, trackOnboardingEvent]);

  // DATA FETCHING

  // Fetch client count every 10s until we find a running client.
  const { data: countRunning } = useClientTelemetryCountRunning({
    projectID,
    options: { refetchInterval: (countRunning?) => (haveClientRunning(countRunning) ? 0 : 10_000) },
  });

  // Fetch model metadata every 10s until the model is initialized.
  //
  // Here, we fetch by tag, which is set on the model as soon as it is initialized.
  const { data: modelCreatedSummary } = useLatestModelSummaryForProject({
    projectID,
    queryParams: {
      tag: ["x-akita-deployment=default", "x-akita-scheduled-model-type=large-model"],
    },
    options: {
      refetchInterval: (modelCreatedSummary?) =>
        haveModelInitialized(modelCreatedSummary) ? 0 : 10_000,
    },
  });

  // Fetch model metadata every 10s until the model is ready.
  //
  // Here, we fetch by version tag, which is only set when the model is done.
  const { data: modelReadySummary } = useLatestModelSummaryForProject({
    projectID,
    deploymentID: "default",
    options: {
      refetchInterval: (modelReadySummary?) => (haveModelReady(modelReadySummary) ? 0 : 10_000),
    },
  });

  // COMPUTED STATE
  const isClientRunning = haveClientRunning(countRunning);
  const isModelInitialized = haveModelInitialized(modelCreatedSummary);
  const isModelReady = haveModelReady(modelReadySummary);
  const isLatestModelErrored = modelReadySummary?.state === "ERROR";

  const [statusClientStarted, statusReceivingData, statusModelReady] = getStepStatuses(
    isClientRunning,
    isModelInitialized,
    isModelReady
  );

  // EVENT TRACKING
  useEffect(() => {
    if (isClientRunning) {
      trackOnboardingEvent("Indicated that client is started", { projectID });
    }
  }, [trackOnboardingEvent, isClientRunning, projectID]);

  useEffect(() => {
    if (isModelInitialized) {
      trackOnboardingEvent("Indicated that model is being prepared", { projectID });
    }
  }, [trackOnboardingEvent, isModelInitialized, projectID]);

  useEffect(() => {
    if (isModelReady) {
      trackOnboardingEvent("Indicated that model is ready", { projectID });
      markCompleted();
    }
  }, [trackOnboardingEvent, isModelReady, markCompleted, projectID]);

  useEffect(() => {
    if (isLatestModelErrored) {
      trackOnboardingEvent("Indicated that model has errors", { projectID });
    }
  }, [trackOnboardingEvent, isLatestModelErrored, projectID]);

  return (
    <>
      <Typography gutterBottom>
        In production environments, the Akita Client captures real-time traffic hitting your API. In
        your local environment, you&rsquo;ll want to send some test traffic to your service – make
        some cURL requests, or click around on its website!
      </Typography>

      <Typography>
        It can take a few minutes for Akita to observe the data and process it.
      </Typography>

      <TrafficCheck
        statusClientStarted={statusClientStarted}
        statusReceivingData={statusReceivingData}
        statusModelReady={statusModelReady}
      />

      {!isLatestModelErrored && !isModelReady && (
        <Alert severity="info">
          Stuck? Check out our{" "}
          <Link target="_blank" href="https://docs.akita.software/docs/troubleshooting">
            troubleshooting guide
          </Link>{" "}
          or let us know in our{" "}
          <Link target="_blank" href="https://akita-community.slack.com/archives/CNGN2JDPE">
            community Slack workspace
          </Link>
          .
        </Alert>
      )}

      {isLatestModelErrored && (
        <Alert severity="error">
          Something went wrong while we were preparing your API model. Please reach out to{" "}
          <Link target="_blank" href="mailto:support@akitasoftware.com">
            support@akitasoftware.com
          </Link>{" "}
          for help.
        </Alert>
      )}

      {isModelReady && (
        <Box>
          <Button
            size="large"
            variant="contained"
            onClick={onExploreModelClick}
            href={`/service/${projectID}/model`}
          >
            Explore Model
          </Button>
        </Box>
      )}

      <OnboardingStepButtons nextHidden />
    </>
  );
};
