import { Link } from "@mui/material";
import React from "react";
import { NavbarListItem } from "./NavbarListItem";
import logo from "assets/img/akita_logo_light.svg";

export const NavbarLogo = ({ href }: { href: string }) => (
  <NavbarListItem>
    <Link
      href={href}
      sx={{
        color: "#FFFFFF",
        height: 20,
        display: "block",
        "&:hover": { opacity: 0.7 },
        transition: "opacity linear 200ms",
        cursor: "pointer",
      }}
    >
      <img src={logo} style={{ display: "block", height: "100%", width: "auto" }} alt="Akita" />
    </Link>
  </NavbarListItem>
);
