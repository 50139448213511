import { Box, ListItem } from "@mui/material";
import React, { ReactNode } from "react";
import { DisplayTimelineValue } from "dashboard/utils/timelines";

type ChartTooltipListItemProps = {
  datum: DisplayTimelineValue;
  children?: ReactNode;
};

export const ChartTooltipListItem = ({ datum, children }: ChartTooltipListItemProps) => (
  <ListItem sx={{ padding: 0 }}>
    <Box
      sx={{
        borderLeft: `8px solid ${datum.color}`,
        padding: 1,
        width: "100%",
      }}
    >
      {children}
    </Box>
  </ListItem>
);
