import { addMinutes, addSeconds, isAfter, parseISO } from "date-fns";
import { ClientPacketCaptureStats, InferredError } from "types/akita_api_types";

/**
 * An Akita agent is considered active if it has reported telemetry in the past
 * six minutes.  (The CLI uploads telemetry every five minutes.)
 */
export const isClientActive = (stats: ClientPacketCaptureStats): boolean =>
  isAfter(
    addSeconds(parseISO(stats.observed_starting_at), stats.observed_duration_in_seconds),
    addMinutes(new Date(), -5)
  );

export const isClientActiveWithError = (stats: ClientPacketCaptureStats): boolean => {
  if (!isClientActive(stats)) return false;
  if (!stats.error) return false;

  // Waiting to hear from a client doesn't necessarily represent an issue, so we won't count that as an error.
  if (stats.error.inferred_capture_error == InferredError.Waiting) return false;

  return true;
};
