import { Box, Button } from "@mui/material";
import { noop } from "lodash";
import React, { useCallback, useContext } from "react";
import { OnboardingStepContext } from "./context";

interface OnboardingStepButtonsProps {
  nextDisabled?: boolean;
  backDisabled?: boolean;
  backHidden?: boolean;
  nextHidden?: boolean;
  nextLabel?: string;
  backLabel?: string;
  onNextClick?: () => void;
  onBackClick?: () => void;
}

export const OnboardingStepButtons = ({
  nextDisabled = false,
  backDisabled = false,
  backHidden = false,
  nextHidden = false,
  nextLabel = "Next",
  backLabel = "Back",
  onNextClick = noop,
  onBackClick = noop,
}: OnboardingStepButtonsProps) => {
  const { nextStep, prevStep } = useContext(OnboardingStepContext);

  const handleNextClick = useCallback(() => {
    onNextClick();
    nextStep();
  }, [onNextClick, nextStep]);

  const handleBackClick = useCallback(() => {
    onBackClick();
    prevStep();
  }, [onBackClick, prevStep]);

  if (backHidden && nextHidden) return null;

  return (
    <Box sx={{ marginTop: 2, display: "inline-flex", flexDirection: "row-reverse" }}>
      {!nextHidden && (
        <Button onClick={handleNextClick} disabled={nextDisabled} variant="contained" size="large">
          {nextLabel}
        </Button>
      )}

      {!backHidden && (
        <Button
          onClick={handleBackClick}
          disabled={backDisabled}
          sx={{ marginRight: 1 }}
          size="large"
          variant="outlined"
        >
          {backLabel}
        </Button>
      )}
    </Box>
  );
};
