import { LoadingButton } from "@mui/lab";
import { Box, Button, SxProps, Theme, Typography } from "@mui/material";
import { cloneDeep, orderBy } from "lodash";
import React, { FormEvent, ReactNode, useCallback, useMemo, useState } from "react";
import { AkiTable } from "../AkiTable/AkiTable";
import { AkiTableBody } from "../AkiTable/AkiTableBody";
import { AkiTableHead } from "../AkiTable/AkiTableHead";
import { FormattedEndpointField } from "../EndpointDetails/formatting";
import { SortableTableHeaderCell } from "../SortableTableHeaderCell";
import { EndpointFieldsTableRow } from "./EndpointFieldsTableRow";

type EndpointFieldsTableProps = {
  editButtonLabel?: ReactNode;
  editError?: ReactNode;
  fields?: FormattedEndpointField[];
  includeContentType?: boolean;
  includePosition?: boolean;
  includeResponseCode?: boolean;
  isEditable?: boolean;
  isEditSaving?: boolean;
  isLoading?: boolean;
  onEditCancel?: () => void;
  onEditSave?: (editedFields: FormattedEndpointField[]) => void;
  onEditStart?: () => void;
  sx?: SxProps<Theme>;
  title: ReactNode;
};

export const EndpointFieldsTable = ({
  editButtonLabel,
  editError,
  fields,
  includeContentType,
  includePosition,
  includeResponseCode,
  isEditable,
  isEditSaving,
  isLoading,
  onEditCancel,
  onEditSave,
  onEditStart,
  sx,
  title,
}: EndpointFieldsTableProps) => {
  const [sort, setSort] = useState<`${string}:${"asc" | "desc"}`>(
    // If includePosition is true, sort by that by default.
    includePosition ? "position:asc" : "path:desc"
  );

  const [activeSortKey = "path", activeSortDirection = "desc"] = sort
    ? (sort.split(":") as [string, "asc" | "desc"])
    : [];

  const [editedFields, setEditedFields] = useState<FormattedEndpointField[] | undefined>();

  const sortedFields = useMemo(
    () =>
      fields &&
      orderBy(
        editedFields || fields,
        [
          (field) => {
            if (activeSortKey === "dataFormats") return field.display.dataFormat;
            if (activeSortKey === "responseCode") return field.display.responseCode;
            if (activeSortKey === "contentType") return field.display.contentType;
            if (activeSortKey === "position") return field.display.position;
            return field.display.fieldName;
          },
          // Always sort by field name, to prevent random re-ordering when sorting by another key
          (field) => field.display.fieldName,
        ],
        [activeSortDirection, activeSortDirection]
      ),
    [fields, editedFields, activeSortKey, activeSortDirection]
  );

  const isEditMode = isEditable && editedFields && !isLoading;

  const handleEditField = useCallback((field: FormattedEndpointField) => {
    setEditedFields((efs) => {
      if (!efs || !field.uniqueKey) return efs;

      const newEditedFields = cloneDeep(efs);
      const editedFieldIndex = efs.findIndex((f) => f.uniqueKey === field.uniqueKey);

      if (editedFieldIndex < 0) return efs;

      newEditedFields[editedFieldIndex] = field;
      return newEditedFields;
    });
  }, []);

  const handleEditStart = useCallback(() => {
    if (!isEditable) return;

    setEditedFields(fields);
    onEditStart?.();
  }, [onEditStart, fields, isEditable]);

  const handleEditCancel = useCallback(() => {
    setEditedFields(undefined);
    onEditCancel?.();
  }, [onEditCancel]);

  const handleEditSave = useCallback(
    (event?: FormEvent) => {
      event?.preventDefault?.();

      if (!isEditable || !editedFields || !onEditSave) return;

      onEditSave(editedFields);
    },
    [isEditable, onEditSave, editedFields]
  );

  const rows = useMemo(() => {
    if (isLoading) {
      return new Array(3)
        .fill(undefined)
        .map((_value, index) => (
          <EndpointFieldsTableRow
            includeContentType={includeContentType}
            includeResponseCode={includeResponseCode}
            includePosition={includePosition}
            isLoading
            key={index}
          />
        ));
    }

    return (
      sortedFields?.map((field, index) => (
        <EndpointFieldsTableRow
          field={field}
          includeContentType={includeContentType}
          includePosition={includePosition}
          includeResponseCode={includeResponseCode}
          isEditMode={isEditMode}
          isFirst={index === 0}
          key={field.uniqueKey}
          onEdit={handleEditField}
        />
      )) ?? []
    );
  }, [
    handleEditField,
    includeContentType,
    includePosition,
    includeResponseCode,
    isEditMode,
    isLoading,
    sortedFields,
  ]);

  const table = (
    <AkiTable size="small">
      <AkiTableHead>
        <SortableTableHeaderCell
          sortKey="path"
          activeSortKey={activeSortKey}
          activeSortDirection={activeSortDirection}
          onSort={setSort}
          isDisabled={isEditMode}
        >
          Field Name
        </SortableTableHeaderCell>

        <SortableTableHeaderCell
          sortKey="dataFormats"
          activeSortKey={activeSortKey}
          activeSortDirection={activeSortDirection}
          onSort={setSort}
          sx={{ paddingLeft: 0, paddingRight: 0 }}
          isDisabled={isEditMode}
        >
          Data Formats
        </SortableTableHeaderCell>

        {includeContentType && (
          <SortableTableHeaderCell
            sortKey="contentType"
            activeSortKey={activeSortKey}
            activeSortDirection={activeSortDirection}
            onSort={setSort}
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            isDisabled={isEditMode}
          >
            Content Type
          </SortableTableHeaderCell>
        )}

        {includeResponseCode && (
          <SortableTableHeaderCell
            align="right"
            sortKey="responseCode"
            activeSortKey={activeSortKey}
            activeSortDirection={activeSortDirection}
            onSort={setSort}
            sx={{ paddingLeft: 0 }}
            isDisabled={isEditMode}
          >
            Response Code
          </SortableTableHeaderCell>
        )}

        {includePosition && (
          <SortableTableHeaderCell
            align="right"
            sortKey="position"
            activeSortKey={activeSortKey}
            activeSortDirection={activeSortDirection}
            onSort={setSort}
            sx={{ paddingLeft: 0 }}
          >
            Position
          </SortableTableHeaderCell>
        )}
      </AkiTableHead>

      <AkiTableBody>{rows}</AkiTableBody>
    </AkiTable>
  );

  if (!isLoading && !fields?.length) {
    return (
      <Box>
        <Box sx={{ display: "flex", marginBottom: 1 }}>
          <Typography variant="h3">{title}</Typography>
        </Box>
        <Typography sx={sx}>No fields observed.</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: "flex", marginBottom: 1 }}>
        <Typography variant="h3" marginRight={isEditable ? 1 : 0}>
          {title}
        </Typography>
        {isEditable && !isLoading && (
          <Button onClick={handleEditStart} variant="outlined" size="xsmall">
            {editButtonLabel || "Edit"}
          </Button>
        )}
      </Box>

      {isEditMode ? (
        <Box component="form" onSubmit={handleEditSave} sx={sx}>
          {table}

          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "flex-start",
            }}
          >
            <LoadingButton variant="contained" type="submit" loading={isEditSaving}>
              Save
            </LoadingButton>

            <Button
              variant="outlined"
              onClick={handleEditCancel}
              sx={{ marginLeft: 1, marginRight: 1 }}
            >
              Cancel
            </Button>

            {editError}
          </Box>
        </Box>
      ) : (
        <Box sx={sx}>{table}</Box>
      )}
    </Box>
  );
};
