import { Box, Typography } from "@mui/material";
import React from "react";
import { CodeBox } from "./CodeBox";

interface ApiKeyPairProps {
  apiKeyId: string | undefined;
  apiKeySecret: string | undefined;
  onCopyKeyId?: () => void;
  onCopyKeySecret?: () => void;
}

export const ApiKeyPair = ({
  apiKeyId,
  apiKeySecret,
  onCopyKeyId,
  onCopyKeySecret,
}: ApiKeyPairProps) => {
  if (!apiKeyId || !apiKeySecret) return null;

  return (
    <Box>
      <Typography fontWeight="bold">API Key ID</Typography>
      <CodeBox code={apiKeyId} label="API Key ID" sx={{ marginBottom: 2 }} onCopy={onCopyKeyId} />

      <Typography fontWeight="bold">API Key Secret</Typography>
      <CodeBox
        code={apiKeySecret}
        label="API Key Secret"
        sx={{ marginBottom: 2 }}
        onCopy={onCopyKeySecret}
      />
    </Box>
  );
};
