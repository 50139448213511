import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useMemo } from "react";
import { ReactivatedUsersTableRow } from "dashboard/pages/InternalUserReactivation/ReactivatedUsersTableRow";
import { ActivationURLResponse } from "types/akita_api_types";

interface ReactivatedUsersTableProps {
  responses: ActivationURLResponse[];
}

export const ReactivatedUsersTable = ({ responses }: ReactivatedUsersTableProps) => {
  const rows = useMemo(
    () => responses.map((r) => <ReactivatedUsersTableRow key={r.user_email} response={r} />),
    [responses]
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User Email</TableCell>
            <TableCell>Reactivation URL</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};
