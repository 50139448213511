import { datadogRum } from "@datadog/browser-rum";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { AkitaFetchError } from "./akita-fetch";

// Send handled fetch errors to Datadog.
const queryCache = new QueryCache({
  onError: (error, query) => {
    datadogRum.addError(error, {
      handledByReactQuery: true,
      queryOptions: query?.options,
      queryKey: query?.queryKey,
      responseStatus: (error as AkitaFetchError)?.response?.status,
      responseStatusText: (error as AkitaFetchError)?.response?.statusText,
    });
  },
});

export const queryClient = new QueryClient({ queryCache });
