import { TabContext } from "@mui/lab";
import React, { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { EndpointDetailsContent } from "dashboard/components/EndpointDetails/EndpointDetailsContent";
import {
  EndpointDetailsHeader,
  EndpointDetailsTabs,
} from "dashboard/components/EndpointDetails/EndpointDetailsHeader";
import { Page, PageContent, PageHeader, PageTitle } from "dashboard/components/Page";
import { useModelMetadata } from "data/queries/models";
import { useImpression } from "hooks/use-impression";

export const EndpointDetailsPage = () => {
  const history = useHistory();

  const {
    serviceID: projectID,
    specID: modelID,
    endpointID,
  } = useParams<{
    serviceID: string;
    specID: string;
    endpointID: string;
  }>();

  // TODO: See if the backend can include the deploymentID on the endpoint itself.
  // https://app.asana.com/0/1203253842162893/1203556409658107/f
  const { data: dataModelMetadata } = useModelMetadata(projectID, modelID);
  const deploymentTag = dataModelMetadata?.tags?.["x-akita-deployment"];
  const deploymentID = Array.isArray(deploymentTag) ? deploymentTag[0] : deploymentTag;

  const backHref = deploymentID
    ? `/service/${projectID}/deployment/${deploymentID}/monitoring`
    : undefined;

  const goBack = useCallback(() => {
    if (backHref) {
      history.push(backHref);
    }
  }, [history, backHref]);

  // TODO: Implement in routing, instead of state
  // https://app.asana.com/0/1203253842162893/1203556409658102/f
  const [activeTab, setActiveTab] = useState<EndpointDetailsTabs>("params");

  useImpression(`Endpoint Details Page - ${activeTab} tab`, { projectID, modelID, endpointID });

  return (
    <Page>
      <TabContext value={activeTab}>
        <PageHeader
          sx={{ paddingBottom: 0, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <PageTitle backHref={backHref} backLabel="Back to Metrics & Errors">
            Endpoint Details
          </PageTitle>

          <EndpointDetailsHeader
            modelID={modelID}
            projectID={projectID}
            endpointID={endpointID}
            setActiveTab={setActiveTab}
          />
        </PageHeader>

        <PageContent>
          <EndpointDetailsContent
            modelID={modelID}
            projectID={projectID}
            endpointID={endpointID}
            onEdit={goBack}
          />
        </PageContent>
      </TabContext>
    </Page>
  );
};
