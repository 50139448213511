import { Box, Theme, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

type DataAlertProps = {
  icon: NonNullable<ReactElement>;
  title: NonNullable<ReactNode>;
  children: ReactNode;
  severity?: "success" | "info" | "warning" | "error" | "none";
};

const getColorForSeverity = (
  theme: Theme,
  severity: "success" | "info" | "warning" | "error" | "none"
) => {
  switch (severity) {
    case "success":
      return theme.palette.success.main;
    case "info":
      return theme.palette.info.main;
    case "warning":
      return theme.palette.warning.main;
    case "error":
      return theme.palette.error.main;
    case "none":
    default:
      return undefined;
  }
};

export const DataAlert = ({ icon, title, children, severity = "none" }: DataAlertProps) => {
  const theme = useTheme();
  const severityColor = getColorForSeverity(theme, severity);

  return (
    <Box
      sx={{
        alignItems: "center",
        border: `1px solid ${severityColor ? alpha(severityColor, 0.5) : theme.palette.divider}`,
        color: severityColor,
        borderRadius: 1,
        display: "flex",
        flexDirection: "row-reverse",
        flexShrink: 0,
        height: 36,
        paddingLeft: 1,
        paddingRight: 1,
      }}
    >
      <Tooltip title={title}>{icon}</Tooltip>

      <Typography
        variant="body2"
        component="span"
        marginRight={1}
        color={theme.palette.text.primary}
      >
        {children}
      </Typography>
    </Box>
  );
};
