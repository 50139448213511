import { useMutation, useQueryClient } from "@tanstack/react-query";
import { akitaFetch, retry } from "data/akita-fetch";
import { SpecEdit } from "types/akita_api_types";

const postEditSpecs = ({
  projectID,
  modelID,
  edit,
}: {
  projectID: string;
  modelID: string;
  edit: SpecEdit;
}) =>
  akitaFetch<Record<string, never>>(`/services/${projectID}/edit-specs`, {
    method: "POST",
    body: edit,
    queryParams: {
      model_id: modelID,
    },
  });

export const usePostEditSpecs = (options?: { onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectID, modelID, edits }: { projectID: string; modelID: string; edits: SpecEdit[] }) => {
      // TODO: This endpoint should ideally accept an array for hiding and unhiding, so we don't have
      // to iterate and send multiple requests like this.
      const requests = edits.map((edit) => postEditSpecs({ projectID, modelID, edit }));

      return Promise.all(requests);
    },
    {
      onSuccess: async (_response, { projectID }) => {
        await queryClient.invalidateQueries(["models", projectID]);
        await queryClient.invalidateQueries(["endpoints-metadata", projectID]);
        await queryClient.invalidateQueries(["endpoint", projectID]);
        await queryClient.invalidateQueries(["timeline-summary", projectID]);

        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      retry,
    }
  );
};
