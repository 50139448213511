import React, { ReactNode } from "react";
import { Pill, PillProps } from "./Pill";
import { SkeletonText } from "./SkeletonText";

type DataPillProps = Omit<PillProps, "children"> & {
  isLoading?: boolean;
  isPlaceholder?: boolean;
  // Make the label required, and the children optional
  children?: ReactNode;
  label: ReactNode;
};

export const DataPill = ({ children, label, isLoading, isPlaceholder, ...rest }: DataPillProps) => {
  if (isLoading || isPlaceholder) {
    return (
      <Pill variant="outlined" label={label} {...rest}>
        <SkeletonText
          animation={isPlaceholder ? false : "pulse"}
          randomWidth
          randomWidthInPixels
          minWidth={20}
          maxWidth={80}
        />
      </Pill>
    );
  }

  return (
    <Pill variant="outlined" label={label} {...rest}>
      {children}
    </Pill>
  );
};
