import GetAppIcon from "@mui/icons-material/GetApp";
import { LoadingButton } from "@mui/lab";
import { Box, FormHelperText, SxProps, Theme } from "@mui/material";
import React, { useCallback, useState } from "react";
import { mergeSxProps } from "dashboard/utils/sx";
import { getSpecsYaml } from "data/queries/specs-yaml";
import { useLogInteraction } from "hooks/use-log-interaction";

type SpecDownloadButtonProps = {
  projectID?: string;
  modelID?: string;
  sx?: SxProps<Theme>;
  sxButton?: SxProps<Theme>;
};

export const SpecDownloadButton = ({
  projectID,
  modelID,
  sx,
  sxButton,
}: SpecDownloadButtonProps) => {
  const logInteraction = useLogInteraction({ projectID, modelID });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const downloadSpec = useCallback(async () => {
    try {
      logInteraction("Clicked 'Download OpenAPI3'");
      setIsLoading(true);
      setIsError(false);
      const spec = await getSpecsYaml(projectID!, modelID!);
      const url = window.URL.createObjectURL(new Blob([spec]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `${modelID}.yaml`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log({ error });
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [projectID, modelID, logInteraction]);

  if (!projectID && !modelID) return null;

  return (
    <Box
      sx={mergeSxProps({ display: "flex", flexDirection: "column", alignItems: "flex-end" }, sx)}
    >
      <LoadingButton
        disabled={!projectID || !modelID}
        onClick={downloadSpec}
        loading={isLoading}
        loadingPosition="start"
        startIcon={<GetAppIcon />}
        variant="outlined"
        color={isError ? "error" : "secondary"}
        sx={mergeSxProps({ flexShrink: 0, whiteSpace: "nowrap" }, sxButton)}
      >
        Download OpenAPI3
      </LoadingButton>

      {isError && (
        <FormHelperText error={isError}>
          {isError ? "Unable to retrieve your OpenAPI3 spec. Please try again." : " "}
        </FormHelperText>
      )}
    </Box>
  );
};
