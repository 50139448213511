import { Box } from "@mui/material";
import React, { useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { ChartTooltip, ChartTooltipProps } from "../ChartTooltip/ChartTooltip";
import { ChartTooltipProvider } from "../ChartTooltip/ChartTooltipProvider";
import { AkiTimelineChart, AkiTimelineChartProps } from "./AkiTimelineChart";

const DEFAULT_PADDING = { left: 72, bottom: 40, top: 24, right: 24 };

type AkiTimelineGroupProps = {
  containerHeight?: number;
  containerWidth?: number | "auto";
  containerPadding?: {
    left: number;
    bottom: number;
    top: number;
    right: number;
  };
  getTooltipContent?: ChartTooltipProps["getContent"];
  isDataStale?: boolean;
  tooltipEnabled?: boolean;
} & AkiTimelineChartProps;

export const AkiTimelineGroup = ({
  containerHeight = 200,
  containerPadding = DEFAULT_PADDING,
  containerWidth = "auto",
  getTooltipContent,
  isDataStale,
  tooltipEnabled = true,
  ...timelineChartProps
}: AkiTimelineGroupProps) => {
  const isStaticWidth = containerWidth !== "auto";
  const containerRef = useRef<HTMLElement>(null);

  return (
    <Box
      data-testid="timeline-group"
      sx={{
        position: "relative",
        transition: "opacity linear 200ms",
        opacity: isDataStale ? 0.5 : 1,
      }}
      ref={containerRef}
    >
      <AutoSizer disableHeight disableWidth={isStaticWidth}>
        {({ width }) => (
          <ChartTooltipProvider
            containerRef={containerRef}
            containerHeight={containerHeight}
            containerWidth={isStaticWidth ? containerWidth : width}
            containerPadding={containerPadding}
            isTooltipEnabled={tooltipEnabled}
          >
            <AkiTimelineChart {...timelineChartProps} />
            <ChartTooltip getContent={getTooltipContent} />
          </ChartTooltipProvider>
        )}
      </AutoSizer>
    </Box>
  );
};
