import { Box, BoxProps } from "@mui/material";
import React from "react";
import { EndpointsTableEndpoint } from "../EndpointsTable/formatting";
import { SkeletonText } from "../SkeletonText";
import { HTTPMethodEntity } from "./HTTPMethodEntity";
import { PathParts } from "./PathParts";
import { ResponseCodeEntity } from "./ResponseCodeEntity";
import { DecodedEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import { mergeSxProps } from "dashboard/utils/sx";
import {
  MethodMetadataResponse,
  MethodResponse,
  TimelineSummaryEndpoint,
} from "types/akita_api_types";
import { BasicEndpoint } from "types/basic-endpoint";

const stopEvent = (event: { stopPropagation: () => void; preventDefault: () => void }) => {
  event.stopPropagation();
  event.preventDefault();
};

type EndpointEntityProps = BoxProps & {
  endpoint?:
    | MethodMetadataResponse
    | MethodResponse
    | TimelineSummaryEndpoint
    | EndpointsTableEndpoint
    | DecodedEndpointUniqueID
    | BasicEndpoint;
  includeResponseCode?: boolean;
  isClickable?: boolean;
  isLoading?: boolean;
  isPlaceholder?: boolean;
  noMethod?: boolean;
  randomWidthPlaceholder?: boolean;
  highlightedParamIndices?: Set<number>;
};

export const EndpointEntity = ({
  endpoint,
  includeResponseCode,
  isClickable = false,
  isLoading,
  isPlaceholder,
  noMethod,
  randomWidthPlaceholder = true,
  highlightedParamIndices,
  sx,
  ...rest
}: EndpointEntityProps) => {
  if (!endpoint && (isLoading || isPlaceholder)) {
    return (
      <Box
        sx={mergeSxProps(
          {
            display: "flex",
            alignItems: "center",
            width: "100%",
          },
          sx
        )}
        {...rest}
      >
        {!noMethod && <HTTPMethodEntity isLoading={isLoading} isPlaceholder={isPlaceholder} />}
        <SkeletonText
          randomWidth={randomWidthPlaceholder}
          animation={isPlaceholder ? false : "pulse"}
        />
      </Box>
    );
  }

  if (!endpoint) {
    return null;
  }

  return (
    <Box
      sx={mergeSxProps(
        {
          display: "flex",
          alignItems: "center",
          minWidth: 0,
        },
        sx
      )}
      {...rest}
    >
      {!noMethod && <HTTPMethodEntity httpMethod={endpoint.operation} />}
      {includeResponseCode && ("responseCode" in endpoint || "response_code" in endpoint) && (
        <ResponseCodeEntity
          sx={{ marginRight: 1 }}
          code={(endpoint as any).responseCode ?? (endpoint as any).response_code}
        />
      )}
      <Box
        sx={{
          padding: 0,
          margin: 0,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontSize: (theme) => theme.typography.body1.fontSize,
          // Ensure this can always be selected and copied
          ...(isClickable ? {} : { cursor: "initial", userSelect: "text" }),
        }}
        onClick={isClickable ? undefined : stopEvent}
        // The HTML `title` attribute lets us use some built-in browser behavior to show the unabridged
        // endpoint name.
        title={`${endpoint.host}${endpoint.path}`}
      >
        {highlightedParamIndices ? (
          <PathParts
            host={endpoint.host}
            path={endpoint.path}
            highlightedParameterIndices={highlightedParamIndices}
          />
        ) : (
          `${endpoint.host}${endpoint.path}`
        )}
      </Box>
    </Box>
  );
};
