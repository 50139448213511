import { Collapse, Stack, StackProps } from "@mui/material";
import React from "react";
import { ProjectIssue, ProjectIssueAlert } from "./ProjectIssueAlert";

type ProjectIssueAlertsListProps = Omit<StackProps, "children"> & {
  issues: ProjectIssue[];
  isCollapsed?: boolean;
};

export const ProjectIssueAlertsList = ({
  issues,
  isCollapsed = false,
  ...rest
}: ProjectIssueAlertsListProps) => (
  <Collapse in={!isCollapsed && issues.length > 0}>
    <Stack spacing={1} {...rest}>
      {issues.map((issue, index) => (
        <ProjectIssueAlert key={`${issue.title}-${index}`} issue={issue} />
      ))}
    </Stack>
  </Collapse>
);
