import { TableBody, TableBodyProps } from "@mui/material";
import { isEmpty } from "lodash";
import React, { ReactNode } from "react";
import { AkiTableEmptyState } from "./AkiTableEmptyState";
import { mergeSxProps } from "dashboard/utils/sx";

type AkiTableBodyProps = TableBodyProps & {
  isRefreshing?: boolean;
  emptyState?: ReactNode;
};

/**
 * TableBody that takes an additional prop `isRefreshing`, to indicate if the Table's data is stale
 * and currently being refreshed.
 */
export const AkiTableBody = ({
  isRefreshing = false,
  children,
  emptyState,
  ...rest
}: AkiTableBodyProps) => (
  <TableBody
    sx={mergeSxProps({
      transition: "opacity linear 200ms",
      // Fade the TableBody, to indicate that data is stale and being refreshed.
      opacity: isRefreshing ? 0.5 : 1,
    })}
    {...rest}
  >
    {!isRefreshing && isEmpty(children)
      ? emptyState || <AkiTableEmptyState text="No results" />
      : children}
  </TableBody>
);
