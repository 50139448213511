import { useQuery } from "@tanstack/react-query";
import { SlackInstallationDetailsResponse } from "../../types/akita_api_types";
import { akitaFetch } from "../akita-fetch";

export const postSlackInstallation = (code: string) =>
  akitaFetch<SlackInstallationDetailsResponse>("/v1/slack/installations", {
    method: "POST",
    body: { code },
  });

const getSlackInstallation = () =>
  akitaFetch<SlackInstallationDetailsResponse>("/v1/slack/installations");

export const useSlackInstallation = () =>
  useQuery(["slack-installation-query"], getSlackInstallation);
