import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { EndpointRemapRuleFilter } from "./LabelsTable";
import { ErrorWidget } from "dashboard/components/ErrorWidget";
import { CustomTable } from "dashboard/components/Table";
import { saveEndpointRemapRules } from "dashboard/utils/backend";
import { AkitaError, AkitaErrorCode } from "dashboard/utils/error";
import { useGetFetch } from "hooks/use-get-fetch";
import { EndpointRemapRule, EndpointRemapRulesResponse } from "types/akita_api_types";

const getFullRuleTableRow = (filter: EndpointRemapRule) => [
  <Typography key={1} variant="body2">
    {filter.condition.host_name_matches}
  </Typography>,
  <Typography key={2}>{filter.condition.http_path_starts_with}</Typography>,
  <Typography key={3}>{filter.condition.http_path_ends_with}</Typography>,
  <Typography key={4}>{filter.condition.request_has_port}</Typography>,
  <Typography key={5}>{filter.condition.source_ip_address}</Typography>,
];

const getUserRuleTableRow = (
  filter: EndpointRemapRule,
  removeItem: (index: number) => void,
  updateItem: (filter: EndpointRemapRule, index: number) => void,
  index: number
) => [
  <EndpointRemapRuleFilter
    key={`${filter.id}-0`}
    index={index}
    updateItem={updateItem}
    filter={filter}
    direction={"unused" as any}
  />,
  <Tooltip title="Delete" aria-label="delete" key={`${filter.id}-1`}>
    <IconButton onClick={() => removeItem(index)} size="small">
      <ClearIcon />
    </IconButton>
  </Tooltip>,
];

const useStyles = makeStyles((theme) => ({
  container: { padding: theme.spacing(2) },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const FiltersTable = (props: { serviceID?: string }) => {
  const classes = useStyles();

  const { oktaAuth, authState } = useOktaAuth();

  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<AkitaError | null>(null);

  const [filterUserRules, setFilterUserRules] = useState<EndpointRemapRule[]>([]);
  const [filterFullRules, setFilterFullRules] = useState<EndpointRemapRule[]>([]);

  const specsURL = `${process.env.REACT_APP_API_URL}/v1/services/${props.serviceID}/endpoint_remap_rules`;
  const [filtersResponse, isFiltersLoading, listFiltersError, refreshFilters] =
    useGetFetch<EndpointRemapRulesResponse>(
      specsURL,
      undefined,
      !props.serviceID,
      "fetching filters for your service"
    );

  useEffect(() => {
    if (isFiltersLoading) {
      return;
    }
    if (filtersResponse?.user_rules) {
      setFilterUserRules(filtersResponse?.user_rules.filter((r) => !!r.action.omit_request));
    }
    if (filtersResponse?.full_rules) {
      setFilterFullRules(filtersResponse?.full_rules.filter((r) => !!r.action.omit_request));
    }
  }, [
    filtersResponse?.full_rules?.length,
    filtersResponse?.user_rules?.length,
    isFiltersLoading,
    props.serviceID,
  ]);

  const filterTableHead = ["Request Identification Filters"];

  const removeFilter = (index: number) => {
    filterUserRules.splice(index, 1);
    setFilterUserRules([...filterUserRules]);
  };
  const updateFilter = (label: EndpointRemapRule, index: number) => {
    const newRules = [...filterUserRules];
    newRules[index] = label;
    setFilterUserRules(newRules);
  };

  const addLabel = () => {
    const emptyRule = {
      id: null,
      action: {
        change_host_name: null,
        label_source_service: null,
        omit_request: "True",
      },
      condition: {
        host_name_ends_with: null,
        host_name_matches: null,
        http_path_ends_with: null,
        http_path_starts_with: null,
        request_has_port: null,
        source_ip_address: null,
      },
    };
    setFilterUserRules([...filterUserRules, emptyRule]);
  };

  const save = async () => {
    setIsSaving(true);
    setSaveError(null);
    try {
      if (props.serviceID && authState) {
        const userRules = filtersResponse?.user_rules
          .filter((r) => !r.action.omit_request)
          .concat(filterUserRules);

        const formattedData = {
          user_rules: userRules || [],
          full_rules: filtersResponse?.full_rules || [],
        };
        await saveEndpointRemapRules(oktaAuth, authState, props.serviceID, formattedData);
      }
    } catch (error) {
      const realError = await error;
      setSaveError({
        customerMessage: `Error saving labels: ${(realError as any).error.message}`,
        error: error as any,
        status: 500,
        code: AkitaErrorCode.PostErr,
      });
    }
    setIsSaving(false);
    return refreshFilters();
  };

  const filterTableData = filterFullRules
    .map((r) => getFullRuleTableRow(r))
    .concat(
      filterUserRules.map((r, index) => getUserRuleTableRow(r, removeFilter, updateFilter, index))
    );

  return (
    <div>
      {isFiltersLoading && <LinearProgress />}
      <div className={classes.container}>
        <Typography variant="h2" gutterBottom>
          Request Filters
        </Typography>
        <Typography variant="body2" gutterBottom>
          Prevent requests from being seen by Akita and included in your model.
        </Typography>
        <div className={classes.section}>
          {filterTableData?.length > 0 && (
            <CustomTable tableHead={filterTableHead} tableData={filterTableData} />
          )}
          <Button
            startIcon={<AddIcon />}
            color="primary"
            variant="contained"
            onClick={() => addLabel()}
          >
            Add Filter
          </Button>
        </div>
        <Divider />
        <div className={classes.section}>
          <ErrorWidget
            errorSources={[listFiltersError, [saveError, () => setSaveError(null)]]}
            errorContext="filters"
          />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Button variant="contained" onClick={() => refreshFilters()} size="large">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => save()}
                size="large"
                disabled={isSaving}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
