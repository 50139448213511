import { Alert } from "@mui/material";
import { isEmpty } from "lodash";
import React, { ReactElement, useMemo } from "react";
import { AkitaError } from "dashboard/utils/error";
import { useErrorLogger } from "hooks/use-error-logger";

interface ErrorNotificationProps {
  akitaError: AkitaError;
  clear: () => void;
  errorContext?: string;
}

// Wrapper to allow us to useEffect for each of the possible multiple errors
// shown in the dialog.
const ErrorWidgetNotification = ({ akitaError, clear, errorContext }: ErrorNotificationProps) => {
  // Log the details of the error to the console.
  console.log(akitaError);

  const { requestID = "", status = "", statusText = "" } = akitaError;

  let info = "";
  if (requestID && status && statusText) {
    info = `(${status}: ${statusText}, request ID: ${requestID})`;
  } else if (requestID && status) {
    info = `(Request ID: ${requestID}, response status: ${status})`;
  } else if (status) {
    info = `(Response status: ${status})`;
  }

  const errorEvent = errorContext ? `Error during ${errorContext}` : "Error without context";

  const errorProps = useMemo(
    () => ({
      requestID,
      status,
      statusText,
      customerMessage: akitaError.customerMessage,
      errorMessage: akitaError.error?.toString(),
    }),
    [requestID, status, statusText, akitaError.customerMessage, akitaError.error]
  );

  useErrorLogger(errorEvent, errorProps);

  return (
    <Alert severity="error" onClose={clear} sx={{ marginBottom: 1 }}>
      <div>
        {akitaError.customerMessage}
        {akitaError.requestID && <div>{info}</div>}
      </div>
    </Alert>
  );
};

interface ErrorWidgetProps {
  errorSources: [AkitaError | null, () => void][];
  ignore404s?: boolean;

  // Specify the event type to use when reporting the error.
  // Most error messages are "We had trouble (something). Please try again soon!"
  // from useGetFetch.  If this is specified we will use the
  // "Error on {errorContext}" event, otherwise we will use the
  // "Error without context" event.
  errorContext?: string;
}

export const ErrorWidget = ({ errorSources, ignore404s, errorContext }: ErrorWidgetProps) => {
  const notifications = errorSources.reduce(
    (result: ReactElement[], [akitaError, clear], index) => {
      if (akitaError === null || (ignore404s && akitaError.status === 404)) return result;

      return [
        ...result,
        <ErrorWidgetNotification
          key={`${index}-${akitaError.customerMessage}`}
          akitaError={akitaError}
          clear={clear}
          errorContext={errorContext}
        />,
      ];
    },
    []
  );

  return !isEmpty(notifications) ? <>{notifications}</> : null;
};
