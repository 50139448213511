import { AuthState, OktaAuth } from "@okta/okta-auth-js";
import { noop } from "lodash";
import { createContext } from "react";
import { oktaAuth } from "auth/config";
import { AkitaUserInfo } from "types/akita_api_types";
import { UserInfo } from "types/okta_types";

// @pr TODO: Prune/merge some of these values to reduce complexity.
interface AuthContextValue {
  akitaUserInfo: AkitaUserInfo | undefined;
  authService: OktaAuth;
  authState: AuthState | undefined;
  isValidUser: boolean;
  setAkitaUserInfo: (akitaUserInfo: AkitaUserInfo) => void;
  userInfo: UserInfo | undefined;
}

export const AuthContext = createContext<AuthContextValue>({
  akitaUserInfo: undefined,
  authService: oktaAuth,
  authState: undefined,
  isValidUser: false,
  setAkitaUserInfo: noop,
  userInfo: undefined,
});
