import { Alert, Link, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { CodeBox } from "dashboard/components/CodeBox";
import { OnboardingStepContext } from "dashboard/components/OnboardingStep/context";

interface CommandStartClientProps {
  apiKeyId: string | undefined;
  apiKeySecret: string | undefined;
  projectName: string | undefined;
}

export const CommandStartClient = ({
  apiKeyId,
  apiKeySecret,
  projectName,
}: CommandStartClientProps) => {
  const dockerCommand = `docker run --rm --network host \\
  -e AKITA_API_KEY_ID=${apiKeyId} \\
  -e AKITA_API_KEY_SECRET=${apiKeySecret} \\
  akitasoftware/cli:latest apidump \\
  --project ${projectName}`;

  const { trackOnboardingEvent } = useContext(OnboardingStepContext);

  const onClickDocs = useCallback(() => {
    trackOnboardingEvent("Clicked on non-Docker docs link");
  }, [trackOnboardingEvent]);

  const onCopy = useCallback(() => {
    trackOnboardingEvent("Client Start Command Copied");
  }, [trackOnboardingEvent]);

  return (
    <>
      <Alert severity="info" sx={{ marginBottom: 2 }}>
        <strong>These are Docker instructions.</strong>
        <br /> Using the Akita Chrome extension or Docker Desktop extension? Return there to finish
        getting set up!
        <br /> Looking to get set up somewhere else? Check out our{" "}
        <Link
          target="_blank"
          href="https://docs.akita.software/docs/part-1-create-a-project"
          onClick={onClickDocs}
        >
          docs
        </Link>
        .
      </Alert>
      <Typography gutterBottom>Run the following command to start capturing data:</Typography>
      <CodeBox
        code={dockerCommand}
        label="command to run the Akita client on your Docker host network"
        onCopy={onCopy}
      />
    </>
  );
};
