import { Stack } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { MonitorsPageContent } from "./MonitorsPageContent";
import { SlackIntegration } from "./SlackIntegration";
import { CreateMonitorButton } from "dashboard/components/CreateMonitorButton";
import { Page, PageHeader, PageTitle } from "dashboard/components/Page";
import { MuteAllButton } from "dashboard/pages/Monitors/components/MuteAllButton";
import { useMonitors, useUpdateMonitors } from "data/queries/monitors";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useImpression } from "hooks/use-impression";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";

export const MonitorsPage = () => {
  useImpression("Monitors - All Monitors");
  const isEnabledManualAlertCreation = useIsFlagEnabled(FeatureFlag.ManualAlertCreationEnabled);

  const { serviceID: projectID, deploymentID } = useParams<{
    serviceID: string;
    deploymentID: string;
  }>();

  const { data: dataAllMonitors } = useMonitors({
    projectID,
    deploymentID,
    queryParams: { state: "ACTIVE" },
  });

  const updateMonitors = useUpdateMonitors(projectID, deploymentID);

  return (
    <Page>
      <PageHeader
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Stack direction="row" spacing={2}>
          <PageTitle>Monitors</PageTitle>
          {isEnabledManualAlertCreation && <CreateMonitorButton size="small" />}
        </Stack>

        <Stack direction="row" spacing={1} paddingTop={{ xs: 1, sm: 0 }}>
          <SlackIntegration />

          <MuteAllButton
            monitors={dataAllMonitors?.monitors}
            updateMonitors={updateMonitors.mutate}
          />
        </Stack>
      </PageHeader>

      <MonitorsPageContent projectID={projectID} deploymentID={deploymentID} />
    </Page>
  );
};
