import { Alert, Link, SxProps, Theme } from "@mui/material";
import React from "react";
import { AkitaFetchError } from "data/akita-fetch";
import { useErrorLogger } from "hooks/use-error-logger";

interface QueryErrorProps {
  label:
    | `We had trouble loading your ${string}.`
    | `We had trouble updating your ${string}.`
    | `We had trouble removing your ${string}.`;
  error?: unknown;
  refetch?: () => Promise<any>;
  sx?: SxProps<Theme>;
}

/**
 * QueryError renders an alert with the given label, when `error` is present, alongside a link to
 * refetch data if a refetch function is given. This component also logs an event to Segment to
 * indicate that an error was shown to the user.
 */
export const QueryError = ({ label, error, refetch, sx }: QueryErrorProps) => {
  useErrorLogger(error ? (error as AkitaFetchError)?.message ?? "Unknown error" : undefined, {
    queryErrorLabel: label,
    status: (error as AkitaFetchError)?.response?.status,
    statusText: (error as AkitaFetchError)?.response?.statusText,
  });

  if (!error) return null;

  return (
    <Alert severity="error" sx={{ marginTop: 1, marginBottom: 1, ...sx }}>
      {label}{" "}
      {refetch ? (
        <Link component="button" onClick={refetch}>
          Try again?
        </Link>
      ) : (
        "Please try again."
      )}
    </Alert>
  );
};
