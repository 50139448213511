import { format, formatDistance, isBefore } from "date-fns";
import {
  Monitor,
  MonitorComparator,
  MonitorSignalKind,
  MonitorUpdate,
  RecentlyAlertedMonitor,
  TimelineAggregation,
} from "types/akita_api_types";
import { BasicEndpoint } from "types/basic-endpoint";

const SIGNAL_KINDS_TO_AGGREGATIONS: Record<MonitorSignalKind, TimelineAggregation> = {
  RATE_4XX: "fraction_4xx",
  RATE_5XX: "fraction_5xx",
  LATENCY_MAX: "max",
  LATENCY_MIN: "min",
  LATENCY_MEAN: "mean",
  LATENCY_MEDIAN: "median",
  LATENCY_90P: "90p",
  LATENCY_95P: "95p",
  LATENCY_99P: "99p",
  COUNT_4XX: "count_4xx",
  COUNT_5XX: "count_5xx",
  COUNT: "count",
};

export const monitorSignalToAggregation = (signal: MonitorSignalKind) =>
  SIGNAL_KINDS_TO_AGGREGATIONS[signal] ?? "count";

const SIGNAL_KINDS_TO_LABELS: Record<MonitorSignalKind, string> = {
  COUNT: "request count",
  COUNT_4XX: "error count (4xx)",
  COUNT_5XX: "error count (5xx)",

  RATE_4XX: "error rate (4xx)",
  RATE_5XX: "error rate (5xx)",

  LATENCY_MAX: "latency (max)",
  LATENCY_MIN: "latency (min)",
  LATENCY_MEAN: "latency (mean)",
  LATENCY_MEDIAN: "latency (median)",
  LATENCY_90P: "latency (p90)",
  LATENCY_95P: "latency (p95)",
  LATENCY_99P: "latency (p99)",
};

export const getMonitorSignalLabel = (signal: MonitorSignalKind) =>
  SIGNAL_KINDS_TO_LABELS[signal] ?? "";

const SIGNAL_KINDS_TO_UNITS: Record<MonitorSignalKind, string> = {
  COUNT: "",
  COUNT_4XX: "",
  COUNT_5XX: "",

  RATE_4XX: "%",
  RATE_5XX: "%",

  LATENCY_MAX: "ms",
  LATENCY_MIN: "ms",
  LATENCY_MEAN: "ms",
  LATENCY_MEDIAN: "ms",
  LATENCY_90P: "ms",
  LATENCY_95P: "ms",
  LATENCY_99P: "ms",
};

export const getMonitorSignalUnits = (signal: MonitorSignalKind) =>
  SIGNAL_KINDS_TO_UNITS[signal] ?? "";

const COMPARATORS_TO_SYMBOLS: Record<MonitorComparator, string> = {
  GT: ">",
  GTE: "≥",
  LT: "<",
  LTE: "≤",
  EQ: "=",
};

export const getMonitorComparatorSymbol = (comparator: MonitorComparator) =>
  COMPARATORS_TO_SYMBOLS[comparator] ?? "";

const COMPARATORS_TO_DESCRIPTIONS: Record<MonitorComparator, string> = {
  GT: "greater than",
  GTE: "greater than (or equal to)",
  LT: "less than",
  LTE: "less than (or equal to)",
  EQ: "equal to",
};

export const getMonitorComparatorDescription = (comparator: MonitorComparator) =>
  COMPARATORS_TO_DESCRIPTIONS[comparator] ?? "";

/**
 * Returns a number for by which to multiply threshold values of the given signal type for display.
 * Returns 100 when the signal type takes a percentage value, and 1 otherwise.
 */
export const getMonitorSignalThresholdScale = (signal: MonitorSignalKind) => {
  if (signal === MonitorSignalKind.RATE_4XX || signal === MonitorSignalKind.RATE_5XX) {
    return 100;
  }

  return 1;
};

/**
 * If the threshold is for a signal type that uses percentages, returns the threshold multiplied by
 * 100 (and stringified) so it can be displayed at the correct scale. Otherwise, returns the threshold
 * value unchanged and stringified.
 */
export const getMonitorThresholdStrForDisplay = (signal: MonitorSignalKind, threshold: number) =>
  (threshold * getMonitorSignalThresholdScale(signal)).toString();

/**
 * Parses the given string as a numeric Monitor threshold value. If the threshold is for a signal
 * type that uses percentages, the threshold will be divided by 100 so it will be saved at the correct
 * scale.
 */
export const parseMonitorThreshold = (signal: MonitorSignalKind, thresholdStr: string) => {
  const parsedThreshold = parseFloat(thresholdStr);

  if (signal === MonitorSignalKind.RATE_4XX || signal === MonitorSignalKind.RATE_5XX) {
    return parsedThreshold / 100;
  }

  return parsedThreshold;
};

export const getMonitorEndpoint = (monitor: Monitor | RecentlyAlertedMonitor): BasicEndpoint => ({
  operation: monitor.http_method,
  host: monitor.host,
  path: monitor.path_template,
});

/** Returns a string of when the monitor is muted until, or undefined if the monitor is not muted. */
export const getMonitorMutedUntil = (monitor?: Monitor) => {
  if (monitor?.muted_forever) {
    return "forever";
  }

  if (monitor?.muted_until) {
    const now = new Date();
    const until = new Date(monitor.muted_until);
    if (now < until) {
      return formatDistance(now, until, { addSuffix: false });
    }
  }

  return undefined;
};

/** Returns a MonitorUpdate object derived from the given Monitor. */
export const getMonitorUpdateFromMonitor = (monitor: Monitor): MonitorUpdate => ({
  monitor_id: monitor.monitor_id,
  signal: monitor.signal,
  comparator: monitor.comparator,
  threshold: monitor.threshold,
  min_event_count: monitor.min_event_count,
  hidden: monitor.hidden,
  muted_until: monitor.muted_until,
  muted_forever: monitor.muted_forever,
});

/** Checks if the given monitor has an alert that is not muted or hidden. */
export const monitorHasVisibleAlert = (monitor: Monitor) => {
  const isMutedTemporarily =
    monitor.muted_until &&
    isBefore(new Date(monitor.observed_at ?? 0), new Date(monitor.muted_until));

  const isMutedOrHidden = isMutedTemporarily || monitor.muted_forever || monitor.hidden;

  return monitor.in_alert_state && !isMutedOrHidden;
};

/**
 * Checks if the given monitor is in an alert state (for the Monitor type), or has an alert start
 * time with no end time (for the RecentlyAlertedMonitor type).
 */
export const getIsMonitorInAlertState = (monitor: Monitor | RecentlyAlertedMonitor) => {
  if ("in_alert_state" in monitor) {
    return monitor.in_alert_state;
  }

  return monitor.alert_start_time && !monitor.alert_end_time;
};

export const getRecentMonitorStartTimeLabel = (monitor: RecentlyAlertedMonitor) =>
  monitor.alert_start_time ? format(new Date(monitor.alert_start_time), "Pp") : "never";

export const getRecentMonitorEndTimeLabel = (monitor: RecentlyAlertedMonitor) => {
  if (!monitor.alert_start_time) return "never";
  if (!monitor.alert_end_time) return "active";
  return format(new Date(monitor.alert_end_time), "Pp");
};
