import { Box, BoxProps } from "@mui/material";
import React, { ReactNode } from "react";
import { mergeSxProps } from "dashboard/utils/sx";

type PageHeaderProps = BoxProps & {
  children: ReactNode;
};

export const PageHeader = ({ children, sx, ...rest }: PageHeaderProps) => (
  <Box component="header" sx={mergeSxProps({ padding: 2, paddingBottom: 0 }, sx)} {...rest}>
    {children}
  </Box>
);
