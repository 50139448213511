import { TableCell, TableCellProps } from "@mui/material";
import React from "react";
import { mergeSxProps } from "dashboard/utils/sx";

type AkiTableCellProps = TableCellProps & {
  grow?: boolean;
};

export const AkiTableCell = ({ sx, grow, ...rest }: AkiTableCellProps) => (
  <TableCell
    sx={mergeSxProps(
      {
        // For arcane reasons, combining "width: 100%"" with "maxWidth: 0" results in the cell
        // growing to fill available space, without causing the table to scroll horizontally.
        width: grow ? "100%" : undefined,
        maxWidth: grow ? 0 : undefined,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        paddingLeft: 1,
        paddingRight: 1,
        paddingTop: 1,
        paddingBottom: 1,
      },
      sx
    )}
    {...rest}
  />
);
