import { AkitaErrorCode, akitaErrorOfFailure } from "dashboard/utils/error";

export function sendInvite(
  accessToken: string,
  firstName: string,
  lastName: string,
  email: string
): Promise<void> {
  const postInviteURL = `${process.env.REACT_APP_API_URL}/v1/organization/invites`;
  const body = {
    first_name: firstName,
    last_name: lastName,
    invitee_email: email,
  };

  return fetch(postInviteURL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .catch((err) => {
      throw akitaErrorOfFailure(err, AkitaErrorCode.PostErr, `sending invite to ${email}`);
    });
}

export function resendInvite(accessToken: string, inviteID: string): Promise<void> {
  const postInviteURL = `${process.env.REACT_APP_API_URL}/v1/organization/invites/${inviteID}/resend`;

  return fetch(postInviteURL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .catch((err) => {
      throw akitaErrorOfFailure(err, AkitaErrorCode.PostErr, `resending invite ${inviteID}`);
    });
}
