import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { IconButton, Menu, Stack, Tooltip, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Duration, add, formatDuration } from "date-fns";
import React, { MouseEvent, useState } from "react";
import { getMonitorMutedUntil, getMonitorUpdateFromMonitor } from "dashboard/utils/monitors";
import { pluralize } from "dashboard/utils/strings";
import { useLogInteraction } from "hooks/use-log-interaction";
import { Monitor, MonitorUpdate } from "types/akita_api_types";

interface MonitorMuteButtonProps {
  monitor?: Monitor;
  updateMonitors: (updates: MonitorUpdate[]) => void;
}

export const MonitorMuteButton = ({ monitor, updateMonitors }: MonitorMuteButtonProps) => {
  const mutedUntil = getMonitorMutedUntil(monitor);

  // Interaction tracking.
  const logInteraction = useLogInteraction();

  // State and handling for the dropdown menu.
  const [muteAnchorEl, setMuteAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(muteAnchorEl);

  const handleMuteClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMuteAnchorEl(event.currentTarget);
  };

  // MUI's typing of the menu close handler is wrong, so we need to accept an event of type `any`.
  const handleMuteClose = (event?: any) => {
    event?.stopPropagation?.();
    setMuteAnchorEl(null);
  };

  // Functions for updating monitor state.
  const unmute = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    if (!monitor) return;

    const monitorUpdate = getMonitorUpdateFromMonitor(monitor);
    monitorUpdate.muted_until = undefined;
    monitorUpdate.muted_forever = false;
    updateMonitors([monitorUpdate]);

    logInteraction("Unmuted monitor");

    handleMuteClose();
  };

  const muteFor = (event: MouseEvent<HTMLLIElement>, duration: Duration | "forever") => {
    event.stopPropagation();
    if (!monitor) return;

    const monitorUpdate = getMonitorUpdateFromMonitor(monitor);
    if (duration === "forever") {
      monitorUpdate.muted_until = undefined;
      monitorUpdate.muted_forever = true;
      logInteraction("Muted monitor", { duration: "forever" });
    } else {
      monitorUpdate.muted_until = add(Date.now(), duration).toISOString();
      monitorUpdate.muted_forever = false;
      logInteraction("Muted monitor", { duration: formatDuration(duration) });
    }

    updateMonitors([monitorUpdate]);

    handleMuteClose();
  };

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Tooltip title="Mute/Unmute">
        <IconButton
          aria-haspopup="true"
          color={mutedUntil ? "secondary" : undefined}
          onClick={handleMuteClick}
          disabled={!monitor}
          size="small-outlined"
        >
          {mutedUntil ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </Tooltip>

      <Menu id="mute-menu" anchorEl={muteAnchorEl} open={isOpen} onClose={handleMuteClose}>
        {mutedUntil && (
          <MenuItem key="unmute" onClick={unmute}>
            Unmute
          </MenuItem>
        )}
        {[1, 3, 6, 12].map((hours) => (
          <MenuItem key={`${hours}-hours`} onClick={(event) => muteFor(event, { hours })}>
            Mute for {hours} {pluralize("hour", hours)}
          </MenuItem>
        ))}
        <MenuItem key="1-day" onClick={(event) => muteFor(event, { days: 1 })}>
          Mute for 1 day
        </MenuItem>
        <MenuItem key="forever" onClick={(event) => muteFor(event, "forever")}>
          Mute forever
        </MenuItem>
      </Menu>

      {mutedUntil && <Typography variant="subtitle2">{mutedUntil}</Typography>}
    </Stack>
  );
};
