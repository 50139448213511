import { useOktaAuth } from "@okta/okta-react";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { AkitaError, AkitaErrorCode, akitaError } from "dashboard/utils/error";

type AkitaErrorHelper = [AkitaError | null, () => void];

export const useGetFetch = <T>(
  url: string,
  params?: Record<string, unknown>,
  shouldSkip?: boolean,
  // specializes the error message on error to
  // `We had trouble fetching ${errorMsg}.`
  errorMsg = "data"
): [T | null, boolean, AkitaErrorHelper, () => void] => {
  const { authState } = useOktaAuth();
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<AkitaError | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const accessToken = authState?.accessToken?.accessToken;
  const isAuthenticated = !!authState?.isAuthenticated;

  const stringifiedParams = params ? queryString.stringify(params) : undefined;

  useEffect(() => {
    if (!isAuthenticated || !accessToken || shouldSkip) {
      return;
    }

    const controller = new AbortController();
    const fetchData = async () => {
      const formattedUrl = params ? `${url}?${stringifiedParams}` : url;
      const asyncFetch = async (signal: AbortSignal) =>
        fetch(formattedUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          signal,
        });

      try {
        setIsLoading(true);
        setError(null);
        const result = await asyncFetch(controller.signal);
        setResponse(await result.json());
        setIsLoading(false);
      } catch (err: any) {
        // Don't set isLoading to false if the user aborts the request (likely they placed another?)
        if (err?.name === "AbortError") {
          return;
        }

        setIsLoading(false);
        setResponse(null);
        setError(
          akitaError(
            AkitaErrorCode.FetchErr,
            err,
            `We had trouble ${errorMsg}. Please try again soon!`
          )
        );
      }
    };

    void fetchData();
    return () => controller.abort();
  }, [url, stringifiedParams, shouldSkip, refreshTrigger]);

  if (isAuthenticated && accessToken && !shouldSkip) {
    return [
      response,
      isLoading,
      [error || null, () => setError(null)],
      () => setRefreshTrigger(refreshTrigger + 1),
    ];
  } else {
    return [null, false, [null, () => null], () => null];
  }
};
