import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Skeleton } from "@mui/material";
import React from "react";
import { Pill } from "../Pill";
import {
  getIsMonitorInAlertState,
  getMonitorComparatorSymbol,
  getMonitorSignalLabel,
  getMonitorSignalUnits,
  getMonitorThresholdStrForDisplay,
} from "dashboard/utils/monitors";
import { Monitor, RecentlyAlertedMonitor } from "types/akita_api_types";

type MonitorStatusEntityProps =
  | {
      isLoading?: boolean;
      monitor: Monitor | RecentlyAlertedMonitor;
    }
  | {
      isLoading: true;
      monitor?: Monitor | RecentlyAlertedMonitor;
    };

export const MonitorStatusEntity = ({ monitor, isLoading }: MonitorStatusEntityProps) => {
  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          flexShrink: 0,
          height: 24,
          marginRight: 1,
          width: 58,
          borderRadius: (theme) => theme.spacing(0.5),
        }}
      />
    );
  }

  if (getIsMonitorInAlertState(monitor)) {
    return (
      <Pill
        sx={{ backgroundColor: (theme) => theme.palette.error.main, marginRight: 1 }}
        size="small"
      >
        <ErrorOutlineIcon fontSize="small" sx={{ marginRight: 0.5 }} />{" "}
        {getMonitorSignalLabel(monitor.signal)} {getMonitorComparatorSymbol(monitor.comparator)}{" "}
        {getMonitorThresholdStrForDisplay(monitor.signal, monitor.threshold)}
        {getMonitorSignalUnits(monitor.signal)}
      </Pill>
    );
  }

  return (
    <Pill
      sx={{ backgroundColor: (theme) => theme.palette.success.main, marginRight: 1 }}
      size="small"
    >
      <CheckIcon fontSize="small" sx={{ marginRight: 0.5 }} /> OK
    </Pill>
  );
};
