import { List, ListItem, Skeleton, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { AkiGridContainer, AkiGridItem } from "dashboard/components/AkiGrid";
import {
  getAlertDurationLabel,
  getAlertEndTimeLabel,
  getAlertStartTimeLabel,
} from "dashboard/utils/alert-history";
import { useAlertHistory } from "data/queries/alert-history";

type MonitorAlertHistoryProps = {
  projectID: string;
  deploymentID: string;
  monitorID: string;
};

export const MonitorAlertHistory = ({
  projectID,
  deploymentID,
  monitorID,
}: MonitorAlertHistoryProps) => {
  const { data, isLoading } = useAlertHistory({
    projectID,
    deploymentID,
    monitorID,
    queryParams: { limit: 20 },
  });

  const listItems = useMemo(
    () =>
      data?.alerts?.map((alertEventPair) => (
        <ListItem
          key={alertEventPair.start_event.event_time}
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            "&:last-of-type": {
              borderBottom: 0,
            },
          }}
        >
          <AkiGridContainer
            sx={{ width: "100%" }}
            minHeightRow={0}
            columns={{ xs: 1, sm: 3, md: 3 }}
            gap={1}
          >
            <AkiGridItem>
              <Stack>
                <Typography variant="h6">Start</Typography>
                <Typography component="div">{getAlertStartTimeLabel(alertEventPair)}</Typography>
              </Stack>
            </AkiGridItem>

            <AkiGridItem>
              <Stack>
                <Typography variant="h6">End</Typography>
                <Typography component="div">{getAlertEndTimeLabel(alertEventPair)}</Typography>
              </Stack>
            </AkiGridItem>

            <AkiGridItem>
              <Stack>
                <Typography variant="h6">Duration</Typography>
                <Typography component="div">{getAlertDurationLabel(alertEventPair)}</Typography>
              </Stack>
            </AkiGridItem>
          </AkiGridContainer>
        </ListItem>
      )) ?? [],
    [data]
  );

  if (isLoading) {
    // 62 is the height of a single list item.
    return <Skeleton variant="rectangular" height={62} />;
  }

  if (listItems.length > 0) {
    return <List disablePadding>{listItems}</List>;
  }

  return (
    <Typography component="div" sx={{ padding: 2 }}>
      This monitor has never triggered an alert.
    </Typography>
  );
};
