import { constructEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import {
  MethodMetadataResponse,
  SpecContentSummary,
  TimelineSummaryEndpoint,
  TimelineSummaryFilters,
} from "types/akita_api_types";

// Filter keys that we want to show the user (in the order we want them listed).
const ENABLED_FILTER_KEYS = [
  "endpoint_categories",
  "hosts",
  "response_codes",
  "authentications",
  "http_methods",
  "data_types",
  "data_kinds",
  "data_formats",
];

// Filter keys for which we want to default the corresponding EndpointsTableFilterOptionsList to open.
export const DEFAULT_OPEN_FILTER_KEYS = ["http_methods", "hosts"];

// Filter keys for which we want to truncate the default number of entries displayed when the corresponding
// filter list is expanded.
export const SEE_MORE_ENABLED_FILTER_KEYS = ["hosts"];

// Number of filter options after which we want to display a "see more" pseudo-option instead of more options.
export const SEE_MORE_FILTER_LIST_SIZE = 10;

const DISPLAY_KEYS: Record<string, string> = {
  endpoint_categories: "Endpoint Categories",
  hosts: "Hosts",
  response_codes: "Response Codes",
  authentications: "Authentications",
  http_methods: "HTTP Methods",
  data_types: "Data Types",
  data_kinds: "Data Kinds",
  data_formats: "Data Formats",
};

export type FilterOption = {
  optionKey: string;
  count: number;
};

export type FilterDefinition = {
  key: string;
  displayKey: string;
  options?: FilterOption[];
};

export const formatFilterDefinitions = (
  summary?: SpecContentSummary | TimelineSummaryFilters
): FilterDefinition[] => {
  if (!summary) return [];
  return Object.entries(summary)
    .map(([key, optionsObj]) => {
      const options: FilterOption[] | undefined = optionsObj
        ? Object.entries(optionsObj).map(([optionKey, count]) => ({
            optionKey,
            count,
          }))
        : undefined;

      const sortedOptions = options?.sort((a, b) => {
        // Put "none" (displayed as "Uncategorized") at the top of the list of categories.
        if (key === "endpoint_categories") {
          if (a.optionKey === "none") return -1;
          if (b.optionKey === "none") return 1;
        }

        // Bump IP addresses to the end of the list of hosts.
        if (key === "hosts") {
          const doesAStartWithDigit = /^\d/.test(a.optionKey);
          const doesBStartWithDigit = /^\d/.test(b.optionKey);

          if (doesAStartWithDigit && !doesBStartWithDigit) return 1;
          if (!doesAStartWithDigit && doesBStartWithDigit) return -1;
        }

        return a.optionKey > b.optionKey ? 1 : -1;
      });

      return {
        key,
        displayKey: DISPLAY_KEYS[key] || key,
        options: sortedOptions,
      };
    })
    .filter((options) => ENABLED_FILTER_KEYS.includes(options.key))
    .sort((a, b) => {
      // Sort the filter definitions based on their position in the ENABLED_FILTER_KEYS array.
      const posA = ENABLED_FILTER_KEYS.indexOf(a.key);
      const posB = ENABLED_FILTER_KEYS.indexOf(b.key);

      return posB > posA ? -1 : 1;
    });
};

export type EndpointsTableEndpoint = {
  type: "model" | "metrics";
  uniqueID: string;
  methodID?: string;
  host: string;
  operation: string;
  path: string;
  responseCode?: number;
  count?: number;
  p50?: number;
  p90?: number;
  p95?: number;
  p99?: number;
  "p99.9"?: number;
};

/**
 * Takes an array of endpoint metadata objects returned by either methods-metadata or timeline/summary,
 * and reshapes their values into EndpointsTableEndpoint objects. We can then use those objects in
 * the EndpointsTable.
 */
export const formatEndpointsTableEndpoints = (
  endpoints: MethodMetadataResponse[] | TimelineSummaryEndpoint[]
): EndpointsTableEndpoint[] =>
  endpoints.map((endpoint) => {
    if ("method_id" in endpoint) {
      return {
        type: "model",
        count: endpoint.performance?.num_measurements,
        host: endpoint.host,
        p50: endpoint.performance?.latency_ms_p50,
        p90: endpoint.performance?.latency_ms_p90,
        p95: endpoint.performance?.latency_ms_p95,
        p99: endpoint.performance?.latency_ms_p99,
        "p99.9": endpoint.performance?.["latency_ms_p99.9"],
        methodID: endpoint.method_id,
        operation: endpoint.operation,
        path: endpoint.path,
        uniqueID: constructEndpointUniqueID(endpoint),
      };
    }

    return {
      type: "metrics",
      count: endpoint.count,
      host: endpoint.host,
      p50: endpoint.latency_median,
      p90: endpoint.latency_90p,
      p95: endpoint.latency_95p,
      p99: endpoint.latency_99p,
      "p99.9": endpoint["latency_99.9p"],
      operation: endpoint.operation,
      path: endpoint.path,
      responseCode: endpoint.response_code,
      uniqueID: constructEndpointUniqueID(endpoint),
    };
  });
