import {
  Box,
  Button,
  Divider,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { ProposedMonitorTile } from "./ProposedMonitorTile";
import { AkiBanner } from "dashboard/components/AkiBanner";
import { AkiGridContainer } from "dashboard/components/AkiGrid";
import { useMonitors } from "data/queries/monitors";

type ProposedMonitorsProps = {
  projectID: string;
  deploymentID: string;
  sx?: SxProps<Theme>;
};

export const ProposedMonitors = ({ projectID, deploymentID, sx }: ProposedMonitorsProps) => {
  const theme = useTheme();
  const [shouldShowAll, setShouldShowAll] = useState(false);

  const minMonitorsToDisplay = useMediaQuery(theme.breakpoints.up("xl")) ? 3 : 2;
  const monitorDisplayLimit = shouldShowAll ? undefined : minMonitorsToDisplay;

  const { data: dataProposed } = useMonitors({
    projectID,
    deploymentID,
    queryParams: {
      state: "PROPOSED",
    },
  });

  const onClickShowAll = useCallback(() => setShouldShowAll(true), []);
  const onClickShowFewer = useCallback(() => setShouldShowAll(false), []);

  const monitors = dataProposed?.monitors;
  const canToggleShowAll = !!monitors && monitors.length > minMonitorsToDisplay;

  if (!monitors || monitors.length < 1) return null;

  return (
    <Box sx={sx}>
      <AkiBanner marginBottom={2}>
        <Typography variant="h4" fontWeight="bold" color="secondary">
          Suggested monitors
        </Typography>
        <Typography>
          After watching your API for a while, we’ve identified{" "}
          {monitors.length > 1 ? "some monitors" : "a monitor"} that we think you’d find useful.
        </Typography>
      </AkiBanner>

      <AkiGridContainer columns={{ md: 6, xl: 9 }}>
        {monitors.slice(0, monitorDisplayLimit).map((monitor) => (
          <ProposedMonitorTile
            key={monitor.monitor_id}
            monitor={monitor}
            projectID={projectID}
            deploymentID={deploymentID}
          />
        ))}
      </AkiGridContainer>

      {canToggleShowAll && (
        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: 2 }}>
          {!shouldShowAll && (
            <Button variant="contained" color="secondary" onClick={onClickShowAll}>
              Show {monitors.length - (monitorDisplayLimit ?? 0)} More Proposed{" "}
              {monitors.length > 1 ? "Monitors" : "Monitor"}
            </Button>
          )}
          {shouldShowAll && (
            <Button variant="outlined" color="secondary" onClick={onClickShowFewer}>
              Show Fewer Proposed Monitors
            </Button>
          )}
        </Box>
      )}

      <Divider sx={{ marginTop: 2 }} />
    </Box>
  );
};
