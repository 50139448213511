import { SxProps, Table, TableContainer, TableProps, Theme } from "@mui/material";
import React from "react";
import { mergeSxProps } from "dashboard/utils/sx";

type AkiTableProps = TableProps & {
  sxContainer?: SxProps<Theme>;
};

/**
 * Most Table Containers contain only the child `Table`, so this component wraps its children in
 * both a `TableContainer` and a `Table` component. Also applies a border and border radius.
 */
export const AkiTable = ({ sxContainer, size, ...rest }: AkiTableProps) => (
  <TableContainer
    sx={mergeSxProps(
      {
        borderRadius: (thene) => `${thene.shape.borderRadius}px`,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        "& .MuiTableRow-root": {
          height: size !== "small" ? "58px" : undefined,
        },
      },
      sxContainer
    )}
  >
    <Table size={size} {...rest} />
  </TableContainer>
);
