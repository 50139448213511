import { Duration, Interval, format, formatDuration, getTime, sub } from "date-fns";

export type TimeRangeRelative = { type: "relative"; duration: Duration };
export type TimeRangeAbsolute = { type: "absolute"; interval: { start: number; end: number } };
export type TimeRange = TimeRangeRelative | TimeRangeAbsolute;

const formatInterval = (
  interval: Interval,
  pattern: string,
  options?: Parameters<typeof format>[2]
): string => {
  const startLabel = format(interval.start, pattern, options);
  const endLabel = format(interval.end, pattern, options);

  return `${startLabel} to ${endLabel}`;
};

/**
 * Takes a Duration object and converts any unusually large values into more appropriate units of time.
 * For example, "168 hours" would become "7 days", and "5 minutes, 90 seconds" would become
 * "6 minutes, 30 seconds".
 *
 * This function intentionally does not deal with weeks (since that's not an especially useful unit)
 * or months/years (since they don't have a constant length, and we don't keep data that long anyway).
 *
 * This function is only needed for *displaying* Durations. date-fns correctly handles offsetting
 * dates by whatever units you throw at it; it just doesn't do this kind of unit normalization when
 * it's time for display.
 *
 * @param duration - A date-fns Duration object.
 * @returns A new Duration object, with the values reorganized in a more comprehensible way if needed.
 */
export const normalizeDuration = (duration: Duration) => {
  const d = { seconds: 0, minutes: 0, hours: 0, days: 0, ...duration };

  // Take any non-whole values from minutes, hours, and days and convert them to extra seconds.
  if (d.minutes % 1 !== 0) {
    const extraSeconds = Math.round((d.minutes % 1) * 60);
    d.seconds = d.seconds + extraSeconds;
    d.minutes = Math.floor(d.minutes);
  }

  if (d.hours % 1 !== 0) {
    const extraSeconds = Math.round((d.hours % 1) * 60 * 60);
    d.seconds = d.seconds + extraSeconds;
    d.hours = Math.floor(d.hours);
  }

  if (d.days % 1 !== 0) {
    const extraSeconds = Math.round((d.days % 1) * 24 * 60 * 60);
    d.seconds = d.seconds + extraSeconds;
    d.days = Math.floor(d.days);
  }

  // Shift any extra seconds, minutes, or hours values upwards.
  if (d.seconds > 59) {
    const extraMinutes = Math.floor(d.seconds / 60);
    d.seconds = d.seconds - extraMinutes * 60;
    d.minutes = d.minutes + extraMinutes;
  }

  if (d.minutes > 59) {
    const extraHours = Math.floor(d.minutes / 60);
    d.minutes = d.minutes - extraHours * 60;
    d.hours = d.hours + extraHours;
  }

  if (d.hours > 23) {
    const extraDays = Math.floor(d.hours / 24);
    d.hours = d.hours - extraDays * 24;
    d.days = d.days + extraDays;
  }

  return d;
};

/**
 * For a relative time range, returns a relative time descriptor (e.g. "6 hours ago" or "2 days, 1 hour ago").
 * For an absolute time range, returns an absolute time descriptor
 * (e.g. "11/18/2022, 2:00 PM to 11/18/2022, 4:20 PM")
 *
 * @param range - An absolute or relative time range.
 * @returns A string describing the time range.
 */
export const getTimeRangeLabel = (
  range: TimeRange,
  { shouldCapitalize = true }: { shouldCapitalize?: boolean } = {}
) => {
  try {
    if (range.type === "relative") {
      return `${shouldCapitalize ? "L" : "l"}ast ${formatDuration(
        normalizeDuration(range.duration),
        {
          delimiter: ", ",
        }
      )}`;
    }

    return formatInterval(range.interval, "Pp");
  } catch (error) {
    console.log("Invalid date", error);
    return "";
  }
};

/**
 * Takes a time range object and returns an absolute time range object, converting if necessary.
 * If the time range object is already absolute, then the object is returned unchanged.
 * If the time range object is relative, then a new absolute time range object is returned, where the
 * end time is equal to the provided time, and the start time is equal to the end time minus the
 * relative duration.
 *
 * @param range - A time range object.
 * @param end - A Date object or number representing the end time.
 * @returns An absolute time range object.
 */
export const getAbsoluteTimeRange = (range: TimeRange, end: Date | number): TimeRangeAbsolute => {
  // If this is already an absolute time range, return it unchanged.
  if (range.type === "absolute") return range;

  const start = sub(end, range.duration).getTime();

  return { type: "absolute", interval: { start, end: getTime(end) } };
};

/**
 * Takes a TimeRange object and converts it into an object that can be spread into the query params
 * we send to the backend when we get endpoints.
 * @param range - A time range object.
 * @returns An object of the shape { time_range: [number, number] } or { duration: number in hours }
 */
export const getTimeRangeQueryValue = (range: TimeRange) =>
  range.type === "absolute"
    ? { time_range: [range.interval.start * 1000, range.interval.end * 1000] }
    : { duration: range?.duration?.hours ?? 12 };
