import { useTheme } from "@mui/material";
import React, { useContext } from "react";
import { LineSegment } from "victory";
import { ChartTooltipContext } from "contexts/chart-tooltip-context";
import { DisplayTimelineValue } from "dashboard/utils/timelines";

type ChartAnnotationMarkerProps = {
  active?: boolean;
  datum?: DisplayTimelineValue;
  x?: number;
  y?: number;
};

export const ChartAnnotationMarker = ({ active, x, y, datum }: ChartAnnotationMarkerProps) => {
  const theme = useTheme();
  const { containerPadding, containerHeight } = useContext(ChartTooltipContext);

  if (typeof x !== "number" || typeof y !== "number") return null;

  return (
    <>
      <LineSegment
        datum={datum}
        x1={x}
        x2={x}
        y1={containerPadding.top}
        y2={containerHeight - containerPadding.bottom}
        style={{
          stroke: datum?.color ?? theme.palette.foreground,
          strokeWidth: active ? 8 : 2,
        }}
      />
    </>
  );
};
