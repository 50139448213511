import React, { useMemo } from "react";
import { AkiNumberTile, AkiNumberTileProps } from "../AkiTile/AkiNumberTile";
import { useEndpointsMetadata } from "data/queries/endpoints-metadata";

type ModelSummaryTileRequestsSeenProps = Omit<
  AkiNumberTileProps,
  "labelSingular" | "labelPlural"
> & {
  projectID?: string;
  modelID?: string;
};

export const ModelSummaryTileRequestsSeen = ({
  projectID,
  modelID,
  ...rest
}: ModelSummaryTileRequestsSeenProps) => {
  // TODO: Figure out a better/simpler way to get this information from the backend
  const { data: endpointsMetadata, isLoading } = useEndpointsMetadata(
    projectID,
    modelID,
    {
      key: ["perf"],
    },
    { refetchOnWindowFocus: false }
  );

  const sum = useMemo(
    () =>
      endpointsMetadata?.metadata?.reduce(
        (result, endpoint) => result + (endpoint?.performance?.num_measurements ?? 0),
        0
      ),
    [endpointsMetadata?.metadata]
  );

  return (
    <AkiNumberTile
      {...rest}
      count={sum}
      isLoading={isLoading}
      labelSingular="request observed"
      labelPlural="requests observed"
    />
  );
};
