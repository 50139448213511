import { Alert, AlertProps, Divider, Link, Stack } from "@mui/material";
import React from "react";
import { mergeSxProps } from "dashboard/utils/sx";

export type ProjectIssueSeverity = "info" | "warning" | "error";

export type ProjectIssue = {
  appLink?: `/${string}`;
  appLinkText?: string;
  description: string;
  docsLink?: string;
  docsLinkText?: string;
  severity: ProjectIssueSeverity;
  title: string;
};

type ProjectIssueAlertProps = Omit<AlertProps, "children" | "severity"> & {
  issue: ProjectIssue;
};

export const ProjectIssueAlert = ({ issue, sx, ...rest }: ProjectIssueAlertProps) => (
  <Alert
    severity={issue.severity}
    variant="outlined"
    sx={mergeSxProps(
      {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 1.5,
        paddingRight: 1.5,

        "& .MuiAlert-action": {
          padding: 0,
          marginRight: 0,
        },
      },
      sx
    )}
    action={<ProjectIssueAlertActions issue={issue} />}
    {...rest}
  >
    {issue.description}
  </Alert>
);

const ProjectIssueAlertActions = ({ issue }: { issue: ProjectIssue }) => {
  const { docsLink, docsLinkText, appLink, appLinkText } = issue;

  const actionDocs = docsLink && docsLinkText && (
    <Link href={docsLink} color="inherit">
      {docsLinkText}
    </Link>
  );

  const actionApp = appLink && appLinkText && (
    <Link href={appLink} color="inherit">
      {appLinkText}
    </Link>
  );

  if (!actionDocs && !actionApp) return null;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      height="100%"
      divider={<Divider orientation="vertical" sx={{ height: "70%" }} />}
    >
      {actionDocs}
      {actionApp}
    </Stack>
  );
};
