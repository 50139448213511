import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { acceptInvite } from "./backend";
import { OrganizationInvite } from "types/akita_api_types";

type AcceptInviteButtonProps = {
  accessToken: string;
  invite: OrganizationInvite;
  setAlert: (alert: JSX.Element) => void;
  onClick?: () => void;
};

export const AcceptInviteButton = (props: AcceptInviteButtonProps) => {
  const onClick = () => {
    props.onClick && props.onClick();
    acceptInvite(props.accessToken, props.invite.id).then(
      () => {
        // Reload the window at the landing page.
        (window.location as any) = "/";
      },
      (akErrPromise) => {
        akErrPromise.then(() => {
          handleFailure();
        });
      }
    );
  };

  const handleFailure = () => {
    props.setAlert(
      <Dialog open>
        <DialogTitle>
          <Typography variant="h2">Couldn't Accept Invite</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Sorry! We had trouble accepting your invitation from {props.invite.inviter_name}. Please
            try again soon.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button onClick={() => props.setAlert(<div />)}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button onClick={() => props.setAlert(<div />)} variant="contained" color="primary">
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Button color="primary" variant="contained" onClick={onClick}>
      Accept
    </Button>
  );
};
