import { ReactNode } from "react";
import { createPortal } from "react-dom";

type ChartTooltipPortalProps = {
  children?: ReactNode;
};

/**
 * MUI's Popover component (which renders into a top-level div via a portal) has some not-great
 * performance characteristics and limited styling options for our Chart Tooltip use case, so
 * we can use our own Portal to render the tooltip. The element this is targetting is added in
 * public/index.html
 */
export const ChartTooltipPortal = ({ children }: ChartTooltipPortalProps) => {
  const containerNode = document.getElementById("chart-tooltip-container");

  if (!containerNode) return null;

  return createPortal(children, containerNode);
};
