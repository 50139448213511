import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import queryString from "query-string";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Page } from "dashboard/components/Page";
import { parseMailbox } from "dashboard/utils/parsers";
import { postPublicReactivateUsers } from "data/queries/reactivate-users";

const maxLength = 250;

const isValidLength = (v: null | undefined | string): boolean =>
  !!v && 0 < v.length && v.length <= maxLength;

export const PublicResendUserActivationPage = () => {
  const location = useLocation();
  const { userEmail } = queryString.parse(location.search);

  const [email, setEmail] = useState<string>(typeof userEmail === "string" ? userEmail : "");
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [isErrorEmail, setIsErrorEmail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onEmailAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsErrorEmail(!(parseMailbox(event.target.value) && isValidLength(event.target.value)));
  };

  const onSent = () => {
    setIsLoading(false);
    setEmailSent(true);
  };

  const handleFailure = () => {
    setIsLoading(false);
    setErrorMessage("Could not resend activation email. Please check the address and try again.");
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    const address = parseMailbox(email);
    if (address === null) {
      setIsErrorEmail(true);
      setIsLoading(false);
      return;
    }
    try {
      await postPublicReactivateUsers([address.email]);
      onSent();
    } catch (e) {
      console.error(e);
      handleFailure();
    }
  };

  return (
    <Page>
      <Dialog open keepMounted>
        <DialogTitle>
          <Typography variant="h2" component="div" gutterBottom>
            Resend user activation email
          </Typography>
          <Typography variant="body2">
            Resend the activation email so you can create an Akita account.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Divider sx={{ marginBottom: 1 }} />

          <form onSubmit={onSubmit}>
            <Box>
              <TextField
                label="Email Address"
                id="email_address"
                type="text"
                size="medium"
                variant="outlined"
                disabled={emailSent}
                fullWidth
                onChange={onEmailAddressChange}
                error={isErrorEmail}
                value={email}
                helperText={
                  isErrorEmail &&
                  (isValidLength(email)
                    ? "Please enter a valid email address"
                    : `Required (max ${maxLength} characters)`)
                }
                margin="dense"
                sx={{ marginBottom: 2 }}
              />
            </Box>

            <Divider />

            <Grid container flexDirection="row-reverse" paddingTop={2} paddingBottom={2}>
              <Grid item>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={isLoading}
                  disabled={isErrorEmail || emailSent}
                >
                  {emailSent ? "✔️ Sent" : "Resend activation email"}
                </LoadingButton>
              </Grid>
            </Grid>

            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </form>
        </DialogContent>
      </Dialog>
    </Page>
  );
};
