import { AuthState, OktaAuth } from "@okta/okta-auth-js";
import { setIsAkiChatTermsAcceptedInStorage } from "./aki-chat";
import { ISSUER, LOGOUT_REDIRECT_URI } from "auth/config";

export async function logout(authState: AuthState, oktaAuth: OktaAuth) {
  // Clear the Aki chat terms acceptance from storage, as we don't want it to carry over between accounts
  setIsAkiChatTermsAcceptedInStorage(false);

  // Read idToken before local session is cleared
  const idToken = authState.idToken?.idToken;

  await oktaAuth.signOut();

  // Clear remote session
  window.location.assign(
    `${ISSUER}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${LOGOUT_REDIRECT_URI}`
  );
}
