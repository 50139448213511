import LinearProgress from "@mui/material/LinearProgress";
import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import { MonitorPage } from "../pages/Monitor/MonitorPage";
import { MonitorsPage } from "../pages/Monitors/MonitorsPage";
import { InternalInferredOrgNameBanner } from "dashboard/components/InternalInferredOrgNameBanner";
import { ProjectBar } from "dashboard/components/ProjectBar/ProjectBar";
import { RumRoute } from "dashboard/components/RumRoute";
import { EndpointRedirect } from "dashboard/pages/EndpointRedirect";
import { MetricsAndErrorsPage } from "dashboard/pages/MetricsAndErrors/MetricsAndErrorsPage";
import { ModelPageForDeployment } from "dashboard/pages/Model/ModelPageForDeployment";
import { NotFound } from "dashboard/pages/NotFound";
import { OverviewPage } from "dashboard/pages/Overview/OverviewPage";
import { getEndpointsTableRedirectLocation } from "dashboard/utils/routes";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";

const ChangeReportContainer = lazy(
  () => import("dashboard/pages/ChangeReport/ChangeReportContainer")
);

export const ServiceAndDeploymentRoutes = () => {
  const isEnabledChangeReportsPage = useIsFlagEnabled(FeatureFlag.ChangeReportsPageEnabled);
  const isEnabledPerformanceAlertsPage = useIsFlagEnabled(FeatureFlag.PerformanceAlertsPageEnabled);

  return (
    <>
      <InternalInferredOrgNameBanner />
      <ProjectBar />
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          <RumRoute
            path="/service/:serviceID/deployment/:deploymentID/monitoring"
            render={(route) => {
              const redirectLocation = getEndpointsTableRedirectLocation(route.location);
              if (redirectLocation) {
                return <Redirect to={redirectLocation} />;
              }

              return <MetricsAndErrorsPage />;
            }}
          />
          <RumRoute
            path="/service/:serviceID/deployment/:deploymentID/endpoint-redirect"
            render={(route) => (
              <EndpointRedirect
                serviceID={route.match.params.serviceID}
                deploymentID={route.match.params.deploymentID}
              />
            )}
          />
          <RumRoute
            path="/service/:serviceID/deployment/:deploymentID/explore"
            render={(route) => (
              <Redirect
                to={`/service/${route.match.params.serviceID}/deployment/${route.match.params.deploymentID}/model`}
              />
            )}
          />
          <RumRoute
            path="/service/:serviceID/deployment/:deploymentID/path-hints"
            render={(route) => (
              <Redirect
                to={`/service/${route.match.params.serviceID}/deployment/${route.match.params.deploymentID}/model`}
              />
            )}
          />
          <RumRoute
            path="/service/:serviceID/deployment/:deploymentID/tags"
            render={(route) => (
              <Redirect
                to={`/service/${route.match.params.serviceID}/deployment/${route.match.params.deploymentID}/model`}
              />
            )}
          />
          {isEnabledChangeReportsPage && (
            <RumRoute
              path="/service/:serviceID/deployment/:deploymentID/change-report"
              render={(route) => (
                <ChangeReportContainer
                  serviceID={route.match.params.serviceID!}
                  deploymentID={route.match.params.deploymentID!}
                />
              )}
            />
          )}
          {isEnabledPerformanceAlertsPage && (
            <RumRoute
              path="/service/:serviceID/deployment/:deploymentID/performance-alerts"
              render={() => <MonitorsPage />}
              exact
            />
          )}
          {isEnabledPerformanceAlertsPage && (
            <RumRoute
              path="/service/:serviceID/deployment/:deploymentID/performance-alerts/:monitorID"
              render={() => <MonitorPage />}
            />
          )}
          <RumRoute
            path="/service/:serviceID/deployment/:deploymentID/model"
            render={(route) => {
              const redirectLocation = getEndpointsTableRedirectLocation(route.location);
              if (redirectLocation) {
                return <Redirect to={redirectLocation} />;
              }

              return <ModelPageForDeployment />;
            }}
          />
          <RumRoute
            path="/service/:serviceID/deployment/:deploymentID/overview"
            render={(route) => (
              <OverviewPage
                projectID={route.match.params.serviceID}
                deploymentID={route.match.params.deploymentID}
              />
            )}
          />
          <RumRoute
            path="/service/:serviceID/deployment/:deploymentID"
            render={() => <NotFound />}
          />
        </Switch>
      </Suspense>
    </>
  );
};
