import { Box, TableCell, TableRowProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { AkiTableRow } from "./AkiTableRow";

type AkiTableEmptyStateProps = Omit<TableRowProps, "children" | "text"> &
  (
    | {
        text?: ReactNode;
        children: ReactNode;
        action?: ReactNode;
      }
    | { text: ReactNode; children?: ReactNode; action?: ReactNode }
  );

export const AkiTableEmptyState = ({
  children,
  action,
  text,
  sx,
  ...rest
}: AkiTableEmptyStateProps) => (
  <AkiTableRow sx={sx} {...rest}>
    <TableCell colSpan={100}>
      {children ?? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            {text}
          </Typography>
          {!!action && <Box sx={{ paddingTop: 1 }}>{action}</Box>}
        </Box>
      )}
    </TableCell>
  </AkiTableRow>
);
