import { ChevronRight } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React from "react";
import { AkiTile } from "dashboard/components/AkiTile/AkiTile";

interface TileSetUpInfoProps {
  projectID?: string;
}

export const TileSetUpInfo = ({ projectID }: TileSetUpInfoProps) => (
  <AkiTile
    spanC={2}
    spanR={1}
    sx={(theme) => ({
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.between("md", "lg")]: {
        flexDirection: "column",
      },
    })}
  >
    <Typography
      component="span"
      sx={(theme) => ({
        fontWeight: "bold",
        [theme.breakpoints.between("md", "lg")]: {
          marginBottom: 1,
        },
      })}
    >
      Setting up an Akita client?
    </Typography>

    <Button
      href={projectID ? `/service/${projectID}/onboarding` : "/onboarding"}
      variant="outlined"
      color="secondary"
      size="small"
      // Height of 30px is needed to maintain consistent overall tile height
      sx={{ flexShrink: 0, height: 30 }}
      endIcon={<ChevronRight />}
    >
      Get Started
    </Button>
  </AkiTile>
);
