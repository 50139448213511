import { akitaFetch } from "data/akita-fetch";

export const getSpecsYaml = (
  projectID: string,
  modelID: string,
  enableRelatedAnnotations?: boolean
) =>
  akitaFetch<string>(`/services/${projectID}/specs/${modelID}/yaml`, {
    queryParams: {
      strip_related_annotations: !enableRelatedAnnotations,
    },
    responseBodyType: "string",
  });
