import { Skeleton, SxProps, Theme, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { Pill } from "dashboard/components/Pill";
import { mergeSxProps } from "dashboard/utils/sx";

interface HTTPMethodEntityProps {
  httpMethod?: string;
  isError?: boolean;
  isLoading?: boolean;
  isPlaceholder?: boolean;
  sx?: SxProps<Theme>;
}

export const HTTPMethodEntity = ({
  httpMethod,
  isError,
  isLoading,
  isPlaceholder,
  sx,
}: HTTPMethodEntityProps) => {
  const theme = useTheme();

  const backgroundColor = useMemo(() => {
    if (isError) return theme.palette.grey[300];

    switch (httpMethod) {
      case "DELETE":
        return theme.palette.httpMethod.delete;
      case "GET":
        return theme.palette.httpMethod.get;
      case "POST":
        return theme.palette.httpMethod.post;
      case "PUT":
        return theme.palette.httpMethod.put;
      default:
        return theme.palette.httpMethod.default;
    }
  }, [isError, httpMethod, theme]);

  const textColor = useMemo(() => {
    if (isError) return theme.palette.error.main;

    return "#FFFFFF";
  }, [isError, theme]);

  if (isLoading || isPlaceholder) {
    return (
      <Skeleton
        variant="rectangular"
        animation={isPlaceholder ? false : "pulse"}
        sx={mergeSxProps(
          {
            borderRadius: (theme) => theme.spacing(0.5),
            flexShrink: 0,
            height: 24,
            marginRight: 1,
            width: 58,
          },
          sx
        )}
      />
    );
  }

  return (
    <Pill sx={mergeSxProps({ marginRight: 1, backgroundColor, color: textColor }, sx)} size="small">
      {httpMethod}
    </Pill>
  );
};
