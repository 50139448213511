import { Close, RemoveDone } from "@mui/icons-material";
import { Box, Button, Grow, Link, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { AkiPagination } from "../AkiPagination";
import { AkiTable } from "../AkiTable/AkiTable";
import { AkiTableBody } from "../AkiTable/AkiTableBody";
import { AkiTableCell } from "../AkiTable/AkiTableCell";
import { AkiTableEmptyState } from "../AkiTable/AkiTableEmptyState";
import { AkiTableHead } from "../AkiTable/AkiTableHead";
import { SortableTableHeaderCell } from "../SortableTableHeaderCell";
import { EndpointTableRow } from "./EndpointTableRow";
import { EndpointsTableAutocomplete } from "./EndpointsTableAutocomplete";
import { EndpointsTableFilters } from "./EndpointsTableFilters";
import { EndpointsTableEndpoint } from "./formatting";
import {
  useEndpointsTableFilters,
  useEndpointsTableSelections,
  useSelectedEndpointColors,
} from "./hooks";
import { Percentile } from "dashboard/utils/percentiles";
import { useLogInteraction } from "hooks/use-log-interaction";
import { useSortParam } from "hooks/use-sort-param";
import { SpecContentSummary, TimelineSummaryFilters } from "types/akita_api_types";

interface EndpointsTableProps {
  endpoints?: EndpointsTableEndpoint[];
  endpointsPerPage?: number;
  isLoading?: boolean;
  isPreviousData?: boolean;
  isSelectable?: boolean;
  openEndpointDetails: (endpoint: EndpointsTableEndpoint) => void;
  percentile?: Percentile;
  selectedEndpointHighlightColor?: string;
  showResponseCode?: boolean;
  summary?: SpecContentSummary | TimelineSummaryFilters;
  sx?: SxProps<Theme>;
  sxPagination?: SxProps<Theme>;
  totalCount?: number;
}

export const EndpointsTable = ({
  endpoints,
  endpointsPerPage = 15,
  isLoading = false,
  isPreviousData = false,
  isSelectable,
  openEndpointDetails,
  percentile = "p90",
  selectedEndpointHighlightColor,
  showResponseCode,
  summary,
  sx,
  sxPagination,
  totalCount,
}: EndpointsTableProps) => {
  const logInteraction = useLogInteraction();

  const { setSort, activeSortKey, activeSortDirection } = useSortParam({
    defaultSortKey: "count",
    defaultSortDirection: "desc",
  });
  const [filters, clearFilters] = useEndpointsTableFilters();
  const [selectedEndpoints, setSelectedEndpoints] = useEndpointsTableSelections();
  const selectedEndpointColors = useSelectedEndpointColors();
  const [hasInitialFilters, setHasInitialFilters] = useState(true);

  const onEndpointSelected = useCallback(
    (endpoint: EndpointsTableEndpoint, newIsSelected: boolean) => {
      const updatedValues = !newIsSelected
        ? selectedEndpoints.filter((e) => e !== endpoint.uniqueID)
        : selectedEndpoints.concat(endpoint.uniqueID);

      setSelectedEndpoints(updatedValues);
      logInteraction("Selected endpoints", { selectedEndpoints: updatedValues });
    },
    [selectedEndpoints, setSelectedEndpoints, logInteraction]
  );

  const rows = useMemo(() => {
    if (isLoading) {
      return new Array(endpointsPerPage)
        .fill(undefined)
        .map((_value, index) => (
          <EndpointTableRow
            isLoading
            isSelectable={isSelectable}
            key={index}
            percentile={percentile}
            showResponseCode={showResponseCode}
          />
        ));
    }

    return (
      endpoints?.map((endpoint) => (
        <EndpointTableRow
          endpoint={endpoint}
          isSelectable={isSelectable}
          isSelected={selectedEndpoints.includes(endpoint.uniqueID)}
          key={endpoint.uniqueID}
          onChangeSelect={onEndpointSelected}
          openDetails={openEndpointDetails}
          percentile={percentile}
          selectedHighlightColor={
            selectedEndpointHighlightColor || selectedEndpointColors[endpoint.uniqueID]
          }
          showResponseCode={showResponseCode}
        />
      )) ?? []
    );
  }, [
    endpoints,
    endpointsPerPage,
    isLoading,
    isSelectable,
    onEndpointSelected,
    openEndpointDetails,
    percentile,
    selectedEndpointColors,
    selectedEndpointHighlightColor,
    selectedEndpoints,
    showResponseCode,
  ]);

  const handleClearFilterClick = useCallback(() => {
    clearFilters();
    setHasInitialFilters(true);
  }, [clearFilters]);

  // Make sure there's always at least one "page" (even when there's not)
  const pageCount = Math.ceil((totalCount || 1) / endpointsPerPage);

  const hasFilters = Object.keys(filters).length > 0;

  return (
    <Box>
      <EndpointsTableAutocomplete isLoading={isLoading} summary={summary} />
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        })}
      >
        <EndpointsTableFilters
          isLoading={isLoading}
          summary={summary}
          onClearFiltersClick={() => setHasInitialFilters(true)}
          onFilterOptionToggled={() => setHasInitialFilters(false)}
        />

        <Box sx={{ width: "100%" }}>
          <AkiTable sxContainer={sx}>
            <AkiTableHead>
              {isSelectable && (
                <AkiTableCell sx={{ paddingRight: 0 }}>
                  <TransitionGroup component={null}>
                    {selectedEndpoints.length > 0 && (
                      <Grow timeout={200}>
                        <Tooltip
                          placement="top"
                          title={`Unselect ${selectedEndpoints.length.toLocaleString()} endpoint${
                            selectedEndpoints.length === 1 ? "" : "s"
                          }`}
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setSelectedEndpoints(null)}
                            size="small"
                            sx={{ minWidth: 0, width: 36, padding: "5px" }}
                          >
                            <RemoveDone fontSize="small" />
                          </Button>
                        </Tooltip>
                      </Grow>
                    )}
                  </TransitionGroup>
                </AkiTableCell>
              )}
              <SortableTableHeaderCell
                sortKey="method"
                activeSortKey={activeSortKey}
                activeSortDirection={activeSortDirection}
                onSort={setSort}
                sx={{ paddingLeft: isSelectable ? 1 : 2, paddingRight: 0 }}
              >
                Method
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                sortKey="endpoint"
                activeSortKey={activeSortKey}
                activeSortDirection={activeSortDirection}
                onSort={setSort}
                sx={{ paddingLeft: 0, paddingRight: 0 }}
              >
                Endpoint
              </SortableTableHeaderCell>
              {showResponseCode && (
                <SortableTableHeaderCell
                  sortKey="response_code"
                  activeSortKey={activeSortKey}
                  activeSortDirection={activeSortDirection}
                  onSort={setSort}
                  align="right"
                >
                  Status
                </SortableTableHeaderCell>
              )}
              <SortableTableHeaderCell
                sortKey="percentile"
                activeSortKey={activeSortKey}
                activeSortDirection={activeSortDirection}
                onSort={setSort}
                align="right"
              >
                {percentile}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                sortKey="count"
                activeSortKey={activeSortKey}
                activeSortDirection={activeSortDirection}
                onSort={setSort}
                align="right"
              >
                Count
              </SortableTableHeaderCell>
              {/* Column for the "Open Endpoint Details" button */}
              <AkiTableCell sx={{ paddingLeft: 0 }} />
            </AkiTableHead>

            <AkiTableBody
              isRefreshing={isPreviousData}
              data-testid="endpoints-table-body"
              emptyState={
                <AkiTableEmptyState
                  text={
                    !hasInitialFilters && hasFilters ? (
                      <>
                        <Typography variant="h4" fontWeight="bold" gutterBottom>
                          No endpoints matching the current filters.
                        </Typography>
                        Try clearing the filters to see if there&rsquo;s traffic you may be
                        interested in that&rsquo;s being filtered out.
                        <br />
                        If that doesn&rsquo;t help, check out{" "}
                        <Link href="https://intercom.help/akita-software/en/articles/6812745-i-m-not-seeing-the-traffic-i-m-looking-for-in-my-api-model">
                          this article
                        </Link>{" "}
                        for more tips on how to find the traffic you&rsquo;re looking for.
                      </>
                    ) : (
                      <>
                        <Typography variant="h4" fontWeight="bold" gutterBottom>
                          No endpoints found.
                        </Typography>
                        Check out{" "}
                        <Link href="https://intercom.help/akita-software/en/articles/6812745-i-m-not-seeing-the-traffic-i-m-looking-for-in-my-api-model">
                          this article
                        </Link>{" "}
                        for more tips on how to find the traffic you&rsquo;re looking for.
                      </>
                    )
                  }
                  action={
                    hasFilters &&
                    !hasInitialFilters && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        endIcon={<Close />}
                        onClick={handleClearFilterClick}
                      >
                        Clear Filters
                      </Button>
                    )
                  }
                />
              }
            >
              {rows}
            </AkiTableBody>
          </AkiTable>

          <AkiPagination
            pageCount={pageCount}
            isLoading={
              // isLoading
              isLoading
            }
            sx={sxPagination}
          />
        </Box>
      </Box>
    </Box>
  );
};
