import { Refresh } from "@mui/icons-material";
import { Box, Button, IconButton, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import React from "react";
import { TimeRangePickerAbsolute } from "./TimeRangePickerAbsolute";
import { TimeRangePickerRelative } from "./TimeRangePickerRelative";
import { TimeRange } from "dashboard/utils/time-range";

type TimeRangePickerProps = {
  maxDateTime?: number;
  minDateTime?: number;
  onChange: (newValue: TimeRange) => void;
  onModeChange: (newMode: "relative" | "absolute") => void;
  onRefresh: () => void;
  sx?: SxProps<Theme>;
  value: TimeRange;
};

export const TimeRangePicker = ({
  maxDateTime,
  minDateTime,
  onChange,
  onModeChange,
  onRefresh,
  sx,
  value,
}: TimeRangePickerProps) => (
  <Box sx={sx}>
    <Typography
      variant="h6"
      sx={(theme) => ({
        marginBottom: 1,
        textAlign: "right",
        height: (theme) => theme.spacing(3),
        [theme.breakpoints.down("md")]: { textAlign: "left" },
      })}
    >
      Time range
      <Tooltip
        placement="top"
        title={
          value.type === "relative"
            ? "Use a custom time range"
            : "Select from a list of relative time ranges"
        }
      >
        <Button
          variant="outlined"
          sx={{ marginLeft: 1 }}
          size="xsmall"
          onClick={() => onModeChange(value.type === "relative" ? "absolute" : "relative")}
        >
          {value.type === "relative" ? "Custom" : "Relative"}
        </Button>
      </Tooltip>
    </Typography>

    {value.type === "relative" && (
      <Tooltip title="Refresh results" placement="top">
        <IconButton onClick={onRefresh} sx={{ marginRight: 1 }} size="small">
          <Refresh />
        </IconButton>
      </Tooltip>
    )}

    {value.type === "relative" ? (
      <TimeRangePickerRelative value={value} onChange={onChange} />
    ) : (
      <TimeRangePickerAbsolute
        value={value}
        onChange={onChange}
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}
      />
    )}
  </Box>
);
