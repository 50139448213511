import React, { Dispatch, SetStateAction } from "react";
import { FilterValues, deleteFilter, updateFilters, useQueryFilters } from "hooks/use-query-filter";

type paginationHelper = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  itemsPerPageOptions: number[];
  itemsPerPage: number;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
};

const GlobalDefaultPage = 0;
const GlobalDefaultItemsPerPage = 10;
const GlobalDefaultItemsPerPageOptions = [10, 25, 50, 100];

export function getLimit(ph: paginationHelper): number {
  return ph.itemsPerPage;
}

export function getOffset(ph: paginationHelper): number {
  return ph.itemsPerPage * ph.page;
}

export default function usePagination(defaultItemsPerPage?: number): paginationHelper {
  const [filters, _url, setFilters] = useQueryFilters();

  function get(name: string, def: number): number {
    const param = getFirstNumber(filters.get(name));
    if (!param || param < 0) {
      return def;
    }
    return param;
  }

  // Track state
  const [page, setPage] = React.useState(get("page", GlobalDefaultPage));
  const [itemsPerPage, setItemsPerPage] = React.useState(
    get("itemsPerPage", defaultItemsPerPage || GlobalDefaultItemsPerPage)
  );

  // Update state when the filters change
  React.useEffect(() => {
    setPage(get("page", GlobalDefaultPage));
    setItemsPerPage(get("itemsPerPage", defaultItemsPerPage || GlobalDefaultItemsPerPage));
  }, [filters]);

  return {
    page,
    setPage: (page) => {
      deleteFilter(setFilters, "page");
      if (page !== undefined) {
        updateFilters(setFilters, "page", `${page}`, true);
      }
    },
    itemsPerPageOptions: GlobalDefaultItemsPerPageOptions,
    itemsPerPage,
    setItemsPerPage: (itemsPerPage) => {
      deleteFilter(setFilters, "itemsPerPage");
      if (itemsPerPage !== undefined) {
        updateFilters(setFilters, "itemsPerPage", `${itemsPerPage}`, true);
      }
    },
  };
}

function getFirstNumber(vals: FilterValues | undefined): number | undefined {
  if (!vals) {
    return undefined;
  }

  for (const [v, present] of vals.entries()) {
    if (!present) {
      continue;
    }
    const parsed = parseInt(v);
    if (!isNaN(parsed)) {
      return parsed;
    }
  }

  return undefined;
}
