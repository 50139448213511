import {
  Alert,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { OrganizationUserDetails } from "types/akita_api_types";

interface InternalOrgUsersTableProps {
  users: OrganizationUserDetails[];
  sx?: SxProps<Theme>;
}

export const InternalOrgUsersTable = ({ users, sx }: InternalOrgUsersTableProps) => {
  const rows = useMemo(
    () =>
      users.map((user) => {
        const createdAtDate = new Date(user.created_at);

        return (
          <TableRow hover key={user.id}>
            <TableCell>
              <Typography component="span" fontWeight="bold" variant="body1">
                {user.name}
              </Typography>
              <br />
              <Typography component="span" variant="body2">
                {user.id}
              </Typography>
            </TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{format(createdAtDate, "PPpp")}</TableCell>
          </TableRow>
        );
      }),
    [users]
  );

  if (users.length === 0)
    return (
      <Alert sx={{ marginTop: 2 }} severity="info">
        No Users
      </Alert>
    );

  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name (ID)</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Created</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};
