import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { Redirect, Switch, matchPath, useLocation } from "react-router-dom";
import { APIKeys } from "./APIKeys/APIKeys";
import { Invites } from "./Invites/Invites";
import { Organization } from "./Organization/Organization";
import { Services } from "./Services/Services";
import { Page, PageHeader, PageTitle } from "dashboard/components/Page";
import { RumRoute } from "dashboard/components/RumRoute";

export const SettingsPage = () => {
  const location = useLocation();
  const matched = matchPath(location.pathname, {
    path: "/settings/:path",
  });

  const path = matched && matched.params ? (matched.params as any).path : "api-keys";

  return (
    <Page>
      <PageHeader
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <PageTitle>Settings</PageTitle>
        <Tabs value={path} indicatorColor="primary">
          <Tab label="API Keys" value="api-keys" href="/settings/api-keys" />
          <Tab label="Invites" value="invites" href="/settings/invites" />
          <Tab label="Projects" value="services" href="/settings/services" />
          <Tab value="team" label="Team" href="/settings/team" />
        </Tabs>
      </PageHeader>

      <Switch>
        <RumRoute path="/settings/services" render={() => <Services />} />
        <RumRoute path="/settings/api-keys" render={() => <APIKeys />} />
        <RumRoute path="/settings/team" render={() => <Organization />} />
        <RumRoute path="/settings/invites" render={() => <Invites />} />
        <RumRoute path="/settings/*" render={() => <Redirect to="/settings/api-keys" />} />
      </Switch>
    </Page>
  );
};
