import React, { useCallback, useEffect, useMemo } from "react";
import { EndpointsTable } from "dashboard/components/EndpointsTable/EndpointsTable";
import {
  EndpointsTableEndpoint,
  formatEndpointsTableEndpoints,
} from "dashboard/components/EndpointsTable/formatting";
import { useEndpointsTableSelections } from "dashboard/components/EndpointsTable/hooks";
import { Percentile } from "dashboard/utils/percentiles";
import { useSearchParam } from "hooks/use-search-param";
import { GetTimelineSummary } from "types/akita_api_types";

export const ENDPOINTS_PER_PAGE = 15;

type MetricsAndErrorsEndpointsTableProps = {
  deploymentID?: string;
  percentile: Percentile;
  projectID?: string;
  timelineSummary?: GetTimelineSummary;
  isLoading?: boolean;
  isPreviousData?: boolean;
};

export const MetricsAndErrorsEndpointsTable = ({
  deploymentID,
  percentile,
  projectID,
  timelineSummary,
  isLoading,
  isPreviousData,
}: MetricsAndErrorsEndpointsTableProps) => {
  const [_, setModalPath] = useSearchParam("modal");
  const [selectedEndpoints, setSelectedEndpoints, isPlaceholder] = useEndpointsTableSelections();

  const endpoints = useMemo(
    () =>
      timelineSummary?.timeline_summary
        ? formatEndpointsTableEndpoints(timelineSummary.timeline_summary)
        : undefined,
    [timelineSummary?.timeline_summary]
  );

  const openEndpointDetails = useCallback(
    (endpoint: EndpointsTableEndpoint) => {
      setModalPath(
        `/project/${projectID}/deployment/${deploymentID}/endpoint/${endpoint.uniqueID}`
      );
    },
    [setModalPath, projectID, deploymentID]
  );

  // Only select the top 5 endpoints once the page is in a "settled" state, and the user hasn't
  // already deselected all endpoints.
  useEffect(() => {
    // isPlaceholder will be true when the user has intentionally deselected all endpoints.
    const areEndpointsNotYetSelected = !selectedEndpoints.length && !isPlaceholder;
    const areEndpointsAvailable = endpoints && endpoints.length > 0;
    const areEndpointsNotLoadingOrStale = !isLoading && !isPreviousData;

    if (areEndpointsNotYetSelected && areEndpointsAvailable && areEndpointsNotLoadingOrStale) {
      const defaultSelectedEndpoints = endpoints.slice(0, 5).map((endpoint) => endpoint.uniqueID);
      // The "replace" option makes sure we don't push our default selections onto the history stack,
      // which would result in one of those annoying "back button doesn't work" situations.
      setSelectedEndpoints(defaultSelectedEndpoints, { replace: true });
    }
  }, [
    endpoints,
    isLoading,
    isPlaceholder,
    isPreviousData,
    selectedEndpoints,
    setSelectedEndpoints,
  ]);

  return (
    <EndpointsTable
      showResponseCode
      endpoints={endpoints}
      endpointsPerPage={ENDPOINTS_PER_PAGE}
      isLoading={isLoading}
      isPreviousData={isPreviousData}
      openEndpointDetails={openEndpointDetails}
      summary={timelineSummary?.filter_summary}
      totalCount={timelineSummary?.total_count}
      percentile={percentile}
      isSelectable
    />
  );
};
