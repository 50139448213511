import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import { Deleter } from "dashboard/components/Deleter";
import { getModelPathname } from "dashboard/utils/routes";
import { ServiceSummary } from "types/akita_api_types";

interface ProjectsTableProps {
  projects: ServiceSummary[] | undefined;
  refetch: () => void;
}

export const ProjectsTable = ({ projects, refetch }: ProjectsTableProps) => {
  const rows = useMemo(
    () =>
      projects?.map((project) => (
        <TableRow key={project.id}>
          <TableCell>
            <Link href={getModelPathname({ projectID: project.id })}>
              <Typography variant="body2">{project.name}</Typography>
            </Link>
          </TableCell>
          <TableCell align="right">
            <Deleter
              noRefresh
              name={project.name}
              url={`${process.env.REACT_APP_API_URL}/v1/services/${project.id}`}
              onSuccess={refetch}
            />
          </TableCell>
        </TableRow>
      )),
    [projects, refetch]
  );

  if (!projects?.length) return null;

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Project Name</TableCell>
            <TableCell align="right">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};
