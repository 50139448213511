import { AutoAwesome } from "@mui/icons-material";
import {
  Box,
  Divider,
  Link,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { AkiTile } from "dashboard/components/AkiTile/AkiTile";
import { LinkBehavior } from "dashboard/components/LinkBehavior";
import { Percentile } from "dashboard/utils/percentiles";
import {
  getEndpointsTableRedirectLocation,
  getLocationWithQueryParams,
} from "dashboard/utils/routes";
import { TimeRange } from "dashboard/utils/time-range";
import { TimelineSummaryFilters } from "types/akita_api_types";

type MetricsAndErrorsQuickLinksProps = {
  filterSummary?: TimelineSummaryFilters;
  percentile: Percentile;
  timeRange: TimeRange;
  projectID: string;
  deploymentID: string;
};

export const MetricsAndErrorsQuickLinks = ({
  filterSummary,
  projectID,
  deploymentID,
  percentile,
}: MetricsAndErrorsQuickLinksProps) => {
  const theme = useTheme();
  const isTinyViewport = useMediaQuery(theme.breakpoints.down(450));

  const locationBase = useMemo(
    () =>
      getLocationWithQueryParams(
        getEndpointsTableRedirectLocation({
          pathname: `/service/${projectID}/deployment/${deploymentID}/monitoring`,
        })!,
        { percentile }
      ),
    [projectID, deploymentID, percentile]
  );

  const locationMostRequests = useMemo(
    () =>
      getLocationWithQueryParams(locationBase, {
        sort: "count:desc",
      }),
    [locationBase]
  );

  const locationLeastRequests = useMemo(
    () =>
      getLocationWithQueryParams(locationBase, {
        sort: "count:asc",
      }),
    [locationBase]
  );

  const locationMostErrors = useMemo(() => {
    const responseCodes = Object.keys(filterSummary?.response_codes ?? {});
    const errorCodes = responseCodes.filter((code) => code.charAt(0) > "3");

    return getLocationWithQueryParams(locationBase, {
      "filter-response_codes": errorCodes,
      sort: "count:desc",
    });
  }, [filterSummary, locationBase]);

  const locationSlowest = useMemo(
    () =>
      getLocationWithQueryParams(locationBase, {
        sort: "percentile:desc",
      }),
    [locationBase]
  );

  const locationFastest = useMemo(
    () =>
      getLocationWithQueryParams(locationBase, {
        sort: "percentile:asc",
      }),
    [locationBase]
  );

  return (
    <AkiTile
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 2,
        [theme.breakpoints.down("md")]: { flexDirection: "column", alignItems: "flex-start" },
        "& a": {
          color: theme.palette.text.primary,
          textDecorationColor: alpha(theme.palette.text.primary, 0.4),
        },
      }}
    >
      <Box sx={{ display: "flex", paddingRight: 1, alignItems: "center" }}>
        <AutoAwesome fontSize="small" color="warning" />
        <Typography component="div" fontWeight="bold" sx={{ paddingLeft: 1 }}>
          Show endpoints with the:
        </Typography>
      </Box>

      <Stack
        direction={isTinyViewport ? "column" : "row"}
        divider={isTinyViewport ? null : <Divider orientation="vertical" flexItem />}
        spacing={isTinyViewport ? 0 : 1}
      >
        <Link component={LinkBehavior} to={locationMostRequests}>
          most requests
        </Link>
        <Link component={LinkBehavior} to={locationLeastRequests}>
          fewest requests
        </Link>
        <Link component={LinkBehavior} to={locationMostErrors}>
          most errors
        </Link>
        <Link component={LinkBehavior} to={locationSlowest}>
          slowest {percentile}s
        </Link>
        <Link component={LinkBehavior} to={locationFastest}>
          fastest {percentile}s
        </Link>
      </Stack>
    </AkiTile>
  );
};
