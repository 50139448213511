import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { trackEvent } from "../analytics/event-utils";
import { useAnalyticsContext } from "./use-analytics-context";

/**
 * useSession sends an event to Segment when the hook is first called, indicating that a session
 * has started. If the browser indicates that the page has *potentially* been hidden or closed (it's
 * not possible to differentiate between those two states), useSession will send another event
 * indicating that a session has ended, and prepare itself to send another Session Start event if
 * the browser indicates that the page has become visible again.
 *
 * Note that, due to browser limitations, the definition of an "App Session" is a continuous period
 * during which the browser maintains focus on the application. So, for example, a user navigating
 * to our app, switching over to a different tab, and then switching back to our app would result in
 * three events: an "App Session Start" event, an "App Session Ended" event, and another "App
 * Session Start" event.
 */
export const useSession = () => {
  const { analytics } = useAnalyticsContext();
  useEffect(() => {
    // Unique ID to differentiate windows during concurrent sessions in multiple windows, or across
    // sequential App Sessions in a single window.
    const windowId = uuidv4();

    // We'll use this to ensure we don't send the Session End event without the Session Start event
    // being sent first (and vice versa)
    let shouldSendEndEvent = true;
    let shouldSendStartEvent = true;

    const sendStartEvent = (browserEventType?: string) => {
      if (shouldSendStartEvent) {
        trackEvent("App Session Start", analytics, {
          browserEventType,
          windowId,
          eventType: "session",
        });
        shouldSendEndEvent = true;
        shouldSendStartEvent = false;
      }
    };

    // Immediately send the Session Start event.
    sendStartEvent();

    const sendEndEvent = (browserEventType?: string) => {
      if (shouldSendEndEvent) {
        // We set the transport method for this event to "sendBeacon" so that we have the best
        // chance of getting this event out before the browser kills the tab completely.
        trackEvent("App Session End", analytics, {
          browserEventType,
          windowId,
          eventType: "session",
        });
        shouldSendEndEvent = false;
        shouldSendStartEvent = true;
      }
    };

    // All of these events are implemented in various quirky ways in different browsers and
    // platforms. By listening for all of these, we give ourselves a better chance of successfully
    // sending the Session End event.
    // More info: https://developer.chrome.com/blog/page-lifecycle-api/#observing-state-changes
    window.addEventListener("pagehide", () => sendEndEvent("pagehide"), { capture: true });

    // These two events indicate (or CAN indicate, in the latter case) the page coming back into
    // visibility. When that's the case, we need to send the Session Start event again.
    window.addEventListener("pageshow", () => sendStartEvent("pageshow"), { capture: true });
    window.addEventListener(
      "visibilitychange",
      () => {
        if (document.visibilityState === "hidden") {
          // If the document has transitioned to being hidden (which can mean either the page is
          // closed or the user has changed tabs, or something else), we'll try to send the Session
          // End event.
          sendEndEvent("visibilitychange");
        } else {
          // If the dcument has transitioned to being visible, let's reset everything.
          sendStartEvent("visibilitychange");
        }
      },
      { capture: true }
    );
  }, [analytics]);
};
