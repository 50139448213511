import React from "react";
import { Redirect, Switch, generatePath } from "react-router-dom";
import { InternalInferredOrgNameBanner } from "dashboard/components/InternalInferredOrgNameBanner";
import { ProjectBar } from "dashboard/components/ProjectBar/ProjectBar";
import { RumRoute } from "dashboard/components/RumRoute";
import { EndpointDetailsPage } from "dashboard/pages/EndpointDetails/EndpointDetailsPage";
import { ModelPageForModelID } from "dashboard/pages/Model/ModelPageForModelID";
import { OnboardingPage } from "dashboard/pages/Onboarding/OnboardingPage";
import { Diagnostics } from "dashboard/pages/ServiceSettings/Diagnostics";
import { getEndpointsTableRedirectLocation } from "dashboard/utils/routes";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useCurrentDeploymentId } from "hooks/use-current-deployment-id";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";

const ProjectRedirector = ({
  projectId,
  pathSuffix,
}: {
  projectId: string;
  pathSuffix?: string;
}) => {
  const currentDeploymentId = useCurrentDeploymentId();

  if (!currentDeploymentId) return null;

  const url = generatePath("/service/:serviceId/deployment/:deploymentId/:pathSuffix", {
    serviceId: projectId,
    deploymentId: currentDeploymentId,
    pathSuffix: pathSuffix || "model",
  });

  return <Redirect to={url} />;
};

export const ServiceRoutes = () => {
  const isEnabledChangeReportsPage = useIsFlagEnabled(FeatureFlag.ChangeReportsPageEnabled);

  return (
    <>
      <InternalInferredOrgNameBanner />
      <ProjectBar hideDeploymentSelector />
      <Switch>
        <RumRoute path="/service/:serviceID/spec/:specID/endpoint/:endpointID">
          <EndpointDetailsPage />
        </RumRoute>

        <RumRoute
          path="/service/:serviceID/spec/:specID/explore"
          render={(route) => (
            <Redirect
              to={`/service/${route.match.params.serviceID}/spec/${route.match.params.specID}/model`}
            />
          )}
        />

        <RumRoute
          path="/service/:serviceID/spec/:specID/model"
          render={(route) => {
            const redirectLocation = getEndpointsTableRedirectLocation(route.location);
            if (redirectLocation) {
              return <Redirect to={redirectLocation} />;
            }

            return <ModelPageForModelID />;
          }}
        />
        <RumRoute
          path="/service/:serviceID/spec/:specID/path-hints"
          render={(route) => (
            <Redirect
              to={`/service/${route.match.params.serviceID}/spec/${route.match.params.specID}/model`}
            />
          )}
        />
        <RumRoute
          path="/service/:serviceID/spec/:specID/tags"
          render={(route) => (
            <Redirect
              to={`/service/${route.match.params.serviceID}/spec/${route.match.params.specID}/model`}
            />
          )}
        />
        <RumRoute
          path="/service/:serviceID/spec/:specID"
          render={(route) => (
            <Redirect
              to={`/service/${route.match.params.serviceID}/spec/${route.match.params.specID}/model`}
            />
          )}
        />
        <RumRoute
          path="/service/:serviceID/diagnostics"
          render={(route) => <Diagnostics projectId={route.match.params.serviceID} />}
        />
        <RumRoute
          path="/service/:serviceID/monitoring"
          render={(route) => (
            <ProjectRedirector projectId={route.match.params.serviceID} pathSuffix="monitoring" />
          )}
        />
        <RumRoute
          path="/service/:serviceID/onboarding"
          render={(route) => <OnboardingPage projectID={route.match.params.serviceID} />}
        />

        <RumRoute
          path={["/service/:serviceID/model", "/service/:serviceID/explore"]}
          render={(route) => (
            <ProjectRedirector projectId={route.match.params.serviceID} pathSuffix="model" />
          )}
        />
        <RumRoute
          path="/service/:serviceID/path-hints"
          render={(route) => (
            <ProjectRedirector projectId={route.match.params.serviceID} pathSuffix="path-hints" />
          )}
        />
        <RumRoute
          path="/service/:serviceID/tags"
          render={(route) => (
            <ProjectRedirector projectId={route.match.params.serviceID} pathSuffix="tags" />
          )}
        />
        {isEnabledChangeReportsPage && (
          <RumRoute
            path="/service/:serviceID/change-report"
            render={(route) => (
              <ProjectRedirector
                projectId={route.match.params.serviceID!}
                pathSuffix="change-report"
              />
            )}
          />
        )}
        <RumRoute
          path="/service/:serviceID"
          render={(route) => (
            <ProjectRedirector projectId={route.match.params.serviceID} pathSuffix="model" />
          )}
        />
      </Switch>
    </>
  );
};
