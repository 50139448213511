import React, { useCallback, useState } from "react";
import { EditMonitorForm } from "dashboard/components/EditMonitorForm/EditMonitorForm";
import {
  getMonitorEndpoint,
  getMonitorThresholdStrForDisplay,
  getMonitorUpdateFromMonitor,
  parseMonitorThreshold,
} from "dashboard/utils/monitors";
import { useUpdateMonitors } from "data/queries/monitors";
import { Monitor, MonitorComparator, MonitorSignalKind } from "types/akita_api_types";

type EditMonitorModalFormProps = {
  closeModal: () => void;
  deploymentID: string;
  monitor: Monitor;
  projectID: string;
};

export const EditMonitorModalForm = ({
  closeModal,
  deploymentID,
  monitor,
  projectID,
}: EditMonitorModalFormProps) => {
  const [signal, setSignal] = useState<MonitorSignalKind>(monitor.signal);
  const [comparator, setComparator] = useState<MonitorComparator>(monitor.comparator);
  const [thresholdStr, setThresholdStr] = useState(
    getMonitorThresholdStrForDisplay(signal, monitor.threshold)
  );
  const [minEventCountStr, setMinEventCountStr] = useState(monitor.min_event_count.toString());

  const threshold = parseMonitorThreshold(signal, thresholdStr);
  const minEventCount = parseInt(minEventCountStr, 10) || 0;
  const endpoint = getMonitorEndpoint(monitor);

  const { mutate: mutateUpdateMonitors, isLoading: isLoadingUpdateMonitors } = useUpdateMonitors(
    projectID,
    deploymentID,
    { onSuccess: closeModal }
  );

  const isValid = !!monitor && !!signal && !!comparator && Number.isFinite(threshold);

  const updateMonitor = useCallback(() => {
    if (!isValid) return;

    const monitorUpdate = {
      ...getMonitorUpdateFromMonitor(monitor),
      signal,
      comparator,
      threshold,
      minEventCount,
    };

    mutateUpdateMonitors([monitorUpdate]);
  }, [comparator, isValid, minEventCount, monitor, mutateUpdateMonitors, signal, threshold]);

  return (
    <EditMonitorForm
      canSave={isValid}
      comparator={comparator}
      deploymentID={deploymentID}
      endpoint={endpoint}
      isSaving={isLoadingUpdateMonitors}
      minEventCountStr={minEventCountStr}
      onCancel={closeModal}
      onSave={updateMonitor}
      projectID={projectID}
      setComparator={setComparator}
      setMinEventCountStr={setMinEventCountStr}
      setSignal={setSignal}
      setThresholdStr={setThresholdStr}
      signal={signal}
      thresholdStr={thresholdStr}
    />
  );
};
