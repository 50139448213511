import React from "react";
import { Redirect, Switch, generatePath } from "react-router-dom";
import { RumRoute } from "dashboard/components/RumRoute";
import { InternalOrgPage } from "dashboard/pages/InternalOrg/InternalOrgPage";
import { InternalOrgsPage } from "dashboard/pages/InternalOrgs/InternalOrgsPage";
import { InternalUserReactivationPage } from "dashboard/pages/InternalUserReactivation/InternalUserReactivationPage";
import { NotFound } from "dashboard/pages/NotFound";
import { OnboardingPage } from "dashboard/pages/Onboarding/OnboardingPage";
import { OverviewPage } from "dashboard/pages/Overview/OverviewPage";
import { SettingsPage } from "dashboard/pages/Settings/SettingsPage";
import { ServiceAndDeploymentRoutes } from "dashboard/routers/ServiceAndDeploymentsRouter";
import { ServiceRoutes } from "dashboard/routers/ServiceRouter";
import { useProjects } from "data/queries/projects";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useCurrentDeploymentId } from "hooks/use-current-deployment-id";
import { useCurrentProjectId } from "hooks/use-current-project-id";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";

// Redirects to a project-and-deployment-scoped URL with the given suffix, or returns a Not Found page.
// If there's no projects to route to, redirects to the overview page (or noProjectsPath, if present).
const RootRedirector = ({
  pathSuffix,
  noProjectsPath,
  includeDeployment = true,
}: {
  pathSuffix: string;
  noProjectsPath?: string;
  includeDeployment?: boolean;
}) => {
  const currentProjectId = useCurrentProjectId();
  const currentDeploymentId = useCurrentDeploymentId();
  const { data: projects, isLoading } = useProjects();

  if (!isLoading && !projects?.length) {
    return <Redirect to={noProjectsPath || "/overview"} />;
  }

  if (currentProjectId) {
    // default redirect url
    let url = generatePath(`/service/:currentProjectId/:pathSuffix`, {
      currentProjectId,
      pathSuffix,
    });

    if (currentDeploymentId && includeDeployment) {
      url = generatePath(`/service/:currentProjectId/deployment/:currentDeploymentId/:pathSuffix`, {
        currentProjectId,
        currentDeploymentId,
        pathSuffix,
      });
    }

    if (url) {
      return <Redirect to={url} />;
    }
  }

  return <NotFound />;
};

export const PrimaryRouter = () => {
  const isEnabledInternalAdminPages = useIsFlagEnabled(FeatureFlag.InternalAdminPagesEnabled);

  return (
    <Switch>
      <RumRoute
        path="/service/:serviceID/deployment/:deploymentID/*"
        render={() => <ServiceAndDeploymentRoutes />}
      />
      <RumRoute path="/service/:serviceID/*" render={() => <ServiceRoutes />} />
      <RumRoute path="/settings/*" render={() => <SettingsPage />} />
      <RumRoute
        path="/onboarding"
        render={() => (
          <RootRedirector
            pathSuffix="onboarding"
            noProjectsPath="/first-time-onboarding"
            includeDeployment={false}
          />
        )}
      />
      <RumRoute path="/first-time-onboarding" render={() => <OnboardingPage />} />
      <RumRoute path="/team" render={() => <Redirect to="/settings/team" />} />
      <RumRoute path="/service" exact render={() => <Redirect to="/settings/services" />} />
      <RumRoute path="/services" exact render={() => <Redirect to="/settings/services" />} />
      <RumRoute
        path="/change-report"
        render={() => <RootRedirector pathSuffix="change-report" />}
      />
      <RumRoute
        path={["/model", "/explore"]}
        render={() => <RootRedirector pathSuffix="model" />}
      />
      <RumRoute path="/settings" render={() => <SettingsPage />} />
      <RumRoute path="/monitoring" render={() => <RootRedirector pathSuffix="monitoring" />} />
      <RumRoute
        path="/performance-alerts"
        render={() => <RootRedirector pathSuffix="performance-alerts" />}
      />

      {isEnabledInternalAdminPages && (
        <RumRoute path="/internal/orgs" render={() => <InternalOrgsPage />} />
      )}

      {isEnabledInternalAdminPages && (
        <RumRoute
          path="/internal/org/:organizationId"
          render={({ match }) => <InternalOrgPage organizationId={match.params.organizationId!} />}
        />
      )}

      {isEnabledInternalAdminPages && (
        <RumRoute
          path="/internal/user-reactivation"
          render={() => <InternalUserReactivationPage />}
        />
      )}

      <RumRoute path={["/", ""]} exact render={() => <RootRedirector pathSuffix="overview" />} />
      <RumRoute
        path="/overview"
        render={() => <RootRedirector pathSuffix="overview" noProjectsPath="/empty-overview" />}
      />
      <RumRoute path="/empty-overview" render={() => <OverviewPage />} />

      <RumRoute render={() => <NotFound />} />
    </Switch>
  );
};
