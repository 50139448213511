import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import { LoadingButton } from "@mui/lab";
import { Box, Tooltip, Typography, alpha, styled } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { FeatureFlag } from "../../../feature_flags/feature-flags";
import { useIsFlagEnabled } from "../../../hooks/use-is-flag-enabled";
import { CollapseEndpointDialog } from "../../components/CollapseEndpointDialog";
import { AkiBanner } from "dashboard/components/AkiBanner";
import { useEndpointsTableSelections } from "dashboard/components/EndpointsTable/hooks";
import { QueryError } from "dashboard/components/QueryError";
import { SpecEditSuccessDialog } from "dashboard/components/SpecEditSuccessDialog";
import { decodeEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import { usePostEditSpecs } from "data/queries/edit-specs";
import { CollapsePathsEditRequest, SpecEdit } from "types/akita_api_types";

const EditButton = styled(LoadingButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
    marginBottom: theme.spacing(1),
  },
  "&:last-of-type": {
    marginRight: 0,
    marginBottom: 0,
  },
}));

type ModelPageEditBannerProps = {
  projectID: string;
  modelID: string;
  exitEditMode: () => void;
};

const getHideEdits = (selectedEndpoints: string[], editType: "hide" | "unhide"): SpecEdit[] =>
  selectedEndpoints.map((selectedEndpoint) => {
    const decodedID = decodeEndpointUniqueID(selectedEndpoint);
    const edit = {
      method: decodedID.operation,
      host: decodedID.host,
      path_template: decodedID.path,
    };

    if (editType === "hide") return { hide_endpoint: edit };
    return { unhide_endpoint: edit };
  });

export const ModelPageEditBanner = ({
  exitEditMode,
  projectID,
  modelID,
}: ModelPageEditBannerProps) => {
  const isEndpointCollapsingEnabled = useIsFlagEnabled(FeatureFlag.EndpointCollapsingEnabled);
  const [selectedEndpoints, setSelectedEndpoints] = useEndpointsTableSelections();
  const { mutate: postEditSpecs, isLoading, error, isSuccess } = usePostEditSpecs();
  const [isCollapseDialogOpen, setIsCollapseDialogOpen] = useState(false);
  const isCollapsible = useMemo(() => {
    // Two or more endpoints must be selected
    if (selectedEndpoints.length < 2) {
      return false;
    }

    const decodedIDs = selectedEndpoints.map(decodeEndpointUniqueID);

    const firstEndpoint = decodedIDs[0];

    // If any of the selected endpoints have a different host than the first, they cannot be collapsed
    if (decodedIDs.some((decodedID) => decodedID.host !== firstEndpoint.host)) {
      return false;
    }

    // If any of the selected endpoints have a different number of path parts than the first,
    // they cannot be collapsed
    const firstEndpointPathPartCount = firstEndpoint.path.split("/").length;

    return decodedIDs.every((decodedID) => {
      const pathPartCount = decodedID.path.split("/").length;
      return pathPartCount === firstEndpointPathPartCount;
    });
  }, [selectedEndpoints]);

  const onEndEdit = useCallback(() => {
    exitEditMode();
    setSelectedEndpoints(null, { replace: true });
  }, [setSelectedEndpoints, exitEditMode]);

  const onHide = useCallback(
    () => postEditSpecs({ projectID, modelID, edits: getHideEdits(selectedEndpoints, "hide") }),
    [modelID, postEditSpecs, projectID, selectedEndpoints]
  );

  const onUnhide = useCallback(
    () => postEditSpecs({ projectID, modelID, edits: getHideEdits(selectedEndpoints, "unhide") }),
    [modelID, postEditSpecs, projectID, selectedEndpoints]
  );

  const onCollapse = useCallback(
    (collapseEditRequest: CollapsePathsEditRequest) => {
      setIsCollapseDialogOpen(false);
      postEditSpecs({ projectID, modelID, edits: [collapseEditRequest] });
    },
    [modelID, postEditSpecs, projectID]
  );

  const hasSelections = selectedEndpoints.length > 0;

  return (
    <>
      {isSuccess && <SpecEditSuccessDialog onClose={onEndEdit} />}
      <AkiBanner sx={{ marginBottom: 2, display: "inherit" }}>
        <Box sx={{ paddingBottom: 1 }}>
          <Typography variant="h5">You are currently editing your API model.</Typography>
          <Typography>
            Select endpoints below and click “Hide” to move them all to the “Hidden” endpoint
            category and hide them by default, or “Uncategorize” to remove them from all categories
            and display them by default.
          </Typography>
        </Box>

        <Box
          sx={(theme) => ({
            borderTop: `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
            paddingTop: 2,
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          })}
        >
          <EditButton
            onClick={onEndEdit}
            variant="outlined"
            color="secondary"
            startIcon={<Close />}
            sx={{ marginRight: "auto" }}
          >
            Cancel
          </EditButton>

          {isEndpointCollapsingEnabled && (
            <Tooltip title="Collapse endpoints" placement="bottom">
              <EditButton
                disabled={!isCollapsible}
                onClick={() => setIsCollapseDialogOpen(true)}
                variant="contained"
                color="secondary"
                startIcon={<MergeTypeIcon />}
                loading={isLoading}
              >
                Collapse
              </EditButton>
            </Tooltip>
          )}

          <Tooltip title="Remove from all endpoint categories" placement="bottom">
            <EditButton
              disabled={!hasSelections}
              onClick={onUnhide}
              variant="contained"
              color="secondary"
              startIcon={<Visibility />}
              loading={isLoading}
            >
              Uncategorize
            </EditButton>
          </Tooltip>

          <Tooltip title={'Move to "Hidden" endpoint category'} placement="bottom-end">
            <EditButton
              disabled={!hasSelections}
              onClick={onHide}
              variant="contained"
              color="secondary"
              startIcon={<VisibilityOff />}
              loading={isLoading}
            >
              Hide
            </EditButton>
          </Tooltip>
        </Box>
        <QueryError error={error} label="We had trouble updating your model." />
      </AkiBanner>
      {isCollapsible && isEndpointCollapsingEnabled && isCollapseDialogOpen && (
        <CollapseEndpointDialog
          onSubmit={onCollapse}
          projectID={projectID}
          selectedEndpoints={selectedEndpoints}
          onClose={() => setIsCollapseDialogOpen(false)}
        />
      )}
    </>
  );
};
