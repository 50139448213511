import { Key } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { ApiKeyPair } from "dashboard/components/ApiKeyPair";
import { OnboardingStepButtons } from "dashboard/components/OnboardingStep/OnboardingStepButtons";
import { OnboardingStepContext } from "dashboard/components/OnboardingStep/context";

interface StepGetAPIKeyProps {
  apiKeyId: string | undefined;
  apiKeySecret: string | undefined;
  fetchNewApiKey: () => void;
  isError: boolean;
  isLoading: boolean;
  isDockerDesktopInstall: boolean;
}

export const StepGetAPIKey = ({
  apiKeyId,
  apiKeySecret,
  fetchNewApiKey,
  isError,
  isLoading,
  isDockerDesktopInstall,
}: StepGetAPIKeyProps) => {
  const { trackOnboardingEvent } = useContext(OnboardingStepContext);

  const onClickGetKey = useCallback(() => {
    trackOnboardingEvent(`${apiKeyId ? "Refresh API Key" : "Get API Key"} Clicked`);
    void fetchNewApiKey();
  }, [fetchNewApiKey, trackOnboardingEvent, apiKeyId]);

  const onCopyKeyId = useCallback(() => {
    trackOnboardingEvent("Key ID Copied");
  }, [trackOnboardingEvent]);

  const onCopyKeySecret = useCallback(() => {
    trackOnboardingEvent("Key Secret Copied");
  }, [trackOnboardingEvent]);

  return (
    <>
      <Typography sx={{ marginBottom: 2 }}>
        You will need {apiKeyId ? "this" : "an"} API key ID and secret to authorize your Akita
        Client.
      </Typography>

      <ApiKeyPair
        apiKeyId={apiKeyId}
        apiKeySecret={apiKeySecret}
        onCopyKeyId={onCopyKeyId}
        onCopyKeySecret={onCopyKeySecret}
      />

      <Box>
        <LoadingButton
          onClick={onClickGetKey}
          loading={isLoading}
          variant={apiKeyId ? "outlined" : "contained"}
          loadingPosition="start"
          startIcon={<Key />}
        >
          {apiKeyId ? "Refresh API Key" : "Get API Key"}
        </LoadingButton>
      </Box>

      {isError && (
        <Alert severity="error">Unable to generate an API key for you. Please try again.</Alert>
      )}

      {apiKeyId && !isDockerDesktopInstall && (
        <Alert severity="info" sx={{ marginTop: 2 }}>
          <strong>Using the Akita Chrome extension or Docker Desktop extension?</strong>
          <br />
          Please return there with your project name and API key ID and secret to finish getting set
          up!
        </Alert>
      )}

      <OnboardingStepButtons nextDisabled={!apiKeyId} />
    </>
  );
};
