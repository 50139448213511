import { Alert, LinearProgress, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { InternalOrgsTable } from "./InternalOrgsTable";
import { Page, PageContent, PageHeader, PageTitle } from "dashboard/components/Page";
import { useInternalOrgs } from "data/queries/internal-orgs";

interface InternalOrgsPageProps {
  whatever?: string;
}

export const InternalOrgsPage = (_props: InternalOrgsPageProps) => {
  const result = useInternalOrgs();

  const content = useMemo(() => {
    if (result.isLoading) {
      return <LinearProgress />;
    }

    if (result.isError) {
      return <Alert severity="error">Something went wrong. Try refreshing.</Alert>;
    }

    if (result?.data) {
      return <InternalOrgsTable organizations={result?.data?.organizations} />;
    }

    return undefined;
  }, [result]);

  return (
    <Page>
      <PageHeader
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <PageTitle>All Organizations</PageTitle>

        <Typography component="div" variant="h2" color="secondary">
          STAFF ONLY
        </Typography>
      </PageHeader>

      <PageContent>{content}</PageContent>
    </Page>
  );
};
