import { useCallback, useRef } from "react";
import { trackEvent } from "../analytics/event-utils";
import { useAnalyticsContext } from "./use-analytics-context";
import { AnalyticsEventParamObject } from "types/metrics";

/**
 * useLogInteraction returns a function that takes a string `interactionName` and an optional object
 * `params`. When called, the function will log a Segment event with a name formatted like
 * "Interaction: {interactionName}", along with the contents of the `params` object.
 *
 * @parameter context - An optional object containing Segment params to include by default in all
 * events sent using the returned function.
 * @returns A function for tracking user interactions.
 */
export const useLogInteraction = (context?: AnalyticsEventParamObject) => {
  const contextRef = useRef<AnalyticsEventParamObject | undefined>();
  contextRef.current = context;

  const { analytics } = useAnalyticsContext();

  return useCallback(
    (interactionName: string, params?: AnalyticsEventParamObject) => {
      const eventName = `Interaction: ${interactionName}`;

      trackEvent(eventName, analytics, {
        ...contextRef.current,
        ...params,
        eventType: "interaction",
      });
    },
    [analytics]
  );
};
