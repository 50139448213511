import { Bolt, VolumeMute, VolumeUp } from "@mui/icons-material";
import React from "react";
import { ModelSummaryTileEndpointsModeled } from "./ModelSummaryTileEndpointsModeled";
import { ModelSummaryTileList } from "./ModelSummaryTileList";
import { ModelSummaryTileRequestsSeen } from "./ModelSummaryTileRequestsSeen";
import { AkiGridContainer } from "dashboard/components/AkiGrid";
import { TurtleIcon } from "dashboard/components/icons/TurtleIcon";

type ModelSummaryTilesProps = {
  modelID?: string;
  projectID?: string;
};

export const ModelSummaryTiles = ({ modelID, projectID }: ModelSummaryTilesProps) => (
  <AkiGridContainer minHeightRow={0} sx={{ marginBottom: 2 }}>
    <ModelSummaryTileRequestsSeen spanC={3} projectID={projectID} modelID={modelID} />
    <ModelSummaryTileEndpointsModeled spanC={3} projectID={projectID} modelID={modelID} />

    <ModelSummaryTileList
      gridColumn="1 / 4"
      gridRow="2 / 4"
      icon={<VolumeUp />}
      modelID={modelID}
      projectID={projectID}
      sort="count:desc"
      title="Busiest endpoints"
    />

    <ModelSummaryTileList
      gridColumn="1 / 4"
      gridRow="4 / 6"
      icon={<VolumeMute />}
      modelID={modelID}
      projectID={projectID}
      sort="count:asc"
      title="Least-busy endpoints"
    />

    <ModelSummaryTileList
      gridColumn="4 / 7"
      gridRow="2 / 4"
      icon={<Bolt />}
      modelID={modelID}
      projectID={projectID}
      sort="p90:asc"
      title="Fastest endpoints"
    />

    <ModelSummaryTileList
      gridColumn="4 / 7"
      gridRow="4 / 6"
      icon={<TurtleIcon />}
      modelID={modelID}
      projectID={projectID}
      sort="p90:desc"
      title="Slowest endpoints"
    />
  </AkiGridContainer>
);
