import { ChevronLeft } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Slide,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { debounce } from "lodash";
import React, { ReactNode, useEffect } from "react";
import { mergeSxProps } from "dashboard/utils/sx";

// Debounce setting the document.title, to avoid flicker.
const debouncedSetDocumentTitle = debounce((title: string) => {
  document.title = title;
}, 250);

type CommonPageTitleProps = TypographyProps & {
  sxContainer?: SxProps<Theme>;
  backLabel?: string;
  backHref?: string;
};

// Require a separate documentTitle prop if the children aren't a simple string.
// Makes children optional if a documentTitle is provided.
type PageTitleProps =
  | (CommonPageTitleProps & {
      children: string;
      documentTitle?: string;
    })
  | (CommonPageTitleProps & {
      children: ReactNode;
      documentTitle: string;
    });

/**
 * PageTitle renders an h1 with the given `children`, and sets the `document.title` to include:
 * - The value of the `documentTitle` prop, if present. OR
 * - The value of the `children` prop, if it is a simple string value.
 */
export const PageTitle = ({
  children,
  documentTitle,
  sxContainer,
  backHref,
  backLabel,
  ...rest
}: PageTitleProps) => {
  let titleStr = "Akita";

  // Prefer the explicit documentTitle value, falling back to children if it's a string.
  if (documentTitle && documentTitle.length > 0) {
    titleStr = `${documentTitle} - Akita`;
  } else if (typeof children === "string" && children.length > 0) {
    titleStr = `${children} - Akita`;
  }

  // Set document.title to include the given title.
  useEffect(() => {
    if (titleStr) {
      debouncedSetDocumentTitle(titleStr);
    }
  }, [titleStr]);

  // Reset the title when unmounting.
  useEffect(
    () => () => {
      debouncedSetDocumentTitle("Akita");
    },
    []
  );

  return (
    <Box sx={mergeSxProps({ display: "flex", alignItems: "flex-start" }, sxContainer)}>
      {!!backLabel && !!backHref && (
        <Slide in>
          <Tooltip title={backLabel} placement="top-end">
            <IconButton href={backHref} size="small" sx={{ marginRight: 1, padding: 0 }}>
              <ChevronLeft fontSize="large" />
            </IconButton>
          </Tooltip>
        </Slide>
      )}

      <Typography variant="h1" {...rest}>
        {children}
      </Typography>
    </Box>
  );
};
