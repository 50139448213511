import { Box, Skeleton, useTheme } from "@mui/material";
import React, { ReactNode, useCallback, useContext, useMemo } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryPortal,
  VictoryScatter,
  VictoryVoronoiContainer,
} from "victory";
import { ChartTooltipMarker } from "../ChartTooltip/ChartTooltipMarker";
import { ChartTooltipContext } from "contexts/chart-tooltip-context";
import { abbreviateNumber } from "dashboard/utils/numbers";
import type { DisplayTimeline, DisplayTimelineValue } from "dashboard/utils/timelines";
import { useVictoryTheme } from "hooks/use-victory-theme";

export type AkiTimelineChartProps = {
  alwaysShowPoints?: boolean;
  children?: ReactNode;
  endMS?: number;
  isDataPartial?: boolean;
  /** Number by which to scale the y-axis label values (e.g. 100 for percentages) */
  scaleY?: number;
  startMS?: number;
  timelines?: DisplayTimeline[];
  unitsY?: string;
  voronoiBlacklist?: string[];
};

export const AkiTimelineChart = ({
  alwaysShowPoints,
  children,
  endMS,
  isDataPartial,
  scaleY,
  startMS,
  timelines,
  unitsY,
  voronoiBlacklist: givenVoronoiBlacklist,
}: AkiTimelineChartProps) => {
  const victoryTheme = useVictoryTheme();
  const theme = useTheme();

  const { containerRef, containerHeight, containerWidth, containerPadding, tooltipLocation } =
    useContext(ChartTooltipContext);

  const tickFormatY = useCallback(
    (value: number) => {
      const scaledValue = value * (scaleY ?? 1);
      return abbreviateNumber(scaledValue, scaledValue > 10 ? 0 : 1).concat(unitsY || "");
    },
    [scaleY, unitsY]
  );

  const voronoiBlacklist = useMemo(
    () =>
      ["activePoints"]
        .concat(timelines?.map((timeline) => `${timeline.title}-points`) ?? [])
        .concat(givenVoronoiBlacklist ?? []),
    [timelines, givenVoronoiBlacklist]
  );

  if (!timelines) {
    return (
      <Box
        sx={{
          width: containerWidth,
          height: containerHeight,
          paddingLeft: `${containerPadding.left}px`,
          paddingRight: `${containerPadding.right}px`,
          paddingTop: `${containerPadding.top}px`,
          paddingBottom: `${containerPadding.bottom}px`,
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 1,
          }}
        />
      </Box>
    );
  }

  return (
    <VictoryChart
      scale={{ x: "time", y: "linear" }}
      domainPadding={{ y: 10 }}
      // Only use the provided start and end values when we don't have full data. Otherwise, we'll
      // let Victory compute the final min and max domain.
      minDomain={startMS && isDataPartial ? { x: startMS } : undefined}
      maxDomain={endMS && isDataPartial ? { x: endMS } : undefined}
      width={containerWidth}
      height={containerHeight}
      padding={containerPadding}
      style={{ parent: { height: containerHeight, width: containerWidth } }}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiBlacklist={voronoiBlacklist}
          containerRef={(x) => (containerRef ? (containerRef.current = x) : undefined)}
          labelComponent={<ChartTooltipMarker />}
          voronoiDimension="x"
          labels={({ datum }: { datum: DisplayTimelineValue }) =>
            `${new Date(datum.x).toLocaleTimeString()}: ${datum.y?.toLocaleString()}`
          }
        />
      }
      theme={victoryTheme}
    >
      <VictoryAxis animate dependentAxis tickFormat={tickFormatY} />
      <VictoryAxis animate fixLabelOverlap />

      {timelines.flatMap((timeline) => {
        const shouldShowPoints = alwaysShowPoints || !timeline.isContinuous;

        return [
          <VictoryLine
            interpolation="monotoneX"
            key={timeline.title}
            name={timeline.title}
            animate={
              {
                duration: 400,
                onLoad: { duration: 800 },
                // There's some issue with Victory's typing that makes TypeScript expect this to be a boolean.
                // We'll just cast this to "any" for now to get around that.
              } as any
            }
            style={{
              data: {
                strokeWidth: "3px",
                stroke: timeline.color,
                strokeOpacity: shouldShowPoints ? 0.6 : 1,
              },
            }}
            data={timeline.values}
          />,
        ].concat(
          shouldShowPoints ? (
            <VictoryScatter
              key={`${timeline.title}-points`}
              name={`${timeline.title}-points`}
              // Make the size more prominent if we're displaying points due to discontinuities.
              size={!timeline.isContinuous ? 4 : 2}
              animate={false}
              style={{
                data: {
                  fill: timeline.color,
                },
              }}
              data={timeline.values}
            />
          ) : (
            []
          )
        );
      })}

      {children}

      {tooltipLocation && (
        // SVG doesn't have z-index; elements are rendered in the order they're included in the DOM.
        // We can wrap these data highlights in VictoryPortal, which moves its child elements later
        // in the DOM so that they appear ABOVE the tooltip marker, which gets rendered last.
        <VictoryPortal>
          <VictoryScatter
            name="activePoints"
            style={{
              data: {
                stroke: theme.palette.text.primary,
                strokeWidth: 2,
                fill: ({ datum }) => datum.color ?? theme.palette.secondary.main,
              },
            }}
            size={6}
            // Only highlight actual data points; don't do anything with annotations.
            data={tooltipLocation.activePoints.filter((point) => !point.isAnnotation)}
          />
        </VictoryPortal>
      )}
    </VictoryChart>
  );
};
