import { Key } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { trackEvent } from "analytics/event-utils";
import { ApiKeyPair } from "dashboard/components/ApiKeyPair";
import { useGenerateAPIKey } from "data/queries/api-keys";
import { useAnalyticsContext } from "hooks/use-analytics-context";

export const NewApiKey = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    mutate: generateAPIKey,
    data: newApiKey,
    isLoading,
    isError,
  } = useGenerateAPIKey({ onSuccess: () => setIsOpen(true) });

  const { analytics } = useAnalyticsContext();

  const getAndDisplayNewKey = useCallback(() => {
    trackEvent("Clicked Generate API Key", analytics, {
      source: "superstar-frontend",
    });

    generateAPIKey();
  }, [analytics, generateAPIKey]);

  const clearModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <LoadingButton
        variant="contained"
        onClick={getAndDisplayNewKey}
        name="generate-api-key"
        loading={isLoading}
        loadingPosition="start"
        startIcon={<Key />}
      >
        Get API Key
      </LoadingButton>

      {isError && (
        <Alert severity="error">Unable to generate an API key for you. Please try again.</Alert>
      )}

      <Dialog
        open={isOpen}
        onClose={clearModal}
        aria-labelledby="api-key-dialog-title"
        aria-describedby="api-key-dialog-description"
      >
        <DialogTitle id="api-key-dialog-title">New API Key</DialogTitle>

        <DialogContent id="api-key-dialog-description">
          <Typography marginBottom={2}>
            Please copy your new API key ID and API key secret below and save them. You will not be
            able to see your API key secret again.
          </Typography>

          <ApiKeyPair apiKeyId={newApiKey?.resource_id} apiKeySecret={newApiKey?.api_key} />
        </DialogContent>

        <DialogActions sx={{ paddingBottom: 2, paddingLeft: 3, paddingRight: 3, paddingTop: 0 }}>
          <Button
            variant="contained"
            color="primary"
            name="close-new-api-key-dialog"
            onClick={clearModal}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
