import { Paper, PaperProps } from "@mui/material";
import React, { ReactNode } from "react";
import { mergeSxProps } from "dashboard/utils/sx";

type PageProps = PaperProps & {
  children: ReactNode;
};

/**
 * Shared component for page layouts. Designed for use with other Page* components, like PageHeader.
 */
export const Page = ({ children, sx, ...rest }: PageProps) => (
  <Paper component="main" sx={mergeSxProps({ position: "relative" }, sx)} {...rest}>
    {children}
  </Paper>
);
