import { useQuery } from "@tanstack/react-query";
import { akitaFetch, retry } from "data/akita-fetch";
import {
  GetClientPacketCaptureStatsResponse,
  GetClientTelemetryCountRunningResponse,
} from "types/akita_api_types";

const getClientTelemetry = (projectID: string, queryParams?: { limit?: number }) =>
  akitaFetch<GetClientPacketCaptureStatsResponse>(`/services/${projectID}/telemetry/client`, {
    queryParams,
  });

export const useClientTelemetry = (projectID?: string, queryParams?: { limit?: number }) =>
  useQuery(
    ["client-telemetry", projectID, queryParams],
    async () => (await getClientTelemetry(projectID!, queryParams))?.stats ?? [],
    {
      enabled: !!projectID,
      retry,
    }
  );

const getClientTelemetryCountRunning = async (projectID: string, deploymentID?: string) => {
  const result = await akitaFetch<GetClientTelemetryCountRunningResponse>(
    deploymentID
      ? `/services/${projectID}/telemetry/client/deployment/${deploymentID}/count-running`
      : `/services/${projectID}/telemetry/client/count-running`
  );

  return result?.count ?? 0;
};

export const useClientTelemetryCountRunning = ({
  projectID,
  deploymentID,
  options,
}: {
  projectID?: string;
  deploymentID?: string;
  options?: { refetchInterval: number | ((data?: number) => number) };
}) =>
  useQuery(
    ["client-telemetry-count-running", projectID, deploymentID],
    async () => getClientTelemetryCountRunning(projectID!, deploymentID),
    { enabled: !!projectID, retry, ...options }
  );
