import { Box, BoxProps, SxProps, Theme, alpha } from "@mui/material";
import React, { ReactNode, forwardRef } from "react";
import { mergeSxProps } from "dashboard/utils/sx";

type AkiBannerProps = BoxProps & {
  button?: ReactNode;
  buttonContainerSx?: SxProps<Theme>;
};

export const AkiBanner = forwardRef(
  ({ sx, buttonContainerSx, button, children, bgcolor, ...rest }: AkiBannerProps, ref) => (
    <Box
      sx={mergeSxProps(
        (theme) => ({
          borderRadius: theme.spacing(1),
          border: `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
          backgroundColor: bgcolor ?? alpha(theme.palette.secondary.main, 0.1),
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }),
        sx
      )}
      ref={ref}
      {...rest}
    >
      <Box>{children}</Box>
      {button && (
        <Box
          sx={mergeSxProps(
            (theme) => ({
              flexShrink: 0,
              marginLeft: 2,
              [theme.breakpoints.down("md")]: {
                marginLeft: 0,
                marginTop: 2,
                width: "100%",

                "& > *": {
                  width: "100%",
                },
              },
            }),
            buttonContainerSx
          )}
        >
          {button}
        </Box>
      )}
    </Box>
  )
);

AkiBanner.displayName = "AkiBanner";
