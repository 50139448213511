import {
  Analytics,
  EventProperties,
  Callback as SegmentCallback,
  Options as SegmentOptions,
} from "@segment/analytics-next";

/**
 * Send an event to Segment (if an instance of Analytics is provided).
 *
 * @param analytics - The Segment Analytics instance to use.
 * @param eventName - The name of the event to send.
 * @param properties - The properties to send with the event.
 * @param options - Options for sending the event to Segment.
 */
export const trackEvent = (
  eventName: string,
  analytics?: Analytics,
  properties?: EventProperties,
  options?: SegmentOptions | SegmentCallback
) => {
  void analytics?.track(eventName, properties, options);
};
