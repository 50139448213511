import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useOktaAuth } from "@okta/okta-react";
import React, { useState } from "react";
import { Deleter } from "dashboard/components/Deleter";
import { ResendInvite } from "dashboard/pages/Settings/Organization/ResendInvite";
import { OrganizationInvite } from "types/akita_api_types";

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: `0px solid ${theme.palette.divider}`,
    },
  },
}));

type InvitesTableProps = {
  invites: OrganizationInvite[] | null;
  refreshInvites: () => void;
};

export function InvitesTable(props: InvitesTableProps) {
  const { authState } = useOktaAuth();
  const classes = useStyles();
  const [alert, setAlert] = useState(null);

  return (
    <div>
      {alert}
      {props.invites?.map((invite) => (
        <Grid container key={invite.invitee_email} alignItems="center" className={classes.border}>
          <Grid item xs={6} zeroMinWidth>
            <Typography variant="body2">{invite.invitee_email}</Typography>
          </Grid>
          <Grid item zeroMinWidth>
            <Typography variant="subtitle2">
              {new Date(invite.created_at).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <ResendInvite
              key="invite"
              accessToken={authState?.accessToken?.accessToken as any}
              inviteID={invite.id}
              setAlert={setAlert}
            />
          </Grid>
          <Grid item>
            <Deleter
              key="deleter"
              name={`this invitation to ${invite.invitee_email}`}
              url={`${process.env.REACT_APP_API_URL}/v1/organization/invites/${invite.id}`}
              onSuccess={props.refreshInvites}
              noConfirm
              noRefresh
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
