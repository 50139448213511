import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import WarningIcon from "@mui/icons-material/Warning";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { addHours, addSeconds, formatDistanceToNowStrict, isAfter, parseISO } from "date-fns";
import React, { ReactFragment } from "react";
import { isClientActive } from "dashboard/utils/client-telemetry";
import { useErrorLogger } from "hooks/use-error-logger";
import {
  AgentResourceUsage,
  ApidumpError,
  ClientPacketCaptureStats,
  InferredError,
  PacketCountSummary,
  PacketCounts,
} from "types/akita_api_types";

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    backgroundColor: "rgba(0, 0, 0, 0.02)",
  },
  accordionDetails: {
    paddingTop: theme.spacing(2),
  },
  cell: {
    paddingLeft: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  border: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: `0px solid ${theme.palette.divider}`,
    },
  },
  header: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  row: {
    paddingTop: theme.spacing(1),
  },
  notice: {
    backgroundColor: alpha(theme.palette.primary.light, 0.5),
    borderRadius: "10px",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  warning: {
    backgroundColor: alpha(theme.palette.warning.light, 0.5),
    borderRadius: "10px",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

type ClientTelemetryProps = {
  telemetry?: ClientPacketCaptureStats[];
};

export const ClientTelemetry = (props: ClientTelemetryProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Grid container alignItems="center" className={classes.header}>
        <Grid item xs={4}>
          <Typography>
            <b>Deployment</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            <b>Client started at</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            <b>Status</b>
          </Typography>
        </Grid>
      </Grid>
      {props.telemetry?.length === 0 && (
        <Typography>No instance of the Akita Client have been started.</Typography>
      )}
      {props.telemetry?.map((stats: ClientPacketCaptureStats) => (
        <Accordion
          key={`${stats.client_id}-${stats.observed_starting_at}-${stats.observed_duration_in_seconds}`}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary className={classes.accordionSummary}>
            <Grid container alignItems="center" className={classes.border}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{stats.deployment}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {new Date(stats.observed_starting_at).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.cell}>
                <ClientStatus stats={stats} />
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Typography variant="subtitle2" sx={{ paddingBottom: 1 }}>
                  {isClientActive(stats) ? <>Active</> : renderLastActive(stats)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Warnings stats={stats} />
            {stats.packet_count_summary && (
              <>
                <TelemetrySummary summary={stats.packet_count_summary} />

                {
                  // Telemetry prior to v0.3 lacked per-host data.
                  versionGTE(stats.packet_count_summary.version, "v0.3") && (
                    <>
                      <Divider className={classes.divider} />
                      <TelemetrySummaryByHost summary={stats.packet_count_summary} />
                    </>
                  )
                }

                {stats.agent_resource_usage && (
                  <>
                    <Divider className={classes.divider} />
                    <AgentResourceUsageTable usage={stats.agent_resource_usage} />
                  </>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

// Regular expression for extracting the major and minor version numbers from
// the CLI telemetry version, e.g. v0.1.  A missing minor version is treated
// as 0, e.g. v0 is the same as v0.0.
const versionRE = /^v(?<MajorVersion>\d+)(\.(?<MinorVersion>\d+))?$/;

// Returns version >= target.  Returns false if either version or target
// do not parse as a version.
const versionGTE = (version: string, target: string): boolean => {
  const leftMatch = version.match(versionRE)?.groups ?? {};
  const rightMatch = target.match(versionRE)?.groups ?? {};

  const leftMajorVersion = leftMatch["MajorVersion"];
  const leftMinorVersion = leftMatch["MinorVersion"] || "0";

  const rightMajorVersion = rightMatch["MajorVersion"];
  const rightMinorVersion = rightMatch["MinorVersion"] || "0";

  if (leftMajorVersion !== rightMajorVersion) {
    return Number(leftMajorVersion) >= Number(rightMajorVersion);
  } else {
    return Number(leftMinorVersion) >= Number(rightMinorVersion);
  }
};

const ClientStatus = (props: { stats: ClientPacketCaptureStats }) => {
  // If no error or inferred error, show success.
  if (!props.stats.error) {
    if (isClientActive(props.stats)) {
      return <CheckCircleIcon color="primary" />;
    } else {
      return <CheckCircleIcon color="disabled" />;
    }
  }

  // If inferred error is "Waiting for telemetry", show hourglass.
  if (props.stats.error.inferred_capture_error == "Waiting for telemetry") {
    return <HourglassTopIcon />;
  }

  // Otherwise signal an error.
  return <WarningIcon color="secondary" />;
};

const renderLastActive = (stats: ClientPacketCaptureStats): ReactFragment => {
  const lastActive = addSeconds(
    parseISO(stats.observed_starting_at),
    stats.observed_duration_in_seconds
  );
  const withinTwelveHours = isAfter(lastActive, addHours(new Date(), -12));
  return withinTwelveHours ? (
    <>Last active {formatDistanceToNowStrict(lastActive)} ago</>
  ) : (
    <>Last active {lastActive.toLocaleString()}</>
  );
};

const TelemetrySummary = (props: { summary: PacketCountSummary }) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container alignItems="center">
        <Grid item xs={2} />
        <Grid item xs={1} />
        <Grid item xs={2}>
          <Typography variant="body2">
            <b>Raw TCP packets</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            <b>HTTP Requests / Responses</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            <b>Unparsable packets</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            <b>TLS Handshakes</b>
          </Typography>
        </Grid>
      </Grid>
      <Row
        title="Total"
        countsByKey={{ "": props.summary.total }}
        className={classes.row}
        version={props.summary.version}
      />
      <Row
        title="By interface"
        countsByKey={props.summary.top_by_interface}
        className={classes.row}
        version={props.summary.version}
      />
      <Row
        title="By port"
        countsByKey={props.summary.top_by_port}
        className={classes.row}
        version={props.summary.version}
      />
    </div>
  );
};

// Only HTTP requests and TLS handshakes are available per host, so we
// render that a little differently.
const TelemetrySummaryByHost = (props: { summary: PacketCountSummary }) => {
  const classes = useStyles();

  // Telemetry prior to v0.3 lacked per-host data.
  if (!versionGTE(props.summary.version, "v0.3")) {
    return null;
  }

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item xs={2} />
        <Grid item xs={5} />
        <Grid item xs={2}>
          <Typography variant="body2">
            <b>HTTP Requests</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            <b>TLS Handshakes</b>
          </Typography>
        </Grid>
      </Grid>
      <HostRow
        title="By host"
        countsByKey={props.summary.top_by_host}
        className={classes.row}
        version={props.summary.version}
      />
    </div>
  );
};

// Telemetry prior to v0.1 lacked tls_hello counts; include the
// column but mark it n/a
const Row = (props: {
  title: string;
  countsByKey?: { [key: number | string]: PacketCounts | undefined };
  className?: any;
  version?: string;
}) => (
  <div className={props.className}>
    {Object.entries(props.countsByKey || {}).map((tuple, idx) => {
      const [rowName, counts] = tuple;
      return (
        <Grid container alignItems="center" key={rowName}>
          {idx == 0 ? (
            <Grid item xs={2}>
              <Typography variant="subtitle2">{props.title}</Typography>
            </Grid>
          ) : (
            <Grid item xs={2} />
          )}
          <Grid item xs={1}>
            <Typography variant="subtitle2">{rowName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">{counts?.tcp_packets}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {counts?.http_requests} / {counts?.http_responses}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">{counts?.unparsed}</Typography>
          </Grid>
          <Grid item xs={2}>
            {props?.version == "v0" ? (
              <Typography variant="body2">n/a</Typography>
            ) : (
              <Typography variant="body2">{counts?.tls_hello}</Typography>
            )}
          </Grid>
        </Grid>
      );
    })}
  </div>
);

const hostnamesUnavailable = "(hosts without available names)";

const HostRow = (props: {
  title: string;
  countsByKey?: { [key: number | string]: PacketCounts | undefined };
  className?: any;
  version?: string;
}) => {
  // The CLI aggregates stats for all hosts we can't get hostnames for.
  // Pull that out and place it at the end, so it doesn't look like a
  // column header.
  type Entry = [string, PacketCounts | undefined];
  const entries: Entry[] = [];
  let unnamedEntry: Entry | undefined = undefined;
  Object.entries(props.countsByKey || {}).forEach(([host, maybeCount]) => {
    if (host === hostnamesUnavailable) {
      unnamedEntry = [host, maybeCount];
    } else {
      entries.push([host, maybeCount]);
    }
  });

  if (unnamedEntry !== undefined) {
    entries.push(unnamedEntry);
  }

  return (
    <div className={props.className}>
      {entries.map((tuple, idx) => {
        const [rowName, counts] = tuple;
        return (
          <Grid container alignItems="center" key={rowName}>
            {idx == 0 ? (
              <Grid item xs={2}>
                <Typography variant="subtitle2">{props.title}</Typography>
              </Grid>
            ) : (
              <Grid item xs={2} />
            )}
            <Grid item xs={5}>
              <Typography variant="subtitle2">{rowName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">{counts?.http_requests}</Typography>
            </Grid>
            <Grid item xs={2}>
              {props?.version == "v0" ? (
                <Typography variant="body2">n/a</Typography>
              ) : (
                <Typography variant="body2">{counts?.tls_hello}</Typography>
              )}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

// Only HTTP requests and TLS handshakes are available per host, so we
// render that a little differently.
const AgentResourceUsageTable = ({ usage }: { usage: AgentResourceUsage }) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container alignItems="center">
        <Grid item xs={7} />
        <Grid item xs={2}>
          <Typography variant="body2">
            <b>Last hour</b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            <b>Peak</b>
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.row}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <Typography variant="subtitle2">CPU usage</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">{usage.recent.relative_cpu.toFixed(2)}%</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">{usage.peak.relative_cpu.toFixed(2)}%</Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={7}>
            <Typography variant="subtitle2">CPU cores used</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">{usage.recent.cpus_used.toFixed(2)}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">{usage.peak.cpus_used.toFixed(2)}</Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={7}>
            <Typography variant="subtitle2">Memory used</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">{(usage.recent.vm_hwm / 1024).toFixed(2)} MB</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">{(usage.peak.vm_hwm / 1024).toFixed(2)} MB</Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

// Send an event to Segment about a user-encountered error,
// but no need to display it to the user a second time.
const UnknownTelemetryError = (props: { unknownErrorType: string }) => {
  useErrorLogger("Unknown Telemetry Error", { unknown_error_type: props.unknownErrorType });
  return <></>;
};

const Warnings = (props: { stats: ClientPacketCaptureStats }) => {
  if (!props.stats.error) {
    return null;
  }

  const err = props.stats.error;
  const total = props.stats?.packet_count_summary?.total;

  if (err?.apidump_error) {
    switch (err?.apidump_error) {
      case ApidumpError.PCAPPermission:
        return (
          <Warning
            title="Client Lacks Permissions"
            message="The Akita client could not capture traffic because it lacks permissions to do so. In a container environment, ensure that the container which has the client can run in privileged mode and has the CAP_NET_RAW capability; this might be disabled by default, or not permitted by your service provider. If running directly on a host or VM, start the client as the root user, or set the CAP_NET_RAW capability on the binary. Please contact support@akitasoftware.com with a description of your runtime environment if you need further assistance."
          />
        );
      case ApidumpError.PCAPInterfaceNotImplemented:
        return (
          <Warning
            title="Architecture Mismatch"
            message={`The Akita client could not capture traffic because it failed to read any network interfaces. This often means the Akita agent was built for a different architecture than the host that's running it. The client error message was "${err?.apidump_error_text}". Please contact support@akitasoftware.com with a description of your runtime environment if you need further assistance.`}
          />
        );
      case ApidumpError.PCAPInterfaceOther:
        return (
          <Warning
            title="Packet Capture Failure"
            message={`The Akita client could not capture traffic because it failed to read any network interfaces. The client error message was "${err?.apidump_error_text}". Please contact support@akitasoftware.com with a description of your runtime environment if you need further assistance.`}
          />
        );
      case ApidumpError.InvalidFilters:
        return (
          <Warning
            title="Filter Parsing Error"
            message={`The Akita client failed to parse the filters you specified on the command line. The client error message was "${err?.apidump_error_text}". The --filter argument takes tcpdump-style arguments, while the host and path filters take Go regular expressions. See the client log for more details, and please contact support@akitasoftware.com if you need help constructing a filter.`}
          />
        );
      case ApidumpError.TraceCreation:
        return (
          <Warning
            title="Trace Creation Failure"
            message={`The Akita client failed to create its initial trace in the Akita cloud. This may indicate a temporary issue such as an Akita outage. The client error message was "${err?.apidump_error_text}". Please contact support@akitasoftware.com with the client log output for assistance.`}
          />
        );
      case ApidumpError.Other:
        return (
          <Warning
            message={`The Akita client signaled an error starting up: "${err?.apidump_error_text}". Please contact support@akitasoftware.com with the client log output for assistance.`}
          />
        );
      default:
        return (
          <>
            <UnknownTelemetryError unknownErrorType={err?.apidump_error} />
            <Warning
              title="Unknown error"
              message={`The Akita backend returned an error type that the frontend does not recognize. This is a bug; sorry! Please let us know at support@akitasoftware.com. The error is "${err?.apidump_error}".`}
            />
          </>
        );
    }
  }

  switch (props.stats.error.inferred_capture_error) {
    case InferredError.Waiting:
      return <Notice message="Waiting for the client to collect a 1-minute snapshot of traffic." />;
    case InferredError.Missing:
      return (
        <Warning
          title="Missing Telemetry"
          message={`The Akita client started but failed to report telemetry data after ${props.stats.observed_duration_in_seconds} seconds. This might be caused by the client crashing or being killed. Please send the client logs to support@akitasoftware.com.`}
        />
      );
    case InferredError.Empty:
      return (
        <Warning
          title="No Packets Observed"
          message={`The Akita client did not observe any network traffic. If you're using Docker, this might indicate that the client is not attached to the correct container. Less commonly, the absence might be because you are filtering out too much traffic with a --filter flag, or specifying the incorrect interface with the --interface flag.`}
        />
      );
    case InferredError.EmptyDockerDesktop:
      return (
        <Warning
          title="No Packets Observed"
          message={
            <p>
              The Akita client did not observe any network traffic. If you're running your service
              on macOS and your service is not in a Docker container, try using the{" "}
              <MuiLink
                href="https://docs.akita.software/docs/macos-local#install-agent"
                underline="hover"
              >
                Akita agent for macOS
              </MuiLink>
              . If you're using Docker, this might indicate that the client is not attached to the
              correct container. Less commonly, the absence might be because you are filtering out
              too much traffic with a --filter flag, or specifying the incorrect interface with the
              --interface flag.
            </p>
          }
        />
      );
    case InferredError.TLS:
      return (
        <Warning
          title="No Unencrypted HTTP Found"
          message={`The Akita client observed ${total?.tcp_packets.toLocaleString()} TCP packets but did not find any unencrypted Web traffic. It did find ${total?.tls_hello.toLocaleString()} encrypted connections, which might indicate that you are attempting to monitor encrypted network traffic.`}
        />
      );
    case InferredError.TLS_v0:
      return (
        <Warning
          title="No Unencrypted HTTP Found"
          message={`The Akita client observed ${total?.tcp_packets.toLocaleString()} TCP packets but did not find any unencrypted Web traffic. It did find ${props.stats?.packet_count_summary?.top_by_port[443]?.tcp_packets.toLocaleString()} packets on port 443, which might indicate that you are attempting to monitor encrypted network traffic.`}
        />
      );
    case InferredError.Unparsable:
      return (
        <Warning
          title="No Unencrypted HTTP Found"
          message={`The Akita client observed ${total?.tcp_packets.toLocaleString()} TCP packets but did not find any unencrypted Web traffic. The agent did not recognize any encrypted connections either, so it might be that you are attempting to monitor a protocol that Akita does not support, like HTTP/2 or QUIC. Less commonly, you may have filtered out all the existing Web traffic via command-line flags.`}
        />
      );
    default:
      return (
        <>
          <UnknownTelemetryError unknownErrorType={err?.inferred_capture_error ?? "nil"} />
          <Warning
            title="Unknown error"
            message={`The Akita backend returned an error type that the frontend does not recognize. This is a bug; sorry! Please let us know at support@akitasoftware.com. The error is "${props.stats.error.inferred_capture_error}".`}
          />
        </>
      );
  }
};

const Notice = (props: { title?: any; message: any }) => {
  const classes = useStyles();
  return (
    <div className={classes.notice}>
      <Grid container direction="column">
        {props.title && (
          <Grid item xs={12}>
            <Typography variant="subtitle2">{props.title}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body2">{props.message}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const Warning = (props: { title?: any; message: any }) => {
  const classes = useStyles();
  return (
    <div className={classes.warning}>
      <Grid container direction="column">
        {props.title && (
          <Grid item xs={12}>
            <Typography variant="subtitle2">{props.title}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body2">{props.message}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};
