import React, { ReactElement } from "react";
import { matchPath } from "react-router-dom";
import { ModalRouteContext } from "contexts/modal-route-context";
import { useSearchParam } from "hooks/use-search-param";

type ModalRouteProps = {
  modalPath: string;
  children: ReactElement;
};

/**
 * Takes a modalPath string (with optional React Router :param placeholders) and attempts to match
 * the current value of the `modal` query parameter against it. If theres a match, the children are
 * wrapped in the ModalRouteContext (which passes down the React Router `match` object) and returned.
 * If there's no match, nothing is rendered.
 */
export const ModalRoute = ({ modalPath, children }: ModalRouteProps) => {
  const [currentModalPath] = useSearchParam("modal");
  const match = matchPath(currentModalPath ?? "", modalPath);

  if (!match) return null;

  return <ModalRouteContext.Provider value={match}>{children}</ModalRouteContext.Provider>;
};
