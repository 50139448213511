import React, { useMemo } from "react";
import { makeDemoTimeline } from "../utils";
import { AkiSparkTile } from "dashboard/components/AkiTile/AkiSparkTile";
import { DisplayTimelineValue } from "dashboard/utils/timelines";

export const TileTrafficTrend = ({
  data,
  deploymentID,
  isEmptyState,
  isLoading,
  projectID,
}: {
  data?: DisplayTimelineValue[];
  deploymentID?: string;
  isEmptyState?: boolean;
  isLoading?: boolean;
  projectID?: string;
}) => {
  const demoData = useMemo(
    () => (isEmptyState ? makeDemoTimeline(30, 400, 20000, -30, 500, true) : undefined),
    [isEmptyState]
  );

  return (
    <AkiSparkTile
      data={isEmptyState ? demoData : data}
      isEmptyState={isEmptyState}
      isLoading={isLoading}
      shouldRoundAggregation={true}
      title="Overall traffic amount"
      units="total requests"
      linkLabel="View Detailed Traffic Metrics"
      linkHref={
        projectID && deploymentID
          ? `/service/${projectID}/deployment/${deploymentID}/monitoring`
          : undefined
      }
    />
  );
};
