import { Box, BoxProps } from "@mui/material";
import React, { ReactNode, forwardRef } from "react";
import { mergeSxProps } from "dashboard/utils/sx";

type PageContentProps = BoxProps & {
  children: ReactNode;
};

export const PageContent = forwardRef(({ children, sx, ...rest }: PageContentProps, ref) => (
  <Box ref={ref} component="section" sx={mergeSxProps({ padding: 2 }, sx)} {...rest}>
    {children}
  </Box>
));

PageContent.displayName = "PageContent";
