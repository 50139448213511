import { Box, List, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { clamp } from "lodash";
import React, { ReactNode, useContext } from "react";
import useMeasure from "react-use-measure";
import { DisplayTimelineValue } from "../../utils/timelines";
import { ChartTooltipListItem } from "./ChartTooltipListItem";
import { ChartTooltipPortal } from "./ChartTooltipPortal";
import { ChartTooltipContext } from "contexts/chart-tooltip-context";

const MIN_WIDTH = 250;
const MIN_HEIGHT = 64;
const MAX_WIDTH = 600;

const resolveTimelineListItemKey = (datum: DisplayTimelineValue) =>
  `${datum.title}-${datum.x}-${datum.y}`;

export type ChartTooltipProps = {
  getContent?: (activePoints: DisplayTimelineValue[]) => ReactNode[];
  offsetX?: number;
  offsetY?: number;
};

/**
 * Renders a tooltip at the position provided by the ChartTooltipContext.
 * Tries to stay inside the bounds of the chart container (as provided by that same context), and
 * always stays within the bounds of the page.
 */
export const ChartTooltip = ({ getContent, offsetX = 16, offsetY = 16 }: ChartTooltipProps) => {
  const theme = useTheme();
  const isNarrowViewport = useMediaQuery(theme.breakpoints.down("md"));

  const { tooltipLocation, isTooltipEnabled, containerWidth, containerHeight, containerRef } =
    useContext(ChartTooltipContext);

  // useMeasure will grab the tooltip's bounds, and then update that value if the element resizes.
  const [tooltipRef, tooltipBounds] = useMeasure();

  const containerRect = containerRef?.current?.getBoundingClientRect();
  const shouldDisplayTooltip = isTooltipEnabled && !!tooltipLocation && !!containerRect;
  if (!shouldDisplayTooltip) return null;

  const { x, y, activePoints } = tooltipLocation;

  // getBoundingClientRect returns a value that is *relative to the viewport*, so the top value actually
  // *decreases* as the user scrolls down the page (same for left, if something's gone horribly wrong
  // and the user can scroll horizontally). We can get the position in fixed, *page* coordinates by
  // offsetting the current top and left values with the window.scrollY and window.scrollX values.
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect#return_value
  const containerTop = containerRect.top + window.scrollY;
  const containerLeft = containerRect.left + window.scrollX;

  // If there's no width or height available yet (which will be the case on the first render), we'll
  // use the min width and height values to prevent jank.
  const tooltipWidth = Math.max(tooltipBounds.width, MIN_WIDTH);
  const tooltipHeight = Math.max(tooltipBounds.height, MIN_HEIGHT);

  // If the active data point is more than 50% to the right of the left edge of the container, then
  // the tooltip should be positioned to the left of it, instead of to the right.
  const shouldBeToLeft = x > containerWidth / 2;

  // If the active data point is more than 50% to the bottom of the top edge of the container, then
  // the tooltip should be positioned above it, instead of below.
  const shouldBeAbove = y > containerHeight / 2;

  // Move the tooltip so that it's positioned as close to the data point as the given offset values
  // and the viewport will allow.
  const anchorX = x + containerLeft + (shouldBeToLeft ? -offsetX - tooltipWidth : offsetX);
  const anchorY = y + containerTop + (shouldBeAbove ? -offsetY - tooltipHeight : offsetY);

  // We want the tooltip to be contained within the current bounds of the (scrolled) viewport (with
  // 8px of padding around the edges).
  const minX = window.scrollX + 8;
  const minY = window.scrollY + 8;
  const maxX = document.documentElement.clientWidth + window.scrollX - 8 - tooltipWidth;
  const maxY = document.documentElement.clientHeight + window.scrollY - 8 - tooltipHeight;

  // "clamp" the x and y values so that they position the tooltip within the bounds we just defined.
  const clampedAnchorX = clamp(anchorX, minX, maxX);
  const clampedAnchorY = clamp(anchorY, minY, maxY);

  const translateAnchor = `translate(${clampedAnchorX}px, ${clampedAnchorY}px)`;

  const tooltipContents = (
    <>
      <Typography
        variant="body1"
        component="div"
        fontWeight="bold"
        sx={{
          padding: 1,
          background: theme.palette.overlay.min,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        {format(activePoints[0].x, "Pp")}
      </Typography>
      <List
        disablePadding
        sx={{
          "& li": {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          "& li:last-of-type": {
            borderBottom: 0,
          },
        }}
      >
        {getContent
          ? getContent(activePoints).map((labelNode, index) => {
              const datum = activePoints[index];

              return (
                <ChartTooltipListItem key={resolveTimelineListItemKey(datum)} datum={datum}>
                  {labelNode}
                </ChartTooltipListItem>
              );
            })
          : activePoints.map((datum) => (
              <ChartTooltipListItem key={resolveTimelineListItemKey(datum)} datum={datum}>
                x: {datum.x}, y: {datum.y}
              </ChartTooltipListItem>
            ))}
      </List>
    </>
  );

  if (isNarrowViewport) {
    return (
      <Box
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "6px",
          boxShadow: theme.shadows[8],
          margin: 1,
          marginTop: 0,
          overflow: "hidden",
          width: containerWidth - 16,
        }}
      >
        {tooltipContents}
      </Box>
    );
  }

  return (
    <ChartTooltipPortal>
      <Box
        ref={tooltipRef}
        // These styles are frequently-changing, so they should be in the `style` prop (meaning
        // they'll get applied as inline styles) instead of in the `sx` prop (which creates a new
        // CSS class for each unique combination of declarations).
        style={{
          transform: translateAnchor,
        }}
        sx={{
          backdropFilter: "blur(4px)",
          backgroundColor: alpha(theme.palette.background.paper, 0.7),
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          boxShadow: theme.shadows[8],
          left: 0,
          maxHeight: "100%",
          maxWidth: MAX_WIDTH,
          minHeight: MIN_HEIGHT,
          minWidth: MIN_WIDTH,
          overflowX: "hidden",
          overflowY: "auto",
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          transition: "transform ease 200ms",
          whiteSpace: "nowrap",
          zIndex: 2000,
        }}
      >
        {tooltipContents}
      </Box>
    </ChartTooltipPortal>
  );
};
