import { useTheme } from "@mui/material";
import { orderBy } from "lodash";
import React, { useContext, useEffect } from "react";
import { LineSegment } from "victory";
import { ChartTooltipContext } from "contexts/chart-tooltip-context";
import { DisplayTimelineValue } from "dashboard/utils/timelines";

type ChartTooltipMarkerProps = {
  active?: boolean;
  height?: number;
  datum?: any;
  x?: number;
  y?: number;
  activePoints?: DisplayTimelineValue[];
};

export const ChartTooltipMarker = ({
  x,
  y,
  height,
  active,
  datum,
  activePoints,
}: ChartTooltipMarkerProps) => {
  const theme = useTheme();
  const { setTooltipLocation, containerPadding } = useContext(ChartTooltipContext);

  useEffect(() => {
    if (!active || typeof x !== "number" || typeof y !== "number" || !activePoints) return;

    return setTooltipLocation({
      x,
      y,
      activePoints: orderBy(
        // Victory adds some secret properties to data, and keeping them around when rendering the
        // activePoints causes problems. Instead, we need to pick the specific properties we care about.
        activePoints.map((point) => ({
          x: point.x,
          y: point.y,
          title: point.title,
          color: point.color,
          isAnnotation: point.isAnnotation,
        })),
        // Sort by y (descending), so that labels will be displayed in the same order top-to-bottom
        // as the data points. If the Y value is missing, it will be sorted as if it has a value of 0
        // (i.e. it will be last). If the values are equal, then sort by title (ascending).
        [(point) => point.y ?? 0, "title"],
        ["desc", "asc"]
      ),
    });
  }, [x, y, active, setTooltipLocation, activePoints]);

  useEffect(() => () => setTooltipLocation(undefined), [setTooltipLocation]);

  if (!active || typeof x !== "number" || typeof y !== "number") return null;

  return (
    <>
      <LineSegment
        datum={datum}
        x1={x}
        x2={x}
        y1={containerPadding.top}
        y2={(height ?? 0) - containerPadding.bottom}
        style={{
          stroke: theme.palette.foreground,
          strokeWidth: 2,
          strokeDasharray: "4",
        }}
      />
    </>
  );
};
