import { Autocomplete, AutocompleteProps, ListItem, TextField } from "@mui/material";
import { matchSorter } from "match-sorter";
import React, { useCallback, useMemo } from "react";
import { EndpointEntity } from "../entities/EndpointEntity";
import { constructEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import { useEndpointsMetadata } from "data/queries/endpoints-metadata";
import { useLatestModelSummaryForProject } from "data/queries/models";
import { MethodMetadataResponse } from "types/akita_api_types";

interface AutocompleteOption {
  label: string;
  id: string;
  endpoint: MethodMetadataResponse;
}

const filterOptions: AutocompleteProps<
  AutocompleteOption,
  true,
  undefined,
  true
>["filterOptions"] = (options, { inputValue }) =>
  matchSorter(options, inputValue, {
    keys: ["label"],
    // When there's no value in the search input, override the sorter to return items unsorted.
    // Otherwise, use the default sorter. This has the effect of displaying the endpoints in their
    // original sort order when the dropdown is first opened, instead of listing them alphabetically.
    sorter: inputValue.length === 0 ? (matchItems) => matchItems : undefined,
  });

const renderInput: AutocompleteProps<AutocompleteOption, true, undefined, true>["renderInput"] = (
  params
) => (
  <TextField
    placeholder="Search for endpoints"
    aria-label="Search for endpoints"
    // Annoyingly, this is the only way I know of to override this default appearance.
    sx={{ fieldset: { borderColor: "divider" } }}
    autoFocus
    {...params}
  />
);

type EndpointAutocompleteProps = {
  deploymentID?: string;
  endpointID: string | undefined;
  projectID?: string;
  setEndpointID: (endpointID: string | undefined) => void;
};

export const EndpointAutocomplete = ({
  deploymentID,
  endpointID,
  projectID,
  setEndpointID,
}: EndpointAutocompleteProps) => {
  const { data: modelSummary, isLoading: isLoadingModelSummary } = useLatestModelSummaryForProject({
    projectID,
    deploymentID,
  });

  const modelID = modelSummary?.id;

  const { data: dataEndpointsMetadata, isLoading: isLoadingEndpointsMetadata } =
    useEndpointsMetadata(projectID, modelID, { sort: "count:desc" });

  const isLoading = isLoadingModelSummary || isLoadingEndpointsMetadata;

  const options = useMemo(() => {
    if (!dataEndpointsMetadata?.metadata) return [] as AutocompleteOption[];

    return dataEndpointsMetadata.metadata.map((endpoint) => ({
      label: `${endpoint.operation} ${endpoint.host}${endpoint.path}`,
      id: constructEndpointUniqueID(endpoint),
      endpoint,
    }));
  }, [dataEndpointsMetadata]);

  const autocompleteValue = useMemo(
    () => options.find((option) => option.id === endpointID),
    [options, endpointID]
  );

  const onChange = useCallback(
    (_event, newValue: AutocompleteOption | null) => {
      setEndpointID(newValue?.id);
    },
    [setEndpointID]
  );

  return (
    <Autocomplete
      openOnFocus
      autoHighlight
      disabled={isLoading}
      filterOptions={filterOptions}
      onChange={onChange}
      options={options}
      renderInput={renderInput}
      value={autocompleteValue ?? null}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <EndpointEntity endpoint={option.endpoint} isClickable />
        </ListItem>
      )}
    />
  );
};
