import { Close, Menu, MenuBook, OpenInNew } from "@mui/icons-material";
import {
  AppBar,
  Collapse,
  Drawer,
  IconButton,
  List,
  Tooltip,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { FeatureToggle } from "../../../feature_flags/components/FeatureToggle";
import { AlertsMenu } from "./AlertsMenu";
import { NavbarLink } from "./NavbarLink";
import { NavbarListItem } from "./NavbarListItem";
import { NavbarLogo } from "./NavbarLogo";
import { SettingsMenu } from "./SettingsMenu";
import { getModelPathname } from "dashboard/utils/routes";
import { useProjects } from "data/queries/projects";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useCurrentDeploymentId } from "hooks/use-current-deployment-id";
import { useCurrentProjectId } from "hooks/use-current-project-id";

const sxList = {
  alignItems: "space-between",
  display: "flex",
  height: "100%",
  listStyle: "none",
  width: "100%",
};

const sxListLeft = {
  alignItems: "center",
  display: "flex",
  flexBasis: 1,
  flexDirection: "row",
  flexGrow: 1,
  flexShrink: 0,
  height: "100%",
  justifyContent: "flex-start",
  width: "100%",
};

const sxListRight = {
  ...sxListLeft,
  flexGrow: 0,
  flexShrink: 0,
  justifyContent: "flex-end",
};

export const Navbar = () => {
  const location = useLocation();
  const theme = useTheme();
  const isNarrowViewport = useMediaQuery(theme.breakpoints.down("md"));
  const shouldBlur = useScrollTrigger({ threshold: 0, disableHysteresis: true });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const currentProjectID = useCurrentProjectId();
  const currentDeploymentID = useCurrentDeploymentId();
  const { data: projects, isLoading: isLoadingProjects } = useProjects();

  const hasProjectsOrIsLoading = isLoadingProjects || (!!projects && projects.length > 0);

  let overviewLink =
    currentProjectID && currentDeploymentID
      ? `/service/${currentProjectID}/deployment/${currentDeploymentID}/overview`
      : "/overview";

  if (!hasProjectsOrIsLoading) {
    overviewLink = "/empty-overview";
  }

  const onToggleDrawer = useCallback(() => {
    setIsDrawerOpen((ido) => !ido);
  }, []);

  const pathname = location.pathname;

  // Whenever the pathname changes, ensure the drawer is closed.
  // This makes it so that the drawer gets out of the way if the user navigates via the settings
  // or notifications menus, but a random query parameter changing for some reason won't collapse
  // the drawer.
  useEffect(
    () => () => {
      setIsDrawerOpen(false);
    },
    [pathname]
  );

  const navItems = useMemo(() => {
    const overviewLinkElement = (
      <NavbarListItem>
        <NavbarLink href={overviewLink} data-testid="navbar-link-overview">
          Overview
        </NavbarLink>
      </NavbarListItem>
    );

    // If there are no projects, only show the empty overview and first time onboarding links.
    if (!hasProjectsOrIsLoading) {
      return (
        <>
          {overviewLinkElement}

          <NavbarListItem>
            <NavbarLink href="/first-time-onboarding" data-testid="navbar-link-onboarding">
              Get Started
            </NavbarLink>
          </NavbarListItem>
        </>
      );
    }

    return (
      <>
        {overviewLinkElement}

        <NavbarListItem>
          <NavbarLink
            href={getModelPathname({
              projectID: currentProjectID,
              deploymentID: currentDeploymentID,
            })}
            data-testid="navbar-link-model"
          >
            API Model
          </NavbarLink>
        </NavbarListItem>

        <NavbarListItem>
          <NavbarLink
            href={
              currentProjectID && currentDeploymentID
                ? `/service/${currentProjectID}/deployment/${currentDeploymentID}/monitoring`
                : "/monitoring"
            }
            data-testid="navbar-link-metrics"
          >
            Metrics & Errors
          </NavbarLink>
        </NavbarListItem>

        <FeatureToggle flag={FeatureFlag.ChangeReportsPageEnabled}>
          <NavbarListItem>
            <NavbarLink
              href={
                currentProjectID && currentDeploymentID
                  ? `/service/${currentProjectID}/deployment/${currentDeploymentID}/change-report`
                  : "/change-report"
              }
              data-testid="navbar-link-change-report"
            >
              Change Report
            </NavbarLink>
          </NavbarListItem>
        </FeatureToggle>

        <FeatureToggle flag={FeatureFlag.PerformanceAlertsPageEnabled}>
          <NavbarListItem>
            <NavbarLink
              href={
                currentProjectID
                  ? `/service/${currentProjectID}/deployment/${currentDeploymentID}/performance-alerts`
                  : "/performance-alerts"
              }
              data-testid="navbar-link-monitors"
            >
              Monitors
            </NavbarLink>
          </NavbarListItem>
        </FeatureToggle>

        <NavbarListItem>
          <NavbarLink
            href={currentProjectID ? `/service/${currentProjectID}/diagnostics` : "/diagnostics"}
            data-testid="navbar-link-diagnostics"
          >
            Diagnostics
          </NavbarLink>
        </NavbarListItem>
      </>
    );
  }, [currentDeploymentID, currentProjectID, hasProjectsOrIsLoading, overviewLink]);

  return (
    <AppBar
      position="fixed"
      sx={[
        {
          zIndex: 1201,
          paddingLeft: 2,
          height: theme.spacing(7),
          boxShadow: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backdropFilter: "blur(8px)",
          transition: "background linear 200ms",
          background: theme.palette.appBar.backgroundBright,

          // Apply consistent color to IconButtons
          "& .MuiIconButton-root": {
            color: "#FFFFFF",
          },
          "& .MuiIconButton-root:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
        shouldBlur && {
          background: theme.palette.appBar.backgroundDim,
        },
      ]}
    >
      <List sx={sxList}>
        <Box sx={sxListLeft}>
          <TransitionGroup component={null}>
            {isNarrowViewport && (
              <Collapse in orientation="horizontal">
                <Tooltip title={isDrawerOpen ? "Close Menu" : "Open Menu"} placement="bottom-start">
                  <IconButton
                    onClick={onToggleDrawer}
                    sx={{
                      marginRight: 1,
                      transform: isDrawerOpen ? "rotate(90deg)" : "rotate(0deg)",
                      transition: "transform 200ms ease",
                    }}
                  >
                    {isDrawerOpen ? <Close /> : <Menu />}
                  </IconButton>
                </Tooltip>
              </Collapse>
            )}
          </TransitionGroup>
          <NavbarLogo href={overviewLink} />
          {!isNarrowViewport && navItems}
        </Box>

        {isNarrowViewport && (
          <Drawer
            anchor="top"
            open={isDrawerOpen}
            variant="temporary"
            onClose={onToggleDrawer}
            ModalProps={{ keepMounted: true }}
            PaperProps={{
              sx: [
                {
                  paddingLeft: 2,
                  paddingright: 2,
                  paddingTop: 1,
                  paddingBottom: 1,
                  top: theme.spacing(7),
                },
              ],
            }}
          >
            {navItems}
          </Drawer>
        )}

        <Box sx={sxListRight}>
          <NavbarListItem sx={{ marginRight: 0 }}>
            <Tooltip
              title={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Documentation{" "}
                  <OpenInNew
                    sx={{ marginLeft: 0.5, fontSize: "12px" }}
                    aria-label="(opens in new window)"
                  />
                </Box>
              }
            >
              <IconButton
                href="https://docs.akita.software"
                target="_blank"
                aria-label="Documentation (opens in new window)"
                data-testid="navbar-link-documentation"
              >
                <MenuBook />
              </IconButton>
            </Tooltip>
          </NavbarListItem>

          <FeatureToggle flag={FeatureFlag.PerformanceAlertsPageEnabled}>
            <NavbarListItem sx={{ marginRight: 0 }}>
              <AlertsMenu />
            </NavbarListItem>
          </FeatureToggle>

          <NavbarListItem>
            <SettingsMenu />
          </NavbarListItem>
        </Box>
      </List>
    </AppBar>
  );
};
