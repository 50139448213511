import { useQuery } from "@tanstack/react-query";
import { akitaFetch, retry } from "data/akita-fetch";
import {
  GetRecentlyAlertedMonitorsQueryParams,
  GetRecentlyAlertedMonitorsResponse,
} from "types/akita_api_types";

const getRecentlyAlertedMonitors = (
  projectID: string,
  deploymentID: string,
  queryParams?: GetRecentlyAlertedMonitorsQueryParams
) =>
  akitaFetch<GetRecentlyAlertedMonitorsResponse>(
    `/services/${projectID}/monitors/${deploymentID}/alert-history`,
    { queryParams }
  );

export const useRecentlyAlertedMonitors = ({
  projectID,
  deploymentID,
  queryParams,
  options,
}: {
  projectID?: string;
  deploymentID?: string;
  queryParams?: GetRecentlyAlertedMonitorsQueryParams;
  options?: { keepPreviousData?: boolean; enabled?: boolean; refetchInterval?: number };
}) =>
  useQuery(
    ["recently-alerted-monitors", projectID, deploymentID, queryParams],
    () => getRecentlyAlertedMonitors(projectID!, deploymentID!, queryParams),
    { enabled: !!projectID && !!deploymentID, retry, keepPreviousData: false, ...options }
  );
