import { datadogRum } from "@datadog/browser-rum";
import memoizeOne from "memoize-one";
import React from "react";
import { type OmitNative } from "react-router";
import { Route, RouteProps, useRouteMatch } from "react-router-dom";

// Memoize the callback, caching only the most recent argument. That way, if this is called more
// than once consecutively with the same path, it won't start a new view.
const startView = memoizeOne((path: string) => {
  datadogRum.startView(path);
});

export function RumRoute<
  T extends Record<string, any> = Record<string, any>,
  Path extends string = string
>(props: RouteProps<Path> & OmitNative<T, keyof RouteProps>) {
  const match = useRouteMatch(props as any);

  // If there's a match, start recording the view
  if (match) {
    startView(match.path);
  }

  return <Route {...props} />;
}
