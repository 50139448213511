import { Link, ListItem, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { AkiListTile } from "dashboard/components/AkiTile/AkiListTile";
import { ListItemLink } from "dashboard/components/ListItemLink";
import { MonitorEntity } from "dashboard/components/entities/MonitorEntity";
import { getMonitorsRoute } from "dashboard/pages/Monitors/utils";
import { monitorHasVisibleAlert } from "dashboard/utils/monitors";
import { useMonitors } from "data/queries/monitors";

interface TileActiveAlertsListProps {
  deploymentID?: string;
  isEmptyState?: boolean;
  projectID?: string;
}

export const TileActiveAlertsList = ({
  deploymentID,
  isEmptyState,
  projectID,
}: TileActiveAlertsListProps) => {
  const monitorsRoute = !!projectID && !!deploymentID && getMonitorsRoute(projectID, deploymentID);

  const { data, isLoading } = useMonitors({ projectID, deploymentID });

  const alertingMonitors = useMemo(
    () =>
      // TODO: This filtering ideally would happen on the backend
      data?.monitors?.filter((monitor) => monitorHasVisibleAlert(monitor)).slice(0, 5) ?? [],
    [data]
  );

  const items = useMemo(() => {
    if (isLoading || isEmptyState) {
      return new Array(3).fill(undefined).map((_, index) => (
        <ListItem key={index}>
          <MonitorEntity isPlaceholder={isEmptyState} isLoading={isLoading} />
        </ListItem>
      ));
    }

    if (alertingMonitors) {
      return alertingMonitors.slice(0, 5).map((monitor) => (
        <ListItemLink key={monitor.monitor_id} href={`${monitorsRoute}/${monitor.monitor_id}`}>
          <MonitorEntity monitor={{ ...monitor, in_alert_state: true }} />
        </ListItemLink>
      ));
    }

    return null;
  }, [isLoading, isEmptyState, alertingMonitors, monitorsRoute]);

  return (
    <AkiListTile
      title="Active alerts"
      spanC={4}
      emptyState={
        <Typography textAlign="center">
          Nice! There are no active alerts from your monitors.
          <br />
          {monitorsRoute && <Link href={monitorsRoute}>View all monitors.</Link>}
        </Typography>
      }
    >
      {items}
    </AkiListTile>
  );
};
