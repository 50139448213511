import { Divider } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { sortBy } from "lodash";
import React from "react";
import { PageContent } from "dashboard/components/Page";
import { QueryError } from "dashboard/components/QueryError";
import { InvitesTable } from "dashboard/pages/Settings/Organization/InvitesTable";
import { SendInvites } from "dashboard/pages/Settings/Organization/SendInvites";
import { UsersTable } from "dashboard/pages/Settings/Organization/UsersTable";
import { useOrganizationInvites } from "data/queries/organization-invites";
import { useOrganizationMembers } from "data/queries/organization-members";
import { useImpression } from "hooks/use-impression";

export const Organization = () => {
  // Get members
  const {
    data: members,
    isLoading: isLoadingMembers,
    refetch: refetchMembers,
    error: errorMembers,
  } = useOrganizationMembers();
  const sortedMembers = sortBy(members ?? [], "name");

  // Get invites
  const {
    data: invites,
    isLoading: isLoadingInvites,
    refetch: refetchInvites,
    error: errorInvites,
  } = useOrganizationInvites();
  const sortedInvites = sortBy(invites ?? [], "invitee_email");

  // Refresh tables after sending new invites
  const refresh = () => {
    void refetchInvites();
    void refetchMembers();
  };

  useImpression("Settings - Team Tab", {
    teamMembersCount: members?.length,
    pendingInvitationsCount: invites?.length,
  });

  return (
    <PageContent>
      <Typography variant="h2" marginBottom={1}>
        Team Members
      </Typography>
      <SendInvites invites={invites ? invites : []} onSent={refresh} />
      {isLoadingMembers && <LinearProgress />}
      <QueryError
        error={errorMembers}
        label="We had trouble loading your team's members."
        refetch={refetchMembers}
      />
      {!!sortedMembers && <UsersTable members={sortedMembers} refreshMembers={refetchMembers} />}

      <Divider sx={{ marginBottom: 2 }} />

      <Typography variant="h2" marginBottom={1}>
        Pending Team Member Invitations
      </Typography>
      {isLoadingInvites && <LinearProgress />}
      <QueryError
        error={errorInvites}
        label="We had trouble loading your invites."
        refetch={refetchInvites}
      />
      {!!invites && invites.length > 0 && (
        <InvitesTable invites={sortedInvites} refreshInvites={refetchInvites} />
      )}
      {!!invites && invites.length < 1 && (
        <Typography variant="body2">No outstanding invitations.</Typography>
      )}
    </PageContent>
  );
};
