import { GetServicesResponse } from "types/akita_api_types";

export const maybeGetLastObservedTime = (
  projects?: GetServicesResponse,
  currentProjectId?: string,
  currentDeploymentID?: string
) => {
  if (!projects || !currentDeploymentID) return undefined;

  const currentProject = projects.find((project) => project.id === currentProjectId);
  const currentDeployment = currentProject?.deployment_infos?.find(
    (deploymentInfo) => deploymentInfo.name === currentDeploymentID
  );

  if (!currentDeployment?.last_observed) return undefined;
  return new Date(currentDeployment.last_observed);
};
