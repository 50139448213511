import { Edit } from "@mui/icons-material";
import { Button, Grow } from "@mui/material";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { MissingDeployment } from "../Service/MissingDeployment";
import { ModelEndpointsTable } from "./ModelEndpointsTable";
import { ModelPageEditBanner } from "./ModelPageEditBanner";
import { ModelPageHeader } from "./ModelPageHeader";
import { ModelSummaryTiles } from "dashboard/components/ModelSummaryTiles/ModelSummaryTiles";
import { Page, PageContent } from "dashboard/components/Page";
import { QueryError } from "dashboard/components/QueryError";
import { useLatestModelSummaryForProject } from "data/queries/models";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useImpression } from "hooks/use-impression";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";
import { useLogInteraction } from "hooks/use-log-interaction";
import { useSearchParamBool } from "hooks/use-search-param-bool";

export const ModelPageForDeployment = () => {
  const logInteraction = useLogInteraction();
  const isEnabledModelPageEditMode = useIsFlagEnabled(FeatureFlag.ModelPageEditModeEnabled);

  const { serviceID: projectID, deploymentID } = useParams<{
    serviceID: string;
    deploymentID: string;
  }>();

  const [isEditModeActive, setIsEditModeActive] = useSearchParamBool("edit");

  const exitEditMode = useCallback(() => {
    setIsEditModeActive(false);
    logInteraction("Exited Edit Mode on API model page");
  }, [setIsEditModeActive, logInteraction]);

  const enterEditMode = useCallback(() => {
    setIsEditModeActive(true);
    logInteraction("Entered Edit Mode on API model page");
  }, [setIsEditModeActive, logInteraction]);

  const {
    data: modelSummary,
    isLoading: isLoadingModelSummary,
    error: errorModelSummary,
    refetch: refetchModelSummary,
  } = useLatestModelSummaryForProject({
    projectID,
    deploymentID,
    queryParams: { key: ["tags"] },
  });

  const isModelNotFound = !isLoadingModelSummary && !modelSummary;
  const modelID = modelSummary?.id;
  const isSettingsDisabled = !modelID || isModelNotFound || isEditModeActive;

  useImpression("Model Page (for deployment)", { projectID, deploymentID, modelID });

  return (
    <Page>
      <ModelPageHeader
        modelID={modelID}
        projectID={projectID}
        isSettingsDisabled={isSettingsDisabled}
      >
        {isEnabledModelPageEditMode && (
          <Button
            disabled={isSettingsDisabled}
            onClick={enterEditMode}
            variant="outlined"
            startIcon={<Edit />}
            color="inherit"
            size="small"
            sx={{
              border: (theme) => `1px solid ${theme.palette.divider}`,
              marginLeft: 1.5,
            }}
          >
            Edit
          </Button>
        )}
      </ModelPageHeader>

      <PageContent>
        {isModelNotFound ? (
          <MissingDeployment projectID={projectID} />
        ) : (
          <QueryError
            error={errorModelSummary}
            label="We had trouble loading your model."
            refetch={refetchModelSummary}
          />
        )}

        {!isModelNotFound && (
          <>
            <TransitionGroup component={null}>
              {!isEditModeActive && (
                // The bare `div` wrapper is needed here since `Grow` needs a child that can take a ref.
                <Grow timeout={{ enter: 400, exit: 0 }} appear={false}>
                  <div>
                    <ModelSummaryTiles projectID={projectID} modelID={modelID} />
                  </div>
                </Grow>
              )}

              {isEditModeActive && !!modelID && !!projectID && (
                <Grow timeout={{ enter: 400, exit: 0 }} appear={false}>
                  <div>
                    <ModelPageEditBanner
                      modelID={modelID}
                      projectID={projectID}
                      exitEditMode={exitEditMode}
                    />
                  </div>
                </Grow>
              )}
            </TransitionGroup>

            <ModelEndpointsTable
              projectID={projectID}
              modelID={modelID}
              isSelectable={isEditModeActive}
            />
          </>
        )}
      </PageContent>
    </Page>
  );
};
