import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UseMutationOptions } from "@tanstack/react-query/src/types";
import { useCallback } from "react";
import { AkitaFetchError, akitaFetch, retry } from "data/akita-fetch";
import {
  GetMonitorHistoryResponse,
  GetMonitorsQueryParams,
  GetMonitorsResponse,
  Monitor,
  MonitorToCreate,
  MonitorUpdate,
} from "types/akita_api_types";

// The query key React Query uses to identify calls to getMonitors.
const getMonitorsQueryKey = (
  projectID: string | undefined,
  deploymentID: string | undefined,
  queryParams?: GetMonitorsQueryParams
) => {
  const queryKey: (string | undefined | GetMonitorsQueryParams)[] = [
    "monitors",
    projectID,
    deploymentID,
  ];

  if (queryParams) {
    queryKey.push(queryParams);
  }

  return queryKey;
};

// Get monitors (without history).
const getMonitors = (
  projectID: string,
  deploymentID: string,
  queryParams?: GetMonitorsQueryParams
) =>
  akitaFetch<GetMonitorsResponse>(`/services/${projectID}/monitors/${deploymentID}`, {
    queryParams,
  });

// React Query-based hook for getting monitors.
export const useMonitors = ({
  projectID,
  deploymentID,
  queryParams,
  options = {},
}: {
  projectID?: string;
  deploymentID?: string;
  queryParams?: GetMonitorsQueryParams;
  options?: { keepPreviousData?: boolean; enabled?: boolean; refetchInterval?: number };
}) =>
  useQuery(
    getMonitorsQueryKey(projectID, deploymentID, queryParams),
    () => getMonitors(projectID!, deploymentID!, queryParams),
    {
      enabled: !!(projectID && deploymentID),
      retry,
      keepPreviousData: false,
      ...options,
    }
  );

// The query key React Query uses to identify calls to getMonitor.
const getMonitorQueryKey = (monitorID: string, projectID?: string, deploymentID?: string) => [
  "monitor",
  projectID,
  deploymentID,
  monitorID,
];

// Get a single monitor by ID.  Returns null if projectID or
// deploymentID are undefined.
const getMonitor = (monitorID: string, projectID: string, deploymentID: string) =>
  akitaFetch<Monitor>(`/services/${projectID}/monitors/${deploymentID}/monitor/${monitorID}`);

// React Query-based hook for getting a single monitor.
export const useMonitor = (monitorID: string, projectID?: string, deployment?: string) =>
  useQuery(
    getMonitorQueryKey(monitorID, projectID, deployment),
    () => getMonitor(monitorID, projectID!, deployment!),
    {
      enabled: !!(projectID && deployment),
      keepPreviousData: true,
      retry,
    }
  );

// Returns a function that, when called, invalidates the cache and forces React Query
// to refetch this monitor.
export const useRefreshMonitor = (monitorID: string, projectID?: string, deploymentID?: string) => {
  const queryClient = useQueryClient();

  return useCallback(
    () =>
      void queryClient.invalidateQueries(getMonitorQueryKey(monitorID, projectID, deploymentID)),
    [deploymentID, monitorID, queryClient, projectID]
  );
};

// The query key React Query uses to identify calls to getMonitorHistory.
const getMonitorHistoryQueryKey = (
  projectID?: string,
  deploymentID?: string,
  monitorID?: string
) => ["monitor-history", projectID, deploymentID, monitorID];

// Get a monitor's history.
const getMonitorHistory = async (projectID: string, deployment: string, monitorID: string) => {
  const result = await akitaFetch<GetMonitorHistoryResponse>(
    `/services/${projectID}/monitors/${deployment}/history/${monitorID}`
  );

  return result?.history ?? [];
};

// React Query-based hook for getting a monitor's history.
export const useMonitorHistory = (projectID?: string, deploymentID?: string, monitorID?: string) =>
  useQuery(
    getMonitorHistoryQueryKey(projectID, deploymentID, monitorID),
    () => getMonitorHistory(projectID!, deploymentID!, monitorID!),
    {
      enabled: !!(projectID && deploymentID && monitorID),
      keepPreviousData: true,
      retry,
    }
  );

// Returns a function that, when called, invalidates the cache and forces React Query
// to refetch this monitor's history.
export const useRefreshMonitorHistory = (
  monitorID: string,
  projectID?: string,
  deploymentID?: string
) => {
  const queryClient = useQueryClient();

  return useCallback(
    () =>
      void queryClient.invalidateQueries(
        getMonitorHistoryQueryKey(projectID, deploymentID, monitorID)
      ),
    [deploymentID, monitorID, queryClient, projectID]
  );
};

const useInvalidateMonitors = (projectID?: string, deploymentID?: string) => {
  const queryClient = useQueryClient();

  return useCallback(
    (monitorIDs?: string[]) => {
      if (!projectID || !deploymentID) return;

      void queryClient.invalidateQueries(getMonitorsQueryKey(projectID, deploymentID));

      if (monitorIDs) {
        monitorIDs.forEach((monitorID) => {
          void queryClient.invalidateQueries(
            getMonitorQueryKey(monitorID, projectID, deploymentID)
          );
        });
      }
    },
    [queryClient, projectID, deploymentID]
  );
};

const updateMonitors = (projectID: string, deploymentID: string, monitorUpdates: MonitorUpdate[]) =>
  akitaFetch<Record<string, never>>(`/services/${projectID}/monitors/${deploymentID}`, {
    method: "PUT",
    body: { updates: monitorUpdates },
  });

export function useUpdateMonitors(
  projectID: string,
  deploymentID: string,
  options?: Omit<
    UseMutationOptions<Record<string, never> | undefined, AkitaFetchError, MonitorUpdate[]>,
    "mutationFn"
  >
) {
  const invalidateMonitors = useInvalidateMonitors(projectID, deploymentID);

  return useMutation(
    (monitorUpdates: MonitorUpdate[]) => updateMonitors(projectID, deploymentID, monitorUpdates),
    {
      ...options,
      onSuccess: (data, monitorUpdates, context) => {
        invalidateMonitors(monitorUpdates.map(({ monitor_id }) => monitor_id));

        const callerOnSuccess = options?.onSuccess;
        if (callerOnSuccess) {
          return callerOnSuccess(data, monitorUpdates, context);
        }
      },
    }
  );
}

const useChangeMonitorState = (
  projectID: string | undefined,
  deploymentID: string | undefined,
  newState: "accept" | "suppress"
) => {
  const invalidateMonitors = useInvalidateMonitors(projectID, deploymentID);

  return useMutation(
    (monitorIDs: string[]) =>
      akitaFetch<any>(`/services/${projectID}/monitors/${deploymentID}/${newState}`, {
        method: "POST",
        body: {
          monitor_ids: monitorIDs,
        },
      }),
    {
      onSuccess: (_data, monitorIDs) => invalidateMonitors(monitorIDs),
    }
  );
};

export const useAcceptMonitor = (projectID?: string, deploymentID?: string) =>
  useChangeMonitorState(projectID, deploymentID, "accept");

export const useSuppressMonitor = (projectID?: string, deploymentID?: string) =>
  useChangeMonitorState(projectID, deploymentID, "suppress");

export const useCreateMonitor = (
  projectID: string | undefined,
  deploymentID: string | undefined,
  { onSuccess }: { onSuccess?: () => void } = {}
) => {
  const invalidateMonitors = useInvalidateMonitors(projectID, deploymentID);

  return useMutation(
    (monitorToCreate: MonitorToCreate) =>
      akitaFetch<any>(`/services/${projectID}/monitors/${deploymentID}`, {
        method: "POST",
        body: {
          monitors: [monitorToCreate],
        },
      }),
    {
      onSuccess: () => {
        invalidateMonitors();

        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};
