import { ChevronRight } from "@mui/icons-material";
import { IconButton, Skeleton, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { AkiTableCell } from "dashboard/components/AkiTable/AkiTableCell";
import { AkiTableRow } from "dashboard/components/AkiTable/AkiTableRow";
import { SkeletonText } from "dashboard/components/SkeletonText";
import { EndpointEntity } from "dashboard/components/entities/EndpointEntity";
import { MonitorStatusEntity } from "dashboard/components/entities/MonitorStatusEntity";
import {
  getMonitorEndpoint,
  getMonitorSignalLabel,
  getRecentMonitorEndTimeLabel,
  getRecentMonitorStartTimeLabel,
} from "dashboard/utils/monitors";
import { RecentlyAlertedMonitor } from "types/akita_api_types";

type MonitorsTableRecentRowProps =
  | {
      deploymentID?: string;
      isLoading: true;
      monitor?: RecentlyAlertedMonitor;
      projectID?: string;
    }
  | {
      deploymentID: string;
      isLoading?: boolean;
      monitor: RecentlyAlertedMonitor;
      projectID: string;
    };

export const MonitorsTableRecentRow = ({
  deploymentID,
  isLoading,
  monitor,
  projectID,
}: MonitorsTableRecentRowProps) => {
  const hrefDetails = `/service/${projectID}/deployment/${deploymentID}/performance-alerts/${monitor?.monitor_id}`;
  const history = useHistory();
  const onClickRow = useCallback(() => {
    history.push(hrefDetails);
  }, [history, hrefDetails]);

  if (isLoading) {
    return (
      <AkiTableRow>
        <AkiTableCell sx={{ paddingLeft: 2 }}>
          <MonitorStatusEntity isLoading />
        </AkiTableCell>

        <AkiTableCell>
          <SkeletonText width={86} randomWidth={false} />
        </AkiTableCell>

        <AkiTableCell grow sx={{ minWidth: 200 }}>
          <EndpointEntity isLoading />
        </AkiTableCell>

        <AkiTableCell>
          <SkeletonText minWidth={100} maxWidth={200} />
        </AkiTableCell>

        <AkiTableCell>
          <SkeletonText minWidth={100} maxWidth={200} />
        </AkiTableCell>

        <AkiTableCell align="right">
          <Skeleton
            variant="circular"
            sx={{ flexShrink: 0, width: 36, height: 36, borderRadius: "100%" }}
          />
        </AkiTableCell>
      </AkiTableRow>
    );
  }

  return (
    <AkiTableRow hover onClick={onClickRow}>
      <AkiTableCell sx={{ paddingLeft: 2 }}>
        <MonitorStatusEntity monitor={monitor} />
      </AkiTableCell>

      <AkiTableCell>
        <Typography variant="subtitle2">{getMonitorSignalLabel(monitor.signal)}</Typography>
      </AkiTableCell>

      <AkiTableCell grow sx={{ minWidth: 200 }}>
        <EndpointEntity endpoint={getMonitorEndpoint(monitor)} />
      </AkiTableCell>

      <AkiTableCell>{getRecentMonitorStartTimeLabel(monitor)}</AkiTableCell>
      <AkiTableCell>{getRecentMonitorEndTimeLabel(monitor)}</AkiTableCell>

      <AkiTableCell align="right">
        <IconButton aria-label="View detailed information" href={hrefDetails}>
          <ChevronRight />
        </IconButton>
      </AkiTableCell>
    </AkiTableRow>
  );
};
