import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { AkitaUserInfo } from "types/akita_api_types";

type UsersTableProps = {
  members: AkitaUserInfo[] | null;
  refreshMembers: () => void;
};

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:last-child": {
      borderBottom: `0px solid ${theme.palette.divider}`,
    },
  },
}));

export const UsersTable = (props: UsersTableProps) => {
  const classes = useStyles();

  return (
    <div>
      {props.members?.map((member) => (
        <Grid container key={member.id} alignItems="center" className={classes.border}>
          <Grid item xs={4} zeroMinWidth>
            <Typography variant="body2">{member.name}</Typography>
          </Grid>
          <Grid item zeroMinWidth>
            <Typography variant="subtitle2">{(member as any).email}</Typography>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Typography variant="subtitle2">
              {new Date(member.created_at).toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};
