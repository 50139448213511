import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import React from "react";

export const Footer = () => (
  <Grid
    component="footer"
    container
    sx={(theme) => ({
      fontSize: (theme) => theme.typography.body2.fontSize,
      justifyContent: "space-between",
      padding: 2,
      paddingBottom: 3,
      paddingTop: 3,
      [theme.breakpoints.up("sm")]: {
        // Push the contents over to clear a "parking space" for the Intercom button
        paddingRight: "100px",
        paddingLeft: "20px",
      },
      "& a": {
        color: "text.primary",
      },
    })}
  >
    <Grid item>
      <Grid container spacing={3}>
        <Grid item>
          <Link href="https://docs.akita.software">Docs</Link>
        </Grid>
        <Grid item>
          <Link href="https://github.com/akitasoftware">Github</Link>
        </Grid>
        <Grid item>
          <Link href="https://akita-community.slack.com/">Slack</Link>
        </Grid>
        <Grid item>
          <Link href="https://www.akitasoftware.com/blog">Akita Blog</Link>
        </Grid>
        <Grid item>
          <Link href="https://docs.akita.software/docs/terms-of-service">Terms</Link>
        </Grid>
      </Grid>
    </Grid>
    <Grid item>&copy; {new Date().getFullYear()} Akita Software, Inc. All rights reserved.</Grid>
  </Grid>
);
