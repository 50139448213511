import { Box, BoxProps } from "@mui/material";
import React from "react";
import { HTTPMethodEntity } from "./HTTPMethodEntity";
import { MonitorStatusEntity } from "./MonitorStatusEntity";
import { SkeletonText } from "dashboard/components/SkeletonText";
import { mergeSxProps } from "dashboard/utils/sx";
import { Monitor } from "types/akita_api_types";

type MonitorEntityProps = BoxProps & {
  isLoading?: boolean;
  isPlaceholder?: boolean;
  monitor?: Monitor;
  randomWidthPlaceholder?: boolean;
};

export const MonitorEntity = ({
  monitor,
  isLoading,
  isPlaceholder,
  sx,
  randomWidthPlaceholder = false,
  ...rest
}: MonitorEntityProps) => {
  if (!monitor && (isLoading || isPlaceholder)) {
    return (
      <SkeletonText
        animation={isPlaceholder ? false : "pulse"}
        randomWidth={randomWidthPlaceholder}
      />
    );
  }

  if (!monitor) {
    return null;
  }

  return (
    <Box
      sx={mergeSxProps(
        [
          {
            display: "flex",
            alignItems: "center",
            minWidth: 0,
            width: "100%",
          },
          monitor.in_alert_state && { color: (theme) => theme.palette.error.main },
        ],
        sx
      )}
      {...rest}
    >
      <MonitorStatusEntity monitor={monitor} />
      <HTTPMethodEntity httpMethod={monitor.http_method} isError={monitor.in_alert_state} />
      <Box
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {monitor.host}
        {monitor.path_template}
      </Box>
    </Box>
  );
};
