import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { AcceptInviteButton } from "./AcceptInviteButton";
import { OrganizationInvite } from "types/akita_api_types";

type AcceptInviteModalProps = {
  close: () => void;
  invite: OrganizationInvite;
  open: boolean;
  showWarning: boolean;
  setAlert: (alert: JSX.Element) => void;
};

export const AcceptInviteModal = ({
  close,
  invite,
  open,
  showWarning,
  setAlert,
}: AcceptInviteModalProps) => {
  const { authState } = useOktaAuth();

  return (
    <Dialog open={open} keepMounted onClose={close}>
      <DialogTitle id="notice-modal-slide-title">
        <Typography component="span" variant="h2">
          You&rsquo;ve been invited to join a team! 🎉
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {invite.inviter_name} ({invite.inviter_email}) has invited you to join their team!
        </Typography>
        {showWarning && (
          <Box paddingTop={2} paddingBottom={2}>
            <Typography variant="h3" fontWeight="bold" gutterBottom>
              Warning!
            </Typography>
            <Typography>
              Joining another team means leaving your API models behind. You will no longer have
              access to:
            </Typography>
            <List sx={{ paddingLeft: 2, listStyle: "initial" }}>
              <ListItem sx={{ display: "list-item", paddingLeft: 0, paddingRight: 0 }}>
                your projects
              </ListItem>
              <ListItem sx={{ display: "list-item", paddingLeft: 0, paddingRight: 0 }}>
                your API models
              </ListItem>
              <ListItem sx={{ display: "list-item", paddingLeft: 0, paddingRight: 0 }}>
                your API keys
              </ListItem>
            </List>

            <Typography gutterBottom>
              Instead, you&rsquo;ll have access to the projects and models of the team you&rsquo;re
              joining.
            </Typography>

            <Typography>
              As a security precaution, your API keys (which are tied to you, not your team) will be
              revoked when you change teams. This cannot be undone.
            </Typography>
          </Box>
        )}

        <Divider />

        <Grid
          container
          sx={{
            paddingTop: 2,
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row-reverse",
          }}
        >
          <Grid item>
            <AcceptInviteButton
              accessToken={authState?.accessToken?.accessToken as any}
              invite={invite}
              onClick={close}
              setAlert={setAlert}
            />
          </Grid>

          <Grid item>
            <Button onClick={close}>Maybe Later</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
