import { Check, ErrorOutline } from "@mui/icons-material";
import { Box, Divider, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import {
  getMonitorComparatorDescription,
  getMonitorSignalLabel,
  getMonitorSignalUnits,
  getMonitorThresholdStrForDisplay,
} from "dashboard/utils/monitors";
import { Monitor } from "types/akita_api_types";

type MonitorStatusTileProps = {
  monitor?: Monitor;
  isLoading?: boolean;
};

export const MonitorStatusDescription = ({ monitor, isLoading }: MonitorStatusTileProps) => {
  const theme = useTheme();

  if (isLoading) {
    return <Skeleton variant="rectangular" sx={{ display: "block", height: 51 }} />;
  }

  if (!monitor) return null;

  const color = monitor.in_alert_state ? theme.palette.error : theme.palette.success;
  const monitorStatusText = monitor.in_alert_state ? "ALERT" : "OK";

  return (
    <Box>
      <Stack
        spacing={0.5}
        alignItems="center"
        direction="row"
        sx={{
          background: color.main,
          padding: 1,
          color: color.contrastText,
        }}
      >
        {monitor.in_alert_state ? <ErrorOutline fontSize="large" /> : <Check fontSize="large" />}
        {!isLoading && (
          <Typography variant="h3" fontWeight="bold" component="div">
            {monitorStatusText}
          </Typography>
        )}
      </Stack>

      <Box padding={1}>
        <Typography>
          This monitor is in an <strong>{monitorStatusText}</strong> state, because the{" "}
          <strong>{getMonitorSignalLabel(monitor.signal)}</strong>{" "}
          {monitor.in_alert_state ? "is " : "is not "}
          <strong>
            {getMonitorComparatorDescription(monitor.comparator)}{" "}
            {getMonitorThresholdStrForDisplay(monitor.signal, monitor.threshold)}
            {getMonitorSignalUnits(monitor.signal)}
          </strong>
          .
        </Typography>

        {monitor.min_event_count > 0 && (
          <>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            <Typography>
              This monitor will only trigger an alert when the number of matching events in the last
              five minutes is <strong>greater than {monitor.min_event_count}</strong>.
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
