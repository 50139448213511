import { isEmpty } from "lodash";
import queryString from "query-string";
import { getAccessToken } from "auth/config";

type AkitaFetchResponseBodyType = "arrayBuffer" | "blob" | "formData" | "string" | "json";
type AkitaFetchHTTPMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

interface AkitaFetchParams {
  method?: AkitaFetchHTTPMethod;
  queryParams?: Record<string, any>;
  body?: Record<string, any>;
  authOverride?: Record<string, any>;
  responseBodyType?: AkitaFetchResponseBodyType;
}

/**
 * Wrapper around `fetch` which handles auth and provides some reasonable defaults.
 * @param path Akita API endpoint, with prefixed `/`
 * @param params Optional object containing body object, method, auth override, and/or queryParams.
 * @param init Optional RequestInit object (same type as the second arg of `fetch`), for more fine-grained control.
 * @returns Promise containing the JSONified response body.
 */
export const akitaFetch = async <T = unknown>(
  path: `/${string}`,
  params?: AkitaFetchParams,
  init?: RequestInit
) => {
  // If no authOverride, check for the access token (or throw an error if it's not present).
  const accessToken = params?.authOverride ? null : await getAccessToken();

  // Destructure the params object, with sensible defaults
  const { body, method = "GET", queryParams = {}, responseBodyType = "json" } = params ?? {};

  const stringifiedParams = !isEmpty(queryParams) ? queryString.stringify(queryParams) : undefined;

  // There isn't a good way to prohibit the leading /v1 at a TypeScript level, so we'll strip it out
  // at runtime if it's present.
  const formattedPath = path.startsWith("/v1") ? path.slice(3) : path;
  let url = `${process.env.REACT_APP_API_URL}/v1${formattedPath}`;

  if (stringifiedParams) {
    url = url.concat(`?${stringifiedParams}`);
  }

  const authObject = params?.authOverride || { Authorization: `Bearer ${accessToken}` };

  const response = await fetch(url, {
    method,
    headers: {
      ...authObject,
      // If there's a body object present, set the Content-Type to json by default
      ...(!isEmpty(body) ? { "Content-Type": "application/json" } : {}),
      ...init?.headers,
    },
    ...(!isEmpty(body) ? { body: JSON.stringify(body) } : {}),
    ...init,
  });

  if (!response.ok) throw new AkitaFetchError(response, "Response was not ok");

  switch (responseBodyType) {
    case "arrayBuffer":
      return (await response.arrayBuffer()) as unknown as T;
    case "blob":
      return (await response.blob()) as unknown as T;
    case "formData":
      return (await response.formData()) as unknown as T;
    case "string":
      return (await response.text()) as unknown as T;
    case "json":
    default:
      return (await response.json()) as T;
  }
};

export class AkitaFetchError extends Error {
  constructor(public response: Response, message?: string) {
    super(message);
  }
}

// React Query retries on some responses I'd prefer not to retry on, like
// 404s.  This defines a sensible retry policy.
const retriableErrors: Record<number, boolean> = {
  // Request timeout due to slow send by client.
  408: true,
  // Rate limiting.
  429: true,
  // Optimistically retry, in case this is a transient error.
  500: true,
  // Bad gateway.
  502: true,
  // Service unavailable.
  503: true,
  // Gateway timeout.
  504: true,
};

export function retry(retryCount: number, err: AkitaFetchError): boolean {
  const responseCode = err?.response?.status;
  const isRetriableError = !!responseCode && retriableErrors.hasOwnProperty(responseCode);
  return retryCount < 4 && isRetriableError;
}
