import { noop } from "lodash";
import { MutableRefObject, createContext } from "react";
import { DisplayTimelineValue } from "dashboard/utils/timelines";

export type TooltipLocation = {
  x: number;
  y: number;
  activePoints: DisplayTimelineValue[];
};

type ChartTooltipContextValue = {
  containerRef?: MutableRefObject<HTMLElement | null>;
  containerHeight: number;
  containerWidth: number;
  containerPadding: { left: number; bottom: number; top: number; right: number };
  isTooltipEnabled: boolean;
  tooltipLocation: TooltipLocation | undefined;
  setTooltipLocation: React.Dispatch<React.SetStateAction<TooltipLocation | undefined>>;
};

export const ChartTooltipContext = createContext<ChartTooltipContextValue>({
  containerRef: undefined,
  containerHeight: 0,
  containerPadding: { left: 0, bottom: 0, top: 0, right: 0 },
  containerWidth: 0,
  isTooltipEnabled: false,
  setTooltipLocation: noop,
  tooltipLocation: undefined,
});
