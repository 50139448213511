import { Box, Typography } from "@mui/material";
import React from "react";

export const MetricsAndErrorsGraphEmptyState = ({
  height,
  hasEndpointsSelected,
}: {
  height?: number;
  hasEndpointsSelected: boolean;
}) => (
  <Box
    sx={{
      width: "100%",
      height: height ?? "auto",
      alignItems: "center",
      display: "flex",
      padding: 1,
    }}
  >
    <Typography width="100%" textAlign="center">
      {hasEndpointsSelected
        ? "No data to display for the selected time range."
        : "No endpoints selected for display."}
    </Typography>
  </Box>
);
