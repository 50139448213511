import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { akitaFetch } from "data/akita-fetch";
import { GenerateApiKeyResponse, GetApiKeysResponse } from "types/akita_api_types";

const getAPIKeys = () => akitaFetch<GetApiKeysResponse>("/api_keys");

export const useAPIKeys = () => useQuery(["api-keys"], getAPIKeys);

const postAPIKeys = () => akitaFetch<GenerateApiKeyResponse>("/api_keys", { method: "POST" });

export const useGenerateAPIKey = (options?: {
  onSuccess: (apiKey: GenerateApiKeyResponse) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation(postAPIKeys, {
    onSuccess: async (apiKey) => {
      await queryClient.invalidateQueries(["api-keys"]);

      if (options?.onSuccess) {
        options.onSuccess(apiKey);
      }
    },
  });
};
