import { Box, BoxProps } from "@mui/material";
import React from "react";
import { mergeSxProps } from "dashboard/utils/sx";

export type AkiGridItemProps = Omit<BoxProps, "gridColumn" | "gridRow"> & {
  /** Span length used for grid-column property. Defaults to 1. */
  spanC?: number;
  /** Span length used for grid-row property. Defaults to 1. */
  spanR?: number;
  /** Column span below the "md" breakpoint. Defaults to 1. */
  mdSpanC?: number;
  /** Row span below the "md" breakpoint. Defaults to value of spanR. */
  mdSpanR?: number;
  /** Ignore the spanC value and define the entire gridColumn value.
   * Useful for when exact positioning is desired. */
  gridColumn?: string;
  /** Ignore the spanR value and define the entire gridRow value.
   * Useful for when exact positioning is desired. */
  gridRow?: string;
  /** Ignore the mdSpanC value and define the entire gridColumn value below the "md" breakpoint.
   * Useful for when exact positioning is desired. */
  mdGridColumn?: string;
  /** Ignore the mdSpanR value and define the entire gridRow value below the "md" breakpoint.
   * Useful for when exact positioning is desired. */
  mdGridRow?: string;
};

export const AkiGridItem = ({
  children,
  sx,
  spanC = 1,
  spanR = 1,
  mdSpanC = 1,
  mdSpanR = spanR,
  gridColumn,
  gridRow,
  mdGridColumn,
  mdGridRow,
  ...rest
}: AkiGridItemProps) => (
  <Box
    sx={mergeSxProps(
      (theme) => ({
        gridColumn: gridColumn || `span ${spanC}`,
        gridRow: gridRow || `span ${spanR}`,
        minWidth: 0,
        [theme.breakpoints.down("md")]: {
          gridColumn: mdGridColumn || `span ${mdSpanC}`,
          gridRow: mdGridRow || `span ${mdSpanR}`,
        },
      }),
      sx
    )}
    {...rest}
  >
    {children}
  </Box>
);
