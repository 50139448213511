import { Alert, Button, Link, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { setIsAkiChatTermsAcceptedInStorage } from "dashboard/utils/aki-chat";
import { useImpression } from "hooks/use-impression";
import { useLogInteraction } from "hooks/use-log-interaction";

type AkiChatTermsProps = {
  setIsTermsAccepted: (isTermsAccepted: boolean) => void;
};

export const AkiChatTerms = ({ setIsTermsAccepted }: AkiChatTermsProps) => {
  const logInteraction = useLogInteraction();

  const onAcceptTerms = useCallback(() => {
    setIsAkiChatTermsAcceptedInStorage(true);
    setIsTermsAccepted(true);
    logInteraction("Accepted Aki Chat terms");
  }, [setIsTermsAccepted, logInteraction]);

  useImpression("Aki Chat Terms");

  return (
    <Stack spacing={1} alignItems="center">
      <Alert severity="warning">
        <strong>Aki is an Alpha-level feature, and is very much a work in progress.</strong> Aki,
        based on OpenAI’s ChatGPT large language model, warrants the same level of caution as using
        ChatGPT itself. While our testing has shown that Aki generally makes truthful statements
        when asked about the API data it has been provided, this isn’t always the case, and large
        language models like ChatGPT are prone to fabricating information and behaving in unexpected
        ways.
      </Alert>

      <Typography variant="body2">
        By clicking &ldquo;Continue&rdquo; below, you authorize us to send the OpenAPI3 spec of your
        endpoint (and any future relevent pieces of data that we may need to make available for Aki)
        to OpenAI, where it will be handled pursuant to their{" "}
        <Link href="https://openai.com/policies/api-data-usage-policies">
          API data usage policies
        </Link>
        .
      </Typography>

      <Button variant="contained" size="large" onClick={onAcceptTerms}>
        Continue
      </Button>
    </Stack>
  );
};
