import { ChevronRight } from "@mui/icons-material";
import { Checkbox, IconButton, Tooltip } from "@mui/material";
import React, { MouseEvent, useCallback } from "react";
import { AkiTableCell } from "../AkiTable/AkiTableCell";
import { AkiTableRow } from "../AkiTable/AkiTableRow";
import { EndpointEntity } from "../entities/EndpointEntity";
import { HTTPMethodEntity } from "../entities/HTTPMethodEntity";
import { ResponseCodeEntity } from "../entities/ResponseCodeEntity";
import { EndpointsTableEndpoint } from "./formatting";
import { SkeletonText } from "dashboard/components/SkeletonText";
import { Percentile } from "dashboard/utils/percentiles";
import { useLogInteraction } from "hooks/use-log-interaction";

type EndpointTableRowProps =
  | {
      endpoint: EndpointsTableEndpoint;
      isLoading?: false;
      isSelectable?: boolean;
      isSelected?: boolean;
      onChangeSelect?: (endpoint: EndpointsTableEndpoint, isSelected: boolean) => void;
      openDetails: (endpoint: EndpointsTableEndpoint) => void;
      percentile: Percentile;
      selectedHighlightColor?: string;
      showResponseCode?: boolean;
    }
  | {
      endpoint?: EndpointsTableEndpoint;
      isLoading: true;
      isSelectable?: boolean;
      isSelected?: boolean;
      onChangeSelect?: (endpoint: EndpointsTableEndpoint, isSelected: boolean) => void;
      openDetails?: (endpoint: EndpointsTableEndpoint) => void;
      percentile: Percentile;
      selectedHighlightColor?: string;
      showResponseCode?: boolean;
    };

export const EndpointTableRow = ({
  endpoint,
  isLoading,
  isSelectable,
  isSelected,
  onChangeSelect,
  openDetails,
  percentile,
  selectedHighlightColor,
  showResponseCode,
}: EndpointTableRowProps) => {
  const logInteraction = useLogInteraction({ endpointID: endpoint?.uniqueID });

  const onToggleSelected = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (isLoading || !onChangeSelect) return;

      onChangeSelect(endpoint, !isSelected);
    },
    [endpoint, isLoading, isSelected, onChangeSelect]
  );

  const onClickDetails = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (isLoading) return;

      logInteraction("Clicked to open Endpoint Details modal");
      openDetails(endpoint);
    },
    [isLoading, endpoint, openDetails, logInteraction]
  );

  const onClickContainer = useCallback(
    (event: MouseEvent) => {
      if (isSelectable) {
        onToggleSelected(event);
      } else {
        onClickDetails(event);
      }
    },
    [isSelectable, onToggleSelected, onClickDetails]
  );

  if (isLoading) {
    return (
      <AkiTableRow>
        {isSelectable && (
          <AkiTableCell sx={{ paddingLeft: 1, paddingRight: 0 }}>
            <Checkbox disabled sx={{ padding: "6px" }} />
          </AkiTableCell>
        )}
        <AkiTableCell sx={{ minWidth: 58, paddingLeft: isSelectable ? 1 : 2, paddingRight: 0 }}>
          <HTTPMethodEntity isLoading sx={{ marginRight: 0 }} />
        </AkiTableCell>
        <AkiTableCell grow sx={{ paddingLeft: 0, paddingRight: 0 }}>
          <EndpointEntity noMethod isLoading />
        </AkiTableCell>
        {showResponseCode && (
          <AkiTableCell align="right" sx={{ minWidth: 38 }}>
            <SkeletonText minWidth={34} maxWidth={34} />
          </AkiTableCell>
        )}
        <AkiTableCell align="right" sx={{ minWidth: 50 }}>
          <SkeletonText minWidth={30} maxWidth={50} />
        </AkiTableCell>
        <AkiTableCell align="right" sx={{ minWidth: 50 }}>
          <SkeletonText minWidth={30} maxWidth={50} />
        </AkiTableCell>
        <AkiTableCell align="right" sx={{ minWidth: 52, paddingLeft: 0, paddingRight: 1 }}>
          <IconButton disabled>
            <ChevronRight />
          </IconButton>
        </AkiTableCell>
      </AkiTableRow>
    );
  }

  const labelPercentile = Number.isFinite(endpoint[percentile])
    ? `${endpoint[percentile]?.toLocaleString()}ms`
    : "N/A";

  const labelCount = endpoint.count?.toLocaleString() ?? 0;

  return (
    <AkiTableRow data-testid="endpoints-table-row" hover onClick={onClickContainer}>
      {isSelectable && (
        <AkiTableCell
          sx={(theme) => ({
            paddingLeft: 1,
            paddingRight: 0,
            position: "relative",
            "&:after": {
              backgroundColor: selectedHighlightColor || theme.palette.overlay.med,
              content: '""',
              height: "100%",
              left: 0,
              opacity: isSelected ? 1 : 0,
              position: "absolute",
              top: 0,
              transition: "width 200ms ease, opacity 200ms linear, background 200ms linear",
              width: isSelected ? 8 : 0,
            },
          })}
        >
          <Checkbox onClick={onToggleSelected} checked={isSelected} sx={{ padding: "6px" }} />
        </AkiTableCell>
      )}
      <AkiTableCell sx={{ minWidth: 58, paddingLeft: isSelectable ? 1 : 2, paddingRight: 0 }}>
        <HTTPMethodEntity httpMethod={endpoint.operation} sx={{ marginRight: 0 }} />
      </AkiTableCell>
      <AkiTableCell grow sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <EndpointEntity endpoint={endpoint} noMethod />
      </AkiTableCell>
      {showResponseCode && (
        <AkiTableCell align="right" sx={{ minWidth: 38 }}>
          <ResponseCodeEntity code={endpoint.responseCode} />
        </AkiTableCell>
      )}
      <AkiTableCell align="right" sx={{ minWidth: 50 }}>
        {labelPercentile}
      </AkiTableCell>
      <AkiTableCell align="right" sx={{ minWidth: 50 }}>
        {labelCount}
      </AkiTableCell>
      <AkiTableCell align="right" sx={{ minWidth: 52, paddingLeft: 0, paddingRight: 1 }}>
        <Tooltip title="Open Endpoint Details" enterDelay={500}>
          <IconButton onClick={onClickDetails}>
            <ChevronRight />
          </IconButton>
        </Tooltip>
      </AkiTableCell>
    </AkiTableRow>
  );
};
