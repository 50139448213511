import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import React, { useCallback } from "react";
import { MonitorsTable } from "./components/MonitorsTable";
import { ProposedMonitors } from "./components/ProposedMonitors";
import { MONITOR_ROWS_PER_PAGE, MonitorsPageTab } from "./utils";
import { MonitorsAutocomplete } from "dashboard/components/MonitorsAutocomplete";
import { PageContent } from "dashboard/components/Page";
import { QueryError } from "dashboard/components/QueryError";
import { useMonitors, useUpdateMonitors } from "data/queries/monitors";
import { useRecentlyAlertedMonitors } from "data/queries/recently-alerted-monitors";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";
import { usePageIndex } from "hooks/use-page-index";
import { useSearchParam } from "hooks/use-search-param";
import { useSearchParamArray } from "hooks/use-search-param-array";

type MonitorsPageContentProps = {
  deploymentID: string;
  projectID: string;
};

export const MonitorsPageContent = ({ projectID, deploymentID }: MonitorsPageContentProps) => {
  const [pageIndex, setPageIndex] = usePageIndex();
  const isEnabledProposedAlerts = useIsFlagEnabled(FeatureFlag.ProposedAlertsEnabled);
  const isEnabledMonitorSearching = useIsFlagEnabled(FeatureFlag.MonitorSearchingEnabled);
  const { mutate: updateMonitors, error: errorUpdateMonitors } = useUpdateMonitors(
    projectID,
    deploymentID
  );

  const [rawActiveTab, setActiveTab] = useSearchParam<MonitorsPageTab>("tab");
  const activeTab = rawActiveTab || "enabled";
  const [requiredEndpoints, setRequiredEndpoints] = useSearchParamArray(
    "filter-required_endpoints"
  );

  const onChangeTab = useCallback(
    (_event, newActiveTab: MonitorsPageTab) => {
      setPageIndex(0);
      // "replace" causes both of these related search param state updates to be recorded as one
      // history item.
      setActiveTab(newActiveTab, { replace: true });
    },
    [setActiveTab, setPageIndex]
  );

  const clearRequiredEndpoints = useCallback(() => {
    setRequiredEndpoints(null);
  }, [setRequiredEndpoints]);

  const {
    data: dataMonitors,
    isLoading: isLoadingMonitors,
    isPreviousData: isPreviousDataMonitors,
    error: errorMonitors,
    refetch: refetchMonitors,
  } = useMonitors({
    projectID,
    deploymentID,
    queryParams: {
      state: "ACTIVE",
      offset: pageIndex * MONITOR_ROWS_PER_PAGE,
      limit: MONITOR_ROWS_PER_PAGE,
      required_endpoints: requiredEndpoints,
    },
    // We want to keep the previous data, so the pageCount doesn't disappear each time the page changes.
    options: { keepPreviousData: true },
  });

  const {
    data: dataRecentMonitors,
    isLoading: isLoadingRecentMonitors,
    isPreviousData: isPreviousDataRecentMonitors,
  } = useRecentlyAlertedMonitors({
    projectID,
    deploymentID,
    queryParams: {
      offset: pageIndex * MONITOR_ROWS_PER_PAGE,
      limit: MONITOR_ROWS_PER_PAGE,
    },
    // We want to keep the previous data, so the pageCount doesn't disappear each time the page changes.
    options: { keepPreviousData: true },
  });

  return (
    <PageContent sx={{ paddingBottom: 0, paddingTop: 1 }}>
      <QueryError
        error={errorMonitors}
        refetch={refetchMonitors}
        label="We had trouble loading your monitors."
      />
      <QueryError error={errorUpdateMonitors} label="We had trouble updating your monitors." />

      {isEnabledProposedAlerts && (
        <ProposedMonitors
          deploymentID={deploymentID}
          projectID={projectID}
          sx={{ marginBottom: 1, marginTop: 2 }}
        />
      )}

      <TabContext value={activeTab}>
        <TabList
          onChange={onChangeTab}
          // Push the tabs in by a border radius, so that they don't "overhang" the top left corner
          // of the table or search box.
          sx={{ paddingLeft: (theme) => `${theme.shape.borderRadius}px` }}
        >
          <Tab label="Search" value="enabled" />
          <Tab label="Recent" value="recent" />
        </TabList>

        <TabPanel sx={{ padding: 0 }} value="enabled">
          {isEnabledMonitorSearching && (
            <MonitorsAutocomplete
              projectID={projectID}
              deploymentID={deploymentID}
              sx={{ marginBottom: 2 }}
            />
          )}

          <MonitorsTable
            deploymentID={deploymentID}
            isLoading={isLoadingMonitors}
            isPreviousData={isPreviousDataMonitors}
            monitors={dataMonitors?.monitors}
            monitorsPerPage={MONITOR_ROWS_PER_PAGE}
            projectID={projectID}
            monitorsPageTab="enabled"
            totalCount={dataMonitors?.total_count}
            updateMonitors={updateMonitors}
            hasRequiredEndpoints={requiredEndpoints.length > 0}
            clearRequiredEndpoints={clearRequiredEndpoints}
          />
        </TabPanel>

        <TabPanel sx={{ padding: 0 }} value="recent">
          <MonitorsTable
            deploymentID={deploymentID}
            isLoading={isLoadingRecentMonitors}
            isPreviousData={isPreviousDataRecentMonitors}
            monitors={dataRecentMonitors?.monitors}
            monitorsPerPage={MONITOR_ROWS_PER_PAGE}
            projectID={projectID}
            monitorsPageTab="recent"
            totalCount={dataRecentMonitors?.total_count}
            updateMonitors={updateMonitors}
          />
        </TabPanel>
      </TabContext>
    </PageContent>
  );
};
