import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { AkiGridContainer, AkiGridItem } from "../AkiGrid";
import { QueryError } from "../QueryError";
import { SpecEditSuccessDialog } from "../SpecEditSuccessDialog";
import { usePostEditSpecs } from "data/queries/edit-specs";
import { MethodResponse, SpecEdit } from "types/akita_api_types";

type EndpointDetailsCategoryManagementProps = {
  endpoint?: MethodResponse;
  modelID?: string;
  projectID: string;
};

export const EndpointDetailsCategoryManagement = ({
  endpoint,
  modelID,
  projectID,
}: EndpointDetailsCategoryManagementProps) => {
  const [isSuccessAcked, setIsSuccessAcked] = useState(false);

  const {
    mutateAsync: postEditSpecs,
    isLoading: isLoadingEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
  } = usePostEditSpecs();

  const onPostEdit = useCallback(
    async (edit: SpecEdit) => {
      try {
        if (!modelID) throw new Error("Unable to edit endpoint: no modelID");
        await postEditSpecs({ projectID, modelID, edits: [edit] });
      } catch (e) {
        console.error(e);
      }
    },
    [modelID, postEditSpecs, projectID]
  );

  const onHide = useCallback(() => {
    if (!endpoint) return;

    void onPostEdit({
      hide_endpoint: {
        method: endpoint.operation,
        host: endpoint.host,
        path_template: endpoint.path,
      },
    });
  }, [onPostEdit, endpoint]);

  const onUnhide = useCallback(() => {
    if (!endpoint) return;

    void onPostEdit({
      unhide_endpoint: {
        method: endpoint.operation,
        host: endpoint.host,
        path_template: endpoint.path,
      },
    });
  }, [onPostEdit, endpoint]);

  return (
    <>
      {isSuccessEdit && !isSuccessAcked && (
        <SpecEditSuccessDialog onClose={() => setIsSuccessAcked(true)} />
      )}

      <QueryError error={errorEdit} label="We had trouble updating your model." />

      <AkiGridContainer gap={0}>
        <AkiGridItem spanC={3}>
          <LoadingButton
            disabled={!endpoint}
            onClick={onUnhide}
            variant="outlined"
            color="secondary"
            startIcon={<Visibility />}
            loading={isLoadingEdit}
          >
            Uncategorize
          </LoadingButton>

          <Typography paddingTop={1}>
            Remove this endpoint from its current category (if it’s in one), causing it to appear by
            default in your model and metrics.
          </Typography>
        </AkiGridItem>

        <AkiGridItem spanC={3}>
          <LoadingButton
            disabled={!endpoint}
            onClick={onHide}
            variant="outlined"
            color="secondary"
            startIcon={<VisibilityOff />}
            loading={isLoadingEdit}
          >
            Hide
          </LoadingButton>

          <Typography paddingTop={1}>
            Move this endpoint to the “Hidden” category, hiding it by default in your model and
            metrics.
          </Typography>
        </AkiGridItem>
      </AkiGridContainer>
    </>
  );
};
