import { ListItem, ListItemButton, ListItemButtonProps } from "@mui/material";
import React from "react";
import { LinkBehavior } from "./LinkBehavior";

type ListItemLinkProps = ListItemButtonProps<typeof LinkBehavior>;

/**
 * Returns a ListItemButton component that uses the LinkBehavior as its base component. Also wraps
 * the ListItemButton component in a padding-less ListItem, to retain semantic correctness.
 * Use this for both external and internal links in lists.
 */
export const ListItemLink = (props: ListItemLinkProps) => (
  <ListItem sx={{ padding: 0, margin: 0 }}>
    <ListItemButton component={LinkBehavior} {...props} />
  </ListItem>
);
