import { Alert, List, ListItem, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CheckCollapseEndpointsRequest,
  CollapsePathsEditRequest,
} from "../../types/akita_api_types";
import { AkiDialog } from "./AkiDialog/AkiDialog";
import { ParamEditListItem } from "./ParamEditListItem";
import { EndpointEntity } from "./entities/EndpointEntity";
import { decodeEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import { useCheckCollapseEndpoints } from "data/queries/check-collapse-endpoints";

type CollapseEndpointDialogProps = {
  projectID: string;
  selectedEndpoints: string[];
  onClose: () => void;
  onSubmit(collapseEditRequest: CollapsePathsEditRequest): void;
};

export const CollapseEndpointDialog = ({
  projectID,
  selectedEndpoints,
  onClose,
  onSubmit,
}: CollapseEndpointDialogProps) => {
  // The endpoints that are selected for collapsing
  const endpointsToCollapse: CheckCollapseEndpointsRequest = useMemo(() => {
    const decodedEndpoints = selectedEndpoints.map((selectedEndpoint) =>
      decodeEndpointUniqueID(selectedEndpoint)
    );

    return decodedEndpoints.map((endpoint) => ({
      method: endpoint.operation,
      host: endpoint.host,
      path_template: endpoint.path,
    }));
  }, [selectedEndpoints]);

  const [highlightedParameterIndex, setHighlightedParameterIndex] = useState<number | undefined>();

  const { data, isLoading } = useCheckCollapseEndpoints(projectID, endpointsToCollapse);
  const [parameterExclusions, setParameterExclusions] = useState<Record<string, Set<string>>>({});
  // Map of parameter names to the user chosen argument name that should be used for that parameter
  const [customParameterNames, setCustomParameterNames] = useState<Record<string, string>>({});

  const collapsedEndpointPreview = useMemo(():
    | {
        method?: string;
        host: string;
        path: string;
      }
    | undefined => {
    if (endpointsToCollapse.length === 0) return undefined;

    const firstEndpoint = endpointsToCollapse[0];

    //  If all endpoints have the same method, use that method. Otherwise, leave it undefined.
    const method = endpointsToCollapse.every((endpoint) => endpoint.method === firstEndpoint.method)
      ? firstEndpoint.method
      : undefined;

    let path = "";

    firstEndpoint.path_template.split("/").forEach((pathPart, index) => {
      // The inferred parameter name is the same as the index of the path part
      const inferredParam = index.toString();

      if (parameterExclusions[inferredParam]) {
        // Use the custom parameter name provided by the user if it exists, otherwise use the inferred parameter name
        path += `{${customParameterNames[inferredParam] ?? pathPart}}`;
      } else {
        path += pathPart;
      }

      if (index < firstEndpoint.path_template.split("/").length - 1) {
        path += "/";
      }
    });

    return {
      method,
      host: firstEndpoint.host,
      path,
    };
  }, [customParameterNames, endpointsToCollapse, parameterExclusions]);

  const getInitialParameterExclusions = useCallback(() => {
    if (!data || !data.inferred_parameters) return {};

    const result: Record<string, Set<string>> = {};

    Object.entries(data.inferred_parameters).forEach(([inferredParam, { observed_values }]) => {
      Object.entries(observed_values).forEach(([observedValue, { exceptional_value }]) => {
        const excludedValues = result[inferredParam] || new Set();

        if (exceptional_value) {
          excludedValues.add(observedValue);
        }

        result[inferredParam] = excludedValues;
      });
    });

    return result;
  }, [data]);

  // Set the initial parameter exclusions when the data changes
  useEffect(() => {
    setParameterExclusions(getInitialParameterExclusions);
  }, [data, getInitialParameterExclusions]);

  // Map the inferred parameter names to the user chosen argument names when the data changes
  useEffect(() => {
    if (!data || !data.inferred_parameters) return;
    const updateArgumentNames = (parameterNames: Record<string, string>) => {
      const updatedNames: Record<string, string> = {};

      Object.keys(data.inferred_parameters).forEach((param) => {
        // XXX: This is a hack to get around the fact that the inferred parameter names are just numbered strings, which don't make sense to the user
        // Instead, we default to using arg1, arg2, etc. as the parameter names
        const defaultName = `arg${parseInt(param)}`;

        updatedNames[param] = parameterNames[param] ?? defaultName;
      });

      return updatedNames;
    };

    setCustomParameterNames((prev) => updateArgumentNames(prev));
  }, [data]);

  const getParameterExclusionHints = useCallback(
    (param: string): string[] => {
      if (!data) return [];

      const paramData = data.inferred_parameters[param];
      if (!paramData) return [];

      return Object.entries(paramData.observed_values)
        .filter(([value, _]) => !parameterExclusions[param].has(value))
        .map(([value, _]) => value)
        .sort((a, b) => {
          if (a.length === b.length) {
            return a.localeCompare(b);
          }
          return a.length - b.length;
        });
    },
    [data, parameterExclusions]
  );

  const handleChangeParameterName = useCallback((param: string, newName: string) => {
    setCustomParameterNames((prev) => ({
      ...prev,
      [param]: newName,
    }));
  }, []);

  const handleAddParameterExclusion = useCallback((param: string, value: string) => {
    setParameterExclusions((prev) => {
      const newParamExclusions = new Set(prev[param]);
      newParamExclusions.add(value);
      return {
        ...prev,
        [param]: newParamExclusions,
      };
    });
  }, []);

  const handleRemoveParameterExclusion = useCallback((param: string, value: string) => {
    setParameterExclusions((prev) => {
      const newParamExclusions = new Set(prev[param]);
      newParamExclusions.delete(value);
      return {
        ...prev,
        [param]: newParamExclusions,
      };
    });
  }, []);

  const handleClearParameterExclusions = useCallback((param: string) => {
    setParameterExclusions((prev) => ({
      ...prev,
      [param]: new Set(),
    }));
  }, []);

  const handleResetAllParameterExclusions = useCallback(() => {
    setParameterExclusions(getInitialParameterExclusions());
  }, [getInitialParameterExclusions]);

  const handleClose = useCallback(() => {
    handleResetAllParameterExclusions();
    onClose();
  }, [handleResetAllParameterExclusions, onClose]);

  const handleSelectParam = useCallback((param: string) => {
    setHighlightedParameterIndex(parseInt(param));
  }, []);

  const handleDeselectParam = useCallback(() => {
    setHighlightedParameterIndex(undefined);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!data) return;

    const path_parameters: Record<string, { name: string; exceptional_values: string[] }> = {};

    Object.keys(data.inferred_parameters).forEach((inferredParam) => {
      const exceptionalValues = Array.from(parameterExclusions[inferredParam]);

      path_parameters[inferredParam] = {
        name: customParameterNames[inferredParam],
        exceptional_values: exceptionalValues,
      };
    });

    onSubmit({
      combine_paths: {
        include_endpoints: endpointsToCollapse,
        path_parameters,
      },
    });
  }, [data, onSubmit, endpointsToCollapse, parameterExclusions, customParameterNames]);

  const isSubmitDisabled = isLoading || !data;
  const highlightedParameterIndices: Set<number> = highlightedParameterIndex
    ? new Set([highlightedParameterIndex])
    : new Set();

  return (
    <AkiDialog onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Collapse Endpoints</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Alert severity="warning">
            Changes will remove existing endpoints and replace them with a single collapsed
            endpoint.
          </Alert>
          <Box>
            <Typography variant="h6">Selected endpoints:</Typography>
            <List>
              {endpointsToCollapse.map(({ method, host, path_template }, idx) => (
                <ListItem key={idx}>
                  <Box>
                    <EndpointEntity
                      highlightedParamIndices={highlightedParameterIndices}
                      endpoint={{ operation: method, host, path: path_template }}
                    />
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
          {!!collapsedEndpointPreview && (
            <Box>
              <Typography variant="h6">Collapsed Result</Typography>
              <EndpointEntity
                paddingLeft={2}
                marginTop={2}
                highlightedParamIndices={highlightedParameterIndices}
                noMethod={!collapsedEndpointPreview.method}
                endpoint={{
                  operation: collapsedEndpointPreview.method ?? "",
                  host: collapsedEndpointPreview.host,
                  path: collapsedEndpointPreview.path,
                }}
              />
            </Box>
          )}
          <Box>
            <Typography variant="h6">Configure Path Parameters</Typography>
            <List>
              {Object.entries(parameterExclusions).map(([pathParam, exclusions]) => (
                <ParamEditListItem
                  key={pathParam}
                  pathParamName={customParameterNames[pathParam] ?? pathParam}
                  disallowedNames={
                    new Set(
                      Object.values(customParameterNames).filter(
                        (name) => name !== customParameterNames[pathParam] ?? pathParam
                      )
                    )
                  }
                  onNameChange={(newTitle) => handleChangeParameterName(pathParam, newTitle)}
                  parameterExclusions={exclusions}
                  options={getParameterExclusionHints(pathParam)}
                  onSelect={() => handleSelectParam(pathParam)}
                  onDeselect={handleDeselectParam}
                  onClearExclusions={() => handleClearParameterExclusions(pathParam)}
                  onDeleteExclusion={(value) => handleRemoveParameterExclusion(pathParam, value)}
                  onAddExclusion={(value) => handleAddParameterExclusion(pathParam, value)}
                />
              ))}
            </List>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </AkiDialog>
  );
};
