import { FormControl, MenuItem, Select, SelectChangeEvent, SxProps, Theme } from "@mui/material";
import React, { useCallback } from "react";
import { getMonitorSignalLabel } from "dashboard/utils/monitors";
import { MonitorSignalKind } from "types/akita_api_types";

const ENABLED_SIGNAL_KINDS = [
  MonitorSignalKind.LATENCY_MEDIAN,
  MonitorSignalKind.LATENCY_90P,
  MonitorSignalKind.LATENCY_95P,
  MonitorSignalKind.LATENCY_99P,
  MonitorSignalKind.RATE_4XX,
  MonitorSignalKind.RATE_5XX,
];

type MonitorSignalSelectProps = {
  signal: MonitorSignalKind;
  onChange: (signal: MonitorSignalKind) => void;
  sx?: SxProps<Theme>;
};

export const MonitorSignalSelect = ({ signal, onChange, sx }: MonitorSignalSelectProps) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<MonitorSignalKind>) => {
      const newSignal = event?.target?.value as MonitorSignalKind;
      if (!newSignal) return;

      onChange(newSignal);
    },
    [onChange]
  );

  return (
    <FormControl sx={sx}>
      <Select
        aria-label="Metric to monitor"
        onChange={handleChange}
        value={signal}
        sx={{
          // Width of longest option
          minWidth: 170,
        }}
      >
        {ENABLED_SIGNAL_KINDS.map((signalKind) => (
          <MenuItem key={signalKind} value={signalKind}>
            {getMonitorSignalLabel(signalKind)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
