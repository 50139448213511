import React, { useMemo } from "react";
import { makeDemoTimeline } from "../utils";
import { AkiSparkTile } from "dashboard/components/AkiTile/AkiSparkTile";
import { DisplayTimelineValue, getAverageTimelineValue } from "dashboard/utils/timelines";

export const TileLatencyTrend = ({
  data,
  deploymentID,
  isEmptyState,
  isLoading,
  projectID,
}: {
  data?: DisplayTimelineValue[];
  deploymentID?: string;
  isEmptyState?: boolean;
  isLoading?: boolean;
  projectID?: string;
}) => {
  const demoData = useMemo(
    () => (isEmptyState ? makeDemoTimeline(30, 200, 400, -5, 2) : undefined),
    [isEmptyState]
  );

  return (
    <AkiSparkTile
      aggregator={getAverageTimelineValue}
      data={isEmptyState ? demoData : data}
      isEmptyState={isEmptyState}
      isLoading={isLoading}
      title="Latency (p90)"
      trendIsUpGood={false}
      units="average latency (ms)"
      linkLabel="View Detailed Latency Metrics"
      linkHref={
        projectID && deploymentID
          ? `/service/${projectID}/deployment/${deploymentID}/monitoring`
          : undefined
      }
    />
  );
};
