import { TimelineAggregation, TimelineValue } from "types/akita_api_types";

/**
 * The backends doesn't have a consistent way of representing a percentile ("90p", "p90",
 * "latency_ms_p90", and "latency_90p" are all used in different places). On the frontend, we'll
 * use these human-readable "p"-prefixed values as much as possible, and then convert to the others
 * when necessary.
 */
export type Percentile = "p90" | "p95" | "p99" | "p99.9";

/**
 * Converts between a human-readable percentile string ("p90", "p95", etc.) and the keys taken by our
 * API ("latency_90p", "latency_95p", etc.).
 * @param p - A percentile string.
 * @returns A TimelineValue enum value.
 */
export const percentileToTimelineValue = (p: Percentile) => {
  switch (p) {
    case "p99.9":
      return TimelineValue.Event_Latency_99_9p;
    case "p99":
      return TimelineValue.Event_Latency_99p;
    case "p95":
      return TimelineValue.Event_Latency_95p;
    default:
      return TimelineValue.Event_Latency_90p;
  }
};

/**
 * Converts between a human-readable percentile string ("p90", "p95", etc.) and the keys taken by our
 * timeline/query endpoint ("90p", "95p", etc.).
 * @param p - A percentile string.
 * @returns That string but with the "p" at the end instead of the beginning.
 */
export const percentileToTimelineAggregateValue = (p: Percentile) =>
  `${p.slice(1)}p` as TimelineAggregation;
