import { Box } from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { Empty } from "dashboard/components/Empty";
import { useClientTelemetry } from "data/queries/client-telemetry";
import { useImpression } from "hooks/use-impression";

export const MissingDeployment = ({ projectID }: { projectID: string }) => {
  const { data: dataTelemetry, isLoading: isLoadingTelemetry } = useClientTelemetry(projectID, {
    limit: 10,
  });

  useImpression("Model Empty State", { projectID });

  if (isLoadingTelemetry) {
    return null;
  }

  // Not sure how best to test for if something returned
  if (dataTelemetry?.[0]?.packet_count_summary?.total) {
    return (
      <Box id="processing-message" sx={{ paddingTop: 3, paddingBottom: 3 }}>
        <Empty
          title="Sit tight!"
          subTitle={
            <Typography variant="body2">
              We&rsquo;ve been watching your APIs and are working hard to build you a model.
            </Typography>
          }
        />
      </Box>
    );
  }

  return (
    <Box id="empty-spec-message" sx={{ paddingTop: 3, paddingBottom: 3 }}>
      <Empty
        title="You have not sent us new traffic"
        subTitle={
          <Typography variant="body2">
            Hm, no data was collected from your project 🤔.
            <br />
            You may want to check the{" "}
            <Link id="model-onboarding-link" href={`/service/${projectID}/onboarding`}>
              onboarding
            </Link>{" "}
            page to make sure you are all set up.
          </Typography>
        }
      />
    </Box>
  );
};
