import { Link, ListItem, SxProps, Theme, Typography } from "@mui/material";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { trackEvent } from "../../../analytics/event-utils";
import { useAnalyticsContext } from "../../../hooks/use-analytics-context";
import { OnboardingStepContext } from "./context";

interface OnboardingStepProps {
  stepIndex: number;
  currentStepIndex: number;
  children: ReactNode;
  title: string;
  isClickable?: boolean;
  setCurrentStepIndex: (index: number) => void;
  sx?: SxProps<Theme>;
  eventPrefix?: string;
}

export const OnboardingStep = ({
  stepIndex,
  currentStepIndex,
  isClickable,
  title,
  setCurrentStepIndex,
  children,
  sx = {},
  eventPrefix = "Onboarding",
}: OnboardingStepProps) => {
  const [isStarted, setIsStarted] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const stepName = `${eventPrefix} Step ${stepIndex + 1} - "${title}"`;
  const eventNameStarted = `${stepName}: Started`;
  const eventNameCompleted = `${stepName}: Completed`;

  const { analytics } = useAnalyticsContext();

  // Callback to mark the step as started
  const markStarted = useCallback(() => {
    trackEvent(eventNameStarted, analytics);
    setIsStarted(true);
  }, [analytics, eventNameStarted]);

  // Callback to mark the step as completed without advancing to the next step.
  const markCompleted = useCallback(() => {
    if (isStarted && !isCompleted) {
      trackEvent(eventNameCompleted, analytics);
      setIsCompleted(true);
    }
  }, [analytics, isStarted, isCompleted, eventNameCompleted]);

  // Callback which child components can use to track events with a useful prefix.
  const trackOnboardingEvent = useCallback(
    (eventName: string, properties?: Record<string, string | number | boolean | undefined>) => {
      trackEvent(`${stepName}: ${eventName}`, analytics, {
        isDockerOnlyOnboarding: true,
        ...properties,
      });
    },
    [analytics, stepName]
  );

  // Value of the OnboardingStepContext
  const contextValue = useMemo(() => {
    const prevStep = () => setCurrentStepIndex(Math.max(0, stepIndex - 1));
    const nextStep = () => {
      markCompleted();
      setCurrentStepIndex(stepIndex + 1);
    };

    return { prevStep, nextStep, markCompleted, stepIndex, trackOnboardingEvent };
  }, [stepIndex, setCurrentStepIndex, markCompleted, trackOnboardingEvent]);

  const onTitleClick = useCallback(() => {
    if (isClickable) {
      setCurrentStepIndex(stepIndex);
      trackOnboardingEvent("Clicked on step title");
    }
  }, [isClickable, setCurrentStepIndex, stepIndex, trackOnboardingEvent]);

  const titleElement = (
    <Link
      component="button"
      onClick={onTitleClick}
      aria-disabled={!isClickable}
      align="left"
      underline={isClickable ? "hover" : "none"}
      sx={{ cursor: isClickable ? "pointer" : "inherit", marginBottom: 1 }}
    >
      <Typography
        variant="h2"
        sx={{ color: currentStepIndex === stepIndex ? "text.primary" : "text.secondary" }}
      >
        {`${stepIndex + 1}.`} {title}
      </Typography>
    </Link>
  );

  // If this step hasn't already been completed, we'll indicate that it's started (this can happen
  // more than once).
  useEffect(() => {
    if (!isCompleted && currentStepIndex === stepIndex) {
      markStarted();
    }
  }, [markStarted, currentStepIndex, stepIndex, isCompleted, analytics]);

  // If the current step index is greater than the index of this step, and we haven't already marked
  // this one as completed, then we can mark it as completed now.
  useEffect(() => {
    if (isStarted && !isCompleted && currentStepIndex > stepIndex) {
      markCompleted();
    }
  }, [isStarted, isCompleted, currentStepIndex, stepIndex, markCompleted]);

  return (
    <ListItem sx={{ display: "block", marginBottom: 2, maxWidth: 1000, ...sx }} disablePadding>
      <OnboardingStepContext.Provider value={contextValue}>
        {titleElement}
        {currentStepIndex === stepIndex && children}
      </OnboardingStepContext.Provider>
    </ListItem>
  );
};
