import { Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { CodeBox } from "dashboard/components/CodeBox";
import { OnboardingStepContext } from "dashboard/components/OnboardingStep/context";

interface DockerDesktopStartProps {
  apiKeyId: string | undefined;
  apiKeySecret: string | undefined;
  projectName: string | undefined;
}

export const DockerDesktopStart = ({
  apiKeyId,
  apiKeySecret,
  projectName,
}: DockerDesktopStartProps) => {
  const { trackOnboardingEvent } = useContext(OnboardingStepContext);

  const onCopy = useCallback(() => {
    trackOnboardingEvent("Docker Desktop Login Data Item Copied");
  }, [trackOnboardingEvent]);

  return (
    <>
      <Typography gutterBottom>
        Paste the following into the login screen in the Akita Docker Desktop extension:
      </Typography>
      <Typography variant="h3" sx={{ paddingTop: 2, fontWeight: "bold" }}>
        Project
      </Typography>
      <CodeBox code={projectName || ""} label="your project name" onCopy={onCopy} />
      <Typography variant="h3" sx={{ paddingTop: 2, fontWeight: "bold" }}>
        API Key
      </Typography>
      <CodeBox code={apiKeyId || ""} label="your API key" onCopy={onCopy} />
      <Typography variant="h3" sx={{ paddingTop: 2, fontWeight: "bold" }}>
        API Secret
      </Typography>
      <CodeBox code={apiKeySecret || ""} label="your API secret" onCopy={onCopy} />
    </>
  );
};
