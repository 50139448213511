import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import React, { ChangeEvent, FormEvent, useCallback, useMemo } from "react";

interface CreateProjectFormProps {
  autoFocus?: boolean;
  errorCode?: number;
  isLoading?: boolean;
  onChange: (projectName: string) => void;
  onSubmit: (projectName: string) => void;
  projectName: string;
}

export const CreateProjectForm = ({
  autoFocus = false,
  errorCode,
  isLoading = false,
  onChange,
  onSubmit,
  projectName,
}: CreateProjectFormProps) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.stopPropagation();
      event.preventDefault();

      onSubmit(projectName);
    },
    [onSubmit, projectName]
  );

  const errorMessage = useMemo(() => {
    if (!errorCode) return "";

    switch (errorCode) {
      case 400:
        return "Project names can contain only alphanumeric characters, dashes, or underscores, and can be up to 32 characters long.";
      case 409:
        return "A project with that name already exists. Please select it from the dropdown menu below.";
      default:
        return "There was an error creating your project. Please reach out to support@akitasoftware.com for help.";
    }
  }, [errorCode]);

  return (
    <form onSubmit={handleSubmit} data-testid="create-project-form">
      <Box display="flex">
        <TextField
          autoFocus={autoFocus}
          disabled={isLoading}
          error={!!errorCode}
          fullWidth
          helperText={errorMessage}
          label="Project Name"
          onChange={handleChange}
          placeholder="my-project"
          size="large"
          sx={{ marginRight: 1 }}
          value={projectName}
          variant="outlined"
        />

        <LoadingButton
          disabled={!projectName?.length}
          loading={isLoading}
          size="large"
          type="submit"
          variant="contained"
        >
          Create
        </LoadingButton>
      </Box>
    </form>
  );
};
