import { Collapse } from "@mui/material";
import { subDays } from "date-fns";
import React, { useMemo } from "react";
import { TransitionGroup } from "react-transition-group";
import { FeatureToggle } from "../../../feature_flags/components/FeatureToggle";
import { OverviewEmptyStateBanner } from "./OverviewEmptyStateBanner";
import { TileActiveAlertsList } from "./Tiles/TileActiveAlertsList";
import { TileClientsReportingNumber } from "./Tiles/TileClientsReportingNumber";
import { TileEndpointsObservedNumber } from "./Tiles/TileEndpointsObservedNumber";
import { TileErrorEndpointsList } from "./Tiles/TileErrorEndpointsList";
import { TileErrorTrend } from "./Tiles/TileErrorTrend";
import { TileLatencyEndpointsList } from "./Tiles/TileLatencyEndpointsList";
import { TileLatencyTrend } from "./Tiles/TileLatencyTrend";
import { TileSetUpInfo } from "./Tiles/TileSetUpInfo";
import { TileTrafficTrend } from "./Tiles/TileTrafficTrend";
import { aggregateTimelines } from "./utils";
import { AkiGridContainer } from "dashboard/components/AkiGrid";
import { Page, PageContent, PageHeader, PageTitle } from "dashboard/components/Page";
import { roundToNearestUTCHours } from "dashboard/utils/dates";
import { useEndpointsMetadata } from "data/queries/endpoints-metadata";
import { useLatestModelSummaryForProject } from "data/queries/models";
import { useTimelineQuery } from "data/queries/use-timeline-query";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useImpression } from "hooks/use-impression";

interface OverviewPageProps {
  projectID?: string;
  deploymentID?: string;
}

export const OverviewPage = ({ projectID, deploymentID }: OverviewPageProps) => {
  useImpression("Overview Page");

  // Since we round the start and end time to the nearest 3 hours, we can recreate this value on
  // every render without worrying about also creating a new cache key on every render.
  // If/when we stop having to round the start and end times, we should store this value in state
  // and implement some kind of periodic refresh.
  const endTime = Date.now();

  const { data: dataTimelines, isLoading: isLoadingTimelines } = useTimelineQuery(
    projectID,
    deploymentID,
    {
      // Align the start and end points to the nearest 3 hour mark.
      endMS: roundToNearestUTCHours(endTime, 3, "floor").getTime(),
      startMS: subDays(roundToNearestUTCHours(endTime, 3, "ceil"), 7).getTime(),
      // 24 * 7 / 3 is 56
      // with the bucket-aligned start and end times we should get 54, but this is a reasonable limit
      count: 56,
      bucket: "3h",
      aggregate: ["90p", "count"],
      key: ["code"],
    },
    { refetchOnWindowFocus: false }
  );

  const { data: dataModelSummary, isLoading: isLoadingModelSummary } =
    useLatestModelSummaryForProject({ projectID, deploymentID });

  const { data: dataEndpoints, isLoading: isLoadingEndpoints } = useEndpointsMetadata(
    projectID,
    dataModelSummary?.id,
    {
      key: ["perf"],
      endpoint_categories: "none",
      http_methods: ["GET", "POST", "PUT", "DELETE", "PATCH"],
    },
    { keepPreviousData: false }
  );

  const aggregatedTimelines = useMemo(
    () => (dataTimelines?.timelines ? aggregateTimelines(dataTimelines.timelines) : undefined),
    [dataTimelines]
  );

  const doesNotExistModel = !isLoadingModelSummary && !dataModelSummary;
  const isEmptyState = !projectID || !deploymentID || doesNotExistModel;

  return (
    <Page>
      <PageHeader>
        <PageTitle>Overview</PageTitle>
        <TransitionGroup>
          {isEmptyState && (
            <Collapse timeout={400} easing="cubic-bezier(0.34, 1.56, 0.64, 1)">
              <OverviewEmptyStateBanner projectID={projectID} />
            </Collapse>
          )}
        </TransitionGroup>
      </PageHeader>

      <PageContent>
        <AkiGridContainer>
          <TileTrafficTrend
            data={aggregatedTimelines?.count}
            deploymentID={deploymentID}
            isEmptyState={isEmptyState}
            isLoading={isLoadingTimelines}
            projectID={projectID}
          />
          <TileLatencyTrend
            data={aggregatedTimelines?.p90}
            deploymentID={deploymentID}
            isEmptyState={isEmptyState}
            isLoading={isLoadingTimelines}
            projectID={projectID}
          />
          <TileErrorTrend
            data={aggregatedTimelines?.errors}
            deploymentID={deploymentID}
            isEmptyState={isEmptyState}
            isLoading={isLoadingTimelines}
            projectID={projectID}
          />

          <TileLatencyEndpointsList
            endpoints={dataEndpoints?.metadata}
            isEmptyState={isEmptyState}
            isLoading={isLoadingEndpoints}
            modelID={dataModelSummary?.id}
            projectID={projectID}
          />
          <TileErrorEndpointsList
            endpoints={dataEndpoints?.metadata}
            isEmptyState={isEmptyState}
            isLoading={isLoadingEndpoints}
            modelID={dataModelSummary?.id}
            projectID={projectID}
          />

          <FeatureToggle flag={FeatureFlag.PerformanceAlertsPageEnabled}>
            <TileActiveAlertsList
              deploymentID={deploymentID}
              isEmptyState={isEmptyState}
              projectID={projectID}
            />
          </FeatureToggle>

          <TileEndpointsObservedNumber
            count={dataModelSummary?.num_endpoints}
            deploymentID={deploymentID}
            isEmptyState={isEmptyState}
            isLoading={isLoadingModelSummary}
            projectID={projectID}
          />
          <TileClientsReportingNumber
            deploymentID={deploymentID}
            isEmptyState={isEmptyState}
            projectID={projectID}
          />
          <TileSetUpInfo projectID={projectID} />
        </AkiGridContainer>
      </PageContent>
    </Page>
  );
};
