import { Box, Typography, useTheme } from "@mui/material";
import { subDays } from "date-fns";
import React, { useCallback, useMemo, useRef } from "react";

import { AkiGridContainer } from "../AkiGrid";
import { AkiTile } from "../AkiTile/AkiTile";
import { AkiTileHeader } from "../AkiTile/AkiTileHeader";
import { DataPill } from "../DataPill";
import { getResponseCodeColor } from "../entities/ResponseCodeEntity";
import { AkiTimelineGroup } from "dashboard/components/AkiTimelineGroup/AkiTimelineGroup";
import {
  DisplayTimelineValue,
  akitaTimelineToDisplayTimeline,
  getMinMaxTimelineValues,
  getSumTimelineValues,
} from "dashboard/utils/timelines";
import { EndpointParams, useEndpoint } from "data/queries/endpoint";
import { useModelMetadata } from "data/queries/models";
import { useTimelineQuery } from "data/queries/use-timeline-query";
import { TimelineValue } from "types/akita_api_types";

type EndpointDetailsGraphsProps = {
  projectID: string;
  modelID?: string;
  endpointID?: string;
  endpointParams?: EndpointParams;
};

export const EndpointDetailsGraphs = ({
  projectID,
  modelID,
  endpointID,
  endpointParams,
}: EndpointDetailsGraphsProps) => {
  const theme = useTheme();

  const { current: endTime } = useRef(Date.now());

  const { data: dataModelMetadata } = useModelMetadata(projectID, modelID);
  const { data: dataEndpoint } = useEndpoint(projectID, modelID, endpointID, endpointParams);

  const deploymentTag = dataModelMetadata?.tags?.["x-akita-deployment"];
  const deploymentID = Array.isArray(deploymentTag) ? deploymentTag[0] : deploymentTag;

  const { data: dataTimelines, isLoading: isLoadingTimeline } = useTimelineQuery(
    projectID,
    deploymentID,
    {
      endMS: endTime,
      startMS: subDays(endTime, 7).getTime(),
      // 24 * 7 / 3 is 56
      count: 56,
      bucket: "3h",
      aggregate: ["90p", "count"],
      path: dataEndpoint?.path,
      host: dataEndpoint?.host,
      method: dataEndpoint?.operation,
      key: ["host", "path", "code", "method"],
    },
    { enabled: !!projectID && !!deploymentID && !!dataEndpoint }
  );

  const rawTimelines = dataTimelines?.timelines;

  const timelinesCount = useMemo(
    () =>
      rawTimelines?.map((timeline) =>
        akitaTimelineToDisplayTimeline(timeline, TimelineValue.Event_Count, {
          title: timeline.group_attrs.response_code?.toString(),
          color: getResponseCodeColor(theme, timeline.group_attrs.response_code),
        })
      ),
    [rawTimelines, theme]
  );

  const timelinesPercentile = useMemo(
    () =>
      rawTimelines?.map((timeline) =>
        akitaTimelineToDisplayTimeline(timeline, TimelineValue.Event_Latency_90p, {
          title: timeline.group_attrs.response_code?.toString(),
          color: getResponseCodeColor(theme, timeline.group_attrs.response_code),
        })
      ),
    [rawTimelines, theme]
  );

  const getTooltipContentCount = useCallback(
    (activePoints: DisplayTimelineValue[]) =>
      activePoints.map((datum) => (
        <Box key={datum.title}>
          Response Code: <strong style={{ color: datum.color }}>{datum.title}</strong>
          <br />
          Count: <strong>{datum.y?.toLocaleString()}</strong>
        </Box>
      )),
    []
  );

  const getTooltipContentDuration = useCallback(
    (activePoints: DisplayTimelineValue[]) =>
      activePoints.map((datum) => (
        <Box key={datum.title}>
          Response Code: <strong style={{ color: datum.color }}>{datum.title}</strong>
          <br />
          Duration (p90):{" "}
          <strong>{datum.y !== null ? `${datum.y.toLocaleString()}ms` : "No Data"}</strong>
        </Box>
      )),
    []
  );

  const totalCount = useMemo(() => {
    const sum = timelinesCount?.reduce((result, timeline) => {
      const count = getSumTimelineValues(timeline.values);

      return result + (count ?? 0);
    }, 0);

    return sum?.toLocaleString();
  }, [timelinesCount]);

  const peakP90 = useMemo(() => {
    const max = timelinesPercentile?.reduce((result, timeline) => {
      const localMax = getMinMaxTimelineValues(timeline.values).max;
      return localMax > result ? localMax : result;
    }, 0);

    return max?.toLocaleString().concat("ms");
  }, [timelinesPercentile]);

  return (
    <AkiGridContainer sx={{ paddingTop: 2 }}>
      <AkiTile spanC={3} sx={{ padding: 0 }}>
        <AkiTileHeader>
          <Typography variant="h5">Count</Typography>
          <DataPill isLoading={isLoadingTimeline} label="Total">
            {totalCount}
          </DataPill>
        </AkiTileHeader>

        <AkiTimelineGroup
          alwaysShowPoints
          timelines={timelinesCount}
          getTooltipContent={getTooltipContentCount}
        />
      </AkiTile>

      <AkiTile spanC={3} sx={{ padding: 0 }}>
        <AkiTileHeader>
          <Typography variant="h5">Duration (p90)</Typography>
          <DataPill isLoading={isLoadingTimeline} label="Peak p90">
            {peakP90}
          </DataPill>
        </AkiTileHeader>

        <AkiTimelineGroup
          alwaysShowPoints
          timelines={timelinesPercentile}
          getTooltipContent={getTooltipContentDuration}
        />
      </AkiTile>
    </AkiGridContainer>
  );
};
