import { Analytics } from "@segment/analytics-next";
import { useEffect } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { PageCategory, categoryMatches, getMatchingPathInfo } from "../analytics/page-category";
import { useAnalyticsContext } from "./use-analytics-context";

const pageCategories: PageCategory[] = [
  // Monitoring
  {
    name: "Monitoring",
    paths: [{ path: "/monitoring" }],
  },

  // Change Reports
  {
    name: "Change Report",
    paths: [{ path: "/change-report" }],
  },

  // Diagnostics
  {
    name: "Diagnostics",
    paths: [
      { title: "Summary", path: "/diagnostics/summary" },
      { title: "All Models", path: "/diagnostics/all-models" },
      { title: "Daemons", path: "/diagnostics/daemons" },
      { title: "Traces", path: "/diagnostics/traces" },
      { title: "Filters", path: "/diagnostics/filters" },
      { title: "Custom Labels", path: "/diagnostics/custom-labels" },
    ],
  },

  // Settings
  {
    name: "Settings",
    paths: [
      { title: "Services", path: "/settings/services" },
      { title: "API Keys", path: "/settings/api-keys" },
      { title: "Invites", path: "/settings/invites" },
      { title: "Team", path: "/settings/team" },
    ],
  },

  // Alerts
  {
    name: "Alerts",
    paths: [{ path: "/performance-alerts" }],
  },

  // Overview
  {
    name: "Overview",
    paths: [{ path: "/overview" }, { path: "/empty-overview" }],
  },

  // Model Page
  {
    name: "API Model",
    paths: [{ path: "/model" }],
  },

  // Onboarding Page
  {
    name: "Onboarding",
    paths: [{ path: "/onboarding" }, { path: "/first-time-onboarding" }],
  },
];

const trackPage = (analytics?: Analytics) => {
  const path = window.location.pathname;

  const serviceID = matchPath<{ serviceID: string }>(location.pathname, {
    path: "/service/:serviceID/*",
  })?.params.serviceID;

  const pageCategory = pageCategories.find((category) => categoryMatches(category, path));

  if (!pageCategory) {
    return;
  }

  const pathInfo = getMatchingPathInfo(pageCategory, path)!;

  const properties = { serviceID };

  void analytics?.page(pageCategory.name, pathInfo?.title, properties);
};

/**
 * Tracks the page being viewed in Segment.
 */
export const usePageTracker = () => {
  const history = useHistory();
  const { analytics } = useAnalyticsContext();

  useEffect(() => {
    if (!history) return;

    trackPage(analytics);
    history.listen(() => trackPage(analytics));
  }, [analytics, history]);
};
