import { useCallback, useMemo } from "react";
import { UseSearchParamOptions, useSearchParam } from "./use-search-param";

/**
 * Hook that sets or removes a boolean flag (indicated by either "1" or "0") from the given search
 * parameter key in a URL, while preserving other search parameters. If the parameter is anything
 * other than "1", then the value is false.
 */
export const useSearchParamBool = (
  key: string
): [value: boolean, setValue: (newValue: boolean, options?: UseSearchParamOptions) => void] => {
  const [value, setValue] = useSearchParam<"1" | "0">(key);

  const setValueBool = useCallback(
    (newValue: boolean, options?: UseSearchParamOptions) => {
      if (newValue) return setValue("1", options);
      return setValue(null, options);
    },
    [setValue]
  );

  return useMemo(() => [value === "1", setValueBool], [value, setValueBool]);
};
