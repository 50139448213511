import { Alert } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { ModelEndpointsTable } from "./ModelEndpointsTable";
import { ModelPageHeader } from "./ModelPageHeader";
import { ModelSummaryTiles } from "dashboard/components/ModelSummaryTiles/ModelSummaryTiles";
import { Page, PageContent } from "dashboard/components/Page";
import { QueryError } from "dashboard/components/QueryError";
import { useModelMetadata } from "data/queries/models";
import { useImpression } from "hooks/use-impression";

export const ModelPageForModelID = () => {
  const { serviceID: projectID, specID: modelID } = useParams<{
    serviceID: string;
    specID: string;
  }>();

  const {
    data: modelMetadata,
    isLoading: isLoadingModelMetadata,
    error: errorModelMetadata,
    refetch: refetchModelMetadata,
  } = useModelMetadata(projectID, modelID);

  const isModelNotFound = !isLoadingModelMetadata && !modelMetadata;

  useImpression("Model Page (for specific model)", { projectID, modelID });

  return (
    <Page>
      <ModelPageHeader
        modelID={modelID}
        projectID={projectID}
        isSettingsDisabled={isModelNotFound}
      />

      <PageContent>
        {isModelNotFound ? (
          <Alert severity="error">That model doesn’t exist.</Alert>
        ) : (
          <QueryError
            error={errorModelMetadata}
            label="We had trouble loading your model."
            refetch={refetchModelMetadata}
          />
        )}

        {!isModelNotFound && (
          <>
            <ModelSummaryTiles projectID={projectID} modelID={modelID} />
            <ModelEndpointsTable projectID={projectID} modelID={modelID} />
          </>
        )}
      </PageContent>
    </Page>
  );
};
