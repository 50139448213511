import { TabContext } from "@mui/lab";
import React, { useCallback, useMemo, useState } from "react";
import { AkiDialog } from "dashboard/components/AkiDialog/AkiDialog";
import { AkiDialogContent } from "dashboard/components/AkiDialog/AkiDialogContent";
import { AkiDialogHeader } from "dashboard/components/AkiDialog/AkiDialogHeader";
import { EndpointDetailsContent } from "dashboard/components/EndpointDetails/EndpointDetailsContent";
import {
  EndpointDetailsHeader,
  EndpointDetailsTabs,
} from "dashboard/components/EndpointDetails/EndpointDetailsHeader";
import { decodeEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import { useLatestModelSummaryForProject } from "data/queries/models";
import { useImpression } from "hooks/use-impression";
import { useModalParams } from "hooks/use-modal-params";
import { useSearchParam } from "hooks/use-search-param";

export const EndpointDetailsModalForDeployment = () => {
  const { projectID, deploymentID, endpointUniqueID } = useModalParams<{
    projectID: string;
    deploymentID: string;
    endpointUniqueID: string;
  }>();

  // TODO: Implement in routing, instead of state
  // https://app.asana.com/0/1203253842162893/1203556409658102/f
  const [activeTab, setActiveTab] = useState<EndpointDetailsTabs>("params");

  const endpointParams = useMemo(() => {
    const decodedID = decodeEndpointUniqueID(endpointUniqueID);

    return {
      host: decodedID.host,
      operation: decodedID.operation,
      path: decodedID.path,
    };
  }, [endpointUniqueID]);

  const { data: modelSummary } = useLatestModelSummaryForProject({ projectID, deploymentID });
  const modelID = modelSummary?.id;

  const [_, setModalPath] = useSearchParam("modal");
  const closeModal = useCallback(() => {
    setModalPath(null);
  }, [setModalPath]);

  useImpression(`Endpoint Details Modal - ${activeTab} tab`, {
    projectID,
    deploymentID,
    endpointUniqueID,
  });

  return (
    <AkiDialog onClose={closeModal} fullWidth maxWidth="lg" align="top" scroll="body">
      <TabContext value={activeTab}>
        <AkiDialogHeader
          title="Endpoint Details"
          sx={{ paddingBottom: 0 }}
          sxContent={{ maxWidth: "100%" }}
        >
          <EndpointDetailsHeader
            modelID={modelID}
            projectID={projectID}
            endpointParams={endpointParams}
            setActiveTab={setActiveTab}
          />
        </AkiDialogHeader>

        <AkiDialogContent>
          <EndpointDetailsContent
            modelID={modelID}
            projectID={projectID}
            endpointParams={endpointParams}
            onEdit={closeModal}
          />
        </AkiDialogContent>
      </TabContext>
    </AkiDialog>
  );
};
