import { ListItem } from "@mui/material";
import React, { ReactNode, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AkiListTile, AkiListTileProps } from "../AkiTile/AkiListTile";
import { ListItemLink } from "../ListItemLink";
import { EndpointEntity } from "../entities/EndpointEntity";
import { getQueryLocation } from "dashboard/utils/routes";
import { useEndpointsMetadata } from "data/queries/endpoints-metadata";
import { useLogInteraction } from "hooks/use-log-interaction";
import { SortParam } from "hooks/use-sort-param";

type ModelSummaryTileListProps = Omit<AkiListTileProps, "icon" | "emptyState" | "title"> & {
  icon: ReactNode;
  modelID?: string;
  projectID?: string;
  sort: SortParam;
  title: string;
};

export const ModelSummaryTileList = ({
  icon,
  modelID,
  projectID,
  sort,
  title,
  ...rest
}: ModelSummaryTileListProps) => {
  const location = useLocation();
  const logInteraction = useLogInteraction({ modelID, projectID, title });

  const { data: dataEndpointsMetadata, isLoading } = useEndpointsMetadata(
    projectID,
    modelID,
    {
      limit: 2,
      sort,
      endpoint_categories: "none",
      http_methods: ["GET", "POST", "PUT", "DELETE", "PATCH"],
    },
    { refetchOnWindowFocus: false }
  );

  const items = useMemo(() => {
    if (isLoading) {
      return new Array(2).fill(undefined).map((_, index) => (
        <ListItem key={index}>
          <EndpointEntity isLoading={isLoading} />
        </ListItem>
      ));
    }

    return dataEndpointsMetadata?.metadata?.map((endpoint) => (
      <ListItemLink
        to={getQueryLocation(
          location,
          "modal",
          `/project/${projectID}/model/${modelID}/endpoint/${endpoint.method_id}`
        )}
        key={endpoint.method_id}
        onClick={() =>
          logInteraction("Clicked model summary endpoint", { endpointID: endpoint.method_id })
        }
      >
        <EndpointEntity endpoint={endpoint} />
      </ListItemLink>
    ));
  }, [dataEndpointsMetadata, isLoading, projectID, modelID, location, logInteraction]);

  return (
    <AkiListTile
      title={title}
      icon={icon}
      emptyState={<>We haven&rsquo;t observed any traffic recently.</>}
      {...rest}
    >
      {items}
    </AkiListTile>
  );
};
