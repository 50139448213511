import { DarkMode, LightMode, Settings } from "@mui/icons-material";
import { Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import React, { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { FeatureToggle } from "../../../feature_flags/components/FeatureToggle";
import { PaletteModeContext } from "contexts/palette-mode";
import { logout } from "dashboard/utils/logout";
import { useUserInvites } from "data/queries/user-invites";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useAuth } from "hooks/use-auth";
import { useLogInteraction } from "hooks/use-log-interaction";

export const SettingsMenu = () => {
  const { userInfo, authState, authService } = useAuth();
  const history = useHistory();
  const { paletteMode, setPaletteMode } = useContext(PaletteModeContext);
  const logInteraction = useLogInteraction({ element: "SettingsMenu" });

  const { data: invites } = useUserInvites();
  const inviteNotificationCount = invites?.length || 0;
  const hasPendingInvite = inviteNotificationCount > 0;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onNavigate = useCallback(
    (href: string) => {
      history.push(href);
      onClose();
    },
    [history, onClose]
  );

  const onLogout = useCallback(() => {
    logInteraction("Logged out");
    void logout(authState!, authService);
  }, [authState, authService, logInteraction]);

  const toggleTheme = useCallback(() => {
    const newPaletteMode = paletteMode === "dark" ? "light" : "dark";
    logInteraction("Toggled color theme", { newPaletteMode });
    setPaletteMode(newPaletteMode);
    onClose();
  }, [logInteraction, onClose, paletteMode, setPaletteMode]);

  const isOpen = !!anchorEl;

  const loggedInUser = userInfo ? `${userInfo.name} (${userInfo.email})` : "";

  return (
    <>
      <Tooltip
        title={`Settings${hasPendingInvite ? " (pending invite)" : ""}`}
        placement="bottom-end"
      >
        <IconButton
          id="settings-menu-button"
          aria-controls={isOpen ? "settings-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={onClick}
          data-testid="navbar-button-settings-menu"
        >
          <Badge badgeContent={hasPendingInvite ? "" : undefined} color="warning">
            <Settings />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        id="settings-menu"
        onClose={onClose}
        open={isOpen}
        MenuListProps={{
          "aria-labelledby": "settings-menu-button",
          sx: {
            minWidth: (theme) => theme.spacing(30),
          },
        }}
      >
        <MenuItem
          onClick={() => onNavigate("/settings/api-keys")}
          autoFocus
          data-testid="settings-menu-item-api-keys"
        >
          API Keys
        </MenuItem>

        <MenuItem
          onClick={() => onNavigate("/settings/invites")}
          data-testid="settings-menu-item-invites"
        >
          {hasPendingInvite ? (
            <>
              Invites&nbsp;
              <b>({inviteNotificationCount})</b>
            </>
          ) : (
            "Invites"
          )}
        </MenuItem>

        <MenuItem
          onClick={() => onNavigate("/settings/services")}
          data-testid="settings-menu-item-projects"
        >
          Projects
        </MenuItem>

        <MenuItem
          onClick={() => onNavigate("/settings/team")}
          data-testid="settings-menu-item-team-members"
        >
          Team Members
        </MenuItem>

        <Divider aria-hidden />

        <FeatureToggle flag={FeatureFlag.InternalAdminPagesEnabled}>
          <Typography
            key="header"
            variant="h5"
            sx={{ padding: 2, paddingTop: 1, paddingBottom: 0.5 }}
            color="secondary"
          >
            STAFF ONLY
          </Typography>

          <MenuItem key="orgs" onClick={() => onNavigate("/internal/orgs")}>
            Orgs
          </MenuItem>

          <MenuItem
            key="user-reactivation"
            onClick={() => onNavigate("/internal/user-reactivation")}
          >
            User Reactivation
          </MenuItem>

          <Divider key="divider" aria-hidden />
        </FeatureToggle>

        <Typography
          key="header"
          variant="h5"
          sx={{ padding: 2, paddingTop: 1, paddingBottom: 0.5 }}
        >
          {loggedInUser}
        </Typography>

        <MenuItem onClick={toggleTheme}>
          {paletteMode === "dark" ? "Use Light Mode" : "Use Dark Mode"}
          {paletteMode === "dark" ? (
            <LightMode sx={{ marginLeft: 1 }} />
          ) : (
            <DarkMode sx={{ marginLeft: 1 }} />
          )}
        </MenuItem>

        <MenuItem onClick={onLogout}>Log Out</MenuItem>
      </Menu>
    </>
  );
};
