import { Alert, Box, Typography } from "@mui/material";
import React from "react";
import { InternalOrgProjectsTable } from "./InternalOrgProjectsTable";
import { InternalOrgUsersTable } from "./InternalOrgUsersTable";
import { OrganizationDetails } from "types/akita_api_types";

interface InternalOrgDetailsProps {
  organization?: OrganizationDetails;
}

export const InternalOrgDetails = ({ organization }: InternalOrgDetailsProps) => {
  if (!organization) return <Alert severity="error">Organization not found.</Alert>;

  return (
    <Box>
      <Typography variant="h2">Users ({organization.users.length})</Typography>
      <InternalOrgUsersTable users={organization.users} sx={{ marginBottom: 4 }} />

      <Typography variant="h2">Projects ({organization.services.length})</Typography>
      <InternalOrgProjectsTable organization={organization} />
    </Box>
  );
};
