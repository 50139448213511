import { Theme } from "@mui/material";
import { findLast, sortBy } from "lodash";
import { Timeline, TimelineAggregation, TimelineValue } from "types/akita_api_types";

export type TrendDirection = "up" | "down" | "flat";
export type TrendImplication = "good" | "bad" | "uncertain";
type DisplayTimelineMetadata = { title?: string; color?: string; isAnnotation?: boolean };
export type DisplayTimelineValue = DisplayTimelineMetadata & { x: number; y: number | null };
export type DisplayTimeline = DisplayTimelineMetadata & {
  values: DisplayTimelineValue[];
  isContinuous: boolean;
};

/**
 * Given an ordered array of DisplayTimelineValues, getTimelineTrend finds the first and last
 * non-null y values in the array and calculates the percentage change between the first and last.
 * If there's less than two non-null values in the timeline, then this returns 0.
 */
const getTimelineTrend = (values: DisplayTimelineValue[]) => {
  const y0 = values.find((value) => value.y !== null)?.y ?? null;
  const y1 = findLast(values, (value) => value.y !== null)?.y ?? null;

  if (y0 === null || y1 === null) return 0;

  return (y1 - y0) / y0;
};

/**
 * Given a trend percentage and an uncertainty value, returns a string indicating the general
 * "direction" of the timeline from which the trend was calculated.
 */
const getTrendDirection = (trend: number, uncertainty = 0.01): TrendDirection => {
  if (trend > uncertainty) return "up";
  if (trend < -uncertainty) return "down";

  return "flat";
};

/**
 * Given a trend direction ("up", "down", or "flat"), and a boolean indicating whether or not the
 * trend going up is a good thing or a bad thing (e.g. good for traffic, bad for latency), returns
 * a string indicating whether the timeline from which the direction was calculated is "good", "bad",
 * or "uncertain".
 */
const getTrendImplication = (direction: TrendDirection, isUpGood = true): TrendImplication => {
  switch (direction) {
    case "up":
      return isUpGood ? "good" : "bad";
    case "down":
      return isUpGood ? "bad" : "good";
    default:
      return "uncertain";
  }
};

export const getTrendInfo = (
  values?: DisplayTimelineValue[],
  { uncertainty, isUpGood }: { uncertainty?: number; isUpGood?: boolean } = {}
) => {
  if (!values || values.length <= 0) {
    return { trend: undefined, direction: undefined, implication: undefined };
  }

  const trend = getTimelineTrend(values);
  const direction = getTrendDirection(trend, uncertainty);
  const implication = getTrendImplication(direction, isUpGood);

  return {
    trend,
    direction,
    implication,
  };
};

export const getMinMaxTimelineValues = (values: DisplayTimelineValue[]) =>
  values.reduce(
    (result, { y }) =>
      y !== null
        ? {
            min: y < result.min ? y : result.min,
            max: y > result.max ? y : result.max,
          }
        : result,
    { min: Infinity, max: -Infinity }
  );

export const getSumTimelineValues = (values?: DisplayTimelineValue[]) =>
  values && values.length > 0 ? values.reduce((result, { y }) => result + (y ?? 0), 0) : undefined;

export const getAverageTimelineValue = (values?: DisplayTimelineValue[]) =>
  values && values.length > 0
    ? getSumTimelineValues(values)! / values.filter(({ y }) => y !== null).length
    : undefined;

/**
 * Converts a Map (where the key is an ISO datetime, and the value is a number dependent on the time)
 * into an ordered array of objects where "x" is a Unix timestamp, and "y" is the dependent variable.
 */
export const mapToDisplayTimelineValues = (
  m: Map<string, number | null>,
  metadata?: DisplayTimelineMetadata
): DisplayTimelineValue[] =>
  sortBy(
    Array.from(m.entries(), ([time, value]) => ({
      x: new Date(time).getTime(),
      y: value,
      ...metadata,
    })),
    "x"
  );

/**
 * Converts from a raw timeline object from the timeline API to something that we can more easily
 * display as a chart. Also tacks on the given metadata at the top level.
 */
export const akitaTimelineToDisplayTimeline = (
  timeline: Timeline,
  timelineValue: TimelineValue,
  metadata: DisplayTimelineMetadata = {}
): DisplayTimeline => {
  const valueMap = new Map<string, number | null>();

  timeline.events.forEach((bucket) => {
    valueMap.set(bucket.time, bucket.values[timelineValue] ?? null);
  });

  const values = mapToDisplayTimelineValues(valueMap, metadata);
  const isContinuous = values.every((value) => value.y !== null);

  return { ...metadata, values, isContinuous };
};

/**
 * Given an MUI Theme object and an index in an array of timelines, returns the corresponding color
 * from the Theme's timelines color palette. Wraps around if the index is out of bounds for the color
 * array.
 * @param theme - MUI Theme object
 * @param index - Index in an array of timelines
 * @returns - Color for a timeline chart
 */
export const getTimelineColorByIndex = (theme: Theme, index: number) =>
  theme.palette.timelines[index % theme.palette.timelines.length];

/**
 * The timeline query endpoint accepts an array of aggregation functions that yield timelines with
 * different keys. This function returns the TimelineValue needed to retrieve the aggregated value
 * from each bucket, for a given aggregation.
 */
export const timelineAggregationToTimelineValue = (
  aggregation: TimelineAggregation
): TimelineValue => {
  switch (aggregation) {
    case "max":
      return TimelineValue.Event_Latency_Max;
    case "min":
      return TimelineValue.Event_Latency_Min;
    case "mean":
      return TimelineValue.Event_Latency_Mean;
    case "median":
      return TimelineValue.Event_Latency_Median;
    case "90p":
      return TimelineValue.Event_Latency_90p;
    case "95p":
      return TimelineValue.Event_Latency_95p;
    case "99p":
      return TimelineValue.Event_Latency_99p;
    case "99.9p":
      return TimelineValue.Event_Latency_99_9p;
    case "rate":
      return TimelineValue.Event_Rate;
    case "count_4xx":
      return TimelineValue.Event_Num_4xx;
    case "count_5xx":
      return TimelineValue.Event_Num_5xx;
    case "fraction_4xx":
      return TimelineValue.Event_Fraction_4xx;
    case "fraction_5xx":
      return TimelineValue.Event_Fraction_5xx;
    case "count":
    default:
      return TimelineValue.Event_Count;
  }
};
