import { useQuery } from "@tanstack/react-query";
import { akitaFetch } from "data/akita-fetch";
import { GetMethodsMetadata } from "types/akita_api_types";

const getEndpointsMetadataQueryKey = (
  projectID?: string,
  modelID?: string,
  queryParams?: Record<string, any>
) => ["endpoints-metadata", projectID, modelID, queryParams];

const getEndpointsMetadata = (
  projectID: string,
  modelID: string,
  queryParams?: Record<string, any>
) =>
  akitaFetch<GetMethodsMetadata>(`/services/${projectID}/specs/${modelID}/methods-metadata`, {
    queryParams,
  });

export const useEndpointsMetadata = (
  projectID?: string,
  modelID?: string,
  queryParams?: Record<string, any>,
  options: { enabled?: boolean; keepPreviousData?: boolean; refetchOnWindowFocus?: boolean } = {}
) =>
  useQuery(
    getEndpointsMetadataQueryKey(projectID, modelID, queryParams),
    () => getEndpointsMetadata(projectID!, modelID!, queryParams),
    {
      enabled: !!(projectID && modelID),
      keepPreviousData: true,
      ...options,
    }
  );
