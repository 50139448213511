import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";

type ParameterNameEditorProps = {
  name: string;
  disallowedNames: Set<string>;
  onNameChange: (newTitle: string) => void;
};

export const ParameterNameEditor = ({
  name,
  disallowedNames,
  onNameChange,
}: ParameterNameEditorProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(name);
  const [userInputErrorMessage, setUserInputErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    setInputValue(name);
  }, [name]);

  useEffect(() => {
    if (!isEditing) {
      setUserInputErrorMessage(undefined);
      return;
    }

    if (disallowedNames.has(inputValue)) {
      setUserInputErrorMessage("Parameter already exists");
      return;
    }

    if (inputValue.trim().length === 0) {
      setUserInputErrorMessage("Parameter name is required");
      return;
    }

    setUserInputErrorMessage(undefined);
  }, [isEditing, inputValue, disallowedNames]);

  const handleParamNameChange = useCallback(() => {
    setIsEditing(false);
    onNameChange(inputValue);
  }, [inputValue, onNameChange]);

  const handleCancelNameEditing = useCallback(() => {
    setIsEditing(false);
    setInputValue(name);
  }, [name]);

  const hasError = !!userInputErrorMessage;

  return (
    <Stack direction="row" alignItems="center">
      {isEditing ? (
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleParamNameChange}
          onKeyDown={(e) => {
            if (!hasError && e.key === "Enter") {
              handleParamNameChange();
            }
          }}
          error={hasError}
          helperText={userInputErrorMessage}
          autoFocus
          size="small"
        />
      ) : (
        <Typography>
          Parameter name: <strong>{name}</strong>
        </Typography>
      )}
      <Tooltip title={isEditing ? "Cancel" : "Change the path parameter name"}>
        <IconButton
          onClick={() => {
            isEditing ? handleCancelNameEditing() : setIsEditing(true);
          }}
        >
          {isEditing ? <CancelIcon /> : <EditIcon />}
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
