import { Close, FilterList } from "@mui/icons-material";
import { Box, Button, Grow, Theme, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo } from "react";
import { TransitionGroup } from "react-transition-group";
import { EndpointsTableFilterOptionsList } from "./EndpointsTableFilterOptionsList";
import { formatFilterDefinitions } from "./formatting";
import { useAllSearchParams } from "hooks/use-all-search-params";
import { useLogInteraction } from "hooks/use-log-interaction";
import { SpecContentSummary, TimelineSummaryFilters } from "types/akita_api_types";

const sxContainer = (theme: Theme) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 1,
  flexShrink: 0,
  marginRight: 2,
  overflow: "hidden",
  paddingBottom: 1,
  width: theme.spacing(35),
  [theme.breakpoints.between("md", 1000)]: {
    width: theme.spacing(30),
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: 2,
    marginRight: 0,
    width: "100%",
  },
});

const sxHeader = (theme: Theme) => ({
  alignItems: "center",
  backgroundColor: theme.palette.overlay.min,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: "flex",
  height: "58px",
  marginBottom: 1,
  paddingLeft: 2,
  paddingRight: 1,
  justifyContent: "space-between",
});

type EndpointsTableFiltersProps = {
  isLoading?: boolean;
  summary?: SpecContentSummary | TimelineSummaryFilters;
  onClearFiltersClick?: () => void;
  onFilterOptionToggled?: (filterDefinitionKey: string, filterOptionKey: string) => void;
};

export const EndpointsTableFilters = ({
  summary,
  onClearFiltersClick,
  onFilterOptionToggled,
}: EndpointsTableFiltersProps) => {
  const [filters, clearFilters] = useAllSearchParams("filter-", {
    "filter-endpoint_categories": "all",
    "filter-http_methods": "all",
  });
  const logInteraction = useLogInteraction();
  const filterDefinitions = useMemo(() => formatFilterDefinitions(summary), [summary]);

  const filterOptionsLists = useMemo(
    () =>
      filterDefinitions.map((filterDefinition, index) => (
        <EndpointsTableFilterOptionsList
          key={filterDefinition.key}
          filterDefinition={filterDefinition}
          isLast={index === filterDefinitions.length - 1}
          onOptionToggled={(optionKey) =>
            onFilterOptionToggled && onFilterOptionToggled(filterDefinition.key, optionKey)
          }
        />
      )),
    [filterDefinitions, onFilterOptionToggled]
  );

  const onClickClear = useCallback(() => {
    const filterCount = Object.entries(filters).reduce((sum, [_, vs]) => (sum += vs.length), 0);

    logInteraction("Clicked to clear filters", { filterCount });
    clearFilters();
    onClearFiltersClick && onClearFiltersClick();
  }, [filters, logInteraction, clearFilters, onClearFiltersClick]);

  return (
    <Box sx={sxContainer}>
      <Box sx={sxHeader}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FilterList sx={{ marginRight: 1 }} />
          <Typography variant="h5">Filters</Typography>
        </Box>

        <TransitionGroup component={null}>
          {!isEmpty(filters) && (
            <Grow timeout={200}>
              <Button
                variant="outlined"
                color="secondary"
                endIcon={<Close />}
                onClick={onClickClear}
                size="small"
              >
                Clear
              </Button>
            </Grow>
          )}
        </TransitionGroup>
      </Box>

      {filterOptionsLists}
    </Box>
  );
};
