import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Deleter } from "dashboard/components/Deleter";
import { OrganizationInvite } from "types/akita_api_types";

type InvitesTableProps = {
  invites: OrganizationInvite[];
  refreshInvites: () => void;
  setAcceptInviteDialog: (invite: OrganizationInvite) => void;
};

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:last-child": {
      borderBottom: `0px solid ${theme.palette.divider}`,
    },
  },
}));

export const InvitesTable = (props: InvitesTableProps) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="h5">From</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5">Sent</Typography>
        </Grid>
      </Grid>
      <Divider />
      {props.invites.map((invite) => (
        <Grid container key={invite.id} className={classes.border} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="body2">{invite.inviter_name}</Typography>
            <Typography variant="body2">{invite.inviter_email}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">{new Date(invite.created_at).toString()}</Typography>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.setAcceptInviteDialog(invite)}
            >
              Accept
            </Button>
          </Grid>
          <Grid item>
            <Deleter
              key="deleter"
              name={`this invitation from ${invite.inviter_name}`}
              url={`${process.env.REACT_APP_API_URL}/v1/user/invites/${invite.id}`}
              onSuccess={props.refreshInvites}
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
};
