import { prettify } from "./strings";
import { MethodResponseField } from "types/akita_api_types";

// Akita-supported data formats that are considered sensitive data.
const sensitiveDataFormats = [
  "rfc_5322_email",
  "rfc_5322_name_email",
  "phone_number_international",
  "phone_number_us",
];

export function isSensitiveDataFormat(fmt: string): boolean {
  return sensitiveDataFormats.some((sensitiveFmt) => fmt === sensitiveFmt);
}

/**
 * Takes a `data_formats` value (e.g. iso_639_1) and returns a prettified version for display
 * (e.g. ISO 639-1)
 */
export const prettifyDataFormat = (dataFormat: string) => {
  if (dataFormat.startsWith("timestamp_")) {
    const rest = dataFormat.replace("timestamp_", "");

    return `Timestamp: ${prettify(rest)}`;
  }

  if (dataFormat === "iso_639_1") return "ISO 639-1";
  if (dataFormat === "iso_639_2") return "ISO 639-2";

  return prettify(dataFormat);
};

// These don't do anything special right now, but we'll use them for the sake of consistency.
// If we need to do any formatting that's specific to data_kinds or basic_type, we can do that here.
export const prettifyDataKind = (dataKind: string) => prettify(dataKind);
export const prettifyDataType = (dataType: string) => prettify(dataType);

/**
 * Takes an endpoint field object and returns a string that includes all of the data formats, data kinds,
 * and/or basic type info that the field contains.
 */
export const summarizeFieldDataFormats = (field: MethodResponseField) => {
  const dataFormats = field.properties.data_formats?.sort() || [];
  const dataKinds = field.properties.data_format_kinds?.sort() || [];
  const prettyDataKinds = dataKinds.map(prettifyDataKind).join(", ");
  const prettyDataFormats = dataFormats.map(prettifyDataFormat).join(", ");

  return dataKinds.length > 0
    ? `${prettyDataKinds} (${prettyDataFormats})`
    : prettifyDataType(field.properties.basic_type);
};
