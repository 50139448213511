import { useQuery } from "@tanstack/react-query";
import { akitaFetch, retry } from "data/akita-fetch";
import { GetMethodResponse } from "types/akita_api_types";

export interface EndpointParams {
  host: string;
  operation: string;
  path: string;
}

const getEndpointQueryKey = (
  projectID?: string,
  modelID?: string,
  endpointID?: string,
  params?: EndpointParams
) => {
  const key = ["endpoint", projectID, modelID];

  if (params) {
    return [...key, params];
  }

  return [...key, endpointID];
};

// Attempt to retrieve an endpoint with the given endpointID.
const getEndpointByID = async (projectID: string, modelID: string, endpointID: string) => {
  const result = await akitaFetch<GetMethodResponse>(
    `/services/${projectID}/specs/${modelID}/methods/${endpointID}`,
    { queryParams: { key: ["perf"] } }
  );

  return result?.method;
};

// Attempt to retrieve an endpoint matching the given filter params.
const getEndpointByParams = async (projectID: string, modelID: string, params: EndpointParams) => {
  const result = await akitaFetch<GetMethodResponse>(
    `/services/${projectID}/specs/${modelID}/method`,
    { queryParams: { ...params, key: ["perf"] } }
  );

  return result?.method;
};

export const useEndpoint = (
  projectID?: string,
  modelID?: string,
  endpointID?: string,
  params?: EndpointParams,
  options?: {
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
  }
) =>
  useQuery(
    getEndpointQueryKey(projectID, modelID, endpointID),
    () => {
      if (params) {
        return getEndpointByParams(projectID!, modelID!, params);
      }

      return getEndpointByID(projectID!, modelID!, endpointID!);
    },
    {
      enabled: !!(projectID && modelID && (endpointID || params)),
      refetchOnWindowFocus: false,
      retry,
      ...options,
    }
  );
