import { useTheme } from "@mui/material";
import { merge } from "lodash";
import { useMemo } from "react";
import { VictoryTheme, VictoryThemeDefinition } from "victory";

export const useVictoryTheme = () => {
  const theme = useTheme();

  return useMemo((): VictoryThemeDefinition => {
    const baseLabelStyles = {
      fontFamily: theme.typography.fontFamily,
      fill: theme.palette.text.primary,
    };

    const centeredLabel = { ...baseLabelStyles, textAnchor: "middle" };

    const themeOverrides: VictoryThemeDefinition = {
      axis: {
        style: {
          tickLabels: baseLabelStyles,
          axisLabel: centeredLabel,
          axis: {
            strokeLinecap: "square",
            stroke: theme.palette.foreground,
          },
          grid: {
            fill: "none",
            stroke: theme.palette.overlay.minPlus,
            pointerEvents: "painted",
          },
          ticks: {
            fill: "transparent",
            size: 4,
            stroke: theme.palette.foreground,
          },
        },
      },
      tooltip: {
        style: baseLabelStyles,
        flyoutStyle: {
          fill: theme.palette.background.paper,
        },
      },
      voronoi: {
        style: {
          labels: baseLabelStyles,
          flyout: {
            fill: theme.palette.background.paper,
          },
        },
      },
    };

    return merge(VictoryTheme.grayscale, themeOverrides);
  }, [theme]);
};
