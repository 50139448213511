import React from "react";
import { AkiNumberTile } from "dashboard/components/AkiTile/AkiNumberTile";
import { useClientTelemetryCountRunning } from "data/queries/client-telemetry";

export const TileClientsReportingNumber = ({
  deploymentID,
  isEmptyState,
  projectID,
}: {
  deploymentID?: string;
  isEmptyState?: boolean;
  projectID?: string;
}) => {
  const { data: count, isLoading } = useClientTelemetryCountRunning({ projectID, deploymentID });

  return (
    <AkiNumberTile
      count={count}
      isEmptyState={isEmptyState}
      isLoading={isLoading}
      labelPlural="clients reporting"
      labelSingular="client reporting"
      linkLabel="View Detailed Diagnostics"
      linkHref={projectID ? `/service/${projectID}/diagnostics/traces` : undefined}
    />
  );
};
