import { Location, LocationDescriptor } from "history";
import React, { useMemo } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { buildURL } from "../utils/urls";

type LinkBehaviorProps = Omit<RouterLinkProps, "to" | "href"> &
  (
    | {
        href: string;
        to?: never;
      }
    | {
        href?: never;
        to: LocationDescriptor | ((location: Location) => LocationDescriptor) | string;
      }
  );

/**
 * LinkBehavior wraps React Router's Link component to play nice with Material UI's Link and Button
 * components, so we can use it across the board and skip dealing with history.push()
 *
 * More info: https://mui.com/material-ui/guides/routing/#global-theme-link
 */
export const LinkBehavior = React.forwardRef<HTMLAnchorElement, LinkBehaviorProps>(
  ({ href, to, ...rest }, ref) => {
    const shouldUseAnchor = useMemo(() => {
      if (!href || typeof href !== "string") return false;

      // If the href is a valid URL (meaning it has a protocol), or begins with `//`, we'll use a
      // regular anchor tag instead.
      if (buildURL(href)) return true;
      if (href.startsWith("//")) return true;

      return false;
    }, [href]);

    if (shouldUseAnchor) {
      return (
        <a
          ref={ref}
          target="_blank"
          rel="noreferrer noopener"
          href={
            // This code is unreachable if href is not a string, so this is safe to do
            href
          }
          {...rest}
        />
      );
    }

    // Add `to` prop for React Router's Link component
    return <RouterLink ref={ref} to={to! || href!} {...rest} />;
  }
);

LinkBehavior.displayName = "LinkBehavior";
