import { Box, SxProps, Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import { InlineCode } from "./InlineCode";
import { mergeSxProps } from "dashboard/utils/sx";
import { useImpression } from "hooks/use-impression";
import { Tags } from "types/akita_api_types";

const ModelTag = ({ tag }: { tag?: string | string[] }) => {
  if (!tag) return null;

  if (Array.isArray(tag)) {
    return (
      <Typography component="ul" variant="body2">
        {tag.map((attribute, index) => (
          <li key={`${attribute}-${index}`}>{attribute}</li>
        ))}
      </Typography>
    );
  }

  return (
    <Typography component="div" variant="body2">
      {tag}
    </Typography>
  );
};

export const ModelTags = ({
  projectID,
  tags,
  sx,
}: {
  projectID?: string;
  tags?: Tags;
  sx?: SxProps<Theme>;
}) => {
  useImpression("Model Page - Tags Tab", {
    projectID,
    tagsCount: tags?.length,
  });

  const orderedKeys = useMemo(() => (tags ? Object.keys(tags).sort() : []), [tags]);

  return (
    <Box sx={mergeSxProps({ padding: 3, maxWidth: "660px" }, sx)}>
      <Typography variant="h2" gutterBottom>
        Tags
      </Typography>
      <Typography variant="body2" gutterBottom>
        Tags include both Akita tags (<InlineCode>x-akita-</InlineCode>) and your custom tags.
        Custom tags are commonly used to set a commit hash or version number to associate with Akita
        observations.
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        You can add custom tags to your API Model using the Akita CLI the command line flag{" "}
        <Link href="https://docs.akita.software/docs/learn#--tags-string">--tags</Link>.
      </Typography>
      {tags && (
        <Grid
          container
          sx={(theme) => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
          })}
        >
          {orderedKeys.map((key) => (
            <Grid
              item
              key={key}
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
                padding: 2,
                "&:last-child": {
                  borderBottom: `0px solid ${theme.palette.divider}`,
                },
              })}
              xs={12}
            >
              <Grid container spacing={2}>
                <Grid item style={{ width: 200 }}>
                  <Typography component="div" variant="body2" fontWeight="bold">
                    {key}
                  </Typography>
                </Grid>
                <Grid item>
                  <ModelTag tag={tags[key]} />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
