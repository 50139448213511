import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import React, { useState } from "react";
import { resendInvite, sendInvite } from "dashboard/pages/Settings/Organization/backend";
import { AkitaError } from "dashboard/utils/error";
import { EmailAddress, parseMailbox } from "dashboard/utils/parsers";
import { OrganizationInvite } from "types/akita_api_types";

type SendInvitesProps = {
  invites: OrganizationInvite[];
  onSent: () => void;
};

const maxLength = 250;

const isValidLength = (v: null | undefined | string): boolean =>
  !!v && 0 < v.length && v.length <= maxLength;

export const SendInvites = ({ invites, onSent }: SendInvitesProps) => {
  const { authState } = useOktaAuth();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formFirstName, setFormFirstName] = useState("");
  const [formLastName, setFormLastName] = useState("");
  const [formEmail, setFormEmail] = useState("");

  const [isErrorFirstName, setIsErrorFirstName] = useState(false);
  const [isErrorLastName, setIsErrorLastName] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);

  const clearModal = () => {
    setIsOpen(false);
    setIsLoading(false);
    setFormEmail("");
    setErrorMessage(undefined);
    setFormFirstName("");
    setFormLastName("");
    setIsErrorFirstName(false);
    setIsErrorLastName(false);
    setIsErrorEmail(false);
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const address = parseMailbox(formEmail);
    if (address !== null) {
      const existingInvite = invites.find((invite) => invite.invitee_email == address.email);
      const response = existingInvite
        ? resendInvite(authState?.accessToken?.accessToken as any, existingInvite.id)
        : sendInvite(
            authState?.accessToken?.accessToken as any,
            formFirstName,
            formLastName,
            address.email
          );
      await response.then(
        () => {
          clearModal();
          onSent();
        },
        (akErrPromise) => {
          akErrPromise.then((akErr: AkitaError) => {
            handleFailure({ address, err: akErr });
          });
        }
      );
    } else {
      setIsErrorEmail(true);
      setIsLoading(true);
    }
  };

  const handleFailure = (error: { address: EmailAddress; err: AkitaError }) => {
    setIsLoading(false);
    setErrorMessage(error.err.customerMessage);
  };

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFirstName(event.target.value);
    setIsErrorFirstName(!isValidLength(event.target.value));
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormLastName(event.target.value);
    setIsErrorLastName(!isValidLength(event.target.value));
  };

  const onEmailAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormEmail(event.target.value);
    setIsErrorEmail(!(parseMailbox(event.target.value) && isValidLength(event.target.value)));
  };

  const canSubmit =
    !isErrorFirstName &&
    !isErrorLastName &&
    !isErrorEmail &&
    formFirstName &&
    formLastName &&
    formEmail;

  return (
    <Box>
      <Box>
        <Button color="primary" onClick={showModal} variant="contained" sx={{ marginBottom: 2 }}>
          Invite Team Member
        </Button>
      </Box>

      <Dialog open={isOpen} keepMounted onClose={clearModal}>
        <DialogTitle>
          <Typography variant="h2" gutterBottom>
            Send Invite
          </Typography>
          <Typography variant="body2">
            Invite someone to your organization. They will receive an email to create and activate
            their account.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Divider sx={{ marginBottom: 1 }} />

          {errorMessage && (
            <Box sx={{ marginBottom: 2 }}>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}

          <form onSubmit={onSubmit}>
            <Box>
              <TextField
                label="First Name"
                id="first_name"
                type="text"
                size="medium"
                variant="outlined"
                fullWidth
                onChange={onFirstNameChange}
                error={isErrorFirstName}
                value={formFirstName}
                helperText={isErrorFirstName && `Required (max ${maxLength} characters)`}
                margin="dense"
              />

              <TextField
                label="Last Name"
                id="last_name"
                type="text"
                size="medium"
                variant="outlined"
                fullWidth
                onChange={onLastNameChange}
                error={isErrorLastName}
                value={formLastName}
                helperText={isErrorLastName && `Required (max ${maxLength} characters)`}
                margin="dense"
              />

              <TextField
                label="Email Address"
                id="email_address"
                type="text"
                size="medium"
                variant="outlined"
                fullWidth
                onChange={onEmailAddressChange}
                error={isErrorEmail}
                value={formEmail}
                helperText={
                  isErrorEmail &&
                  (isValidLength(formEmail)
                    ? "Please enter a valid email address"
                    : `Required (max ${maxLength} characters)`)
                }
                margin="dense"
                sx={{ marginBottom: 2 }}
              />
            </Box>

            <Divider />

            <Grid
              container
              flexDirection="row-reverse"
              alignItems="center"
              justifyContent="space-between"
              paddingTop={2}
              paddingBottom={2}
            >
              <Grid item>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={isLoading}
                  disabled={!canSubmit}
                >
                  Invite
                </LoadingButton>
              </Grid>

              <Grid item>
                <Button onClick={clearModal}>Cancel</Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
