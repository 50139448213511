import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AuthState, OktaAuth } from "@okta/okta-auth-js";
import React, { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { trackEvent } from "../../analytics/event-utils";
import { useAnalyticsContext } from "../../hooks/use-analytics-context";
import { createUser, getUser } from "dashboard/utils/backend";
import { AkitaError, AkitaErrorCode, akitaErrorOfFailure } from "dashboard/utils/error";
import { logout } from "dashboard/utils/logout";
import { getUserInvites } from "data/queries/user-invites";
import { AkitaUserInfo } from "types/akita_api_types";

const useStyles = makeStyles((theme) => ({
  bottom: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

type CheckUserProps = {
  authState: AuthState;
  authService: OktaAuth;
  location: any;
  onSuccess?: (user: AkitaUserInfo) => void;
};

// Checks if user has agreed to TOS
export const CheckUser = ({ authState, authService, onSuccess }: CheckUserProps) => {
  const classes = useStyles();
  const [alert, setAlert] = useState<ReactElement | null>(null);
  const [alreadyChecked, setAlreadyChecked] = useState(false);
  const navigate = useHistory();
  const { analytics } = useAnalyticsContext();

  const showTOS = () => {
    setAlert(
      <Dialog open>
        <DialogTitle>Terms of Service</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            By clicking continue, you agree to Akita Software&apos;s{" "}
            <Link href="https://docs.akita.software/docs/terms-of-service">Terms of Service</Link>.
          </Typography>
          <br />
          <Divider />
          <Grid container className={classes.bottom} justifyContent="space-between">
            <Grid item>
              <Button onClick={() => logout(authState, authService)}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setAlert(null);
                  return updateTOS();
                }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const updateTOS = () => {
    const updateUserURL = `${process.env.REACT_APP_API_URL}/v1/user/tos`;
    return fetch(updateUserURL, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        // If we just created a user that has team invitations, redirect
        // to the invitations page.
        return getUserInvites().then((invites) => {
          if (invites.length > 0) {
            navigate.push("/settings/invites");
          }
        });
      })
      .catch((err) => {
        throw akitaErrorOfFailure(err, AkitaErrorCode.PostErr, "accepting the terms of service");
      });
  };

  const onError = (akErr: AkitaError) => {
    // Log the error to Segment; even though /v1/user may have failed,
    // we should have already gotten a username from Okta.
    trackEvent("error during CheckUser", analytics, {
      source: "superstar-frontend",
      eventType: "error",
      requestID: akErr.requestID,
      status: akErr.status,
      statusText: akErr.statusText,
      errorMessage: akErr.error?.toString(),
    });

    setAlert(
      <Dialog open>
        <DialogTitle>Whoops!</DialogTitle>
        <DialogContent>
          <Typography variant="body2">{akErr.customerMessage}</Typography>
          <br />
          <Divider />
          <Grid container className={classes.bottom}>
            <Grid item>
              <Button onClick={() => logout(authState, authService)}>Close</Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => logout(authState, authService)}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  useEffect(() => {
    let isMounted = true;
    if (authState.isAuthenticated && !alreadyChecked) {
      void getUser(authState)
        .then((response) => {
          onSuccess && onSuccess(response);
          if (isMounted) setAlreadyChecked(true);

          if (!response.tos_accepted_at) {
            // Doesn't return
            if (isMounted) showTOS();
          }
        })
        .catch((akErrPromise) => {
          if (akErrPromise.then) {
            return akErrPromise.then((akErr: AkitaError) => {
              if (akErr.status && akErr.status === 401) {
                // The auth check in front returns a 401 if it can't find the user.
                // https://github.com/akitasoftware/test-service/blob/1705670c947a83143b25f591c2ffe84c4a4dc425/services/front/auth.py#L92
                createUser(authService, authState).then(
                  (response: AkitaUserInfo) => {
                    onSuccess && onSuccess(response);
                    // We need to just reload here
                    window.location.reload();
                  },
                  (akErrPromise) => akErrPromise.then(onError)
                );
              } else {
                onError(akErr);
              }
            });
          }
          // Error is not a promise.
          throw akErrPromise;
        });
    }
    return () => {
      isMounted = false;
    };
  }, [authState.isAuthenticated]);

  return alert;
};
