import { Close } from "@mui/icons-material";
import { Box, BoxProps, IconButton, SxProps, Theme, Typography } from "@mui/material";
import React, { ReactNode, useContext } from "react";
import { DialogContext } from "contexts/dialog-context";
import { mergeSxProps } from "dashboard/utils/sx";

type AkiDialogHeaderProps = Omit<BoxProps, "children"> & {
  children?: ReactNode;
  sxButton?: SxProps<Theme>;
  sxContent?: SxProps<Theme>;
  sxTitle?: SxProps<Theme>;
  title: ReactNode;
};

export const AkiDialogHeader = ({
  children,
  sx,
  sxButton,
  sxContent,
  sxTitle,
  title,
  ...rest
}: AkiDialogHeaderProps) => {
  const { titleID, onClose } = useContext(DialogContext);

  return (
    <Box
      component="header"
      sx={mergeSxProps({ padding: 2, width: "100%", position: "relative" }, sx)}
      {...rest}
    >
      <IconButton
        onClick={onClose}
        aria-label="Close Dialog"
        size="medium-outlined"
        sx={mergeSxProps(
          {
            position: "absolute",
            right: (theme) => theme.spacing(children ? 2 : 1.5),
            top: (theme) => theme.spacing(children ? 2 : 1.5),
          },
          sxButton
        )}
      >
        <Close />
      </IconButton>

      <Box maxWidth="calc(100% - 58px)" sx={sxContent}>
        <Typography
          variant="h1"
          id={titleID}
          sx={mergeSxProps({ marginBottom: children ? 1 : 0 }, sxTitle)}
        >
          {title}
        </Typography>

        {children}
      </Box>
    </Box>
  );
};
