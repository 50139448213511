import { TableCell, TableCellProps, TableSortLabel } from "@mui/material";
import React, { useCallback } from "react";
import { mergeSxProps } from "dashboard/utils/sx";
import { SortDirection, SortParam } from "hooks/use-sort-param";

type SortableTableHeaderCellProps = TableCellProps & {
  activeSortDirection?: SortDirection;
  activeSortKey?: string;
  isDisabled?: boolean;
  onSort: (sort: SortParam) => void;
  sortKey: string;
};

export const SortableTableHeaderCell = ({
  activeSortDirection,
  activeSortKey,
  children,
  isDisabled,
  onSort,
  sortKey,
  sx,
  ...rest
}: SortableTableHeaderCellProps) => {
  const handleClick = useCallback(() => {
    if (activeSortKey === sortKey) {
      if (activeSortDirection === "asc") {
        return onSort(`${sortKey}:desc`);
      }

      return onSort(`${sortKey}:asc`);
    }

    return onSort(`${sortKey}:desc`);
  }, [activeSortKey, onSort, activeSortDirection, sortKey]);

  return (
    <TableCell
      sx={mergeSxProps({ paddingLeft: 1, paddingRight: 1 }, sx)}
      sortDirection={activeSortKey === sortKey ? activeSortDirection : undefined}
      {...rest}
    >
      <TableSortLabel
        active={activeSortKey === sortKey}
        direction={activeSortKey === sortKey ? activeSortDirection : "desc"}
        onClick={handleClick}
        disabled={isDisabled}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};
