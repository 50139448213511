import { Buffer } from "buffer";
import { useMutation } from "@tanstack/react-query";
import { akitaFetch } from "data/akita-fetch";
import { PublicReactivateUsersResponse, ReactivateUsersResponse } from "types/akita_api_types";

const postReactivateUsers = async (userEmails: string[]) =>
  akitaFetch<ReactivateUsersResponse>("/admin/users/reactivate", {
    method: "POST",
    body: { user_emails: userEmails },
  });

export const postPublicReactivateUsers = async (userEmails: string[]) =>
  akitaFetch<PublicReactivateUsersResponse>("/v1/users/reactivate", {
    method: "POST",
    body: { user_emails: userEmails },
    authOverride: {
      Authorization: `Basic ${Buffer.from("shared:opensesame", "utf-8").toString("base64")}`,
    },
  });

export const useReactivateUsers = (options?: {
  onSuccess: (response: ReactivateUsersResponse) => void;
}) =>
  useMutation(postReactivateUsers, {
    onSuccess: (apiKey) => {
      if (options?.onSuccess) {
        options.onSuccess(apiKey);
      }
    },
  });
