import { Typography } from "@mui/material";
import React from "react";
import { HTTPMethodEntity } from "dashboard/components/entities/HTTPMethodEntity";
import {
  prettifyDataFormat,
  prettifyDataKind,
  prettifyDataType,
} from "dashboard/utils/data_formats";
import { prettify } from "dashboard/utils/strings";

type PseudoOptionLabelProps = { pseudoOptionTextOverride: string };

type EndpointsTableFilterOptionLabelProps =
  | {
      filterKey: string;
      optionKey: string;
    }
  | PseudoOptionLabelProps;

const getText = (filterKey: string, optionKey: string) => {
  if (filterKey === "endpoint_categories" && optionKey === "none") return "Uncategorized";
  if (filterKey === "hosts") return optionKey;
  if (filterKey === "data_types") return prettifyDataType(optionKey);
  if (filterKey === "data_kinds") return prettifyDataKind(optionKey);
  if (filterKey === "data_formats") return prettifyDataFormat(optionKey);

  return prettify(optionKey, { replaceDashes: true });
};

type FilterOptionLabelInnerProps = { text: string };

const FilterOptionLabelInner = ({ text }: FilterOptionLabelInnerProps) => (
  <Typography
    variant="body2"
    component="div"
    sx={{
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      paddingRight: 1,
    }}
    title={text}
  >
    {text}
  </Typography>
);

export const EndpointsTableFilterOptionLabel = (props: EndpointsTableFilterOptionLabelProps) => {
  if ("pseudoOptionTextOverride" in props) {
    return <FilterOptionLabelInner text={props.pseudoOptionTextOverride} />;
  }

  if (props.filterKey === "http_methods") {
    return <HTTPMethodEntity httpMethod={props.optionKey} />;
  }

  const text = getText(props.filterKey, props.optionKey);
  return <FilterOptionLabelInner text={text} />;
};
