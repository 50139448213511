import { VolumeUp } from "@mui/icons-material";
import { IconButton, Menu, Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Duration, add, formatDuration } from "date-fns";
import React, { MouseEvent, useCallback, useState } from "react";
import { getMonitorUpdateFromMonitor } from "dashboard/utils/monitors";
import { pluralize } from "dashboard/utils/strings";
import { useLogInteraction } from "hooks/use-log-interaction";
import { Monitor, MonitorUpdate } from "types/akita_api_types";

interface MuteAllButtonProps {
  monitors?: Monitor[];
  updateMonitors: (updates: MonitorUpdate[]) => void;
}

export const MuteAllButton = ({ monitors, updateMonitors }: MuteAllButtonProps) => {
  // Interaction tracking.
  const logInteraction = useLogInteraction();

  // State and handling for the dropdown menu.
  const [muteAnchorEl, setMuteAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(muteAnchorEl);

  const handleMuteClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMuteAnchorEl(event.currentTarget);
  }, []);

  const handleMuteClose = useCallback(() => {
    setMuteAnchorEl(null);
  }, []);

  // Functions for updating monitor state.
  const unmute = useCallback(() => {
    if (!monitors) return;

    const monitorUpdates = monitors.map((m) => {
      const update = getMonitorUpdateFromMonitor(m);
      update.muted_until = undefined;
      update.muted_forever = false;
      return update;
    });
    updateMonitors(monitorUpdates);

    logInteraction("Unmuted all monitors");

    handleMuteClose();
  }, [handleMuteClose, logInteraction, monitors, updateMonitors]);

  const muteFor = useCallback(
    (duration: Duration | "forever") => {
      if (!monitors) return;

      const monitorUpdates = monitors.map((m) => {
        const update = getMonitorUpdateFromMonitor(m);
        if (duration === "forever") {
          update.muted_until = undefined;
          update.muted_forever = true;
        } else {
          update.muted_until = add(Date.now(), duration).toISOString();
          update.muted_forever = false;
        }
        return update;
      });
      updateMonitors(monitorUpdates);

      if (duration === "forever") {
        logInteraction("Muted all monitor", { duration: "forever" });
      } else {
        logInteraction("Muted all monitor", { duration: formatDuration(duration) });
      }

      handleMuteClose();
    },
    [handleMuteClose, logInteraction, monitors, updateMonitors]
  );

  return (
    <>
      <Tooltip title="Mute/Unmute All">
        <IconButton
          aria-controls={isOpen ? "mute-all-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="true"
          disabled={!monitors}
          id="mute-all-button"
          onClick={handleMuteClick}
          size="small-outlined"
        >
          <VolumeUp />
        </IconButton>
      </Tooltip>

      <Menu
        id="mute-all-menu"
        anchorEl={muteAnchorEl}
        open={isOpen}
        onClose={handleMuteClose}
        MenuListProps={{
          "aria-labelledby": "mute-all-button",
        }}
      >
        <MenuItem key="unmute" onClick={unmute}>
          Unmute All
        </MenuItem>
        {[1, 3, 6, 12].map((hours) => (
          <MenuItem key={`${hours}-hours`} onClick={() => muteFor({ hours })}>
            Mute all for {hours} {pluralize("hour", hours)}
          </MenuItem>
        ))}
        <MenuItem key="1-day" onClick={() => muteFor({ days: 1 })}>
          Mute all for 1 day
        </MenuItem>
        <MenuItem key="forever" onClick={() => muteFor("forever")}>
          Mute all forever
        </MenuItem>
      </Menu>
    </>
  );
};
