import { useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { EndpointsTable } from "dashboard/components/EndpointsTable/EndpointsTable";
import {
  EndpointsTableEndpoint,
  formatEndpointsTableEndpoints,
} from "dashboard/components/EndpointsTable/formatting";
import { useEndpointsTableFilters } from "dashboard/components/EndpointsTable/hooks";
import { useEndpointsMetadata } from "data/queries/endpoints-metadata";
import { usePageIndex } from "hooks/use-page-index";
import { useSearchParam } from "hooks/use-search-param";
import { useSortParam } from "hooks/use-sort-param";

const ENDPOINTS_PER_PAGE = 15;

type ModelEndpointsTableProps = {
  isSelectable?: boolean;
  modelID?: string;
  projectID?: string;
};

export const ModelEndpointsTable = ({
  projectID,
  modelID,
  isSelectable,
}: ModelEndpointsTableProps) => {
  const theme = useTheme();
  const [_, setModalPath] = useSearchParam("modal");
  const [pageIndex] = usePageIndex();
  const { activeSortKey, activeSortDirection } = useSortParam({
    defaultSortKey: "count",
    defaultSortDirection: "desc",
  });
  const [filters] = useEndpointsTableFilters();

  const {
    data: endpointsMetadata,
    isLoading,
    isPreviousData,
  } = useEndpointsMetadata(projectID, modelID, {
    key: ["perf", "filters"],
    offset: pageIndex * ENDPOINTS_PER_PAGE,
    limit: ENDPOINTS_PER_PAGE,
    sort: `${activeSortKey === "percentile" ? "p90" : activeSortKey}:${activeSortDirection}`,
    ...filters,
  });

  const openEndpointDetails = useCallback(
    (endpoint: EndpointsTableEndpoint) => {
      setModalPath(`/project/${projectID}/model/${modelID}/endpoint/${endpoint.methodID}`);
    },
    [setModalPath, projectID, modelID]
  );

  const endpoints = useMemo(
    () =>
      endpointsMetadata?.metadata
        ? formatEndpointsTableEndpoints(endpointsMetadata.metadata)
        : undefined,
    [endpointsMetadata?.metadata]
  );

  return (
    <EndpointsTable
      isSelectable={isSelectable}
      endpoints={endpoints}
      endpointsPerPage={ENDPOINTS_PER_PAGE}
      isLoading={isLoading}
      isPreviousData={isPreviousData}
      openEndpointDetails={openEndpointDetails}
      summary={endpointsMetadata?.summary}
      sxPagination={{ paddingBottom: 0 }}
      totalCount={endpointsMetadata?.total_count}
      selectedEndpointHighlightColor={theme.palette.secondary.main}
    />
  );
};
