import { InputAdornment } from "@mui/material";
import React from "react";
import { NumberField } from "dashboard/components/NumberField";
import { pluralize } from "dashboard/utils/strings";

type MonitorMinEventCountInputProps = {
  minEventCountStr: string;
  onChange: (minEventCountStr: string) => void;
};

export const MonitorMinEventCountInput = ({
  minEventCountStr,
  onChange,
}: MonitorMinEventCountInputProps) => (
  <NumberField
    aria-label="Minimum number of events required to trigger an alert"
    value={minEventCountStr}
    onChange={onChange}
    placeholder="0"
    size="medium"
    sx={{ width: { md: 180 } }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {pluralize("event", parseInt(minEventCountStr, 10))}
        </InputAdornment>
      ),
    }}
  />
);
