/**
 * Returns a boolean indicating whether or not the given string begins and ends with curly braces,
 * like a path parameter token.
 */
export const isPathParameter = (pathPiece: string) =>
  pathPiece.startsWith("{") && pathPiece.endsWith("}");

/**
 * Object where each key is a path parameter name, and its value represents its location in an
 * endpoint path.
 */
export type PathParameterPositions = { [pathParameterName: string]: number };

/**
 * Takes an endpoint path (e.g. "/services/{serviceID}/spec/{specID}") and returns an object where
 * each key is a path parameter name, and its value represents its location in an endpoint path
 * (e.g. { serviceID: 2, specID: 4 }).
 */
export const getParameterPositions = (endpointPath?: string): PathParameterPositions => {
  if (!endpointPath) return {};

  const pieces = endpointPath.split("/");

  return pieces.reduce(
    (result: PathParameterPositions, piece, index) =>
      isPathParameter(piece) ? { ...result, [piece.slice(1, -1)]: index } : result,
    {}
  );
};

/**
 * Given an endpoint path template string and a path parameter position number, returns the path
 * string preceeding the given position.
 */
export const getPathTemplatePrefixForParameter = (endpointPath: string, paramPosition: number) =>
  endpointPath.split("/").slice(0, paramPosition).join("/");
