import LoopIcon from "@mui/icons-material/Loop";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopIcon from "@mui/icons-material/Stop";
import { Button, Divider, LinearProgress, Link, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { CodeBox } from "dashboard/components/CodeBox";
import { ErrorWidget } from "dashboard/components/ErrorWidget";
import { CustomTable } from "dashboard/components/Table";
import { ActiveDaemonTable } from "dashboard/pages/Service/ActiveDaemonTable";
import { DaemonList } from "dashboard/pages/Service/DaemonList";
import { PreviousTraceCard } from "dashboard/pages/Service/PreviousTraceCard";
import { useGetFetch } from "hooks/use-get-fetch";
import { GetMiddlewareResponse } from "types/akita_api_types";

type ServiceLiveCollectionProps = {
  serviceID: string;
  serviceName: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const ServiceLiveCollection = (props: ServiceLiveCollectionProps) => {
  const serviceID = props.serviceID;
  const { authState } = useOktaAuth();
  const classes = useStyles();

  // Fetch middleware status
  const middlewareURL = `${process.env.REACT_APP_API_URL}/v1/services/${serviceID}/active_middleware`;
  const [middle, middleLoading, middleError, middleRefresh] = useGetFetch<GetMiddlewareResponse>(
    middlewareURL,
    undefined,
    false,
    "fetching the middleware status"
  );

  // Refresh list every 30 seconds
  useEffect(() => {
    const id = setInterval(() => {
      middleRefresh();
    }, 30 * 1000);
    return () => clearInterval(id);
  }, [authState?.isAuthenticated, props]);

  // Use a different icon for the button when an operation is in flight.
  // This is sort of cheesy and doesn't completely work; the icon
  // switched back before the remainder of the refresh.  So delaying it until
  // the data has returned would be better.
  const [startIcon, setStartIcon] = useState(<PlayCircleOutlineIcon />);
  const [stopIcon, setStopIcon] = useState(<StopIcon />);

  // Number of hours to run the trace
  const [hours, setHours] = useState(1);

  const errorSources = [middleError];
  if (middleLoading || !middle) {
    return (
      <div>
        <ErrorWidget errorSources={errorSources} errorContext="middleware" />
        <LinearProgress />
      </div>
    );
  }

  const sessionTableData = (middle.scheduled_trace_sessions || [])
    .filter((trace) => {
      for (const a of middle.active_trace_sessions) {
        if (trace.trace_id == a.trace_id) {
          return true;
        }
      }
      return false;
    })
    .map((trace) => [
      <Link key="link" href={`/service/${serviceID}/trace/${trace.trace_id}`}>
        {trace.trace_name}
      </Link>,
      new Date(trace.start_time).toLocaleString(),
      new Date(trace.end_time).toLocaleString(),
      "",
    ]);

  // no daemons
  //     active trace sessions => report a problem  (TODO)
  //     future scheduled trace sessions => show upcoming, say no daemons  (TODO)
  //     nothing => show introduction
  // daemons
  //     no active trace sessions => show start button
  //     active trace sessions => show stop button and name of trace(s)

  const clientConfig =
    "import akitaMW from `akita-express-middleware`;\n\n" +
    "app.use(akitaMW({\n" +
    '    "clientName" : "<your client name here>",\n' +
    `    "serviceName" : "${props.serviceName}",\n` +
    '    "daemonHost"  : "<daemon host>:50080",\n' +
    "}));\n";

  const topSection = (
    <React.Fragment>
      <Typography variant="h2">Daemons</Typography>
      <Typography variant="body2">
        We recommend the Daemon mode for running with middleware integrations such as{" "}
        <Link href="https://docs.akita.software/docs/staging-and-production-expressjs-via-middleware">
          Express.js
        </Link>{" "}
        and <Link href="https://docs.akita.software/docs/django-on-heroku">Django on Heroku</Link>.
      </Typography>
      <br />
      <Divider />
      <br />
    </React.Fragment>
  );

  if (!middle || (middle?.daemons || []).length == 0) {
    return (
      <div className={classes.container}>
        {topSection}
        <div className={classes.section}>
          <Typography variant="h2" gutterBottom>
            No active middleware
          </Typography>
          <Typography variant="body2" gutterBottom>
            No daemons are currently ready to perform a trace of this project.
          </Typography>
          <Typography variant="body2" gutterBottom>
            To record a trace, first start the Akita CLI in daemon mode, in a location where
            middleware services can access it. Give it a name you will recognize; the default value
            is the name of the host on which the daemon is running. The daemon runs on port 50080
            unless you specify a different one with the <code>--port</code> flag.
          </Typography>
          <CodeBox code="akita daemon --name <daemon name>" label="daemon command" />
          <DaemonList />
        </div>
        <Divider />
        <div className={classes.section}>
          <Typography variant="h2" gutterBottom>
            Setting up Express.JS
          </Typography>
          <Typography variant="body2" gutterBottom>
            Configure the{" "}
            <Link href="https://github.com/akitasoftware/express-middleware">
              Akita Software Express.JS middleware
            </Link>{" "}
            with a descriptive name, the host and port of the daemon, and the project named "
            {props.serviceName}".
          </Typography>
          <CodeBox code={clientConfig} label="middleware configuration" />
        </div>
        <Divider />
        <div className={classes.section}>
          <PreviousTraceCard
            serviceID={serviceID}
            scheduledTraces={middle.scheduled_trace_sessions || []}
          />
        </div>
      </div>
    );
  }

  const interiorTextStyle = {
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
    display: "inline-block",
  };

  const hourInputStyle = {
    verticalAlign: "middle",
    width: "60pt",
    display: "inline-block",
  };

  const stopButton = () => {
    setStopIcon(<LoopIcon />);
    const startURL = `${process.env.REACT_APP_API_URL}/v1/services/${serviceID}/stop_trace`;
    return fetch(startURL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    }).then(() => {
      middleRefresh();
      setStopIcon(<StopIcon />);
    });
    // TODO: handle errors
  };

  if (middle.active_trace_sessions.length > 0) {
    return (
      <div className={classes.container}>
        {topSection}
        <div className={classes.section}>
          <Button color="primary" variant="contained" onClick={stopButton} startIcon={stopIcon}>
            Stop
          </Button>
          <Typography variant="body2" style={interiorTextStyle}>
            all middleware traces for this project.
          </Typography>
        </div>
        <Divider />
        <div className={classes.section}>
          <Typography variant="h2">Active traces</Typography>
          <CustomTable
            tableHead={["Trace Name", "Started", "Ends", ""]}
            tableData={sessionTableData}
          />
        </div>
        <Divider />
        <div className={classes.section}>
          <Typography variant="h2">Daemons registered for this project</Typography>
          <ActiveDaemonTable daemons={middle.daemons} />
        </div>
        <Divider />
        <div className={classes.section}>
          <PreviousTraceCard
            serviceID={serviceID}
            scheduledTraces={middle.scheduled_trace_sessions}
          />
        </div>
      </div>
    );
  }

  const startButton = () => {
    setStartIcon(<LoopIcon />);
    const startURL = `${process.env.REACT_APP_API_URL}/v1/services/${serviceID}/start_simple_trace`;
    return fetch(startURL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        duration: hours * 3600,
      }),
    }).then(() => {
      middleRefresh();
      setStartIcon(<PlayCircleOutlineIcon />);
    });
    // TODO: handle errors
  };

  const handleHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const h = Number(event.currentTarget.value);
    // TODO: real input validation
    if (h > 0) {
      setHours(h);
    }
  };

  const firstTimeText = middle.scheduled_trace_sessions?.length == 0 && (
    <Typography variant="body2">
      If this your first time collecting a trace for this service, we recommend starting with a 1
      hour trace. You will be able to stop the trace early, if desired.
    </Typography>
  );

  const textMatchesButton = {
    color: "#0abfbe",
  };

  return (
    <div className={classes.container}>
      {topSection}
      <div className={classes.section}>
        <Button color="primary" variant="contained" onClick={startButton} startIcon={startIcon}>
          Start
        </Button>
        <Typography variant="body2" style={interiorTextStyle}>
          trace for
        </Typography>
        <TextField
          type="number"
          value={hours}
          onChange={handleHourChange}
          size="small"
          margin="normal"
          style={hourInputStyle}
        />
        <Typography variant="body2" style={interiorTextStyle}>
          hours.
        </Typography>
      </div>
      <Divider />
      <div className={classes.section}>
        <Typography variant="h2" gutterBottom>
          No active traces
        </Typography>
        <Typography variant="body2" gutterBottom>
          The middleware and daemon are ready to collect a trace, but are currently idle. Click the{" "}
          <span style={textMatchesButton}>Start</span> button above to start a trace.
        </Typography>
        {firstTimeText}
      </div>
      <Divider />
      <div className={classes.section}>
        <Typography variant="h2" gutterBottom>
          Daemons registered for this project
        </Typography>
        <Typography variant="body2" gutterBottom>
          The following daemons have been contacted by middleware for {props.serviceName}:
        </Typography>
        <ActiveDaemonTable daemons={middle.daemons || []} />
      </div>
      <Divider />
      <div className={classes.section}>
        <PreviousTraceCard
          serviceID={serviceID}
          scheduledTraces={middle.scheduled_trace_sessions}
        />
      </div>
    </div>
  );
};
