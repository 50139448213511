import { LinearProgress } from "@mui/material";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import React, { Suspense, lazy } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { OktaErrorRedirect } from "auth/components/OktaErrorRedirect";
import { oktaAuth } from "auth/config";
import { Layout } from "dashboard/Layout";
import { AuthProvider } from "dashboard/components/AuthProvider";
import { ProjectAndDeploymentIdsProvider } from "dashboard/components/ProjectAndDeploymentIdsProvider";
import { PublicResendUserActivationPage } from "dashboard/pages/PublicResendUserActivation/PublicResendUserActivationPage";

const AuthLayout = lazy(() => import("auth/Layout"));

export const SecureRouter = () => {
  const navigate = useHistory();

  const onAuthRequired = () => {
    navigate.push("/login");
  };

  const restoreOriginalUri = (_oktaAuth: any, originalUri: any) =>
    navigate.replace(toRelativeUrl(originalUri || "/", window.location.origin));

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          <Route path="/login" render={() => <AuthLayout />} />
          <Route
            path="/implicit/callback"
            render={() => <LoginCallback errorComponent={OktaErrorRedirect} />}
          />
          <Route path="/user/reactivate" render={() => <PublicResendUserActivationPage />} />
          <Route path="/slack/callback" render={() => <>Loading</>} />
          <Route path="/healthz" render={() => <div />} />
          <SecureRoute
            path="*"
            render={() => (
              <AuthProvider>
                <ProjectAndDeploymentIdsProvider>
                  <Layout />
                </ProjectAndDeploymentIdsProvider>
              </AuthProvider>
            )}
          />
        </Switch>
      </Suspense>
    </Security>
  );
};
