import { Box, SxProps, Theme, alpha, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { VictoryArea } from "victory";
import { mergeSxProps } from "dashboard/utils/sx";
import {
  DisplayTimelineValue,
  TrendImplication,
  getMinMaxTimelineValues,
} from "dashboard/utils/timelines";

const getDataStyleForImplication = (theme: Theme, implication?: TrendImplication) => {
  switch (implication) {
    case "good":
      return {
        fill: alpha(theme.palette.success.main, 0.3),
        stroke: theme.palette.success.main,
      };
    case "bad":
      return {
        fill: alpha(theme.palette.error.main, 0.3),
        stroke: theme.palette.error.main,
      };
    default:
      return {
        fill: alpha(theme.palette.grey[600], 0.3),
        stroke: theme.palette.grey[600],
      };
  }
};

interface SparklineProps {
  data: DisplayTimelineValue[];
  height?: number;
  implication?: TrendImplication;
  sx?: SxProps<Theme>;
  width?: number;
}

export const Sparkline = ({ data, height, implication, sx, width }: SparklineProps) => {
  const theme = useTheme();
  const dataStyle = getDataStyleForImplication(theme, implication);

  const { max } = useMemo(() => getMinMaxTimelineValues(data), [data]);

  return (
    <Box
      sx={mergeSxProps(
        {
          "& svg": {
            display: "block",
          },
        },
        sx
      )}
    >
      <VictoryArea
        animate={{
          duration: 400,
          onLoad: { duration: 800 },
        }}
        style={{
          parent: {
            height: "auto",
          },
          data: {
            strokeWidth: "8px",
            ...dataStyle,
          },
        }}
        data={data}
        padding={0}
        minDomain={{ y: 0 }}
        // Add 10% padding, to prevent clipping of tops of graph
        maxDomain={{ y: max + Math.abs(max) * 0.1 }}
        width={width ?? 400}
        height={height ?? 200}
      />
    </Box>
  );
};
