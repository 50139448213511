import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import cx from "classnames";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: "right",
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
    overflow: "auto",
  },
  tableShoppingHead: {},
  tableHeadFontSize: {
    fontSize: "1.25em",
  },
  tableHeadCell: {
    border: "none",
  },
  tableCell: {
    padding: theme.spacing(2, 1),
    verticalAlign: "middle",
    borderBottom: "none",
    borderTop: `1px solid ${theme.palette.divider}`,
    position: "relative",
  },
  tableCellTotal: {
    fontSize: "1.25em",
    paddingTop: "14px",
    textAlign: "right",
  },
  tableCellAmount: {
    fontSize: "26px",
    marginTop: theme.spacing(1),
    textAlign: "right",
  },
  tableResponsive: {
    minHeight: "0.1%",
    overflowX: "auto",
  },
  tableStripedRow: {},
  tableRowHover: {},
  tableRowBody: {
    height: "48px",
  },
  tableRowHead: {
    height: "56px",
  },
  tableRow: {},
}));

export const CustomTable = (props: {
  tableHead: any[];
  tableData: any[];
  hover: boolean;
  coloredColls: number[];
  colorsColls: [];
  customCellClasses: string[];
  customClassesForCells: number[];
  customHeadCellClasses: string[];
  customHeadClassesForCells: number[];
  striped: boolean;
  pagination?: {
    count: number;
    page: number;
    itemsPerPage: number;
    itemsPerPageOptions: number[];
    setPage: any;
    setItemsPerPage: any;
  };
}) => {
  const classes = useStyles();
  const {
    tableHead,
    tableData,
    hover,
    colorsColls,
    coloredColls,
    customCellClasses,
    customClassesForCells,
    striped,
  } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead>
            <TableRow className={clsx(classes.tableRow, classes.tableRowHead)}>
              {tableHead.map((prop, key) => (
                <TableCell className={clsx(classes.tableCell, classes.tableHeadCell)} key={key}>
                  <Typography variant="h5">{prop}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            const tableRowClasses = cx({
              [classes.tableRowBody]: true,
              [classes.tableRowHover]: hover,
              [classes.tableStripedRow]: striped && key % 2 === 0,
            });
            if (prop.total) {
              return (
                <TableRow key={key} hover={hover} className={tableRowClasses}>
                  <TableCell className={classes.tableCell} colSpan={prop.colspan} />
                  <TableCell className={clsx(classes.tableCell, classes.tableCellTotal)}>
                    Total
                  </TableCell>
                  <TableCell className={clsx(classes.tableCell, classes.tableCellAmount)}>
                    {prop.amount}
                  </TableCell>
                  {tableHead.length - (prop.colspan - 0 + 2) > 0 ? (
                    <TableCell
                      className={classes.tableCell}
                      colSpan={tableHead.length - (prop.colspan - 0 + 2)}
                    />
                  ) : null}
                </TableRow>
              );
            }
            if (prop.purchase) {
              return (
                <TableRow key={key} hover={hover} className={tableRowClasses}>
                  <TableCell className={classes.tableCell} colSpan={prop.colspan} />
                  <TableCell
                    className={classes.tableCell + " " + classes.right}
                    colSpan={prop.col.colspan}
                  >
                    {prop.col.text}
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow key={key} hover={hover} className={clsx(classes.tableRow, tableRowClasses)}>
                {prop.map((innerProp: any, key: number) => {
                  const tableCellClasses =
                    classes.tableCell +
                    " " +
                    cx({
                      [classes[colorsColls[coloredColls.indexOf(key)]]]:
                        coloredColls.indexOf(key) !== -1,
                      [customCellClasses[customClassesForCells.indexOf(key)]]:
                        customClassesForCells.indexOf(key) !== -1,
                    });
                  return (
                    <TableCell
                      className={tableCellClasses}
                      key={key}
                      align={prop.length - 1 === key ? "right" : "left"}
                    >
                      {innerProp}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {props.pagination && (
        <TablePagination
          style={{ float: "right" }}
          count={props.pagination.count}
          rowsPerPage={props.pagination.itemsPerPage}
          rowsPerPageOptions={props.pagination.itemsPerPageOptions}
          page={props.pagination.page}
          onPageChange={(event, newPage) => {
            props.pagination?.setPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            props.pagination?.setPage(0);
            props.pagination?.setItemsPerPage(+event.target.value);
          }}
        />
      )}
    </div>
  );
};

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
};
