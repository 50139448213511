import { LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { ErrorWidget } from "dashboard/components/ErrorWidget";
import { QueryError } from "dashboard/components/QueryError";
import { ClientTelemetry } from "dashboard/pages/ServiceSettings/ClientTelemetry";
import { useClientTelemetry } from "data/queries/client-telemetry";
import { useGetFetch } from "hooks/use-get-fetch";
import { GetUsageStatsResponse } from "types/akita_api_types";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  border: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: `0px solid ${theme.palette.divider}`,
    },
  },
  header: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

export const LearnSessionsTable = (props: { serviceID?: string }) => {
  const classes = useStyles();

  // Fetch packet capture telemetry.
  const {
    data: dataClientTelemetry,
    isLoading: isLoadingClientTelemetry,
    error: errorClientTelemetry,
    refetch: refetchClientTelemetry,
  } = useClientTelemetry(props.serviceID, { limit: 10 });

  // Fetch usage metering information
  const usageURL = `${process.env.REACT_APP_API_URL}/v1/organizations/usage/stats`;
  const [usage, isUsageLoading, getUsageError] = useGetFetch<GetUsageStatsResponse>(
    usageURL,
    undefined,
    false,
    "fetching usage information"
  );

  const usageText =
    isUsageLoading || usage == null ? (
      <Typography variant="body2"></Typography>
    ) : usage.total_witness_count == 0 ? (
      <Typography variant="body2">
        Your organization has not sent any traffic this month.
      </Typography>
    ) : usage.has_projected_stats ? (
      <div>
        <Typography variant="body2">
          Your organization has sent {usage.total_witness_count.toLocaleString()} events to Akita
          Cloud this month.
        </Typography>
        <Typography variant="body2">
          At this rate, we estimate you&rsquo;ll send{" "}
          {usage.projected_witness_count?.toLocaleString()} events by the end of the month.
        </Typography>
      </div>
    ) : (
      <Typography variant="body2">
        Your organization has sent {usage.total_witness_count.toLocaleString()} events to Akita
        Cloud this month.
      </Typography>
    );

  return (
    <React.Fragment>
      <div className={classes.container}>
        <ErrorWidget errorSources={[getUsageError]} />
        <Typography variant="h2">Usage</Typography>
        <div style={{ height: "4px" }}>{isUsageLoading && <LinearProgress />}</div>
        {usageText}
      </div>
      <div className={classes.container}>
        <Typography variant="h2">Client</Typography>
        <div style={{ height: "4px" }}>{isLoadingClientTelemetry && <LinearProgress />}</div>
        <QueryError
          label="We had trouble loading your client telemetry."
          error={errorClientTelemetry}
          refetch={refetchClientTelemetry}
        />
        <Typography variant="body2">
          When the Akita client runs, it attempts to send statistics and errors to Akita. The ten
          most recent client runs are reported here. Click on a row of this table for more detailed
          information, including any reported errors.
        </Typography>
        <ClientTelemetry telemetry={dataClientTelemetry} />
      </div>
    </React.Fragment>
  );
};
