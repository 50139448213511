import { useQuery } from "@tanstack/react-query";
import { akitaFetch, retry } from "data/akita-fetch";
import { GetSpecMetadataResponse, GetSpecsResponse, SpecSummary } from "types/akita_api_types";

const getModelsQueryKey = (
  projectID?: string,
  deploymentID?: string,
  queryParams?: Record<string, any>
) => ["models", projectID, deploymentID, queryParams];

const getModels = async (
  projectID: string,
  deploymentID?: string,
  queryParams?: Record<string, any>
) => {
  const params = {
    // Specify an empty key set by default.
    key: [""],
    // If a deployment was given, request its large model.
    version_tag: deploymentID ? `x-akita-large-model:${deploymentID}` : undefined,
    ...queryParams,
  };

  const result = await akitaFetch<GetSpecsResponse>(`/services/${projectID}/specs`, {
    queryParams: params,
  });

  return result.specs;
};

export const useLatestModelSummaryForProject = ({
  projectID,
  deploymentID,
  queryParams,
  options,
}: {
  projectID?: string;
  deploymentID?: string;
  queryParams?: Record<string, any>;
  options?: { refetchInterval?: number | ((data?: SpecSummary) => number); enabled?: boolean };
}) =>
  useQuery(
    getModelsQueryKey(projectID, deploymentID, queryParams),
    async () => {
      const result = await getModels(projectID!, deploymentID, queryParams);

      // Return the first element (there should be at most one element)
      // We coalesce to `null` here if result[0] is undefined, to tell React Query that we know
      // it doesn't exist. Otherwise, React Query will do "foreground" loading (as opposed to the
      // normal background refetching).
      return result?.[0] ?? null;
    },
    { enabled: !!projectID, refetchOnWindowFocus: false, retry, ...options }
  );

const getModelMetadataQueryKey = (projectID?: string, modelID?: string) => {
  const queryKey: string[] = ["model-metadata"];

  if (projectID) {
    queryKey.push(projectID);

    if (modelID) {
      queryKey.push();
    }
  }

  return queryKey;
};

const getModelMetadata = (projectID: string, modelID: string) =>
  akitaFetch<GetSpecMetadataResponse>(`/services/${projectID}/specs/${modelID}/metadata`);

export const useModelMetadata = (projectID?: string, modelID?: string) =>
  useQuery(
    getModelMetadataQueryKey(projectID, modelID),
    () => getModelMetadata(projectID!, modelID!),
    { enabled: !!(projectID && modelID), retry, refetchOnWindowFocus: false }
  );
