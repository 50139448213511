import { SxProps, Theme } from "@mui/material";

/**
 * Combines two SxProps values into one
 * @param sx1 - A component's sx value.
 * @param [sx2] - Another sx value, usually passed in as a prop for further customization.
 * @returns - An array combining the two sx values.
 */
export const mergeSxProps = (sx1: SxProps<Theme>, sx2?: SxProps<Theme>) => {
  const sxArr: SxProps<Theme> = Array.isArray(sx1) ? sx1 : [sx1];

  if (sx2) {
    return sxArr.concat(sx2);
  }

  return sxArr;
};
