import { Box, Button, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { MonitorsTableMonitorRow } from "./MonitorsTableMonitorRow";
import { MonitorsTableRecentRow } from "./MonitorsTableRecentRow";
import { AkiPagination } from "dashboard/components/AkiPagination";
import { AkiTable } from "dashboard/components/AkiTable/AkiTable";
import { AkiTableBody } from "dashboard/components/AkiTable/AkiTableBody";
import { AkiTableCell } from "dashboard/components/AkiTable/AkiTableCell";
import { AkiTableEmptyState } from "dashboard/components/AkiTable/AkiTableEmptyState";
import { AkiTableHead } from "dashboard/components/AkiTable/AkiTableHead";
import { CreateMonitorButton } from "dashboard/components/CreateMonitorButton";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";
import { Monitor, MonitorUpdate, RecentlyAlertedMonitor } from "types/akita_api_types";

type MonitorsTableProps =
  | {
      clearRequiredEndpoints?: () => void;
      deploymentID: string;
      hasRequiredEndpoints?: boolean;
      isLoading: boolean;
      isPreviousData: boolean;
      monitors?: RecentlyAlertedMonitor[];
      monitorsPageTab: "recent";
      monitorsPerPage: number;
      projectID: string;
      totalCount?: number;
      updateMonitors: (updates: MonitorUpdate[]) => void;
    }
  | {
      clearRequiredEndpoints?: () => void;
      deploymentID: string;
      hasRequiredEndpoints?: boolean;
      isLoading: boolean;
      isPreviousData: boolean;
      monitors?: Monitor[];
      monitorsPageTab: "enabled";
      monitorsPerPage: number;
      projectID: string;
      totalCount?: number;
      updateMonitors: (updates: MonitorUpdate[]) => void;
    };

export const MonitorsTable = ({
  clearRequiredEndpoints,
  deploymentID,
  hasRequiredEndpoints,
  isLoading,
  isPreviousData,
  monitors,
  monitorsPageTab,
  monitorsPerPage,
  projectID,
  totalCount,
  updateMonitors,
}: MonitorsTableProps) => {
  const isEnabledManualAlertCreation = useIsFlagEnabled(FeatureFlag.ManualAlertCreationEnabled);

  const rows = useMemo(() => {
    if (isLoading) {
      return new Array(monitorsPerPage)
        .fill(undefined)
        .map((_, index) =>
          monitorsPageTab === "recent" ? (
            <MonitorsTableRecentRow key={index} isLoading />
          ) : (
            <MonitorsTableMonitorRow key={index} isLoading />
          )
        );
    }

    if (monitorsPageTab === "recent") {
      return (
        monitors?.map((monitor) => (
          <MonitorsTableRecentRow
            isLoading={isLoading}
            deploymentID={deploymentID}
            key={monitor.monitor_id}
            monitor={monitor}
            projectID={projectID}
          />
        )) ?? []
      );
    }

    return (
      monitors?.map((monitor) => (
        <MonitorsTableMonitorRow
          isLoading={isLoading}
          deploymentID={deploymentID}
          key={monitor.monitor_id}
          monitor={monitor}
          projectID={projectID}
          updateMonitors={updateMonitors}
        />
      )) ?? []
    );
  }, [
    isLoading,
    monitors,
    monitorsPerPage,
    monitorsPageTab,
    deploymentID,
    projectID,
    updateMonitors,
  ]);

  // Make sure there's always at least one "page" (even when there's not)
  const pageCount = Math.ceil((totalCount || 1) / monitorsPerPage);

  const emptyState = useMemo(
    () => (
      <AkiTableEmptyState
        text={
          hasRequiredEndpoints
            ? "There aren’t any monitors that match your search."
            : "You don’t have any monitors."
        }
        action={
          <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
            {hasRequiredEndpoints && !!clearRequiredEndpoints && (
              <Button variant="outlined" color="secondary" onClick={clearRequiredEndpoints}>
                Clear Search
              </Button>
            )}
            {isEnabledManualAlertCreation && (
              <CreateMonitorButton variant="contained" includeContext />
            )}
          </Stack>
        }
      />
    ),
    [clearRequiredEndpoints, hasRequiredEndpoints, isEnabledManualAlertCreation]
  );

  return (
    <Box>
      <AkiTable>
        <AkiTableHead sx={{ height: 58 }}>
          <AkiTableCell sx={{ paddingLeft: 2 }}>Status</AkiTableCell>
          <AkiTableCell>Monitoring</AkiTableCell>
          {monitorsPageTab !== "recent" && <AkiTableCell>Muted</AkiTableCell>}
          <AkiTableCell>Endpoint</AkiTableCell>
          {monitorsPageTab === "recent" && <AkiTableCell>Start</AkiTableCell>}
          {monitorsPageTab === "recent" && <AkiTableCell>End</AkiTableCell>}
          <AkiTableCell align="right" />
        </AkiTableHead>

        <AkiTableBody isRefreshing={isPreviousData} emptyState={emptyState}>
          {rows}
        </AkiTableBody>
      </AkiTable>

      <AkiPagination pageCount={pageCount} isLoading={isLoading} />
    </Box>
  );
};
