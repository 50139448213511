const lerp = (min: number, max: number, a: number) => min * (1 - a) + max * a;
export const clamp = (x: number, min: number, max: number) => Math.max(Math.min(max, x), min);
export const randNumberInRange = (min: number, max: number) => lerp(min, max, Math.random());

export const abbreviateNumber = (x: number, precision = 1) => {
  if (x < 1000) return x.toFixed(precision);

  // If we're in the thousands, abbreviate with K
  if (x / 1000 < 1000) {
    return `${(x / 1000).toFixed(precision)}K`;
  }

  // If we're in the millions, abbreviate with M
  if (x / 1_000_000 < 1000) {
    return `${(x / 1_000_000).toFixed(precision)}M`;
  }

  // If we're in the billions, abbreviate with B
  if (x / 1_000_000_000 < 1000) {
    return `${(x / 1_000_000_000).toFixed(precision)}B`;
  }

  // If we're in the trillions, abbreviate with T
  if (x / 1_000_000_000_000 < 1000) {
    return `${(x / 1_000_000_000_000).toFixed(precision)}T`;
  }

  // If we encounter numbers this large in the real world, we've got bigger problems.
  return x.toExponential();
};

export const getClosestValueInArray = (x: number, arr: number[]) =>
  arr.reduce(
    (prev, current) => (Math.abs(current - x) < Math.abs(prev - x) ? current : prev),
    arr[0]
  );
