import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { akitaFetch, retry } from "data/akita-fetch";
import { CreateServiceResponse, GetServicesResponse, ServiceSummary } from "types/akita_api_types";

const getProjects = async () => {
  const result = await akitaFetch<GetServicesResponse>("/services");

  // Sort by name
  return result.sort((a, b) => a.name.localeCompare(b.name));
};

export const useProjects = () => useQuery(["projects"], getProjects);

const postProject = async (projectName: string) =>
  akitaFetch<CreateServiceResponse>("/services", {
    method: "POST",
    body: { name: projectName },
  });

export const useCreateProject = (options?: { onSuccess: (newProject: ServiceSummary) => void }) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (projectName: string) => {
      const { resource_id: projectId } = await postProject(projectName);
      return { id: projectId, name: projectName, deployments: ["default"], deployment_infos: [] };
    },
    {
      onSuccess: async (newProject) => {
        await queryClient.invalidateQueries(["projects"]);

        if (options?.onSuccess) {
          options.onSuccess(newProject);
        }
      },
      retry,
    }
  );
};
