import { CssBaseline, LinkProps, PaletteMode } from "@mui/material";
import {
  StyledEngineProvider,
  Theme,
  ThemeOptions,
  ThemeProvider,
  alpha,
  createTheme,
} from "@mui/material/styles";
import "@mui/styles";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import "typeface-inter";
// https://mui.com/material-ui/about-the-lab/#typescript
import type {} from "@mui/lab/themeAugmentation";
import { PaletteModeContext } from "./contexts/palette-mode";
import { LinkBehavior } from "./dashboard/components/LinkBehavior";

const FONT_FAMILY_BODY = "'Inter', sans-serif";
const HEIGHT_XSMALL = 24;
const HEIGHT_SMALL = 36;
const HEIGHT_MEDIUM = 40;
const HEIGHT_LARGE = 48;

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles" {
  interface Palette {
    appBar: {
      backgroundBright: string;
      backgroundDim: string;
    };
    foreground: string;
    overlay: {
      min: string;
      hover: string;
      minPlus: string;
      med: string;
      max: string;
    };
    httpMethod: {
      delete: string;
      get: string;
      post: string;
      put: string;
      default: string;
    };
    timelines: string[];
  }

  interface PaletteOptions {
    appBar?: {
      backgroundBright?: string;
      backgroundDim?: string;
    };
    foreground?: string;
    overlay?: {
      min?: string;
      hover?: string;
      minPlus?: string;
      med?: string;
      max?: string;
    };
    httpMethod?: {
      delete?: string;
      get?: string;
      post?: string;
      put?: string;
      default?: string;
    };
    timelines?: string[];
  }
}

// This adds "xsmall" as a size option on MUI Buttons.
declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    xsmall: true;
  }
}

// This adds "outlined" variants MUI IconButtons. (IconButton has no `variant` prop to override, so
// we'll use `size`)
declare module "@mui/material/IconButton" {
  interface IconButtonPropsSizeOverrides {
    "small-outlined": true;
    "medium-outlined": true;
    "large-outlined": true;
  }
}

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

const baseThemeOptions: ThemeOptions = {
  palette: {
    secondary: {
      light: "#f6c444",
      main: "#ED5500",
      dark: "#ED5500",
    },
    error: {
      main: "#CC3432",
      contrastText: "rgba(255,255,255,1)",
    },
    httpMethod: {
      delete: "#CC3432",
      get: "#2F8032",
      post: "#248FB2",
      put: "#95507C",
      default: "#757575",
    },
    // Taken from amcharts' Material theme: https://github.com/amcharts/amcharts5/blob/master/src/.internal/themes/MaterialTheme.ts
    // Rearranged and pruned to make timelines more distinct from each other for colorblind users.
    timelines: [
      "#3F51B5",
      "#03A9F4",
      "#4CAF50",
      "#FFC107",
      "#FF9800",
      "#9C27B0",
      "#2196F3",
      "#00BCD4",
      "#009688",
      "#CDDC39",
      "#FFC107",
      "#FF5722",
      "#795548",
      "#9E9E9E",
      "#607D8B",
    ],
  },
  shape: {
    borderRadius: 10,
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 150,
      short: 150,
      // most basic recommended timing
      standard: 200,
      // this is to be used in complex animations
      complex: 400,
      // recommended when something is entering screen
      enteringScreen: 200,
      // recommended when something is leaving screen
      leavingScreen: 200,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: FONT_FAMILY_BODY,
    allVariants: {
      fontFeatureSettings: '"calt" 0',
    },
    h1: {
      fontFamily: FONT_FAMILY_BODY,
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 28,
    },
    h2: {
      fontFamily: FONT_FAMILY_BODY,
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 24,
    },
    h3: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 20,
    },
    h4: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 18,
    },
    h5: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 16,
    },
    h6: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 14,
    },
    subtitle1: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 12,
      textTransform: "none",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 12,
      textTransform: "none",
    },
    body1: {
      fontStyle: "normal",
      fontSize: 16,
    },
    body2: {
      fontStyle: "normal",
      fontSize: 14,
    },
    button: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 14,
      textTransform: "none",
    },
  },
  components: {
    MuiTabs: {
      defaultProps: {
        scrollButtons: "auto",
        variant: "scrollable",
      },
    },
    MuiTabList: {
      defaultProps: {
        scrollButtons: "auto",
        variant: "scrollable",
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      },
    },
    // https://github.com/mui/material-ui/issues/22456
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          display: "block",
          width: "100%",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 10,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        // Set a nice-looking focus ring, to match the focus ring on buttons and for consistent
        // behavior across browsers.
        root: {
          position: "relative",
          "&:after": {
            borderRadius: "10px",
            content: '""',
            height: "100%",
            left: "0",
            position: "absolute",
            top: "0",
            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
            transitionDuration: "150ms",
            // To play nice with any existing transitions, we'll just include all of the (GPU-composited) ones.
            transitionProperty:
              "box-shadow, border-color, background, color, opacity, transform !important",
            width: "100%",
            border: "1px solid transparent",
          },
          "&:focus, &:focus-visible": {
            outline: 0,
          },
          "&.Mui-focusVisible:after": {
            borderColor: "1px solid rgba(128, 189, 255, 1)",
            boxShadow: "0 0 0 4px rgba(128, 189, 255, 0.5) !important",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
        disableRipple: true,
      },
      styleOverrides: {
        // This sets a nice-looking focus ring for all button elements, to replace the MUI "ripple"
        // effect, which we've disabled.
        root: {
          // To play nice with any existing transitions, we'll just include all of the (GPU-composited) ones.
          transitionProperty:
            "box-shadow, border-color, background, color, opacity, transform !important",
          outlineOffset: "0",
          outline: "0",
          "&.Mui-focusVisible": {
            borderColor: "rgba(128, 189, 255, 1)",
            boxShadow: "0 0 0 4px rgba(128, 189, 255, 0.5) !important",
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiAlert: {
      styleOverrides: {
        // Adds a border (colored by default to match the Alert's text color) to make Alerts pop more
        standard: {
          border: "1px solid",
        },
      },
    },
    MuiToggleButtonGroup: {
      variants: [
        {
          props: { size: "small" },
          style: {
            height: HEIGHT_SMALL,
          },
        },
      ],
    },
    MuiSelect: {
      defaultProps: {
        size: "medium",
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            height: HEIGHT_SMALL,
            "& .MuiSelect-select": {
              padding: "6.5px 8px",
              // body2 fontSize
              fontSize: 14,
            },
          },
        },
        {
          props: { size: "medium" },
          style: {
            height: HEIGHT_MEDIUM,
            "& .MuiSelect-select": {
              padding: "8.5px 10px",
            },
          },
        },
        {
          props: { size: "large" },
          style: {
            height: HEIGHT_LARGE,
            "& .MuiSelect-select": {
              padding: "12.5px 14px",
            },
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-inputAdornedEnd": {
            paddingRight: "0 !important",
          },
          "& .MuiInputBase-inputAdornedStart": {
            paddingLeft: "0 !important",
          },
        },
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            minHeight: HEIGHT_SMALL,
            "& .MuiInputBase-input": {
              padding: "6.5px 8px",
              // body2 fontSize
              fontSize: 14,
            },
          },
        },
        {
          props: { size: "medium" },
          style: {
            minHeight: HEIGHT_MEDIUM,
            "& .MuiInputBase-input": {
              padding: "8.5px 10px",
            },
          },
        },
        {
          props: { size: "large" },
          style: {
            minHeight: HEIGHT_LARGE,
            "& .MuiInputBase-input": {
              padding: "12.5px 14px",
            },
          },
        },
      ],
    },
  },
};

const lightThemeOptions: ThemeOptions = {
  ...baseThemeOptions,
  palette: {
    ...baseThemeOptions.palette,
    mode: "light",
    primary: {
      main: "#2F8032",
      light: "#45ba49",
    },
    success: {
      main: "#2F8032",
      light: "#45ba49",
    },
    appBar: {
      backgroundBright: alpha("#07360C", 1),
      backgroundDim: alpha("#07360C", 0.7),
    },
    foreground: "rgba(0,0,0,1)",
    text: {
      primary: "rgba(0,0,0,1)",
      secondary: "rgba(0,0,0,0.7)",
      disabled: "rgba(0,0,0,0.4)",
    },
    // Matches the default outline on inputs
    divider: "rgba(0,0,0,0.23)",
    background: {
      paper: "#FFFFFF",
      default: "#EEEEEE",
    },
    overlay: {
      min: "rgba(0,0,0,0.02)",
      hover: "rgba(0,0,0,0.04)",
      minPlus: "rgba(0,0,0,0.05)",
      med: "rgba(0,0,0,0.4)",
      max: "rgba(0,0,0,0.8)",
    },
  },
};

const darkThemeOptions: ThemeOptions = {
  ...lightThemeOptions,
  palette: {
    ...lightThemeOptions.palette,
    mode: "dark",
    primary: {
      main: "#45ba49",
      light: "#80D083",
    },
    success: {
      main: "#45ba49",
      light: "#80D083",
      contrastText: "rgba(255,255,255,1)",
    },
    secondary: {
      light: "#FFAD29",
      main: "#FF640A",
      dark: "#ED5500",
    },
    appBar: {
      backgroundBright: alpha("#2F8032", 0.7),
      backgroundDim: alpha("#2F8032", 0.4),
    },
    foreground: "rgba(255,255,255,1)",
    text: {
      primary: "rgba(255,255,255,1)",
      secondary: "rgba(255,255,255,0.7)",
      disabled: "rgba(255,255,255,0.4)",
    },
    // Matches the default outline on inputs
    divider: "rgba(255,255,255,0.23)",
    background: {
      default: "#000000",
      paper: "#121212",
    },
    overlay: {
      min: "rgba(255,255,255,0.05)",
      hover: "rgba(255,255,255,0.08)",
      minPlus: "rgba(255,255,255,0.2)",
      med: "rgba(255,255,255,0.4)",
      max: "rgba(255,255,255,0.8)",
    },
  },
};

// Some component customizations are dependent on the color-scheme, like when we use the "divider"
// value from the color palette. Instead of hard-coding the right value in each theme config, we can
// apply the theme-dependent component customizations as a second step.
const applyThemeDependentComponentConfigs = (themeOptions: ThemeOptions): ThemeOptions => ({
  ...themeOptions,
  components: {
    ...themeOptions.components,
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
              background: themeOptions.palette!.overlay!.hover,
            },
          },
        },
        notchedOutline: {
          transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          transitionDuration: "150ms",
          // To play nice with any existing transitions, we'll just include all of the (GPU-composited) ones.
          transitionProperty:
            "box-shadow, border-color, background, color, opacity, transform !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Buttons shouldn't shrink or wrap. Honestly, MUI.
          flexShrink: 0,
        },
      },
      variants: [
        {
          props: { size: "xsmall" },
          style: {
            height: HEIGHT_XSMALL,
            minWidth: 0,
            padding: "5px 10px",
          },
        },
        {
          props: { size: "small" },
          style: {
            height: HEIGHT_SMALL,
          },
        },
        {
          props: { size: "medium" },
          style: {
            height: HEIGHT_MEDIUM,
          },
        },
        {
          props: { size: "large" },
          style: {
            height: HEIGHT_LARGE,
          },
        },
        {
          props: { color: "inherit" },
          style: {
            border: `1px solid ${themeOptions.palette!.divider}`,
            "&:hover": {
              border: `1px solid ${themeOptions.palette!.foreground}`,
            },
          },
        },
      ],
    },
    MuiIconButton: {
      // Makes the icon be the normal foreground text color, instead of being dimmed on light mode
      defaultProps: {
        color: "inherit",
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            height: HEIGHT_SMALL,
            width: HEIGHT_SMALL,
          },
        },
        {
          props: { size: "small-outlined" },
          style: {
            height: HEIGHT_SMALL,
            width: HEIGHT_SMALL,
            borderRadius: themeOptions.shape!.borderRadius,
            border: `1px solid ${themeOptions.palette!.divider}`,
            "&:hover": {
              border: `1px solid ${themeOptions.palette!.foreground}`,
            },
          },
        },
        {
          props: { size: "medium-outlined" },
          style: {
            height: HEIGHT_MEDIUM,
            width: HEIGHT_MEDIUM,
            borderRadius: themeOptions.shape!.borderRadius,
            border: `1px solid ${themeOptions.palette!.divider}`,
            "&:hover": {
              border: `1px solid ${themeOptions.palette!.foreground}`,
            },
          },
        },
        {
          props: { size: "large-outlined" },
          style: {
            height: HEIGHT_LARGE,
            width: HEIGHT_LARGE,
            borderRadius: themeOptions.shape!.borderRadius,
            border: `1px solid ${themeOptions.palette!.divider}`,
            "&:hover": {
              border: `1px solid ${themeOptions.palette!.foreground}`,
            },
          },
        },
      ],
    },
  },
});

export const getTheme = (paletteMode: PaletteMode = "light") =>
  createTheme(
    applyThemeDependentComponentConfigs(
      paletteMode === "dark" ? darkThemeOptions : lightThemeOptions
    )
  );

export const AkiThemeProvider = ({ children }: { children: ReactNode }) => {
  const initialPaletteMode = useMemo(() => {
    const prefValue = window.localStorage.getItem("akita-palette-mode");

    return prefValue === "dark" ? "dark" : "light";
  }, []);

  const [paletteMode, setPaletteMode] = useState<PaletteMode>(initialPaletteMode);
  const theme = useMemo(() => getTheme(paletteMode), [paletteMode]);
  const paletteModeValue = useMemo(() => ({ paletteMode, setPaletteMode }), [paletteMode]);

  useEffect(() => {
    // Update the color-scheme property, so the browser knows to use the right color of scrollbars, etc.
    document.documentElement.style.setProperty("color-scheme", paletteMode);

    // Store the value in localStorage for next time
    window.localStorage.setItem("akita-palette-mode", paletteMode);
  }, [paletteMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <PaletteModeContext.Provider value={paletteModeValue}>
          <CssBaseline />
          {children}
        </PaletteModeContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
