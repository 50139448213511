import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Stack, Tooltip } from "@mui/material";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { MonitorDeleteButton } from "../Monitors/components/MonitorDeleteButton";
import { getMonitorsRoute } from "../Monitors/utils";
import { EditMonitorButton } from "dashboard/components/EditMonitorButton";
import { PageHeader, PageTitle } from "dashboard/components/Page";
import { MonitorMuteButton } from "dashboard/pages/Monitors/components/MonitorMuteButton";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";
import { Monitor, MonitorUpdate } from "types/akita_api_types";

interface MonitorHeaderProps {
  deploymentID: string;
  monitor?: Monitor;
  onRefresh: () => void;
  projectID: string;
  updateMonitors: (updates: MonitorUpdate[]) => void;
}

export const MonitorHeader = ({
  deploymentID,
  monitor,
  onRefresh,
  projectID,
  updateMonitors,
}: MonitorHeaderProps) => {
  const history = useHistory();
  const monitorsRoute = getMonitorsRoute(projectID, deploymentID);
  const isEnabledManualAlertCreation = useIsFlagEnabled(FeatureFlag.ManualAlertCreationEnabled);

  const onDelete = useCallback(() => {
    history.push(monitorsRoute);
  }, [history, monitorsRoute]);

  return (
    <PageHeader>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Stack spacing={2} direction="row">
          <PageTitle backHref={monitorsRoute} backLabel="All monitors">
            Monitor
          </PageTitle>

          {isEnabledManualAlertCreation && <EditMonitorButton monitorID={monitor?.monitor_id} />}
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <Tooltip title="Refresh">
            <IconButton onClick={onRefresh} disabled={!monitor} size="small-outlined">
              <RefreshIcon />
            </IconButton>
          </Tooltip>

          <MonitorMuteButton monitor={monitor} updateMonitors={updateMonitors} />

          <MonitorDeleteButton
            projectID={projectID}
            deploymentID={deploymentID}
            monitor={monitor}
            onDelete={onDelete}
          />
        </Stack>
      </Stack>
    </PageHeader>
  );
};
