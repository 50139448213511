import { Theme } from "@mui/material";
import { DisplayTimeline, DisplayTimelineValue } from "./timelines";
import { MonitorHistoryItem } from "types/akita_api_types";

export const getMonitorHistoryTransitionAnnotations = (
  monitorHistory: MonitorHistoryItem[],
  theme: Theme,
  startMS: number
): DisplayTimeline => {
  // Find the items in the monitor history array that are edges (transitions between alert states).
  const edges = monitorHistory.reduce((result, item) => {
    startMS;

    const lastEdge = result[result.length - 1];

    if (!lastEdge) {
      result.push(item);
    } else if (lastEdge.in_alert_state !== item.in_alert_state) {
      result.push(item);
    }

    return result;
  }, [] as MonitorHistoryItem[]);

  const values = edges
    .map(
      (edge): DisplayTimelineValue => ({
        x: new Date(edge.observed_at).getTime(),
        y: 0,
        isAnnotation: true,
        color: edge.in_alert_state ? theme.palette.error.main : theme.palette.success.main,
        title: edge.in_alert_state ? "ALERT" : "OK",
      })
    )
    .filter((value) => value.x >= startMS);

  return {
    title: "Alert Transitions",
    isAnnotation: true,
    isContinuous: false,
    values,
  };
};
