import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Box, SxProps, Theme, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ErrorWidget } from "dashboard/components/ErrorWidget";
import { InlineCode } from "dashboard/components/InlineCode";
import { savePathParameters } from "dashboard/utils/backend";
import { AkitaError, AkitaErrorCode } from "dashboard/utils/error";
import { mergeSxProps } from "dashboard/utils/sx";
import { useGetFetch } from "hooks/use-get-fetch";
import { useImpression } from "hooks/use-impression";
import { useLogInteraction } from "hooks/use-log-interaction";

const getItemStyle = (theme: Theme, _isDragging: boolean, draggableStyle: any) => ({
  background: theme.palette.background.paper,
  ...draggableStyle,
});

export interface IPathParameterItem {
  annotation: string;
  created_at?: string;
  host: string;
  priority: number;
}

const Annotation = (props: {
  annotation: IPathParameterItem;
  updateAnnotation: (annotation: IPathParameterItem, index: number) => void;
  deleteAnnotation: (index: number) => void;
  index: number;
}) => {
  const [annotation, setAnnotation] = useState(props.annotation.annotation);
  const [host, setHost] = useState(props.annotation.host);

  return (
    <Box sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}`, padding: 2 }}>
      <Grid container spacing={2} alignItems="center" wrap="nowrap">
        <Grid item>
          <IconButton size="large">
            <DragHandleIcon />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Path Hint</Typography>
          <TextField
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(event) => {
              setAnnotation(event.target.value);
              props.updateAnnotation(
                {
                  annotation: event.target.value,
                  host,
                  priority: props.annotation.priority,
                  created_at: props.annotation.created_at,
                },
                props.index
              );
            }}
            value={annotation}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Host</Typography>
          <TextField
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            onChange={(event) => {
              setHost(event.target.value);
              props.updateAnnotation(
                {
                  annotation,
                  host: event.target.value,
                  priority: props.annotation.priority,
                  created_at: props.annotation.created_at,
                },
                props.index
              );
            }}
            value={host}
          />
        </Grid>
        <Grid item xs={2}>
          {props.annotation.created_at && (
            <Typography variant="subtitle2">
              Created
              <br />
              {new Date(props.annotation.created_at).toLocaleString()}
            </Typography>
          )}
        </Grid>
        <Grid item style={{ marginLeft: "auto" }}>
          <Tooltip title="Delete" aria-label="delete">
            <IconButton onClick={() => props.deleteAnnotation(props.index)} size="small">
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export const PathParameterAnnotations = ({
  projectID,
  sx,
}: {
  projectID?: string;
  sx?: SxProps<Theme>;
}) => {
  const logInteraction = useLogInteraction({ projectID });
  const { oktaAuth, authState } = useOktaAuth();

  const pathParametersURL = `${process.env.REACT_APP_API_URL}/v1/services/${projectID}/path_parameter_annotations`;
  const [pathParameterResponse, isPathParametersLoading, pathParametersError] = useGetFetch<
    IPathParameterItem[]
  >(pathParametersURL, undefined, !projectID, "fetching traces");

  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<AkitaError | null>(null);
  const [localPathParameterResponse, setLocalPathParameterResponse] = useState<
    IPathParameterItem[]
  >([]);

  useEffect(() => {
    pathParameterResponse &&
      pathParameterResponse.length > 0 &&
      setLocalPathParameterResponse(
        pathParameterResponse.sort((a, b) => (a.priority < b.priority ? 1 : -1))
      );
  }, [pathParameterResponse]);

  const updateAnnotation = (item: IPathParameterItem, index: number) => {
    localPathParameterResponse[index] = item;
    setLocalPathParameterResponse(localPathParameterResponse);
  };

  const addEmptyAnnotation = () => {
    const newAnnotation = {
      annotation: "",
      host: "",
      priority: 0,
    };
    setLocalPathParameterResponse([...localPathParameterResponse, newAnnotation]);
    logInteraction("Added a path hint");
  };

  const save = async () => {
    setIsSaving(true);
    setSaveError(null);
    try {
      if (projectID && authState) {
        const total = localPathParameterResponse.length - 1;
        const formattedData = {
          annotations: localPathParameterResponse.map((a, index) => ({
            annotation: a.annotation,
            priority: total - index,
            host: a.host,
          })),
        };
        await savePathParameters(oktaAuth, authState, projectID, formattedData);
        logInteraction("Saved path hint");
      }
    } catch (error: any) {
      setSaveError({
        customerMessage: "Error saving path parameters",
        error,
        status: 500,
        code: AkitaErrorCode.PostErr,
      });
    }
    setIsSaving(false);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      localPathParameterResponse,
      result.source.index,
      result.destination.index
    );

    setLocalPathParameterResponse(items as any);

    logInteraction("Reordered path hint");
  };

  const deleteAnnotation = (index: number) => {
    localPathParameterResponse.splice(index, 1);
    setLocalPathParameterResponse([...localPathParameterResponse]);
    logInteraction("Deleted a path hint");
  };

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useImpression("Model Page - Customize Paths Tab", {
    projectID,
    pathHintsCount: pathParameterResponse?.length,
  });

  const theme = useTheme();

  return (
    <React.Fragment>
      {isPathParametersLoading && <LinearProgress />}
      <Box sx={mergeSxProps({ padding: 3 }, sx)}>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h2" gutterBottom>
            Customize path parameters
          </Typography>
          <Typography variant="body2" gutterBottom>
            You can define path hints to supplement Akita’s automatic API endpoint inference:
          </Typography>
          <Typography component="ul" variant="body2" marginBottom={2}>
            <li>
              <b>Collapse paths</b>. If Akita is showing you{" "}
              <InlineCode>/post/post-id-1</InlineCode> and
              <InlineCode>/post/post-id-2</InlineCode> you can add a path hint{" "}
              <InlineCode>/post/&#123;postID2&#125;</InlineCode> to make sure anything starting with{" "}
              <InlineCode>/post/</InlineCode> collapses to{" "}
              <InlineCode>/post/&#123;postID2&#125;</InlineCode>.{" "}
            </li>
            <li>
              <b>Split paths</b>. Say Akita is showing you{" "}
              <InlineCode>/post/&#123;arg2&#125;</InlineCode> and you want path inference to be less
              aggressive, you can add a path hint like <InlineCode>/post/new</InlineCode> to make
              sure it is always separated from <InlineCode>/post/&#123;arg2&#125;</InlineCode>.
            </li>
          </Typography>
          <Typography variant="body2" gutterBottom>
            How path hints get applied:
          </Typography>

          <Typography component="ul" variant="body2" marginBottom={2}>
            <li>In the order they appear. Put your more specific ones first.</li>
            <li>A URL matches on the first path hint and at most one.</li>
            <li>
              Hints are “prefix match,” so the hint{" "}
              <InlineCode>/post/&#123;postID2&#125;</InlineCode> will match a longer path like{" "}
              <InlineCode>/post/post-id-1/edit</InlineCode>.
            </li>
            <li>Path hints will apply to all future traffic analysis.</li>
          </Typography>

          <Typography variant="body2">
            Read more in our docs{" "}
            <Link href="https://docs.akita.software/docs/customize-path-parameters">here</Link>.
          </Typography>
        </Box>
        {localPathParameterResponse && localPathParameterResponse?.length > 0 && (
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h2" gutterBottom>
              Path Hints
            </Typography>
            {isSaving && <LinearProgress />}
            <ErrorWidget
              errorSources={[pathParametersError, [saveError, () => setSaveError(null)]]}
              errorContext="path parameters"
            />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {localPathParameterResponse.map((item, index) => (
                      <Draggable
                        key={`${item.annotation}-${item.host}`}
                        draggableId={`${item.annotation}-${item.host}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              theme,
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Annotation
                              annotation={item}
                              index={index}
                              updateAnnotation={updateAnnotation}
                              deleteAnnotation={deleteAnnotation}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {/* ensure the list doesn't change height when dragging an item */}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        )}
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button variant="contained" startIcon={<AddIcon />} onClick={addEmptyAnnotation}>
              Add a path hint
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={save} disabled={isSaving}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
