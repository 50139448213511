import { useQuery } from "@tanstack/react-query";
import {
  CheckCollapseEndpointsRequest,
  CheckCollapseEndpointsResponse,
} from "../../types/akita_api_types";
import { akitaFetch } from "../akita-fetch";

const checkCollapseEndpoints = (projectID: string, request: CheckCollapseEndpointsRequest) =>
  akitaFetch<CheckCollapseEndpointsResponse>(`/v1/services/${projectID}/check-collapse-endpoints`, {
    method: "GET",
    queryParams: {
      endpoints: JSON.stringify(request),
    },
  });

export const useCheckCollapseEndpoints = (
  projectID: string,
  endpoints: CheckCollapseEndpointsRequest
) =>
  useQuery<CheckCollapseEndpointsResponse, Error>(
    ["checkCollapseEndpoints", projectID, endpoints],
    () => checkCollapseEndpoints(projectID, endpoints),
    {
      enabled: endpoints.length > 1,
    }
  );
