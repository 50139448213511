import { Box, Pagination, SxProps, Theme } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { clamp } from "dashboard/utils/numbers";
import { mergeSxProps } from "dashboard/utils/sx";
import { usePageIndex } from "hooks/use-page-index";

interface AkiPaginationProps {
  pageCount: number;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
}

/**
 * Wrapper for MUI's Pagination component, for consistent styling and (slightly) better ergonomics.
 * Call the hook usePageIndex to retrieve the current page index.
 */
export const AkiPagination = ({ pageCount, isLoading, sx }: AkiPaginationProps) => {
  const [pageIndex, setPageIndex] = usePageIndex();

  const onChangePage = useCallback(
    (_event, value: number) => {
      setPageIndex(value - 1);
    },
    [setPageIndex]
  );

  // Whenever the pageCount changes, ensure that the pageIndex is within the range
  // [0, pageCount].
  useEffect(() => {
    if (isLoading) return;

    const clampedIndex = clamp(pageIndex, 0, Math.max(0, pageCount - 1));

    if (clampedIndex !== pageIndex) {
      setPageIndex(clampedIndex);
    }
  }, [pageCount, pageIndex, setPageIndex, isLoading]);

  return (
    <Box
      sx={mergeSxProps(
        {
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          opacity: isLoading ? 0 : 1,
          paddingBottom: 2,
          paddingTop: 2,
          transition: "opacity linear 200ms",
        },
        sx
      )}
    >
      <Pagination count={pageCount} page={pageIndex + 1} onChange={onChangePage} />
    </Box>
  );
};
