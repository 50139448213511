export enum FeatureFlag {
  PerformanceAlertsPageEnabled = "superstar-frontend.pages.performance-alerts.enabled",
  ChangeReportsPageEnabled = "superstar-frontend.pages.change-reports.enabled",
  InternalAdminPagesEnabled = "superstar-frontend.pages.internal.admin.enabled",
  PrivateBetaExitEnabled = "superstar-frontend.features.private-beta-exit.enabled",
  ModelPageEditModeEnabled = "frontend.model-page-edit-mode.enabled",
  AskAkiEnabled = "frontend.ask-aki.enabled",
  ProposedAlertsEnabled = "frontend.proposed-alerts.enabled",
  MonitorSearchingEnabled = "frontend.monitor-searching.enabled",
  EndpointCollapsingEnabled = "frontend.endpoint-collapsing.enabled",
  ManualAlertCreationEnabled = "frontend.manual-alert-creation.enabled",
}
