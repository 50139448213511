import { FormControl, MenuItem, Select, SelectChangeEvent, SxProps, Theme } from "@mui/material";
import React, { useCallback } from "react";
import { getMonitorComparatorDescription } from "dashboard/utils/monitors";
import { MonitorComparator } from "types/akita_api_types";

type MonitorComparatorSelectProps = {
  comparator: MonitorComparator;
  onChange: (comparator: MonitorComparator) => void;
  sx?: SxProps<Theme>;
};

export const MonitorComparatorSelect = ({
  comparator,
  onChange,
  sx,
}: MonitorComparatorSelectProps) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<MonitorComparator>) => {
      const newComparator = event?.target?.value as MonitorComparator;
      if (!newComparator) return;

      onChange(newComparator);
    },
    [onChange]
  );

  return (
    <FormControl sx={sx}>
      <Select
        aria-label="How to compare the metric value to the threshold value"
        onChange={handleChange}
        value={comparator}
        size="medium"
        sx={{
          // Width of longest option
          minWidth: 240,
        }}
      >
        {Object.values(MonitorComparator).map((comparatorOption) => (
          <MenuItem key={comparatorOption} value={comparatorOption}>
            {getMonitorComparatorDescription(comparatorOption)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
