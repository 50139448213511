import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { Deleter } from "dashboard/components/Deleter";
import { GetApiKeysResponse } from "types/akita_api_types";

type ApiKeysTableProps = {
  apiKeys: GetApiKeysResponse;
};

export const ApiKeysTable = ({ apiKeys }: ApiKeysTableProps) => {
  const rows = useMemo(
    () =>
      apiKeys.map((key) => (
        <TableRow key={key.id}>
          <TableCell>{key.id}</TableCell>
          <TableCell>{new Date(key.created_at).toLocaleString()}</TableCell>
          <TableCell align="right">
            <Deleter
              key="deleter"
              name={key.id}
              url={`${process.env.REACT_APP_API_URL}/v1/api_keys/${key.id}`}
            />
          </TableCell>
        </TableRow>
      )),
    [apiKeys]
  );

  if (apiKeys?.length < 1 || (apiKeys as any).message) {
    return <Typography>No API Keys</Typography>;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Key ID</TableCell>
            <TableCell>Created</TableCell>
            <TableCell align="right">Delete</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};
