import { Box } from "@mui/material";
import { maxTime, minTime } from "date-fns";
import { debounce } from "lodash";
import React, { useMemo } from "react";
import { AbsoluteDateTimePicker } from "./AbsoluteDateTimePicker";
import { TimeRangeAbsolute } from "dashboard/utils/time-range";

type TimeRangePickerAbsoluteProps = {
  onChange: (newValue: TimeRangeAbsolute) => void;
  value: TimeRangeAbsolute;
  minDateTime?: number;
  maxDateTime?: number;
};

export const TimeRangePickerAbsolute = ({
  value,
  onChange,
  minDateTime = minTime,
  maxDateTime = maxTime,
}: TimeRangePickerAbsoluteProps) => {
  // Debounce the call to onChange, since the DateTimePicker component will call this at a high
  // frequency if the user is navigating with the keyboard. The component mantains its own internal
  // state for the selected value anyway, so its `value` prop being stale momentarily is fine.
  const onChangeDebounced = useMemo(
    () =>
      debounce((newRange: TimeRangeAbsolute) => onChange(newRange), 500, {
        leading: true,
        trailing: true,
      }),
    [onChange]
  );

  return (
    <Box
      sx={{
        display: "flex",
        "& > div:first-of-type": {
          marginRight: 1,
        },
      }}
    >
      <AbsoluteDateTimePicker
        maxDateTime={maxDateTime}
        minDateTime={minDateTime}
        onChange={onChangeDebounced}
        side="start"
        size="small"
        value={value}
      />

      <AbsoluteDateTimePicker
        maxDateTime={maxDateTime}
        minDateTime={minDateTime}
        onChange={onChangeDebounced}
        side="end"
        size="small"
        value={value}
      />
    </Box>
  );
};
