import { Box, Typography } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { CreateProjectForm } from "dashboard/components/CreateProjectForm";
import { OnboardingStepButtons } from "dashboard/components/OnboardingStep/OnboardingStepButtons";
import { OnboardingStepContext } from "dashboard/components/OnboardingStep/context";
import { SelectProject } from "dashboard/components/SelectProject";
import { useCreateProject, useProjects } from "data/queries/projects";
import { ServiceSummary } from "types/akita_api_types";

interface StepSelectProjectProps {
  project: ServiceSummary | undefined;
  setProject: (project: ServiceSummary | undefined) => void;
}

export const StepSelectProject = ({ project, setProject }: StepSelectProjectProps) => {
  const [projectName, setProjectName] = useState("");
  const { nextStep, trackOnboardingEvent } = useContext(OnboardingStepContext);
  const { data: projects } = useProjects();

  const handleSelect = useCallback(
    (selectedProject: ServiceSummary | undefined) => {
      setProject(selectedProject);

      if (selectedProject) {
        trackOnboardingEvent("Existing Project Selected", { projectId: selectedProject.id });
        nextStep();
      }
    },
    [nextStep, setProject, trackOnboardingEvent]
  );

  const {
    mutate: createProject,
    isLoading,
    error,
  } = useCreateProject({
    onSuccess: (newProject) => {
      trackOnboardingEvent("Created Project", { projectId: newProject.id });
      setProject(newProject);
      nextStep();
    },
  });

  return (
    <Box>
      <Typography sx={{ marginBottom: 1 }}>
        Give it a name that reflects the service(s) you&rsquo;re monitoring, like
        &ldquo;my-service-local&rdquo; or &ldquo;my-service-prod&rdquo;:
      </Typography>

      <CreateProjectForm
        autoFocus={!project}
        errorCode={error?.response?.status}
        isLoading={isLoading}
        onChange={setProjectName}
        onSubmit={createProject}
        projectName={projectName}
      />

      {!!projects?.length && (
        <>
          <Typography sx={{ marginBottom: 1, marginTop: 2 }}>
            Or pick a project you have already created:
          </Typography>
          <SelectProject projects={projects} selectedProject={project} onSelect={handleSelect} />
        </>
      )}

      <OnboardingStepButtons backHidden nextDisabled={!project} />
    </Box>
  );
};
