import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { ErrorWidget } from "dashboard/components/ErrorWidget";
import { CustomTable } from "dashboard/components/Table";
import { saveEndpointRemapRules } from "dashboard/utils/backend";
import { AkitaError, AkitaErrorCode } from "dashboard/utils/error";
import { useGetFetch } from "hooks/use-get-fetch";
import { EndpointRemapRule, EndpointRemapRulesResponse } from "types/akita_api_types";

type directionType = "sending" | "receiving";

const getFullRuleTableRow = (filter: EndpointRemapRule, direction: directionType) => [
  <Typography variant="body2" key={0}>
    {direction === "sending" ? filter.action.label_source_service : filter.action.change_host_name}
  </Typography>,
  <Typography key={1} variant="body2">
    {filter.condition.host_name_matches}
  </Typography>,
  <Typography key={2}>{filter.condition.http_path_starts_with}</Typography>,
  <Typography key={3}>{filter.condition.http_path_ends_with}</Typography>,
  <Typography key={4}>{filter.condition.request_has_port}</Typography>,
  <Typography key={5}>{filter.condition.source_ip_address}</Typography>,
];

export const EndpointRemapRuleFilter = (props: {
  filter: EndpointRemapRule;
  updateItem: (filter: EndpointRemapRule, index: number, direction: directionType) => void;
  direction: directionType;
  index: number;
}) => {
  const keys = [
    "host_name_ends_with",
    "host_name_matches",
    "http_path_ends_with",
    "http_path_starts_with",
    "request_has_port",
    "source_ip_address",
  ];

  const getStartingKey = () =>
    keys.filter((k) => {
      if ((props.filter.condition as any)[k]) {
        return k;
      }
      return false;
    })[0];

  const [currentType, setCurrentType] = useState(getStartingKey() || "");

  useEffect(() => {
    setCurrentType(getStartingKey());
  }, [props.filter.id]);

  return (
    <React.Fragment>
      <FormControl variant="outlined" style={{ width: 180, marginRight: 12 }}>
        <Typography variant="subtitle2">Filter Type</Typography>
        <Select
          labelId="filter-label"
          value={currentType}
          onChange={(event) => setCurrentType(event.target.value as any)}
          fullWidth
          size="small"
        >
          <MenuItem value="host_name_matches">Hostname matches</MenuItem>
          <MenuItem value="host_name_ends_with">Hostname ends with</MenuItem>
          <MenuItem value="http_path_starts_with">HTTP Path starts with</MenuItem>
          <MenuItem value="http_path_ends_with">HTTP Path ends with</MenuItem>
          <MenuItem value="request_has_port">Request Port</MenuItem>
          <MenuItem value="source_ip_address">Source IP Address</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <Typography variant="subtitle2" style={{ marginBottom: -8 }}>
          Filter
        </Typography>
        <TextField
          type="text"
          size="small"
          variant="outlined"
          onChange={(event) => {
            const newItem = { ...props.filter } as any;
            keys.forEach((k) => {
              newItem.condition[k] = null;
            });
            if (currentType) {
              newItem.condition[currentType] =
                event.target.value.length > 0 ? event.target.value : null;
              props.updateItem(newItem, props.index, props.direction);
            }
          }}
          margin="dense"
          value={currentType && ((props.filter.condition as any)[currentType] || "")}
        />
      </FormControl>
    </React.Fragment>
  );
};

const getUserRuleTableRow = (
  filter: EndpointRemapRule,
  removeItem: (type: directionType, index: number) => void,
  updateItem: (filter: EndpointRemapRule, index: number, direction: directionType) => void,
  direction: directionType,
  index: number
) => [
  <TextField
    key={filter.id}
    size="small"
    type="text"
    variant="outlined"
    onChange={(event) => {
      const newItem = { ...filter };
      if (direction === "sending") {
        newItem.action.label_source_service = event.target.value;
      } else {
        newItem.action.change_host_name = event.target.value;
      }
      updateItem(newItem, index, direction);
    }}
    margin="dense"
    value={
      direction === "sending" ? filter.action.label_source_service : filter.action.change_host_name
    }
  />,
  <EndpointRemapRuleFilter
    key={`${filter.id}-0`}
    filter={filter}
    direction={direction}
    updateItem={updateItem}
    index={index}
  />,
  <Tooltip title="Delete" aria-label="delete" key={6}>
    <IconButton onClick={() => removeItem(direction, index)} size="small">
      <ClearIcon />
    </IconButton>
  </Tooltip>,
];

const useStyles = makeStyles((theme) => ({
  container: { padding: theme.spacing(2) },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const LabelsTable = (props: { serviceID?: string }) => {
  const classes = useStyles();

  const { oktaAuth, authState } = useOktaAuth();

  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<AkitaError | null>(null);

  const [sendingUserRules, setSendingUserRules] = useState<EndpointRemapRule[]>([]);
  const [receivingUserRules, setReceivingUserRules] = useState<EndpointRemapRule[]>([]);

  const [sendingFullRules, setSendingFullRules] = useState<EndpointRemapRule[]>([]);
  const [receivingFullRules, setReceivingFullRules] = useState<EndpointRemapRule[]>([]);

  const specsURL = `${process.env.REACT_APP_API_URL}/v1/services/${props.serviceID}/endpoint_remap_rules`;
  const [filtersResponse, isFiltersLoading, listFiltersError, refreshFilters] =
    useGetFetch<EndpointRemapRulesResponse>(
      specsURL,
      undefined,
      !props.serviceID,
      "fetching filters for your service"
    );

  useEffect(() => {
    if (filtersResponse?.user_rules) {
      setSendingUserRules(
        filtersResponse?.user_rules.filter((r) => !!r.action.label_source_service)
      );
      setReceivingUserRules(filtersResponse?.user_rules.filter((r) => !!r.action.change_host_name));
    }
    if (filtersResponse?.full_rules) {
      setSendingFullRules(
        filtersResponse?.full_rules.filter((r) => !!r.action.label_source_service)
      );
      setReceivingFullRules(filtersResponse?.full_rules.filter((r) => !!r.action.change_host_name));
    }
  }, [
    filtersResponse?.full_rules?.length,
    filtersResponse?.user_rules?.length,
    isFiltersLoading,
    props.serviceID,
  ]);

  const removeLabel = (direction: directionType, index: number) => {
    if (direction === "receiving") {
      receivingUserRules.splice(index, 1);
      setReceivingUserRules([...receivingUserRules]);
    } else {
      sendingUserRules.splice(index, 1);
      setSendingUserRules([...sendingUserRules]);
    }
  };
  const updateLabel = (label: EndpointRemapRule, index: number, direction: directionType) => {
    if (direction === "receiving") {
      const newRules = [...receivingUserRules];
      newRules[index] = label;
      setReceivingUserRules(newRules);
    } else {
      const newRules = [...sendingUserRules];
      newRules[index] = label;
      setSendingUserRules(newRules);
    }
  };

  const addLabel = (direction: directionType) => {
    const emptyRule = {
      id: null,
      action: {
        change_host_name: null,
        label_source_service: null,
        omit_request: null,
      },
      condition: {
        host_name_ends_with: null,
        host_name_matches: null,
        http_path_ends_with: null,
        http_path_starts_with: null,
        request_has_port: null,
        source_ip_address: null,
      },
    };
    if (direction === "receiving") {
      setReceivingUserRules([...receivingUserRules, emptyRule]);
    } else {
      setSendingUserRules([...sendingUserRules, emptyRule]);
    }
  };

  const save = async () => {
    setIsSaving(true);
    setSaveError(null);
    try {
      if (props.serviceID && authState) {
        const userRules = filtersResponse?.user_rules
          .filter((r) => !!r.action.omit_request)
          .concat(receivingUserRules)
          .concat(sendingUserRules);

        const formattedData = {
          user_rules: userRules || [],
          full_rules: filtersResponse?.full_rules || [],
        };
        await saveEndpointRemapRules(oktaAuth, authState, props.serviceID, formattedData);
      }
    } catch (error) {
      const realError = await error;
      setSaveError({
        customerMessage: `Error saving labels: ${(realError as any).error.message}`,
        error: error as any,
        status: 500,
        code: AkitaErrorCode.PostErr,
      });
    }
    setIsSaving(false);
    return refreshFilters();
  };

  const filterTableHead = ["Label", "Host Identification Filter"];

  const sendingTableData = sendingFullRules
    .map((response) => getFullRuleTableRow(response, "sending"))
    .concat(
      sendingUserRules.map((response, index) =>
        getUserRuleTableRow(response, removeLabel, updateLabel, "sending", index)
      )
    );
  const receivingTableData = receivingFullRules
    .map((response) => getFullRuleTableRow(response, "receiving"))
    .concat(
      receivingUserRules.map((response, index) =>
        getUserRuleTableRow(response, removeLabel, updateLabel, "receiving", index)
      )
    );

  return (
    <div>
      {(isFiltersLoading || isSaving) && <LinearProgress />}
      <div className={classes.container}>
        <Typography variant="h2" gutterBottom>
          Customize Displayed Hostnames
        </Typography>
        <Typography variant="body2" gutterBottom>
          This page allows you to customize the display of hosts in your API Model and across Akita.
        </Typography>
        <Typography variant="body2" gutterBottom>
          <b>Note:</b> These labels only apply to API Models going forward and are not retroactive.
        </Typography>
        <br />
        <Divider />
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>
            Label for host(s) <u>receiving</u> traffic
          </Typography>
          <Typography variant="body2" gutterBottom>
            Change the display name for hosts that receive requests that match a filter.
          </Typography>
          <Typography variant="body2" gutterBottom>
            This can be useful for labeling a database such as changing the display of the database
            from an IP address like ‘192.168.1.1:5432’ to ‘Postgres’.
          </Typography>
          {receivingTableData && receivingTableData.length > 0 && (
            <CustomTable tableHead={filterTableHead} tableData={receivingTableData} />
          )}
          <Button
            startIcon={<AddIcon />}
            color="primary"
            variant="contained"
            onClick={() => addLabel("receiving")}
          >
            Add Label
          </Button>
        </div>
        <Divider />
        <div className={classes.section}>
          <Typography variant="h3" gutterBottom>
            Label for host(s) <u>sending</u> traffic
          </Typography>
          <Typography variant="body2" gutterBottom>
            Change the display name for hosts that send requests that match a filter. This rewrites
            which service is the origin of this requests.
          </Typography>
          <Typography variant="body2" gutterBottom>
            This can be useful for labeling a load balancer as ‘load balancer’ rather than as many
            individual IP addresses.
          </Typography>
          {sendingTableData && sendingTableData.length > 0 && (
            <CustomTable tableHead={filterTableHead} tableData={sendingTableData} />
          )}
          <Button
            startIcon={<AddIcon />}
            color="primary"
            variant="contained"
            onClick={() => addLabel("sending")}
          >
            Add Label
          </Button>
        </div>
        <Divider />
        <div className={classes.section}>
          <ErrorWidget
            errorSources={[listFiltersError, [saveError, () => setSaveError(null)]]}
            errorContext="labels"
          />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Button variant="contained" onClick={() => refreshFilters()} size="large">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={save}
                size="large"
                disabled={isSaving}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
