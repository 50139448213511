import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { QueryClientProvider } from "@tanstack/react-query";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { SecureRouter } from "SecureRouter";
import { AkiThemeProvider } from "Theme";
import { AnalyticsProvider } from "analytics/components/AnalyticsProvider";
import { queryClient } from "data/query-client";

void (async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID!,
  });

  const rootElement = document.getElementById("root");

  const App = () => (
    <LDProvider>
      <BrowserRouter>
        <AnalyticsProvider>
          <QueryClientProvider client={queryClient}>
            <AkiThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SecureRouter />
              </LocalizationProvider>
            </AkiThemeProvider>
          </QueryClientProvider>
        </AnalyticsProvider>
      </BrowserRouter>
    </LDProvider>
  );

  ReactDOM.render(<App />, rootElement);
})();
