import { ChevronRight } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import React from "react";
import { AkiTile } from "./AkiTile";
import { AkiGridItemProps } from "dashboard/components/AkiGrid";
import { abbreviateNumber } from "dashboard/utils/numbers";
import { mergeSxProps } from "dashboard/utils/sx";

export type AkiNumberTileProps = AkiGridItemProps & {
  /** Whether or not the count value is being loaded, and this should show a placeholder. */
  isLoading?: boolean;
  /** Whether or not to show a non-pulsing placeholder, even if not loading. */
  isEmptyState?: boolean;
  /** Label to be displayed after the count, when the count is 1. */
  labelSingular: string;
  /** Label to be displayed after the count, when the count is any number other than 1. */
  labelPlural: string;
  /** Number to be displayed. */
  count?: number;
  /** Label for the link button */
  linkLabel?: string;
  /** Href for the link button */
  linkHref?: string;
  /** Whether or not to round the count to the nearest whole number. Defaults to true. */
  shouldRoundCount?: boolean;
};

export const AkiNumberTile = ({
  count,
  isEmptyState,
  isLoading,
  labelPlural,
  labelSingular,
  linkHref,
  linkLabel,
  sx,
  shouldRoundCount = true,
  ...rest
}: AkiNumberTileProps) => (
  <AkiTile
    sx={mergeSxProps(
      {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      sx
    )}
    spanC={2}
    spanR={1}
    {...rest}
  >
    <Typography
      variant="h5"
      sx={{ display: "flex", alignItems: "center", whiteSpace: "nowrap", overflow: "hidden" }}
    >
      {isLoading || isEmptyState ? (
        <Skeleton
          variant="rectangular"
          animation={isEmptyState ? false : "pulse"}
          sx={{
            borderRadius: (theme) => theme.spacing(0.5),
            marginRight: 1,
          }}
          height={20}
          width={24}
        />
      ) : (
        <Box
          component="span"
          sx={{ color: (theme) => theme.palette.secondary.main, marginRight: 1 }}
        >
          {count !== undefined &&
            abbreviateNumber(
              shouldRoundCount ? Math.round(count) : count,
              shouldRoundCount && count < 1000 ? 0 : 1
            )}
        </Box>
      )}{" "}
      <Box
        component="span"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "inline-block",
        }}
      >
        {count === 1 ? labelSingular : labelPlural}
      </Box>
    </Typography>
    {linkHref && linkLabel && (
      <IconButton
        href={linkHref}
        sx={{
          border: (theme) => `1px solid ${theme.palette.divider}`,
          padding: 0.5,
        }}
        aria-label={linkLabel}
      >
        <ChevronRight fontSize="small" />
      </IconButton>
    )}
  </AkiTile>
);
