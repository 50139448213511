import { isBefore } from "date-fns";
import React, { ReactNode, useMemo, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ProjectAndDeploymentIdsContext } from "contexts/project-and-deployment-ids-context";
import { LastSelectedProjectAndDeploymentKey } from "dashboard/utils/local_storage_keys";
import { useProjects } from "data/queries/projects";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";
import { ServiceSummary } from "types/akita_api_types";
import { ProjectAndDeployment } from "types/client_side_util_types";

interface CurrentProjectIdProviderProps {
  children: ReactNode;
}

const maybeGetProjectAndDeploymentFromLocalStorage = (
  projects: ServiceSummary[] | undefined,
  isAdmin: boolean
): ProjectAndDeployment | null => {
  const maybeSerializedProjectAndDeployment = window.localStorage.getItem(
    LastSelectedProjectAndDeploymentKey
  );
  if (!maybeSerializedProjectAndDeployment) return null;

  try {
    const projectAndDeployment: ProjectAndDeployment = JSON.parse(
      maybeSerializedProjectAndDeployment
    );

    // If the projectID in localStorage doesn't exist in the user's array of projects (and the user
    // isn't an admin), clear the value from localStorage and return null so we don't select an
    // inaccessible project.
    if (
      projects &&
      !projects?.some((project) => project.id === projectAndDeployment.serviceId) &&
      !isAdmin
    ) {
      window.localStorage.removeItem(LastSelectedProjectAndDeploymentKey);

      return null;
    }

    return projectAndDeployment;
  } catch (e) {
    window.localStorage.removeItem(LastSelectedProjectAndDeploymentKey);
    return null;
  }
};

const maybeGetProjectAndDeploymentWithMostRecentWitness = (
  projects: ServiceSummary[]
): ProjectAndDeployment | null => {
  if (!projects.length) {
    return null;
  }
  let mostRecentWitnessTime: Date | undefined;
  let mostRecentProjectAndDeployment: ProjectAndDeployment | null = null;

  projects.forEach((project) => {
    const deploymentInfos = project.deployment_infos || [];
    deploymentInfos.forEach((deploymentInfo) => {
      if (!deploymentInfo.last_observed) return;
      const deploymentLastObserved = new Date(deploymentInfo.last_observed);
      if (mostRecentWitnessTime && isBefore(deploymentLastObserved, mostRecentWitnessTime)) {
        return;
      }
      mostRecentWitnessTime = deploymentLastObserved;
      mostRecentProjectAndDeployment = {
        deploymentName: deploymentInfo.name,
        serviceId: project.id,
      };
    });
  });

  return mostRecentProjectAndDeployment;
};

const useProvideCurrentProjectId = (
  projects: ServiceSummary[] | undefined,
  isFetching: boolean
) => {
  const match = useRouteMatch<{ serviceId?: string }>({ path: "/service/:serviceId/*" });
  const pathProjectId = match?.params?.serviceId;

  const isAdmin = useIsFlagEnabled(FeatureFlag.InternalAdminPagesEnabled);

  const currentProjectIdRef = useRef<string | undefined>(pathProjectId);

  const maybeProjectAndDeploymentWithMostRecentWitness = useMemo(
    () => (projects ? maybeGetProjectAndDeploymentWithMostRecentWitness(projects) : null),
    [projects]
  );

  const maybeProjectAndDeploymentFromLocalStorage = maybeGetProjectAndDeploymentFromLocalStorage(
    projects,
    isAdmin
  );

  // The Project ID in the path always takes precedent. Otherwise, we have several fallbacks:
  // - the project ID from the ref
  // - the last project ID that was selected by the user, which we keep in localstorage
  // - the project ID with the most recent witness, since it likely contains pertinent info
  // - the first project in the list
  let value: string | undefined = undefined;
  if (pathProjectId) {
    value = pathProjectId;
  } else if (currentProjectIdRef.current) {
    value = currentProjectIdRef.current;
  } else if (maybeProjectAndDeploymentFromLocalStorage) {
    value = maybeProjectAndDeploymentFromLocalStorage.serviceId;
  } else if (maybeProjectAndDeploymentWithMostRecentWitness) {
    value = maybeProjectAndDeploymentWithMostRecentWitness.serviceId;
  } else {
    value = projects?.[0]?.id;
  }

  // If we're not doing an initial load or refetch, and the value doesn't exist in our projects
  // array, set it to the first ID in the projects array (if any). This ensures we don't have, for
  // instance, links on the page pointing to a recently-deleted project.
  //
  // If the user is an admin (has an akitasoftware.com email), we skip this check to allow admin
  // to view other user's projects.
  if (!isFetching && !projects?.some((project) => project.id === value) && !isAdmin) {
    value = projects?.[0]?.id;
  }

  // Store the value for next time
  currentProjectIdRef.current = value;

  return value;
};

const useProvideCurrentDeploymentId = (
  projects: ServiceSummary[] | undefined,
  currentProjectId: string | undefined
) => {
  const isEnabledInternalAdminPages = useIsFlagEnabled(FeatureFlag.InternalAdminPagesEnabled);
  const navigate = useHistory();
  const match = useRouteMatch<{ serviceId?: string; deploymentId?: string }>({
    path: "/service/:serviceId/deployment/:deploymentId/*",
  });
  const pathDeploymentId = match?.params?.deploymentId;

  const isAdmin = useIsFlagEnabled(FeatureFlag.InternalAdminPagesEnabled);

  const currentDeploymentIdRef = useRef<string | undefined>(pathDeploymentId);

  const deployments =
    projects?.find((project) => project.id === currentProjectId)?.deployments ?? undefined;

  const maybeProjectAndDeploymentWithMostRecentWitness = useMemo(
    () => (projects ? maybeGetProjectAndDeploymentWithMostRecentWitness(projects) : null),
    [projects]
  );

  const maybeProjectAndDeploymentFromLocalStorage = maybeGetProjectAndDeploymentFromLocalStorage(
    projects,
    isAdmin
  );

  const fallbackDeploymentId = useMemo(() => {
    if (!deployments?.length) return undefined;

    // If we have a project and deployment in LocalStorage and the deployment belongs to the current
    // project, use that as the fallback
    if (
      maybeProjectAndDeploymentFromLocalStorage &&
      deployments.includes(maybeProjectAndDeploymentFromLocalStorage.deploymentName)
    )
      return maybeProjectAndDeploymentFromLocalStorage.deploymentName;

    // Next try the project and deployment that has most recently received a witness. If this
    // deployment is a deployment of the current project, use it as the fallback
    if (
      maybeProjectAndDeploymentWithMostRecentWitness &&
      deployments.includes(maybeProjectAndDeploymentWithMostRecentWitness.deploymentName)
    )
      return maybeProjectAndDeploymentWithMostRecentWitness.deploymentName;

    if (deployments.includes("default")) return "default";

    deployments.sort();

    const firstProdDeploymentId = deployments.find((d) => d.toLowerCase().includes("prod"));
    if (firstProdDeploymentId) return firstProdDeploymentId;

    return deployments[0];
  }, [
    deployments,
    maybeProjectAndDeploymentWithMostRecentWitness,
    maybeProjectAndDeploymentFromLocalStorage,
  ]);

  let value: string | undefined = undefined;

  // If the deployment ID in the path doesn't exist, redirect to the default
  // deployment ID (drawn from the list of existing deployments).  Otherwise,
  // the deployment ID in the path always takes precedent,followed by the
  // value in state.
  //
  // If the internal admin page feature flag is enabled, skip checking if the
  // deployment exists; it won't exist if we're looking at customer
  // deployments.
  const isPathDeploymentIdInDeployments = deployments?.some((d) => d === pathDeploymentId);
  const shouldSwitchPathDeployment =
    pathDeploymentId && fallbackDeploymentId && !isPathDeploymentIdInDeployments;
  if (shouldSwitchPathDeployment && !isEnabledInternalAdminPages) {
    navigate.push(match.url.replace(`/${pathDeploymentId}/`, `/${fallbackDeploymentId}/`));
    value = fallbackDeploymentId;
  } else if (pathDeploymentId) {
    value = pathDeploymentId;
  } else if (deployments?.includes(currentDeploymentIdRef.current ?? "")) {
    value = currentDeploymentIdRef.current;
  } else if (fallbackDeploymentId) {
    value = fallbackDeploymentId;
  }

  // Store the value for next time
  currentDeploymentIdRef.current = value;

  return value;
};

// Provides the current project ID using the route params, and stores it so we can return users to
// to the project they were most recently looking at, after they've visited a non-project route.
export const ProjectAndDeploymentIdsProvider = ({ children }: CurrentProjectIdProviderProps) => {
  const { data: projects, isLoading, isRefetching } = useProjects();

  const currentProjectId = useProvideCurrentProjectId(projects, isLoading || isRefetching);
  const currentDeploymentId = useProvideCurrentDeploymentId(projects, currentProjectId);

  const value: [string | undefined, string | undefined] = useMemo(
    () => [currentProjectId, currentDeploymentId],
    [currentProjectId, currentDeploymentId]
  );

  return (
    <ProjectAndDeploymentIdsContext.Provider value={value}>
      {children}
    </ProjectAndDeploymentIdsContext.Provider>
  );
};
