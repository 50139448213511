import { Autocomplete, Chip, ListItem, Stack, Typography } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React from "react";
import { ParameterNameEditor } from "./ParameterNameEditor";

interface ParamEditListItemProps {
  key: string;
  pathParamName: string;
  disallowedNames: Set<string>;
  onNameChange: (newTitle: string) => void;
  parameterExclusions: Set<string>;
  options: string[];
  onSelect: () => void;
  onDeselect: () => void;
  onClearExclusions: () => void;
  onDeleteExclusion: (value: string) => void;
  onAddExclusion: (value: string) => void;
}

export const ParamEditListItem = ({
  key,
  pathParamName,
  disallowedNames,
  options,
  parameterExclusions,
  onNameChange,
  onSelect,
  onDeselect,
  onClearExclusions,
  onAddExclusion,
  onDeleteExclusion,
}: ParamEditListItemProps) => (
  <ListItem key={key} alignItems="flex-start">
    <ListItemText
      primary={
        <ParameterNameEditor
          disallowedNames={disallowedNames}
          name={pathParamName}
          onNameChange={(newTitle) => onNameChange(newTitle)}
        />
      }
      secondary={
        <Stack spacing={1}>
          <Typography variant="body2">
            <strong>Excluded values:</strong>
          </Typography>
          <Autocomplete
            value={Array.from(parameterExclusions)}
            multiple
            freeSolo
            onMouseOver={() => onSelect()}
            onMouseLeave={onDeselect}
            onChange={(_, value, reason, __) => {
              if (reason === "selectOption" || reason === "createOption") {
                onAddExclusion(value[value.length - 1]);
              }

              if (reason === "clear") {
                onClearExclusions();
              }
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  marginTop: 2,
                }}
                {...params}
                variant="outlined"
                placeholder="Add value to exclude"
                size="small"
              />
            )}
            onFocus={() => onSelect()}
            onBlur={onDeselect}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  {...getTagProps({ index })}
                  label={option}
                  onDelete={() => onDeleteExclusion(option)}
                />
              ))
            }
            options={options}
          />
        </Stack>
      }
    />
  </ListItem>
);
