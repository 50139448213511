import { Construction } from "@mui/icons-material";
import { Box, IconButton, Paper, Stack, Tooltip, useTheme } from "@mui/material";
import { isBefore, subHours } from "date-fns";
import { sortBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { ProjectIssue } from "../ProjectIssueAlertsList/ProjectIssueAlert";
import { ProjectIssueAlertsList } from "../ProjectIssueAlertsList/ProjectIssueAlertsList";
import { ProjectBarStatus } from "./ProjectBarStatus";
import { maybeGetLastObservedTime } from "./utils";
import { DeploymentSelector } from "dashboard/components/DeploymentSelector";
import { ProjectSelector } from "dashboard/components/ProjectSelector";
import { isClientActiveWithError } from "dashboard/utils/client-telemetry";
import { useClientTelemetry, useClientTelemetryCountRunning } from "data/queries/client-telemetry";
import { useProjects } from "data/queries/projects";
import { useCurrentDeploymentId } from "hooks/use-current-deployment-id";
import { useCurrentProjectId } from "hooks/use-current-project-id";

type ProjectBarProps = {
  hideDeploymentSelector?: boolean;
};

export const ProjectBar = ({ hideDeploymentSelector }: ProjectBarProps) => {
  const theme = useTheme();

  const { data: projects, isLoading, dataUpdatedAt } = useProjects();
  const projectID = useCurrentProjectId();
  const deploymentID = useCurrentDeploymentId();
  const [isCollapsedIssues, setIsCollapsedIssues] = useState(false);

  const toggleCollapsedIssues = useCallback(() => setIsCollapsedIssues((value) => !value), []);

  const maybeLastObservedDate = useMemo(
    () => maybeGetLastObservedTime(projects, projectID, deploymentID),
    [projects, deploymentID, projectID]
  );

  const { data: activeClients, isLoading: isLoadingActiveClients } = useClientTelemetryCountRunning(
    {
      projectID,
      deploymentID,
      options: {
        // Refetch every five minutes
        refetchInterval: 300_000,
      },
    }
  );

  // Fetch packet capture telemetry.
  const { data: dataClientTelemetry, isLoading: isLoadingClientTelemetry } = useClientTelemetry(
    projectID,
    { limit: 10 }
  );

  const hasActiveClientWithError = useMemo(
    () =>
      dataClientTelemetry
        ?.filter((stats) => stats.deployment === deploymentID)
        .some((stats) => isClientActiveWithError(stats)),
    [dataClientTelemetry, deploymentID]
  );

  // We compare against the last time we fetched projects, to avoid showing this spuriously when we
  // just have stale data.
  const shouldShowStaleDataWarning =
    !!maybeLastObservedDate && isBefore(maybeLastObservedDate, subHours(dataUpdatedAt, 4));

  const issues = useMemo(() => {
    const result: ProjectIssue[] = [];

    if (shouldShowStaleDataWarning) {
      result.push({
        title: "Missing data",
        description: "Akita has not seen data for this deployment in more than four hours.",
        severity: "warning",
        docsLink: "https://docs.akita.software/docs/troubleshooting#not-capturing-traffic",
        docsLinkText: "More info",
      });
    }

    if (!shouldShowStaleDataWarning && !maybeLastObservedDate) {
      result.push({
        title: "No data",
        description: "Akita hasn’t received any data for this deployment yet.",
        severity: "info",
        appLink: `/service/${projectID}/onboarding`,
        appLinkText: "Setup",
      });
    }

    // If data has been seen before, and there's no clients running
    if (maybeLastObservedDate && !activeClients && !isLoadingActiveClients) {
      result.push({
        title: "No clients reporting",
        description: "No Akita clients are currently running.",
        severity: "error",
        appLink: `/service/${projectID}/onboarding`,
        appLinkText: "Setup",
      });
    }

    if (!isLoadingClientTelemetry && hasActiveClientWithError) {
      result.push({
        title: "Client errors",
        description: "One or more client(s) is experiencing errors.",
        severity: "error",
        appLink: `/service/${projectID}/diagnostics/traces`,
        appLinkText: "View",
      });
    }

    // Sort by severity, in descending order.
    return sortBy(result, (issue) => {
      switch (issue.severity) {
        case "error":
          return 0;
        case "warning":
          return 1;
        default:
          return 2;
      }
    });
  }, [
    shouldShowStaleDataWarning,
    maybeLastObservedDate,
    activeClients,
    isLoadingActiveClients,
    isLoadingClientTelemetry,
    hasActiveClientWithError,
    projectID,
  ]);

  const hasIssues = issues.length > 0;
  const highestIssueSeverity = issues[0]?.severity;

  return (
    <Paper sx={{ marginBottom: 2, padding: 1, paddingLeft: 2, paddingRight: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            justifyContent: "flex-start",
          },
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            [theme.breakpoints.down("md")]: { paddingBottom: 1 },
          }}
        >
          <Stack direction="row" spacing={1} alignItems="flex-end" flexGrow={1}>
            <ProjectSelector
              projects={projects}
              projectsAreLoading={isLoading}
              sx={{ minWidth: 176, flexGrow: 1, [theme.breakpoints.down("sm")]: { minWidth: 0 } }}
            />
            <Tooltip title="Project Settings and Diagnostics">
              <IconButton size="small-outlined" href={`/service/${projectID}/diagnostics`}>
                <Construction />
              </IconButton>
            </Tooltip>
          </Stack>

          {!hideDeploymentSelector && (
            <DeploymentSelector
              projects={projects}
              projectsAreLoading={isLoading}
              sx={{ minWidth: 176, flexGrow: 2, [theme.breakpoints.down("sm")]: { minWidth: 0 } }}
            />
          )}
        </Stack>

        {!hideDeploymentSelector && (
          <ProjectBarStatus
            activeClients={activeClients}
            hasIssues={hasIssues}
            highestIssueSeverity={highestIssueSeverity}
            isCollapsedIssues={isCollapsedIssues}
            lastObservedDate={maybeLastObservedDate}
            toggleCollapsedIssues={toggleCollapsedIssues}
          />
        )}
      </Box>

      {!hideDeploymentSelector && (
        <ProjectIssueAlertsList
          isCollapsed={isCollapsedIssues}
          issues={issues}
          sx={{ marginTop: 1 }}
        />
      )}
    </Paper>
  );
};
