import React, { useCallback } from "react";
import { EditMonitorModalForm } from "./EditMonitorModalForm";
import { AkiDialog } from "dashboard/components/AkiDialog/AkiDialog";
import { AkiDialogContent } from "dashboard/components/AkiDialog/AkiDialogContent";
import { AkiDialogHeader } from "dashboard/components/AkiDialog/AkiDialogHeader";
import { QueryError } from "dashboard/components/QueryError";
import { useMonitor } from "data/queries/monitors";
import { useModalParams } from "hooks/use-modal-params";
import { useSearchParam } from "hooks/use-search-param";

export const EditMonitorModal = () => {
  const { projectID, deploymentID, monitorID } = useModalParams<{
    projectID: string;
    deploymentID: string;
    monitorID: string;
  }>();

  const {
    data: dataMonitor,
    error: errorMonitor,
    refetch: refetchMonitor,
  } = useMonitor(monitorID, projectID, deploymentID);

  const [_, setModalPath] = useSearchParam("modal");
  const closeModal = useCallback(() => {
    setModalPath(null);
  }, [setModalPath]);

  return (
    <AkiDialog onClose={closeModal} fullWidth maxWidth="lg" align="top" scroll="body">
      <AkiDialogHeader title="Edit Monitor" />

      <AkiDialogContent>
        <QueryError
          error={errorMonitor}
          label="We had trouble loading your monitor."
          refetch={refetchMonitor}
        />

        {dataMonitor && (
          <EditMonitorModalForm
            monitor={dataMonitor}
            closeModal={closeModal}
            projectID={projectID}
            deploymentID={deploymentID}
          />
        )}
      </AkiDialogContent>
    </AkiDialog>
  );
};
