import { TabContext, TabList, TabPanel } from "@mui/lab";
import { LinearProgress, Tab } from "@mui/material";
import React, { useCallback, useState } from "react";
import { AkiDialog } from "dashboard/components/AkiDialog/AkiDialog";
import { AkiDialogContent } from "dashboard/components/AkiDialog/AkiDialogContent";
import { AkiDialogHeader } from "dashboard/components/AkiDialog/AkiDialogHeader";
import { ModelTags } from "dashboard/components/ModelTags";
import { PathParameterAnnotations } from "dashboard/components/PathParameterAnnotations";
import { QueryError } from "dashboard/components/QueryError";
import { useModelMetadata } from "data/queries/models";
import { useModalParams } from "hooks/use-modal-params";
import { useSearchParam } from "hooks/use-search-param";

export const ModelSettingsModal = () => {
  const { projectID, modelID } = useModalParams<{
    projectID: string;
    modelID: string;
  }>();
  const [_, setModalPath] = useSearchParam("modal");

  const {
    data: modelMetadata,
    isLoading: isLoadingModelMetadata,
    error: errorModelMetadata,
    refetch: refetchModelMetadata,
  } = useModelMetadata(projectID, modelID);

  // TODO: Implement in routing, instead of state
  const [activeTab, setActiveTab] = useState<"customize" | "tags">("customize");

  const closeModal = useCallback(() => {
    setModalPath(null);
  }, [setModalPath]);

  return (
    <AkiDialog onClose={closeModal} fullWidth maxWidth="lg" align="top">
      <TabContext value={activeTab}>
        <AkiDialogHeader title="Model Settings" sx={{ paddingBottom: 0 }}>
          <TabList
            onChange={(_event, newActiveTab: "customize" | "tags") => setActiveTab(newActiveTab)}
          >
            <Tab label="Path Hints" value="customize" />
            <Tab label="Tags" value="tags" />
          </TabList>
        </AkiDialogHeader>

        {isLoadingModelMetadata && <LinearProgress />}

        <AkiDialogContent>
          <QueryError
            error={errorModelMetadata}
            label="We had trouble loading your model settings."
            refetch={refetchModelMetadata}
          />

          <TabPanel sx={{ padding: 0 }} value="customize">
            <PathParameterAnnotations projectID={projectID} sx={{ padding: 0 }} />
          </TabPanel>

          <TabPanel sx={{ padding: 0 }} value="tags">
            <ModelTags
              projectID={projectID}
              tags={modelMetadata?.tags_set || modelMetadata?.tags}
              sx={{ padding: 0, maxWidth: "initial" }}
            />
          </TabPanel>
        </AkiDialogContent>
      </TabContext>
    </AkiDialog>
  );
};
