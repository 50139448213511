import { Box } from "@mui/material";
import React, { useMemo } from "react";

interface PathPartsProps {
  host: string;
  path: string;
  highlightedParameterIndices: Set<number>;
}

export const PathParts = ({ path, host, highlightedParameterIndices }: PathPartsProps) => {
  const pathParts = useMemo(() => path.split("/"), [path]);

  if (highlightedParameterIndices.size === 0) {
    return (
      // Don't highlight anything if there are no highlighted parameters.
      <>
        {host}
        {path}
      </>
    );
  }

  return (
    <>
      <Box component="span" sx={(theme) => ({ color: theme.palette.text.secondary, opacity: 0.4 })}>
        {host}
      </Box>
      {pathParts.map((part, index) => (
        <React.Fragment key={`${index}-${part}`}>
          <Box
            component="span"
            sx={(theme) => ({
              color: highlightedParameterIndices.has(index)
                ? theme.palette.secondary.dark
                : theme.palette.text.secondary,
              opacity: highlightedParameterIndices.has(index) ? 1 : 0.4,
            })}
          >
            {part}
          </Box>
          {index < pathParts.length - 1 && (
            <Box
              component="span"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                opacity: 0.4,
              })}
            >
              /
            </Box>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
