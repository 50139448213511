import { TextField, TextFieldProps } from "@mui/material";
import React, { ChangeEvent, useCallback } from "react";

type NumberFieldProps = Omit<TextFieldProps, "value" | "onChange"> & {
  value: string;
  onChange: (value: string) => void;
  isInteger?: boolean;
};

export const NumberField = ({ value, onChange, isInteger, ...rest }: NumberFieldProps) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;

      // These must be re-created each time they're evaluated, to avoid gotchas with RegExp's internal state
      const patternStrip = isInteger ? /[^0-9\-+]/gi : /[^0-9.\-+]/gi;
      const patternValid = isInteger ? /^[-|+]?[0-9]*/g : /^[-|+]?[0-9]*\.?[0-9]*$/g;

      const cleanValue = inputValue.replace(patternStrip, "");
      const isValid = patternValid.test(cleanValue);

      if (!isValid) return;
      onChange(cleanValue);
    },
    [isInteger, onChange]
  );

  return (
    <TextField
      value={value}
      onChange={handleChange}
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode#values
      inputMode={isInteger ? "numeric" : "decimal"}
      {...rest}
    />
  );
};
