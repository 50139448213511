import { capitalize } from "lodash";

const ABBREVIATIONS = [
  "ansci",
  "ascii",
  "aws",
  "csv",
  "ec2",
  "html",
  "http",
  "https",
  "id",
  "imds",
  "iso",
  "json",
  "mac",
  "pdf",
  "rfc",
  "uri",
  "url",
  "utc",
  "utf",
  "uuid",
  "vm",
  "xml",
  "yaml",
];

// Pluralize word (add "s") if count != 1.
export function pluralize(word: string, count: number): string {
  return `${word}${count === 1 ? "" : "s"}`;
}

/**
 * Return a string with the first letter converted to uppercase, or the entire string uppercased
 * if the given word is a known abbreviation. Also handles some special cases like "ipv4" -> "IPv4".
 */
const akiCapitalize = (word: string) => {
  if (word.toLowerCase() === "ipv4") {
    return "IPv4";
  }

  if (word.toLowerCase() === "ipv6") {
    return "IPv6";
  }

  if (ABBREVIATIONS.includes(word.toLowerCase())) {
    return word.toUpperCase();
  }

  return capitalize(word);
};

/** Replace underscores (and optionally dashes) with spaces and capitalize each word. */
export function prettify(str: string, { replaceDashes = false }: { replaceDashes?: boolean } = {}) {
  const parts = str.split(replaceDashes ? /_|-/g : "_");
  const capitalizedParts = parts.map(akiCapitalize);

  return capitalizedParts.join(" ");
}

// Join array of strings but only if strings are not already joined
export const safeJoin = (array: string[], separator: string) =>
  array.reduce((joined, current) => {
    const currentSeparator =
      joined.endsWith(separator) || current.startsWith(separator) ? "" : separator;
    return joined + currentSeparator + current;
  }, "");
