import mail from "email-addresses";

export type EmailAddress = {
  email: string;
  name?: string | null;
};

const instanceOfParsedMailbox = (
  obj: mail.ParsedMailbox | mail.ParsedGroup
): obj is mail.ParsedMailbox => obj.type === "mailbox";

const convertParsedMailbox = (m: mail.ParsedMailbox): EmailAddress => ({
  email: m.address,
  name: m.name,
});

export const parseMailbox = (input: string): EmailAddress | null => {
  const addr = mail.parseOneAddress(input);
  if (addr !== null && instanceOfParsedMailbox(addr)) {
    return convertParsedMailbox(addr);
  }
  return null;
};
