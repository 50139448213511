import { format, formatDuration, intervalToDuration } from "date-fns";
import { AlertHistoryEventPair } from "types/akita_api_types";

export const getAlertStartTimeLabel = (eventPair: AlertHistoryEventPair) =>
  format(new Date(eventPair.start_event.event_time), "Pp");

export const getAlertEndTimeLabel = (eventPair: AlertHistoryEventPair) => {
  if (!eventPair.end_event) return "ongoing";

  return format(new Date(eventPair.end_event.event_time), "Pp");
};

export const getAlertDurationLabel = (eventPair: AlertHistoryEventPair) => {
  if (!eventPair.end_event) return "ongoing";
  return formatDuration(
    intervalToDuration({
      start: new Date(eventPair.start_event.event_time),
      end: new Date(eventPair.end_event.event_time),
    })
  );
};
