import React from "react";
import { CommandStartClient } from "./CommandStartClient";
import { DockerDesktopStart } from "./DockerDesktopStart";
import { OnboardingStepButtons } from "dashboard/components/OnboardingStep/OnboardingStepButtons";

interface StepStartClientProps {
  apiKeyId: string | undefined;
  apiKeySecret: string | undefined;
  projectName: string | undefined;
  isDockerDesktopInstall: boolean;
}

export const StepStartClient = (props: StepStartClientProps) => (
  <>
    {props.isDockerDesktopInstall ? (
      <DockerDesktopStart {...props} />
    ) : (
      <CommandStartClient {...props} />
    )}
    <OnboardingStepButtons nextHidden={props.isDockerDesktopInstall} />
  </>
);
