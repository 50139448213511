import { MethodMetadataResponse, TimelineSummaryEndpoint } from "types/akita_api_types";

const ENDPOINT_ID_SEPERATOR = "_!!_";

/**
 * Takes an endpoint representation and returns a string that encodes the HTTP operation, host, and
 * path, along with a response code if present. This gives us a unique, stable-across-models ID that
 * can be decoded to grab useful information without having to fetch endpoint data from the backend.
 */
export const constructEndpointUniqueID = (
  endpoint:
    | MethodMetadataResponse
    | TimelineSummaryEndpoint
    | { host: string; path: string; operation: string; responseCode?: number }
) => {
  const idElements = [`O:${endpoint.operation}`, `H:${endpoint.host}`, `P:${endpoint.path}`];

  if ("response_code" in endpoint) {
    idElements.push(`R:${endpoint.response_code}`);
  }

  return idElements.join(ENDPOINT_ID_SEPERATOR);
};

export type DecodedEndpointUniqueID = {
  rawID: string;
  operation: string;
  host: string;
  path: string;
  responseCode?: number;
};

export const decodeEndpointUniqueID = (uniqueID: string) => {
  const idElements = uniqueID.split(ENDPOINT_ID_SEPERATOR);
  const decodedID: DecodedEndpointUniqueID = {
    rawID: uniqueID,
    operation: "",
    host: "",
    path: "",
  };

  idElements.forEach((element) => {
    const prefix = element.charAt(0);
    const content = element.slice(2);

    switch (prefix) {
      case "O":
        return (decodedID.operation = content);
      case "H":
        return (decodedID.host = content);
      case "P":
        return (decodedID.path = content);
      case "R":
        return (decodedID.responseCode = parseInt(content, 10));
    }
  });

  return decodedID;
};
