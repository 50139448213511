import React, { MutableRefObject, ReactNode, useMemo, useState } from "react";
import { ChartTooltipContext, TooltipLocation } from "contexts/chart-tooltip-context";

type ChartTooltipProviderProps = {
  containerRef: MutableRefObject<HTMLElement | null>;
  children: ReactNode;
  isTooltipEnabled: boolean;
  containerPadding: { left: number; bottom: number; top: number; right: number };
  containerHeight: number;
  containerWidth: number;
};

export const ChartTooltipProvider = ({
  containerRef,
  children,
  isTooltipEnabled,
  containerPadding,
  containerHeight,
  containerWidth,
}: ChartTooltipProviderProps) => {
  const [tooltipLocation, setTooltipLocation] = useState<TooltipLocation>();

  const contextValue = useMemo(
    () => ({
      containerRef,
      containerHeight,
      containerPadding,
      containerWidth,
      isTooltipEnabled,
      setTooltipLocation,
      tooltipLocation,
    }),
    [
      containerRef,
      containerHeight,
      containerPadding,
      containerWidth,
      isTooltipEnabled,
      tooltipLocation,
    ]
  );

  return (
    <ChartTooltipContext.Provider value={contextValue}>{children}</ChartTooltipContext.Provider>
  );
};
