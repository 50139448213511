import { TextField } from "@mui/material";
import React, { useCallback } from "react";
import { AkiTableCell } from "../AkiTable/AkiTableCell";
import { AkiTableRow } from "../AkiTable/AkiTableRow";
import { FormattedEndpointField } from "../EndpointDetails/formatting";
import { SkeletonText } from "../SkeletonText";

type EndpointFieldsTableRowProps =
  | {
      field: FormattedEndpointField;
      includeContentType?: boolean;
      includePosition?: boolean;
      includeResponseCode?: boolean;
      isEditMode?: boolean;
      isFirst?: boolean;
      isLoading?: false;
      onEdit?: (editedField: FormattedEndpointField) => void;
    }
  | {
      field?: FormattedEndpointField;
      includeContentType?: boolean;
      includePosition?: boolean;
      includeResponseCode?: boolean;
      isEditMode?: boolean;
      isFirst?: boolean;
      isLoading: true;
      onEdit?: (editedField: FormattedEndpointField) => void;
    };

export const EndpointFieldsTableRow = ({
  field,
  includeContentType,
  includePosition,
  includeResponseCode,
  isEditMode,
  isFirst,
  isLoading,
  onEdit,
}: EndpointFieldsTableRowProps) => {
  const onChangeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isEditMode || !onEdit || !field) return;
      const value = event.target.value;

      onEdit({
        ...field,
        display: { ...field.display, fieldName: value },
      });
    },
    [onEdit, isEditMode, field]
  );

  return (
    <AkiTableRow>
      {!isLoading && isEditMode ? (
        <AkiTableCell grow sx={{ paddingLeft: "6px" }}>
          <TextField
            aria-label="Field Name"
            autoComplete="off"
            autoFocus={isFirst}
            fullWidth
            onChange={onChangeName}
            placeholder={field.display.originalFieldName}
            required
            size="small"
            sx={{ maxWidth: "600px" }}
            value={field?.display.fieldName}
          />
        </AkiTableCell>
      ) : (
        <AkiTableCell grow>{isLoading ? <SkeletonText /> : field.display.fieldName}</AkiTableCell>
      )}
      <AkiTableCell sx={{ minWidth: 200, paddingLeft: 0 }}>
        {isLoading ? <SkeletonText /> : field.display.dataFormat}
      </AkiTableCell>
      {includeContentType && (
        <AkiTableCell sx={{ minWidth: 120, paddingLeft: 0 }}>
          {isLoading ? <SkeletonText /> : field.display.contentType}
        </AkiTableCell>
      )}
      {includeResponseCode && (
        <AkiTableCell sx={{ minWidth: 150, paddingLeft: 0 }} align="right">
          {isLoading ? <SkeletonText /> : field.display.responseCode}
        </AkiTableCell>
      )}
      {includePosition && (
        <AkiTableCell sx={{ minWidth: 100, paddingLeft: 0 }} align="right">
          {isLoading ? <SkeletonText /> : field.display.position}
        </AkiTableCell>
      )}
    </AkiTableRow>
  );
};
