import { Box, alpha } from "@mui/material";
import type { ChatCompletionRequestMessage } from "openai";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { SkeletonText } from "../SkeletonText";

type AkiChatMessageProps =
  | {
      message: ChatCompletionRequestMessage;
      isLoading?: boolean;
    }
  | {
      message?: ChatCompletionRequestMessage;
      isLoading: true;
    };

export const AkiChatMessage = ({ isLoading, message }: AkiChatMessageProps) => (
  <Box
    sx={{
      paddingBottom: 2,
      display: "flex",
      justifyContent: isLoading || message.role === "assistant" ? "flex-start" : "flex-end",
    }}
  >
    <Box
      sx={(theme) => ({
        borderRadius: "10px",
        borderBottomLeftRadius: isLoading || message.role === "assistant" ? 0 : "10px",
        borderBottomRightRadius: message?.role === "user" ? 0 : "10px",
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor:
          message?.role === "user"
            ? alpha(theme.palette.primary.light, 0.1)
            : theme.palette.overlay.min,
        paddingLeft: 2,
        paddingRight: 2,
        width: "90%",
        overflowX: "auto",
      })}
    >
      {isLoading ? (
        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <SkeletonText randomWidth={false} />
          <SkeletonText randomWidth={false} />
          <SkeletonText />
        </Box>
      ) : (
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.content}</ReactMarkdown>
      )}
    </Box>
  </Box>
);
