import { useQuery } from "@tanstack/react-query";
import type { ChatCompletionRequestMessage, CreateChatCompletionResponse } from "openai";
import { akitaFetch } from "data/akita-fetch";

const getAkiChat = (
  messages: ChatCompletionRequestMessage[],
  context?: Record<string, string | undefined>
) =>
  akitaFetch<CreateChatCompletionResponse>("/integrations/openai/chat/completions", {
    method: "POST",
    body: {
      model: "gpt-3.5-turbo",
      messages,
    },
    queryParams: context,
  });

export const useAkiChat = (
  messages: ChatCompletionRequestMessage[],
  {
    context,
    ...options
  }: {
    enabled?: boolean;
    /** An object of strings that will be sent to the backend as query params for usage tracking. */
    context?: Record<string, string | undefined>;
  } = {}
) =>
  useQuery(["aki-chat", messages], () => getAkiChat(messages, context), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 1 * 60 * 60 * 1000,
    staleTime: 1 * 60 * 60 * 1000,
    retry: 1,
    ...options,
  });
