import React from "react";
import { Redirect, Route, Switch, generatePath } from "react-router-dom";
import { Page, PageContent, PageHeader, PageTitle } from "dashboard/components/Page";
import { NotFound } from "dashboard/pages/NotFound";
import { DiagnosticsTabs } from "dashboard/pages/ServiceSettings/DiagnosticsTabs";
import { FiltersTable } from "dashboard/pages/ServiceSettings/FiltersTable";
import { LabelsTable } from "dashboard/pages/ServiceSettings/LabelsTable";
import { LearnSessionsTable } from "dashboard/pages/ServiceSettings/LearnSessionsTable";
import { ServiceLiveCollection } from "dashboard/pages/ServiceSettings/ServiceLiveCollection";
import { SpecsTable } from "dashboard/pages/ServiceSettings/SpecsTable";
import { useProjects } from "data/queries/projects";

const DiagnosticsRedirector = (props: { serviceID?: string }) => {
  if (!props.serviceID) {
    return <NotFound />;
  }
  const url = generatePath(`/service/:serviceID/diagnostics/traces`, {
    serviceID: props.serviceID,
  });

  return <Redirect to={url} />;
};

export const Diagnostics = ({ projectId }: { projectId?: string }) => {
  const { data: projects } = useProjects();
  const project = projects?.find((p) => p.id === projectId);

  return (
    <Page>
      <PageHeader sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
        <PageTitle>Diagnostics</PageTitle>
        <DiagnosticsTabs projectID={projectId} />
      </PageHeader>

      <PageContent sx={{ paddingTop: 0 }}>
        <Switch>
          <Route
            path="/service/:serviceID/diagnostics/all-models"
            render={(route) => <SpecsTable serviceID={route.match.params.serviceID} />}
          />
          <Route
            path="/service/:serviceID/diagnostics/daemons"
            render={() => (
              <ServiceLiveCollection
                serviceID={projectId || ""}
                serviceName={project?.name || ""}
              />
            )}
          />
          <Route
            path="/service/:serviceID/diagnostics/traces"
            render={(route) => <LearnSessionsTable serviceID={route.match.params.serviceID} />}
          />
          <Route
            path="/service/:serviceID/diagnostics/filters"
            render={(route) => <FiltersTable serviceID={route.match.params.serviceID} />}
          />
          <Route
            path="/service/:serviceID/diagnostics/custom-labels"
            render={(route) => <LabelsTable serviceID={route.match.params.serviceID} />}
          />
          <Route
            path="/service/:serviceID/diagnostics*"
            render={(route) => <DiagnosticsRedirector serviceID={route.match.params.serviceID} />}
          />
        </Switch>
      </PageContent>
    </Page>
  );
};
