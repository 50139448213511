import { Box, List, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { ReactNode } from "react";
import { AkiTile } from "./AkiTile";
import { AkiGridItemProps } from "dashboard/components/AkiGrid";
import { mergeSxProps } from "dashboard/utils/sx";

export type AkiListTileProps = Omit<AkiGridItemProps, "title"> & {
  title: ReactNode;
  emptyState: ReactNode;
  icon?: ReactNode;
};

export const AkiListTile = ({
  children,
  emptyState,
  icon,
  sx,
  title,
  ...rest
}: AkiListTileProps) => {
  const content = isEmpty(children) ? (
    <Box
      sx={{
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 1,
        paddingTop: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {emptyState}
    </Box>
  ) : (
    <List disablePadding>{children}</List>
  );

  return (
    <AkiTile
      sx={mergeSxProps(
        { display: "flex", flexDirection: "column", padding: 0, paddingTop: 2, paddingBottom: 1 },
        sx
      )}
      spanC={3}
      {...rest}
    >
      <Box
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          marginBottom: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {icon && (
          <Box
            sx={{
              marginRight: 1,
              "& > *": {
                display: "block",
              },
            }}
          >
            {icon}
          </Box>
        )}
        <Typography variant="h5">{title}</Typography>
      </Box>

      {content}
    </AkiTile>
  );
};
