import { Analytics } from "@segment/analytics-next";
import { useEffect } from "react";
import { trackEvent } from "../analytics/event-utils";
import { useAnalyticsContext } from "./use-analytics-context";
import { AnalyticsEventParamObject } from "types/metrics";

/**
 * logError sends an event of type "error".
 *
 * @param analytics - The Segment Analytics instance to use.
 * @param errorName - Name of the event
 * @param params - Object containing analytics event params
 */
export const logError = (
  analytics: Analytics | undefined,
  errorName: string,
  params?: AnalyticsEventParamObject
) => {
  const eventName = `Error displayed: ${errorName}`;

  trackEvent(eventName, analytics, {
    ...params,
    source: "superstar-frontend",
    eventType: "error",
  });
};

/**
 * useErrorLogger sends a Segment error event just once per errorName/params pair. If no errorName
 * is given, no error event is sent.
 *
 * @param errorName - Name of the event
 * @param params - Object containing analytics event params
 */
export const useErrorLogger = (errorName?: string, params?: AnalyticsEventParamObject) => {
  const { analytics } = useAnalyticsContext();

  useEffect(() => {
    if (errorName) {
      logError(analytics, errorName, params);
    }
  }, [analytics, errorName, params]);
};
