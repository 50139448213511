import { ListItem } from "@mui/material";
import { orderBy } from "lodash";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AkiListTile } from "dashboard/components/AkiTile/AkiListTile";
import { LinkBehavior } from "dashboard/components/LinkBehavior";
import { ListItemLink } from "dashboard/components/ListItemLink";
import { EndpointEntity } from "dashboard/components/entities/EndpointEntity";
import { getQueryLocation } from "dashboard/utils/routes";
import { MethodMetadataResponse } from "types/akita_api_types";

interface TileErrorEndpointsListProps {
  endpoints?: MethodMetadataResponse[];
  isEmptyState?: boolean;
  isLoading?: boolean;
  modelID?: string;
  projectID?: string;
}

export const TileErrorEndpointsList = ({
  endpoints,
  isEmptyState,
  isLoading,
  modelID,
  projectID,
}: TileErrorEndpointsListProps) => {
  const location = useLocation();

  const items = useMemo(() => {
    if (isLoading || isEmptyState) {
      return new Array(6).fill(undefined).map((_, index) => (
        <ListItem key={index}>
          <EndpointEntity isPlaceholder={isEmptyState} isLoading={isLoading} />
        </ListItem>
      ));
    }

    if (endpoints) {
      const mostErrorEndpoints = orderBy(
        endpoints,
        (endpoint) => (endpoint.performance?.num_4xx ?? 0) + (endpoint.performance?.num_5xx ?? 0),
        "desc"
      ).slice(0, 6);

      return mostErrorEndpoints.map((endpoint) => (
        <ListItemLink
          component={LinkBehavior}
          to={getQueryLocation(
            location,
            "modal",
            `/project/${projectID}/model/${modelID}/endpoint/${endpoint.method_id}`
          )}
          key={endpoint.method_id}
        >
          <EndpointEntity endpoint={endpoint} />
        </ListItemLink>
      ));
    }

    return null;
  }, [endpoints, isLoading, isEmptyState, projectID, modelID, location]);

  return (
    <AkiListTile
      title="Endpoints with the most errors"
      emptyState={<>We haven&rsquo;t observed any traffic recently.</>}
    >
      {items}
    </AkiListTile>
  );
};
