import FileCopy from "@mui/icons-material/FileCopy";
import { Box, SxProps, Theme, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { ReactNode, useCallback } from "react";

type CodeBoxProps = {
  children?: ReactNode;
  code: string;
  compact?: boolean;
  label: string;
  onCopy?: () => void;
  sx?: SxProps<Theme>;
};

export const CodeBox = ({ children, code, label, onCopy, sx }: CodeBoxProps) => {
  const handleCopy = useCallback(async () => {
    onCopy?.();

    try {
      await navigator.clipboard.writeText(code);
    } catch (error) {
      console.log(error);
    }
  }, [onCopy, code]);

  return (
    <Box display="flex" maxWidth="100%" sx={sx}>
      <Box flexGrow={1} marginRight={1} flexShrink={1} overflow="auto">
        <Box
          component="pre"
          sx={{
            overflowX: "auto",
            backgroundColor: (theme) => theme.palette.overlay.min,
            color: (theme) => theme.palette.text.primary,
            padding: 1,
            margin: 0,
            marginRight: 1,
            borderRadius: 1,
          }}
        >
          {children || code}
        </Box>
      </Box>

      <Tooltip title={`Copy ${label}`}>
        <IconButton onClick={handleCopy} size="medium-outlined">
          <FileCopy />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
