import { Edit } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { LinkBehavior } from "./LinkBehavior";
import { getQueryLocation } from "dashboard/utils/routes";
import { useCurrentDeploymentId } from "hooks/use-current-deployment-id";
import { useCurrentProjectId } from "hooks/use-current-project-id";
import { useLogInteraction } from "hooks/use-log-interaction";

type EditMonitorButtonProps = {
  includeContext?: boolean;
  monitorID?: string;
} & Omit<ButtonProps<typeof LinkBehavior>, "to" | "href">;

export const EditMonitorButton = ({
  includeContext,
  monitorID,
  ...rest
}: EditMonitorButtonProps) => {
  const projectID = useCurrentProjectId();
  const deploymentID = useCurrentDeploymentId();
  const logInteraction = useLogInteraction();
  const location = useLocation();

  return (
    <Button
      variant="outlined"
      startIcon={<Edit />}
      color="inherit"
      size="small"
      component={LinkBehavior}
      onClick={() => logInteraction("Opened Edit Monitor Modal", { monitorID })}
      disabled={!monitorID}
      to={getQueryLocation(
        location,
        "modal",
        `/project/${projectID}/deployment/${deploymentID}/monitors/${monitorID}/edit`
      )}
      {...rest}
    >
      {includeContext ? "Edit Monitor" : "Edit"}
    </Button>
  );
};
