import { getTime } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { TimeRange, getAbsoluteTimeRange } from "../dashboard/utils/time-range";
import { AnalyticsEventParamObject } from "../types/metrics";

interface TimeRangeManager {
  timeRange: TimeRange;
  absoluteStartDate: Date;
  absoluteEndDate: Date;
  relativeEndDate: Date;
  changeTimeRangeMode: (mode: "relative" | "absolute") => void;
  refreshTimeRange: () => void;
  setTimeRange: (timeRange: TimeRange) => void;
}

interface UseTimeRangeManagerParams {
  initialTimeRange: TimeRange;
  // This is used to set the default relative time range when the user switches to relative mode
  // If not provided, the default is 12 hours
  defaultRelativeDuration?: Duration;
  onLogInteraction?: (interactionName: string, params?: AnalyticsEventParamObject) => void;
}

export const useTimeRangeManager = ({
  initialTimeRange,
  defaultRelativeDuration,
  onLogInteraction,
}: UseTimeRangeManagerParams): TimeRangeManager => {
  const [relativeEndDate, setRelativeEndDate] = useState(new Date());
  const [timeRange, setTimeRange] = useState<TimeRange>(initialTimeRange);

  const { absoluteStartDate, absoluteEndDate } = useMemo(() => {
    const absoluteTimeRange = getAbsoluteTimeRange(timeRange, relativeEndDate);

    return {
      absoluteStartDate: new Date(absoluteTimeRange.interval.start),
      absoluteEndDate: new Date(absoluteTimeRange.interval.end),
    };
  }, [timeRange, relativeEndDate]);

  const onTimeRangeModeChange = useCallback(
    (mode: "relative" | "absolute") => {
      if (mode === "relative" && timeRange.type === "absolute") {
        setTimeRange({ type: "relative", duration: defaultRelativeDuration ?? { hours: 12 } });
        setRelativeEndDate(new Date());
      } else if (mode === "absolute" && timeRange.type === "relative") {
        setTimeRange({
          type: "absolute",
          interval: {
            start: getTime(absoluteStartDate),
            end: getTime(absoluteEndDate),
          },
        });
      }
    },
    [timeRange.type, defaultRelativeDuration, absoluteStartDate, absoluteEndDate]
  );

  const onRefresh = useCallback(() => {
    // If this isn't a relative time range, there's nothing to refresh.
    if (timeRange.type !== "relative") return;

    setRelativeEndDate(new Date());
    onLogInteraction?.("Clicked refresh button");
  }, [onLogInteraction, timeRange.type]);

  const onSetTimeRange = useCallback(
    (newTimeRange: TimeRange) => {
      setTimeRange(newTimeRange);
      onLogInteraction?.("Changed time range");
    },
    [onLogInteraction]
  );

  return {
    timeRange,
    setTimeRange: onSetTimeRange,
    absoluteStartDate,
    absoluteEndDate,
    relativeEndDate,
    changeTimeRangeMode: onTimeRangeModeChange,
    refreshTimeRange: onRefresh,
  };
};
