import React from "react";
import { AkiNumberTile, AkiNumberTileProps } from "../AkiTile/AkiNumberTile";
import { useEndpointsMetadata } from "data/queries/endpoints-metadata";

type ModelSummaryTileEndpointsModeledProps = Omit<
  AkiNumberTileProps,
  "labelSingular" | "labelPlural"
> & {
  projectID?: string;
  modelID?: string;
};

export const ModelSummaryTileEndpointsModeled = ({
  projectID,
  modelID,
  ...rest
}: ModelSummaryTileEndpointsModeledProps) => {
  const { data: endpointsMetadata, isLoading } = useEndpointsMetadata(
    projectID,
    modelID,
    {
      // Matching the limit used by other summary tiles, to use the cached value and save a request
      limit: 2,
      sort: "count:desc",
    },
    { refetchOnWindowFocus: false }
  );

  return (
    <AkiNumberTile
      {...rest}
      count={endpointsMetadata?.total_count}
      labelSingular="endpoint modeled"
      labelPlural="endpoints modeled"
      isLoading={isLoading}
    />
  );
};
