import React, { useCallback, useMemo, useState } from "react";
import { AkiDialog } from "dashboard/components/AkiDialog/AkiDialog";
import { AkiDialogContent } from "dashboard/components/AkiDialog/AkiDialogContent";
import { AkiDialogHeader } from "dashboard/components/AkiDialog/AkiDialogHeader";
import { EditMonitorForm } from "dashboard/components/EditMonitorForm/EditMonitorForm";
import { decodeEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import { parseMonitorThreshold } from "dashboard/utils/monitors";
import { useCreateMonitor } from "data/queries/monitors";
import { useModalParams } from "hooks/use-modal-params";
import { useSearchParam } from "hooks/use-search-param";
import { MonitorComparator, MonitorSignalKind, MonitorToCreate } from "types/akita_api_types";

export const NewMonitorModal = () => {
  // @pr TODO: Replace endpointID with modal-level route parameters
  const [endpointID, setEndpointID] = useState<string>();

  const [signal, setSignal] = useState<MonitorSignalKind>(MonitorSignalKind.LATENCY_90P);
  const [comparator, setComparator] = useState<MonitorComparator>(MonitorComparator.GT);
  const [thresholdStr, setThresholdStr] = useState<string>("1000");
  const [minEventCountStr, setMinEventCountStr] = useState<string>("0");

  const { projectID, deploymentID } = useModalParams<{
    projectID: string;
    deploymentID: string;
  }>();

  const [_, setModalPath] = useSearchParam("modal");
  const closeModal = useCallback(() => {
    setModalPath(null);
  }, [setModalPath]);

  const decodedEndpointID = useMemo(
    () => (endpointID ? decodeEndpointUniqueID(endpointID) : undefined),
    [endpointID]
  );

  const { mutate: mutateCreateMonitor, isLoading: isLoadingCreateMonitor } = useCreateMonitor(
    projectID,
    deploymentID,
    { onSuccess: closeModal }
  );

  const threshold = parseMonitorThreshold(signal, thresholdStr);
  const minEventCount = parseInt(minEventCountStr, 10);
  const isValid = !!decodedEndpointID && !!signal && !!comparator && Number.isFinite(threshold);

  const createMonitor = useCallback(() => {
    if (!isValid) return;

    const monitorToCreate: MonitorToCreate = {
      signal,
      comparator,
      threshold,
      min_event_count: minEventCount,
      http_method: decodedEndpointID.operation,
      path_template: decodedEndpointID.path,
      host: decodedEndpointID.host,
    };

    mutateCreateMonitor(monitorToCreate);
  }, [
    isValid,
    signal,
    comparator,
    threshold,
    minEventCount,
    decodedEndpointID,
    mutateCreateMonitor,
  ]);

  return (
    <AkiDialog onClose={closeModal} fullWidth maxWidth="lg" align="top" scroll="body">
      <AkiDialogHeader title="New Monitor" />

      <AkiDialogContent>
        <EditMonitorForm
          canSave={isValid}
          comparator={comparator}
          deploymentID={deploymentID}
          endpoint={decodedEndpointID}
          endpointID={endpointID}
          isSaving={isLoadingCreateMonitor}
          minEventCountStr={minEventCountStr}
          onCancel={closeModal}
          onSave={createMonitor}
          projectID={projectID}
          saveButtonLabel="Create"
          setComparator={setComparator}
          setEndpointID={setEndpointID}
          setMinEventCountStr={setMinEventCountStr}
          setSignal={setSignal}
          setThresholdStr={setThresholdStr}
          signal={signal}
          thresholdStr={thresholdStr}
        />
      </AkiDialogContent>
    </AkiDialog>
  );
};
