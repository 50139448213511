import Clear from "@mui/icons-material/Clear";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import { useOktaAuth } from "@okta/okta-react";
import React, { useState } from "react";
import { AkitaErrorCode, akitaErrorOfFailure } from "dashboard/utils/error";

const useStyles = makeStyles((theme) => ({
  dialog: {},
  spacer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

type DeleterProps = {
  name: string;
  url: string;
  onSuccess?: () => void;
  noConfirm?: boolean;
  noRefresh?: boolean;
};

export const Deleter = (props: DeleterProps) => {
  const { authState } = useOktaAuth();
  const classes = useStyles();
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const confirmDelete = () => {
    setAlertVisible(true);
  };

  const tryDelete = async () => {
    setIsLoading(true);
    const response = await fetch(props.url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authState!.accessToken?.accessToken}`,
      },
    });

    setIsLoading(false);

    if (!response.ok) {
      return akitaErrorOfFailure(response, AkitaErrorCode.DeleteErr, `deleting ${props.name}`);
    }

    return successDelete();
  };

  const onClick = () => {
    if (props.noConfirm) {
      return tryDelete();
    } else {
      confirmDelete();
    }
  };

  const successDelete = () => {
    hideAlert();
    props.onSuccess && props.onSuccess();
    if (!props.noRefresh) {
      window.location.reload();
    }
  };

  const hideAlert = () => {
    setAlertVisible(false);
  };

  return (
    <div>
      <Tooltip title="Delete" aria-label="delete">
        <IconButton onClick={onClick} size="small">
          <Clear />
        </IconButton>
      </Tooltip>
      <Dialog open={isAlertVisible}>
        <div className={classes.dialog}>
          <DialogTitle>
            <Typography variant="h2" gutterBottom>
              Are you sure?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" gutterBottom>
              You will not be able to recover <b>{props.name}</b>.
            </Typography>
            <br />
            <Divider />
            <div className={classes.spacer}>
              <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item>
                  <Button color="primary" onClick={hideAlert}>
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={tryDelete}
                    disabled={isLoading}
                  >
                    Yes, delete it!
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};
