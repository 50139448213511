import { AuthState, OktaAuth } from "@okta/okta-auth-js";
import { IPathParameterItem } from "dashboard/components/PathParameterAnnotations";
import { AkitaError, AkitaErrorCode, akitaErrorOfFailure } from "dashboard/utils/error";
import { logout } from "dashboard/utils/logout";
import { AkitaUserInfo, EndpointRemapRule } from "types/akita_api_types";

const handle401 = (authService: OktaAuth, authState: AuthState, akiErr: AkitaError): AkitaError => {
  // Logout on 401 (unauthorized) errors.  This will bring the user
  // to the login screen, and then redirect to the dashboard.
  // TODO(cns): Figure out how to redirect back to the page the user
  //            left.
  if (akiErr.status === 401) {
    void logout(authState, authService);
  }
  return akiErr;
};

export const createUser = (authService: OktaAuth, authState: AuthState): Promise<AkitaUserInfo> => {
  const createUserURL = `${process.env.REACT_APP_API_URL}/v1/users`;
  return fetch(createUserURL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authState.accessToken?.accessToken}`,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    })
    .catch((err) => {
      throw akitaErrorOfFailure(err, AkitaErrorCode.PostErr, "creating your user").then((akiErr) =>
        handle401(authService, authState, akiErr)
      );
    });
};

export const getUser = (authState: AuthState): Promise<AkitaUserInfo> => {
  const getUserURL = `${process.env.REACT_APP_API_URL}/v1/user`;
  return fetch(getUserURL, {
    headers: {
      Authorization: `Bearer ${authState.accessToken?.accessToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .catch((err) => {
      throw akitaErrorOfFailure(err, AkitaErrorCode.PostErr, `loading your user data`);
      // Don't handle 401 here.  We use 401 downstream to indicate that we need to try creating
      // a user.
    });
};

export const savePathParameters = (
  authService: OktaAuth,
  authState: AuthState,
  serviceID: string,
  body: { annotations: IPathParameterItem[] }
): Promise<IPathParameterItem[]> => {
  const genAPIKeyURL = `${process.env.REACT_APP_API_URL}/v1/services/${serviceID}/path_parameter_annotations`;
  return fetch(genAPIKeyURL, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${authState.accessToken?.accessToken}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .catch((err) => {
      throw akitaErrorOfFailure(err, AkitaErrorCode.PostErr, "saving path parameters").then(
        (akiErr) => handle401(authService, authState, akiErr)
      );
    });
};

export const saveEndpointRemapRules = (
  authService: OktaAuth,
  authState: AuthState,
  serviceID: string,
  body: { user_rules: EndpointRemapRule[] }
): Promise<{ user_rules: EndpointRemapRule[] }> => {
  const genAPIKeyURL = `${process.env.REACT_APP_API_URL}/v1/services/${serviceID}/endpoint_remap_rules`;
  return fetch(genAPIKeyURL, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${authState.accessToken?.accessToken}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .catch((err) => {
      throw akitaErrorOfFailure(err, AkitaErrorCode.PostErr, "saving path parameters").then(
        (akiErr) => handle401(authService, authState, akiErr)
      );
    });
};
