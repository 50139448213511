import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { EndpointsTableFilterOptionLabel } from "./EndpointsTableFilterOptionLabel";
import { FilterOption } from "./formatting";

type EndpointsTableFilterOptionProps = {
  filterKey: string;
  option: FilterOption;
  isChecked: boolean;
  toggleOptionChecked: (optionKey: string) => void;
  style?: React.CSSProperties;
};

const sharedListItemButtonProps = {
  dense: true,
  sx: {
    padding: 0,
    paddingLeft: 2,
    // Extra 0.5 (4px) of padding to visually line up with expand/collapse arrow on FilterOptionsList.
    paddingRight: 2.5,
  },
};

const sharedListItemIconProps = {
  sx: { minWidth: "24px", marginRight: 1 },
};

const sharedListItemTextProps = {
  disableTypography: true,
  sx: {
    display: "flex",
    justifyContent: "space-between",
  },
};

const EndpointsTableFilterOption = memo(
  ({
    filterKey,
    option,
    isChecked,
    toggleOptionChecked,
    style,
  }: EndpointsTableFilterOptionProps) => (
    <ListItem key={option.optionKey} dense sx={{ padding: 0 }} style={style}>
      <ListItemButton
        {...sharedListItemButtonProps}
        onClick={() => toggleOptionChecked(option.optionKey)}
      >
        <ListItemIcon {...sharedListItemIconProps}>
          <Checkbox
            sx={{ padding: 0, margin: 0 }}
            disableRipple
            edge="start"
            checked={isChecked}
            tabIndex={-1}
            inputProps={{ "aria-labelledby": `${option.optionKey}-label` }}
          />
        </ListItemIcon>

        <ListItemText {...sharedListItemTextProps} id={`${option.optionKey}-label`}>
          <EndpointsTableFilterOptionLabel optionKey={option.optionKey} filterKey={filterKey} />
          <Typography variant="body2" component="div">
            {option.count}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
);
EndpointsTableFilterOption.displayName = "EndpointsTableFilterOption";

type SeeMorePseudoFilterOptionProps = {
  onClick: () => void;
};

// A fake filter option with the text "See more...", and no checkbox or count.
export const SeeMorePseudoFilterOption = ({ onClick }: SeeMorePseudoFilterOptionProps) => (
  <ListItem dense sx={{ padding: 0 }}>
    <ListItemButton {...sharedListItemButtonProps} onClick={onClick}>
      <ListItemIcon {...sharedListItemIconProps} />
      <ListItemText {...sharedListItemTextProps}>
        <EndpointsTableFilterOptionLabel pseudoOptionTextOverride="See more..." />
      </ListItemText>
    </ListItemButton>
  </ListItem>
);
SeeMorePseudoFilterOption.displayName = "SeeMorePseudoFilterOption";

export const WindowedEndpointsTableFilterOption = memo(
  ({
    index,
    style,
    data,
  }: {
    index: number;
    style: React.CSSProperties;
    data: {
      options: FilterOption[];
      filterKey: string;
      selectedOptions: string[];
      toggleOptionChecked: (optionKey: string) => void;
    };
  }) => {
    const { options, filterKey, selectedOptions, toggleOptionChecked } = data;
    const option = options[index];

    return (
      <EndpointsTableFilterOption
        style={style}
        key={option.optionKey}
        filterKey={filterKey}
        isChecked={selectedOptions.includes(option.optionKey)}
        option={option}
        toggleOptionChecked={toggleOptionChecked}
      />
    );
  }
);
WindowedEndpointsTableFilterOption.displayName = "WindowedFilterOption";
