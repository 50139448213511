import { Settings } from "@mui/icons-material";
import { TabList } from "@mui/lab";
import { Box, Stack, Tab } from "@mui/material";
import React from "react";
import { Pill } from "../Pill";
import { EndpointDetailsGraphs } from "./EndpointDetailsGraphs";
import { EndpointEntity } from "dashboard/components/entities/EndpointEntity";
import { EndpointParams, useEndpoint } from "data/queries/endpoint";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";

export type EndpointDetailsTabs = "params" | "headers" | "body" | "settings" | "openapi3" | "aki";

type EndpointDetailsHeaderProps = {
  projectID: string;
  modelID?: string;
  endpointID?: string;
  endpointParams?: EndpointParams;
  setActiveTab: (newActiveTab: EndpointDetailsTabs) => void;
};

export const EndpointDetailsHeader = ({
  projectID,
  modelID,
  endpointID,
  setActiveTab,
  endpointParams,
}: EndpointDetailsHeaderProps) => {
  const isEnabledAskAki = useIsFlagEnabled(FeatureFlag.AskAkiEnabled);

  const { data: dataEndpoint, isLoading: isLoadingEndpoint } = useEndpoint(
    projectID,
    modelID,
    endpointID
  );

  return (
    <Box>
      <EndpointEntity sx={{ marginTop: 1 }} endpoint={dataEndpoint} isLoading={isLoadingEndpoint} />

      <EndpointDetailsGraphs
        projectID={projectID}
        modelID={modelID}
        endpointID={endpointID}
        endpointParams={endpointParams}
      />

      <TabList
        sx={{ marginTop: 1 }}
        onChange={(_event, newActiveTab: EndpointDetailsTabs) => setActiveTab(newActiveTab)}
      >
        <Tab label="Parameters" value="params" />
        <Tab label="Headers" value="headers" />
        <Tab label="Body" value="body" />
        <Tab label="View as OpenAPI3" value="openapi3" />
        {isEnabledAskAki && (
          <Tab
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <span>Ask Aki</span>
                <Pill
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 0, color: "currentcolor", borderColor: "currentcolor" }}
                >
                  Alpha
                </Pill>
              </Stack>
            }
            value="aki"
          />
        )}
        <Tab
          label="Settings"
          icon={<Settings />}
          iconPosition="start"
          sx={{ marginLeft: "auto", minWidth: "auto", minHeight: "auto" }}
          title="Settings"
          value="settings"
        />
      </TabList>
    </Box>
  );
};
