import Typography from "@mui/material/Typography";
import React from "react";
import { Page, PageContent, PageHeader, PageTitle } from "dashboard/components/Page";

export const NotFound = () => (
  <Page>
    <PageHeader>
      <PageTitle>Not Found</PageTitle>
    </PageHeader>
    <PageContent>
      <Typography>Sorry! We couldn&rsquo;t find what you were looking for.</Typography>
    </PageContent>
  </Page>
);
