import { LocationDescriptorObject } from "history";
import { uniq } from "lodash";
import { useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getQueryLocation } from "dashboard/utils/routes";

/**
 * Hook that returns a record of all search params in the current URL (with all values of each
 * included in an array), along with a function that clears all search params.
 *
 * Optionally filters for a prefix on each key.
 *
 * Optionally allows for a record of keys mapped to placeholder values. If all search params are
 * cleared, any keys provided will have their value set to a placeholder, instead of being deleted
 * entirely. Note that, for perf and safety reasons, this value will be frozen on mount and should
 * not be expected to mutate for the lifetime of the calling component.
 */
export const useAllSearchParams = (
  prefix: string | undefined,
  placeholders?: Record<string, string>
): [Record<string, string[]>, () => void] => {
  const location = useLocation();
  const history = useHistory();

  // Set this ref's initial value to the initial `placeholders` value, to keep from breaking memoization.
  const placeholdersRef = useRef(placeholders ?? {});

  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const keys = uniq(
      Array.from(searchParams.keys())
        .concat(Object.keys(placeholdersRef.current))
        .filter((key) => !prefix || key.startsWith(prefix))
    );

    const allParams: Record<string, string[]> = {};

    keys.forEach((key) => {
      const values = searchParams.getAll(key);

      if (values.length < 1) return;

      // If there's a placeholder value set for this key, and the only matching value is the expected
      // placeholder value, don't include the value.
      if (
        placeholdersRef.current[key] &&
        values.length === 1 &&
        values[0] === placeholdersRef.current[key]
      ) {
        return;
      }

      const formattedKey = prefix ? key.replace(prefix, "") : key;
      allParams[formattedKey] = values;
    });

    const clearAll = () => {
      const newLocation = keys.reduce(
        (result: LocationDescriptorObject, key) =>
          getQueryLocation(result, key, placeholdersRef.current[key] ?? null),
        location
      );

      history.push(newLocation);
    };

    return [allParams, clearAll];
  }, [location, prefix, history]);
};
