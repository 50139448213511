import React from "react";
import { AkiNumberTile } from "dashboard/components/AkiTile/AkiNumberTile";
import { getModelPathname } from "dashboard/utils/routes";

export const TileEndpointsObservedNumber = ({
  count,
  deploymentID,
  isEmptyState,
  isLoading,
  projectID,
}: {
  count?: number;
  deploymentID?: string;
  isEmptyState?: boolean;
  isLoading?: boolean;
  projectID?: string;
}) => (
  <AkiNumberTile
    count={count}
    isEmptyState={isEmptyState}
    isLoading={isLoading}
    labelPlural="endpoints modeled"
    labelSingular="endpoint modeled"
    linkLabel="View All Endpoints"
    linkHref={projectID && deploymentID ? getModelPathname({ projectID, deploymentID }) : undefined}
  />
);
