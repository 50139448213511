import { Alert, TableCell, TableRow } from "@mui/material";
import React from "react";
import { ActivationURLResponse } from "types/akita_api_types";

interface ReactivatedUsersTableRowProps {
  response: ActivationURLResponse;
}

export const ReactivatedUsersTableRow = ({ response }: ReactivatedUsersTableRowProps) => {
  let error = response.error_message && (
    <Alert severity="error">Error: {response.error_message}</Alert>
  );
  switch (response.response_code) {
    case 403:
      error = <Alert severity="warning">User is already activated.</Alert>;
      break;
    case 404:
      error = <Alert severity="error">User not found.</Alert>;
      break;
  }

  return (
    <TableRow hover key={response.user_email}>
      <TableCell>{response.user_email}</TableCell>
      <TableCell>{error ? error : response.activation_link}</TableCell>
    </TableRow>
  );
};
