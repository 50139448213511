import React from "react";
import { AkiGridItem, AkiGridItemProps } from "dashboard/components/AkiGrid";
import { mergeSxProps } from "dashboard/utils/sx";

type AkiTileProps = AkiGridItemProps & {
  hasHeader?: boolean;
};

export const AkiTile = ({ children, hasHeader, sx, ...rest }: AkiTileProps) => (
  <AkiGridItem
    sx={mergeSxProps(
      {
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        overflow: "hidden",
        padding: hasHeader ? 0 : 2,
      },
      sx
    )}
    spanR={3}
    {...rest}
  >
    {children}
  </AkiGridItem>
);
