import { OktaAuth } from "@okta/okta-auth-js";

export const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const CALLBACK_PATH = "/implicit/callback";

const AUTHORIZATION_SERVER_ID = process.env.REACT_APP_OKTA_AUTH_SERVER_ID;
const HOST = window.location.host;
const HTTP =
  process.env.NODE_ENV === "production" || process.env.REACT_APP_HTTPS === "true"
    ? "https"
    : "http";
export const SCOPES = ["openid", "profile", "email"];

const OKTA_DOMAIN = "okta.akita.software";
export const REDIRECT_URI = `${HTTP}://${HOST}${CALLBACK_PATH}`;
export const LOGOUT_REDIRECT_URI = `${HTTP}://${HOST}`;
export const ISSUER = `https://${OKTA_DOMAIN}/oauth2/${AUTHORIZATION_SERVER_ID}`;

export const oktaAuth = new OktaAuth({
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES,
});

const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

/**
 * getGoogleOauthClientId attempts to retrieve the client ID for google Oauth, and throws an error if it can't
 * @returns The Akita google auth client id, as a string
 */
export const getGoogleOauthClientId = () => {
  if (GOOGLE_OAUTH_CLIENT_ID === undefined) {
    throw new Error("google oauth client id missing");
  }
  return GOOGLE_OAUTH_CLIENT_ID;
};

/**
 * getAccessToken attempts to retrieve the current Okta access token, and throws an error if it can't
 * @returns An Okta access token for accessing Akita APIs
 */
export const getAccessToken = async () => {
  const isAuthenticated = await oktaAuth.isAuthenticated();
  if (!isAuthenticated) throw new Error("Not authenticated");

  const accessToken = oktaAuth.getAccessToken();
  if (!accessToken) throw new Error("Missing access token");

  return accessToken;
};
