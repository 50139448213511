import { Box, BoxProps } from "@mui/material";
import React, { ReactNode } from "react";
import { mergeSxProps } from "dashboard/utils/sx";

export type PillProps = BoxProps & {
  children: ReactNode;
  label?: ReactNode;
  variant?: "filled" | "outlined";
  size?: "normal" | "small";
};

export const Pill = ({
  children,
  label,
  sx,
  variant = "filled",
  size = "normal",
  ...rest
}: PillProps) => (
  <Box
    sx={mergeSxProps(
      {
        alignItems: "center",
        backgroundColor:
          variant === "outlined"
            ? (theme) => theme.palette.overlay.min
            : (theme) => theme.palette.grey[600],
        borderRadius: (theme) => theme.spacing(0.5),
        color: variant === "outlined" ? (theme) => theme.palette.text.primary : "#FFFFFF",
        border:
          variant === "outlined" ? (theme) => `1px solid ${theme.palette.divider}` : undefined,
        display: "inline-flex",
        flexShrink: 0,
        fontSize: size === "small" ? "10px" : (theme) => theme.typography.body2.fontSize,
        fontWeight: size === "small" ? "bold" : (theme) => theme.typography.body2.fontWeight,
        justifyContent: "center",
        minWidth: "58px",
        textAlign: "center",
        minHeight: "24px",
        whiteSpace: "nowrap",
      },
      sx
    )}
    {...rest}
  >
    {label && (
      <Box
        sx={{
          alignItems: "center",
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          display: "flex",
          height: "100%",
          justifyContent: "center",
          padding: "2px 4px 2px 6px",
        }}
      >
        {label}&nbsp;
      </Box>
    )}
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: label ? (theme) => theme.palette.overlay.min : "transparent",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        padding: "2px 6px",
      }}
    >
      {children}
    </Box>
  </Box>
);
