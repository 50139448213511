import { useEffect, useRef } from "react";
import { trackEvent } from "../analytics/event-utils";
import { useAnalyticsContext } from "./use-analytics-context";
import { AnalyticsEventParamObject } from "types/metrics";

/**
 * useImpression times the duration of a user impression.
 *
 * When first called, useImpression will send an event named in the format
 * "Impression Start: {elementName}", along with the initial `params` object.
 *
 * When the component calling useImpression unmounts (or `elementName` changes), the hook concludes
 * timing and sends an event named in the format "Impression End: {elementName}" to Segment, along
 * with the latest `params` object.
 *
 * @param elementName - Name of the UI element you're tracking (ex: "Model Page", "Project Dropdown")
 * @param params - Object containing analytics event params
 */
export const useImpression = (elementName: string, params?: AnalyticsEventParamObject) => {
  // We'll store the params in a ref, and update it immediately on each render.
  // This way, we can use the latest version of the params object if it changes over the lifetime
  // of the component calling this hook, without tracking a new Segment event whenever the params
  // object changes.
  const paramsRef = useRef(params);
  paramsRef.current = params;

  const { analytics } = useAnalyticsContext();

  useEffect(() => {
    const eventNameStart = `Impression Start: ${elementName}`;
    const eventNameEnd = `Impression End: ${elementName}`;

    // Send the Impression Start event
    trackEvent(eventNameStart, analytics, {
      ...(paramsRef.current ?? {}),
      elementName,
      eventType: "impression",
      impressionPhase: "start",
    });

    return () => {
      // Including the elementName as a param here, for flexibility in Segment
      trackEvent(eventNameEnd, analytics, {
        ...(paramsRef.current ?? {}),
        elementName,
        eventType: "impression",
        impressionPhase: "end",
      });
    };
  }, [analytics, elementName]);
};
