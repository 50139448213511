import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { AkiDialog, AkiDialogProps } from "./AkiDialog/AkiDialog";
import { AkiDialogContent } from "./AkiDialog/AkiDialogContent";
import { AkiDialogHeader } from "./AkiDialog/AkiDialogHeader";

type SpecEditSuccessDialogProps = Omit<AkiDialogProps, "children">;

export const SpecEditSuccessDialog = ({ onClose, ...rest }: SpecEditSuccessDialogProps) => (
  <AkiDialog onClose={onClose} {...rest}>
    <AkiDialogHeader title="Edits saved" />
    <AkiDialogContent>
      <Typography>Your edits have been saved and will take effect immediately.</Typography>
      <Box sx={{ paddingTop: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onClose} variant="contained" size="large">
          Okay
        </Button>
      </Box>
    </AkiDialogContent>
  </AkiDialog>
);
