import { InputAdornment } from "@mui/material";
import React from "react";
import { NumberField } from "dashboard/components/NumberField";
import { getMonitorSignalUnits } from "dashboard/utils/monitors";
import { MonitorSignalKind } from "types/akita_api_types";

type MonitorThresholdInputProps = {
  thresholdStr: string;
  onChange: (thresholdStr: string) => void;
  signal: MonitorSignalKind;
};

export const MonitorThresholdInput = ({
  thresholdStr,
  onChange,
  signal,
}: MonitorThresholdInputProps) => {
  const signalUnits = getMonitorSignalUnits(signal);

  return (
    <NumberField
      aria-label="Threshold value to compare to the metric value"
      value={thresholdStr}
      onChange={onChange}
      required
      placeholder="42"
      size="medium"
      sx={{ width: { md: 120 } }}
      InputProps={{
        endAdornment: signalUnits ? (
          <InputAdornment position="end">{signalUnits}</InputAdornment>
        ) : undefined,
      }}
    />
  );
};
