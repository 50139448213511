import { useCallback, useMemo } from "react";
import { useSearchParam } from "./use-search-param";

/**
 * Hook that reads and writes from the `page` search parameter in a URL. Value of pageIndex is 0
 * when no `page` parameter is found. Preserves other search parameters.
 */
// For some weird reason ts-unused-exports is complaining about this function, despite it being used
// ts-unused-exports:disable-next-line
export const usePageIndex = (): [number, (newPageIndex: number) => void] => {
  const [pageStr, setPageStr] = useSearchParam("page");
  const pageIndex = parseInt(pageStr || "0", 10) || 0;

  const setPageIndex = useCallback(
    (newPageIndex: number) => setPageStr(newPageIndex.toString()),
    [setPageStr]
  );

  return useMemo(() => [pageIndex, setPageIndex], [pageIndex, setPageIndex]);
};
