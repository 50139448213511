import { LoadingButton } from "@mui/lab";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { AkiTile } from "dashboard/components/AkiTile/AkiTile";
import { EndpointEntity } from "dashboard/components/entities/EndpointEntity";
import {
  getMonitorComparatorDescription,
  getMonitorEndpoint,
  getMonitorSignalLabel,
  getMonitorSignalUnits,
  getMonitorThresholdStrForDisplay,
} from "dashboard/utils/monitors";
import { useAcceptMonitor, useSuppressMonitor } from "data/queries/monitors";
import { Monitor } from "types/akita_api_types";

type ProposedMonitorTileProps = {
  deploymentID: string;
  monitor: Monitor;
  projectID: string;
};

export const ProposedMonitorTile = ({
  deploymentID,
  monitor,
  projectID,
}: ProposedMonitorTileProps) => {
  const monitorID = monitor.monitor_id;

  const { mutate: acceptMonitor, isLoading: isLoadingAccept } = useAcceptMonitor(
    projectID,
    deploymentID
  );

  const { mutate: suppressMonitor, isLoading: isLoadingSuppress } = useSuppressMonitor(
    projectID,
    deploymentID
  );

  const isLoadingAny = isLoadingAccept || isLoadingSuppress;

  const onAcceptMonitor = useCallback(() => {
    acceptMonitor([monitorID]);
  }, [acceptMonitor, monitorID]);

  const onDismissMonitor = useCallback(() => {
    suppressMonitor([monitorID]);
  }, [suppressMonitor, monitorID]);

  return (
    <AkiTile spanC={3} spanR={1} sx={{ padding: 1.5 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <Typography gutterBottom>
            Alert when the <strong>{getMonitorSignalLabel(monitor.signal)}</strong> of
          </Typography>
          <EndpointEntity endpoint={getMonitorEndpoint(monitor)} sx={{ marginBottom: 0.5 }} />
          <Typography gutterBottom>
            is{" "}
            <strong>
              {getMonitorComparatorDescription(monitor.comparator)}{" "}
              {getMonitorThresholdStrForDisplay(monitor.signal, monitor.threshold)}
              {getMonitorSignalUnits(monitor.signal)}
            </strong>
            .
          </Typography>
          {monitor.min_event_count > 0 && (
            <Typography gutterBottom>
              Only trigger an alert when the number of matching events in the last five minutes is{" "}
              <strong>greater than {monitor.min_event_count}</strong>.
            </Typography>
          )}
        </Box>

        <Box>
          <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
          <Typography variant="h6">Why we suggested this</Typography>
          <Typography variant="body2">
            <em>{monitor.proposed_reason}</em>
          </Typography>

          <Stack direction="row-reverse" spacing={1} justifyContent="flex-start" paddingTop={1}>
            <LoadingButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={onAcceptMonitor}
              loading={isLoadingAccept}
              disabled={isLoadingAny}
            >
              Accept
            </LoadingButton>

            <LoadingButton
              variant="outlined"
              color="secondary"
              size="small"
              onClick={onDismissMonitor}
              loading={isLoadingSuppress}
              disabled={isLoadingAny}
            >
              Dismiss
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </AkiTile>
  );
};
