import { ChevronRight } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { subHours } from "date-fns";
import { capitalize } from "lodash";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { MonitorAlertHistory } from "./MonitorAlertHistory";
import { MonitorHeader } from "./MonitorHeader";
import { MonitorStatusDescription } from "./MonitorStatusTile";
import { AkiGridContainer } from "dashboard/components/AkiGrid";
import { AkiTile } from "dashboard/components/AkiTile/AkiTile";
import { AkiTileHeader } from "dashboard/components/AkiTile/AkiTileHeader";
import { MonitorTimeline } from "dashboard/components/MonitorTimeline";
import { Page, PageContent } from "dashboard/components/Page";
import { QueryError } from "dashboard/components/QueryError";
import { EndpointEntity } from "dashboard/components/entities/EndpointEntity";
import { constructEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import { getMonitorEndpoint, getMonitorSignalLabel } from "dashboard/utils/monitors";
import { useRefreshAlertHistory } from "data/queries/alert-history";
import {
  useMonitor,
  useRefreshMonitor,
  useRefreshMonitorHistory,
  useUpdateMonitors,
} from "data/queries/monitors";
import { useImpression } from "hooks/use-impression";
import { useSearchParam } from "hooks/use-search-param";

export const MonitorPage = () => {
  const [_, setModalPath] = useSearchParam("modal");
  const [endMS, setEndMS] = useState(Date.now());
  const startMS = subHours(endMS, 6).getTime();

  const {
    serviceID: projectID,
    deploymentID,
    monitorID,
  } = useParams<{
    serviceID: string;
    deploymentID: string;
    monitorID: string;
  }>();

  const {
    data: dataMonitor,
    error: errorMonitor,
    refetch: refetchMonitor,
    isLoading: isLoadingMonitor,
  } = useMonitor(monitorID, projectID, deploymentID);

  const { mutate: updateMonitors, error: errorUpdateMonitors } = useUpdateMonitors(
    projectID,
    deploymentID
  );

  const refreshMonitorHistory = useRefreshMonitorHistory(monitorID, projectID, deploymentID);
  const refreshMonitor = useRefreshMonitor(monitorID, projectID, deploymentID);
  const refreshAlertHistory = useRefreshAlertHistory(projectID, deploymentID, monitorID);

  const onRefresh = useCallback(() => {
    setEndMS(Date.now());
    refreshMonitorHistory();
    refreshMonitor();
    refreshAlertHistory();
  }, [refreshMonitorHistory, refreshMonitor, refreshAlertHistory]);

  const onClickEndpointDetails = useCallback(() => {
    if (!dataMonitor) return;

    const endpoint = getMonitorEndpoint(dataMonitor);

    setModalPath(
      `/project/${projectID}/deployment/${deploymentID}/endpoint/${constructEndpointUniqueID(
        endpoint
      )}`
    );
  }, [setModalPath, projectID, deploymentID, dataMonitor]);

  const signal = dataMonitor?.signal ? getMonitorSignalLabel(dataMonitor?.signal) : undefined;

  useImpression("Monitors - Single Monitor", { monitorID });

  return (
    <Page>
      <MonitorHeader
        projectID={projectID}
        deploymentID={deploymentID}
        monitor={dataMonitor}
        onRefresh={onRefresh}
        updateMonitors={updateMonitors}
      />

      <PageContent>
        <QueryError
          error={errorMonitor}
          label="We had trouble loading your monitor."
          refetch={refetchMonitor}
        />

        <QueryError error={errorUpdateMonitors} label="We had trouble updating your monitors." />

        <AkiGridContainer columns={12}>
          <AkiTile spanC={12} spanR={1} hasHeader>
            <AkiTileHeader>
              <Typography variant="h5">Endpoint</Typography>
            </AkiTileHeader>

            <Stack
              padding={2}
              paddingTop={1}
              paddingBottom={1}
              direction="row"
              spacing={1}
              justifyContent="space-between"
            >
              <EndpointEntity
                endpoint={dataMonitor ? getMonitorEndpoint(dataMonitor) : undefined}
                isLoading={isLoadingMonitor}
              />

              <Tooltip title="Open Endpoint Details" enterDelay={500}>
                <IconButton onClick={onClickEndpointDetails} disabled={!dataMonitor}>
                  <ChevronRight />
                </IconButton>
              </Tooltip>
            </Stack>
          </AkiTile>

          <AkiTile hasHeader spanC={3} spanR={2}>
            <AkiTileHeader>
              <Typography variant="h5">Status</Typography>
            </AkiTileHeader>

            <MonitorStatusDescription monitor={dataMonitor} isLoading={isLoadingMonitor} />
          </AkiTile>

          <AkiTile hasHeader spanC={9} spanR={2}>
            <AkiTileHeader>
              <Typography variant="h5">
                {signal ? capitalize(signal) : "History"} – Last 6 Hours
              </Typography>
            </AkiTileHeader>

            {!!dataMonitor && (
              <MonitorTimeline
                projectID={projectID}
                deploymentID={deploymentID}
                monitorID={dataMonitor.monitor_id}
                signal={dataMonitor.signal}
                threshold={dataMonitor.threshold}
                endpoint={getMonitorEndpoint(dataMonitor)}
                endMS={endMS}
                startMS={startMS}
              />
            )}
          </AkiTile>

          <AkiTile hasHeader spanC={12} spanR={1}>
            <AkiTileHeader>
              <Typography variant="h5">Alert History</Typography>
            </AkiTileHeader>

            <MonitorAlertHistory
              projectID={projectID}
              deploymentID={deploymentID}
              monitorID={monitorID}
            />
          </AkiTile>
        </AkiGridContainer>
      </PageContent>
    </Page>
  );
};
