import { UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import { differenceInHours } from "date-fns";
import { getClosestValueInArray } from "dashboard/utils/numbers";
import { AkitaFetchError, akitaFetch, retry } from "data/akita-fetch";
import { GetTimelineResponse, TimelineAggregation } from "types/akita_api_types";

const DURATION_TO_BUCKET_SIZE: Record<number, [count: number, timeUnit: string]> = {
  0: [1, "minute"],
  1: [2, "minute"],
  3: [5, "minute"],
  6: [5, "minute"],
  12: [10, "minute"],
  24: [1, "hour"],
  168: [2, "hour"],
};

const KNOWN_DURATIONS = [0, 1, 3, 6, 12, 24, 168];

interface GetTimelineParams {
  aggregate?: TimelineAggregation[];
  endMS: number;
  host?: string;
  code?: number;
  key?: string[];
  count?: number;
  method?: string;
  path?: string;
  responseCode?: number;
  startMS: number;
  bucket?: string;
}

const getTimelineQuery = (projectID: string, deploymentID: string, params: GetTimelineParams) => {
  const { count, startMS, endMS, bucket, ...rest } = params;

  let finalBucket = bucket || (count ? `${(endMS - startMS) / count}ms` : undefined);

  if (!finalBucket && endMS && startMS) {
    const difference = differenceInHours(endMS, startMS);
    const closestDuration = getClosestValueInArray(difference, KNOWN_DURATIONS);

    const bucketSize = DURATION_TO_BUCKET_SIZE[closestDuration][0];
    const timeUnit = DURATION_TO_BUCKET_SIZE[closestDuration][1];
    finalBucket = `${bucketSize}${timeUnit[0]}`;
  }

  return akitaFetch<GetTimelineResponse>(`/services/${projectID}/timeline/${deploymentID}/query`, {
    queryParams: {
      ...rest,
      limit: count,
      bucket: finalBucket,
      start: startMS * 1000,
      end: endMS * 1000,
    },
  });
};

export const useTimelineQuery = (
  projectID?: string,
  deploymentID?: string,
  params?: GetTimelineParams,
  options?: {
    refetchOnWindowFocus?: boolean;
    enabled?: boolean;
    keepPreviousData?: boolean;
  }
) =>
  useQuery(
    ["timeline-query", projectID, deploymentID, params],
    () => getTimelineQuery(projectID!, deploymentID!, params!),
    {
      retry,
      keepPreviousData: false,
      enabled: !!projectID && !!deploymentID && !!params,
      ...options,
    }
  );

export const useTimelineQueries = (
  projectID?: string,
  deploymentID?: string,
  queries?: {
    params?: GetTimelineParams;
    options?: { refetchOnWindowFocus?: boolean; enabled?: boolean; keepPreviousData?: boolean };
  }[]
): {
  results: UseQueryResult<GetTimelineResponse, AkitaFetchError>[];
  updateKey: number;
  isLoadingAny: boolean;
  isLoadingAll: boolean;
  isPreviousDataAny: boolean;
  isPreviousDataAll: boolean;
} => {
  const results = useQueries({
    queries:
      queries?.map(({ params, options }) => ({
        queryKey: ["timeline-query", projectID!, deploymentID!, params],
        queryFn: () => getTimelineQuery(projectID!, deploymentID!, params!),
        retry,
        keepPreviousData: false,
        enabled: !!projectID && !!deploymentID && !!params,
        ...options,
      })) ?? [],
  });

  const metadata = results.reduce(
    (
      { updateKey, isLoadingAny, isLoadingAll, isPreviousDataAny, isPreviousDataAll },
      { dataUpdatedAt, isLoading, isPreviousData }
    ) => ({
      updateKey: updateKey + dataUpdatedAt,
      isLoadingAny: isLoadingAny || isLoading,
      isLoadingAll: isLoadingAll && isLoading,
      isPreviousDataAny: isPreviousDataAny || isPreviousData,
      isPreviousDataAll: isPreviousDataAll && isPreviousData,
    }),
    {
      updateKey: 0,
      isLoadingAny: false,
      isLoadingAll: true,
      isPreviousDataAny: false,
      isPreviousDataAll: true,
    }
  );

  return { results, ...metadata };
};
