import { Typography } from "@mui/material";
import React from "react";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";
import { useSearchParam } from "hooks/use-search-param";

export const InternalInferredOrgNameBanner = () => {
  const isEnabledInternalAdminPages = useIsFlagEnabled(FeatureFlag.InternalAdminPagesEnabled);
  const [organizationInferredName] = useSearchParam("organization_inferred_name");

  if (!organizationInferredName || !isEnabledInternalAdminPages) return null;

  return (
    <Typography variant="h4" sx={{ paddingBottom: 2 }}>
      Organization inferred name: {organizationInferredName}
    </Typography>
  );
};
