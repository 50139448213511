import { LinearProgress, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { Deleter } from "dashboard/components/Deleter";
import { CustomTable } from "dashboard/components/Table";
import usePagination, { getLimit, getOffset } from "dashboard/utils/pagination";
import { getModelPathname } from "dashboard/utils/routes";
import {
  ReservedTags,
  filterBigModelTag,
  getBigModelDeployment,
  getTagAsString,
} from "dashboard/utils/tags";
import { useGetFetch } from "hooks/use-get-fetch";
import { GetSpecsResponse } from "types/akita_api_types";

const useStyles = makeStyles((theme) => ({
  container: { padding: theme.spacing(2) },
  noSpecsMessage: {
    paddingTop: theme.spacing(3),
  },
  link: {
    fontSize: 14,
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  diff: {
    marginBottom: theme.spacing(2),
  },
  toggleButtonGroup: {
    marginBottom: theme.spacing(1),
  },
  gridRight: {
    textAlign: "right",
  },
}));

export const SpecsTable = (props: { serviceID: string }) => {
  const classes = useStyles();

  const pagination = usePagination();
  const { page, itemsPerPage } = pagination;

  // Track the total number of specs returned in the paginated response.  Starts as -1 until
  // the user clicks through to the last page.
  const [totalSpecs, setTotalSpecs] = useState(-1);

  const specsURL = `${process.env.REACT_APP_API_URL}/v1/services/${props.serviceID}/specs`;
  const [specsResponse, isSpecsLoading, _listSpecsError, refreshSpecs] =
    useGetFetch<GetSpecsResponse>(
      specsURL,
      { limit: getLimit(pagination), offset: getOffset(pagination) },
      false,
      "fetching specs for your project"
    );

  // When we've reached the last page, compute the total count, which we'll use to disable the "next
  // page" button in the pagination widget.
  useEffect(() => {
    if (specsResponse && specsResponse.last_page) {
      setTotalSpecs(page * itemsPerPage + (specsResponse.specs?.length ?? 0));
    }
  }, [specsResponse, page, itemsPerPage]);

  // Track whether there are specs to display and, if not, why not.
  const defaultNoSpecsMessage = (
    <Typography variant="body2">No models yet for this service.</Typography>
  );
  const [doesHaveSpecs, setHaveSpecs] = useState(false);
  const [noSpecsMessage, setNoSpecsMessage] = useState(defaultNoSpecsMessage);

  // Filter and analyze the specs for this service, if any.
  useEffect(() => {
    // If there are no specs for this service, say that.
    if (!specsResponse || !specsResponse.specs || !specsResponse.specs[0]) {
      setHaveSpecs(false);
      setNoSpecsMessage(defaultNoSpecsMessage);
      return;
    }
    setHaveSpecs(true);
  }, [specsResponse]);

  const getSpecsTableData = () =>
    (specsResponse?.specs ?? []).map((spec) => {
      const modelName = spec.name ? spec.name : spec.id;
      const bigModelName = getBigModelDeployment(spec.version_tags);
      const displayName = bigModelName ? "aggregate model for " + bigModelName : modelName;

      const nameCell = (
        <Link
          href={getModelPathname({ projectID: props.serviceID, modelID: spec.id })}
          className={classes.link}
        >
          {displayName}
        </Link>
      );

      const specStateCell = <Typography variant="body2">{spec.state}</Typography>;

      const lastUpdatedCell = (
        <Typography variant="subtitle2">{new Date(spec.edit_time).toLocaleString()}</Typography>
      );

      const versionCell = (
        <Typography variant="body2">{filterBigModelTag(spec.version_tags).join(", ")}</Typography>
      );

      const deleteCell = (
        <Deleter
          noRefresh
          name={spec.id}
          url={`${process.env.REACT_APP_API_URL}/v1/services/${props.serviceID}/specs/${spec.id}`}
          onSuccess={refreshSpecs}
        />
      );

      const deployment = getTagAsString(spec.tags, ReservedTags.XAkitaDeployment);

      const deploymentCell = deployment ? (
        <Typography variant="body2">{deployment}</Typography>
      ) : (
        <Typography variant="body2">unknown</Typography>
      );

      const startTime = spec.trace_start_time && new Date(spec.trace_start_time).toLocaleString();
      const endTime = spec.trace_end_time && new Date(spec.trace_end_time).toLocaleString();
      const timeCell =
        startTime && endTime ? (
          <Typography variant="subtitle2">
            From: {startTime}
            <br />
            To: {endTime}
          </Typography>
        ) : (
          <Typography variant="subtitle2">unknown</Typography>
        );

      return [
        nameCell,
        <Typography variant="body2" key="2">
          {getTagAsString(spec.tags, ReservedTags.XAkitaSource) || "User"}
        </Typography>,
        deploymentCell,
        specStateCell,
        timeCell,
        lastUpdatedCell,
        versionCell,
        deleteCell,
      ];
    });

  const [specsTableData, setSpecsTableData] = useState(getSpecsTableData());

  // Update table data when spec data changes.
  useEffect(() => {
    setSpecsTableData(getSpecsTableData());
  }, [props.serviceID, specsResponse]);

  const specsTableHead = [
    "Name",
    "Source",
    "Deployment",
    "Status",
    "Time Range",
    "Updated",
    "Version",
    "" /* mark stable, delete */,
  ];

  return (
    <div>
      {isSpecsLoading && <LinearProgress />}
      <div className={classes.container}>
        <Typography variant="h2" gutterBottom>
          API Model History
        </Typography>
        <Typography variant="body2" gutterBottom>
          The Akita agent periodically creates models based on your API traffic. Failed models will
          be listed as “ERROR”.
        </Typography>
        {!isSpecsLoading && doesHaveSpecs && (
          <CustomTable
            tableHead={specsTableHead}
            tableData={specsTableData}
            pagination={{
              ...pagination,
              count: totalSpecs,
            }}
          />
        )}
        {!isSpecsLoading && !doesHaveSpecs && (
          <Typography variant="body2" className={classes.noSpecsMessage}>
            {noSpecsMessage}
          </Typography>
        )}
      </div>
    </div>
  );
};
