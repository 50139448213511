import { Typography } from "@mui/material";
import React from "react";
import { CustomTable } from "dashboard/components/Table";
import { ScheduledTraceSession } from "types/akita_api_types";

type PreviousTraceCardProps = {
  serviceID: string;
  scheduledTraces: ScheduledTraceSession[];
};

export const PreviousTraceCard = (props: PreviousTraceCardProps) => {
  const now = new Date();

  const previousTraces = props.scheduledTraces
    .filter(
      (trace) =>
        // Look for traces in the past
        new Date(trace.end_time).getTime() < now.getTime()
    )
    .map((trace) => ({
      trace_id: trace.trace_id,
      trace_name: trace.trace_name,
      start_time: new Date(trace.start_time),
      end_time: new Date(trace.end_time),
    }));

  // Sort so the most recent is the first
  previousTraces.sort((a, b) => {
    if (a.end_time.getTime() > b.end_time.getTime()) {
      return -1;
    } else if (a.end_time.getTime() < b.end_time.getTime()) {
      return 1;
    } else {
      return 0;
    }
  });

  const prevTableData = previousTraces.map((trace) => [
    trace.trace_name,
    trace.start_time.toLocaleString(),
    trace.end_time.toLocaleString(),
    "",
  ]);

  if (prevTableData.length == 0) {
    return (
      <React.Fragment>
        <Typography variant="h2">Previous traces</Typography>
        <Typography variant="body2">No previously scheduled traces were found.</Typography>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="h2">Previous traces</Typography>
      <CustomTable tableHead={["Trace Name", "Started", "Ended", ""]} tableData={prevTableData} />
    </React.Fragment>
  );
};
