import { Button, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import { AkiBanner } from "dashboard/components/AkiBanner";

export const OverviewEmptyStateBanner = forwardRef(({ projectID }: { projectID?: string }, ref) => (
  <AkiBanner
    ref={ref}
    button={
      <Button
        size="large"
        href={projectID ? `/service/${projectID}/onboarding` : "/onboarding"}
        variant="contained"
        color="secondary"
      >
        Get Started
      </Button>
    }
    marginTop={1}
  >
    <Typography gutterBottom>
      We&rsquo;re showing placeholder data here to give you a glimpse of the kind of insights you
      can get with Akita.
    </Typography>
    <Typography fontWeight="bold">
      Click &ldquo;Get Started&rdquo; to replace this with an overview of your API!
    </Typography>
  </AkiBanner>
));

OverviewEmptyStateBanner.displayName = "OverviewEmptyStateBanner";
