import { LocationDescriptorObject } from "history";

export const getQueryLocation = (
  location: LocationDescriptorObject,
  key: string,
  value: string | string[] | null
): LocationDescriptorObject => {
  const searchParams = new URLSearchParams(location.search);

  // If the given value isn't null, set the search param to that value.
  // If it is null, we'll delete it.
  if (typeof value === "string") {
    searchParams.set(key, value);
  } else if (Array.isArray(value)) {
    // Delete any existing values, since URLSearchParams.append() doesn't overwrite existing values.
    searchParams.delete(key);
    value.forEach((v) => searchParams.append(key, v));
  } else {
    searchParams.delete(key);
  }

  return {
    ...location,
    search: searchParams.toString() ? `?${searchParams.toString()}` : "",
  };
};

export const getLocationWithQueryParams = (
  location: LocationDescriptorObject,
  queryParams: { [key: string]: string | string[] | null }
): LocationDescriptorObject =>
  Object.entries(queryParams).reduce(
    (result, [key, value]) => getQueryLocation(result, key, value),
    location
  );

export const getModelPathname = ({
  deploymentID,
  modelID,
  projectID,
}: {
  deploymentID?: string;
  modelID?: string;
  projectID?: string;
}) => {
  if (projectID && deploymentID) {
    return `/service/${projectID}/deployment/${deploymentID}/model`;
  }

  if (projectID && modelID) {
    return `/service/${projectID}/spec/${modelID}/model`;
  }

  if (projectID) {
    return `/service/${projectID}/model`;
  }

  return "/model";
};

/** Given a Location for a page using EndpointTable query-based routing, returns a new Location with
 * default values for endpoint filters, if they aren't present or explicity unset. If the filters ARE
 * set or explicitly unset, returns null. */
export const getEndpointsTableRedirectLocation = (routeLocation: LocationDescriptorObject) => {
  const filterDefaults: {
    queryParameter: string;
    defaultValue: string | string[];
  }[] = [
    // If the route has no endpoint_categories filter set (including the placeholder
    // value "all"), then default that filter to "none" to filter out health checks etc.
    {
      queryParameter: "filter-endpoint_categories",
      defaultValue: "none",
    },
    // If the route has no http_methods filter set (include the placeholder value
    // "all"), then use a default value that omits CONNECT, HEAD, OPTIONS, and TRACE.
    {
      queryParameter: "filter-http_methods",
      defaultValue: ["GET", "POST", "PUT", "DELETE", "PATCH"],
    },
  ];

  // Apply defaults.
  let redirectLocation: LocationDescriptorObject | undefined;
  filterDefaults.forEach((filterDefault) => {
    if (!routeLocation.search?.includes(filterDefault.queryParameter)) {
      redirectLocation = getQueryLocation(
        redirectLocation ?? routeLocation,
        filterDefault.queryParameter,
        filterDefault.defaultValue
      );
    }
  });

  return redirectLocation ?? null;
};
