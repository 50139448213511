import { clamp, randNumberInRange } from "dashboard/utils/numbers";
import { DisplayTimelineValue, mapToDisplayTimelineValues } from "dashboard/utils/timelines";
import { Timeline } from "types/akita_api_types";

export const makeDemoTimeline = (
  samples: number,
  min = 0,
  max = 10,
  minDelta = -0.5,
  maxDelta = 0.5,
  shouldRound = false
): { x: number; y: number }[] =>
  new Array(samples).fill(undefined).reduce((result, _v, index) => {
    const y =
      index > 0
        ? clamp(
            ((result[index - 1]?.y ?? 0) as number) + randNumberInRange(minDelta, maxDelta),
            min,
            max
          )
        : randNumberInRange(min, max);

    return result.concat({
      x: index + 1,
      y: shouldRound ? Math.round(y) : y,
    });
  }, []);

interface AggregatedTimelines {
  count: DisplayTimelineValue[];
  p90: DisplayTimelineValue[];
  errors: DisplayTimelineValue[];
}

export const aggregateTimelines = (timelines: Timeline[]) => {
  const countMap: Map<string, number> = new Map();
  const p90Map: Map<string, number> = new Map();
  const errorsMap: Map<string, number> = new Map();

  timelines.forEach((timeline) => {
    timeline.events.forEach((bucket) => {
      countMap.set(bucket.time, (countMap.get(bucket.time) ?? 0) + (bucket.values.count ?? 0));

      if (timeline.group_attrs.response_code === 200 && !!bucket.values.latency_90p) {
        p90Map.set(bucket.time, bucket.values.latency_90p);
      }

      if ((timeline.group_attrs.response_code ?? 0) >= 400) {
        errorsMap.set(bucket.time, (errorsMap.get(bucket.time) ?? 0) + (bucket.values.count ?? 0));
      }
    });
  });

  const result: AggregatedTimelines = {
    count: mapToDisplayTimelineValues(countMap),
    p90: mapToDisplayTimelineValues(p90Map),
    errors: mapToDisplayTimelineValues(errorsMap),
  };

  return result;
};
