import React from "react";
import { ModalRoute } from "dashboard/components/ModalRoute";
import { EditMonitorModal } from "dashboard/modals/EditMonitor/EditMonitorModal";
import { EndpointDetailsModalForDeployment } from "dashboard/modals/EndpointDetails/EndpointDetailsModalForDeployment";
import { EndpointDetailsModalForModelID } from "dashboard/modals/EndpointDetails/EndpointDetailsModalForModelID";
import { ModelSettingsModal } from "dashboard/modals/ModelSettings/ModelSettingsModal";
import { NewMonitorModal } from "dashboard/modals/NewMonitor/NewMonitorModal";

/**
 * Most small confirmation dialogs are one-off, incidental pieces of UI that don't need to be
 * stored in the URI; those can be simply managed in state. However, some modals (for example,
 * the Endpoint Details modal) are important and long-lived enough that we want to store them in
 * the URI so that they users can bookmark and share links to the modal, as well as navigate to and
 * from the modal via history.
 *
 * We could handle these modals as normal sub-routes (e.g. /service/spec/endpoint), but that would
 * add complexity to our primary routes, and wouldn't clearly decouple a modal from the page it's
 * overlayed on.
 *
 * Instead, we can store an entirely separate path in the `modal` query parameter, and use that for
 * routing just as we do the primary path. We'll call that the "modalPath".
 *
 * Note that this is just a fragment containing ModalRoute components, which handle the actual work
 * of matching modalPaths. Since this component has no special logic of its own, it's possible to
 * have multiple modals displayed at once if paths aren't exclusive. Please make sure any routes you
 * add here don't overlap with others!
 */
export const ModalRouter = () => (
  <>
    <ModalRoute modalPath="/project/:projectID/model/:modelID/endpoint/:endpointID">
      <EndpointDetailsModalForModelID />
    </ModalRoute>

    <ModalRoute
      // Greedy (`*` suffix) route path, since endpointUniqueID can contain `/` characters
      modalPath="/project/:projectID/deployment/:deploymentID/endpoint/:endpointUniqueID*"
    >
      <EndpointDetailsModalForDeployment />
    </ModalRoute>

    <ModalRoute modalPath="/project/:projectID/model/:modelID/settings">
      <ModelSettingsModal />
    </ModalRoute>

    <ModalRoute modalPath="/project/:projectID/deployment/:deploymentID/monitors/new">
      <NewMonitorModal />
    </ModalRoute>

    <ModalRoute modalPath="/project/:projectID/deployment/:deploymentID/monitors/:monitorID/edit">
      <EditMonitorModal />
    </ModalRoute>
  </>
);
