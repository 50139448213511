import { AkitaErrorCode, akitaErrorOfFailure } from "dashboard/utils/error";

export function acceptInvite(accessToken: string, invitationID: string): Promise<null> {
  const acceptInviteURL = `${process.env.REACT_APP_API_URL}/v1/organization/invites/${invitationID}/accept`;

  return fetch(acceptInviteURL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .catch((err) => {
      throw akitaErrorOfFailure(
        err,
        AkitaErrorCode.PostErr,
        `accepting invite with ID ${invitationID}`
      );
    });
}
