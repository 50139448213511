import { SxProps, Theme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { LastSelectedProjectAndDeploymentKey } from "dashboard/utils/local_storage_keys";
import { prettify } from "dashboard/utils/strings";
import { useCurrentDeploymentId } from "hooks/use-current-deployment-id";
import { useCurrentProjectId } from "hooks/use-current-project-id";
import { GetServicesResponse } from "types/akita_api_types";
import { ProjectAndDeployment } from "types/client_side_util_types";

type DeploymentSelectorProps = {
  projects?: GetServicesResponse;
  projectsAreLoading: boolean;
  sx?: SxProps<Theme>;
};

export const missingDeploymentID = "no-deployment";

export const DeploymentSelector = ({
  projects,
  projectsAreLoading,
  sx,
}: DeploymentSelectorProps) => {
  const navigate = useHistory();
  const location = useLocation();
  const currentProjectId = useCurrentProjectId();
  const currentDeploymentId = useCurrentDeploymentId();

  const deployments =
    projects?.find((project) => project.id === currentProjectId)?.deployments ?? [];

  const navigateToDeployment = (event: SelectChangeEvent<string>) => {
    const newDeploymentId = event.target.value;

    const path = matchPath<{ deploymentId: string }>(location.pathname, {
      path: "/service/:serviceId/deployment/:deploymentId/*",
    });
    const previousDeploymentId = path?.params.deploymentId;
    if (!previousDeploymentId) {
      return console.error("No previous deployment ID", previousDeploymentId);
    }
    const newURL = location.pathname.replace(previousDeploymentId, newDeploymentId);

    if (currentProjectId /* just appeasing the typechecker */) {
      const projectAndDeployment: ProjectAndDeployment = {
        serviceId: currentProjectId,
        deploymentName: newDeploymentId,
      };
      window.localStorage.setItem(
        LastSelectedProjectAndDeploymentKey,
        JSON.stringify(projectAndDeployment)
      );
    }

    navigate.push(newURL);
  };

  // Hide the dropdown if we're loading the projects, or if the only deployment is the default one
  if (projectsAreLoading || (currentDeploymentId === "default" && deployments.length < 2)) {
    return null;
  }

  return (
    <FormControl sx={sx}>
      <Typography variant="h6" id="deployment-selector-label">
        Deployment
      </Typography>
      <Select
        labelId="deployment-selector-label"
        name="deployment-selector"
        onChange={navigateToDeployment}
        size="small"
        value={currentDeploymentId}
      >
        {deployments.map((d) => (
          <MenuItem key={d} value={d}>
            {prettify(d)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
