import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getTime, sub } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";
import { useTimeRangeManager } from "../../../hooks/use-time-range-manager";
import {
  ENDPOINTS_PER_PAGE,
  MetricsAndErrorsEndpointsTable,
} from "./MetricsAndErrorsEndpointsTable";
import { MetricsAndErrorsGraphs } from "./MetricsAndErrorsGraphs";
import { MetricsAndErrorsQuickLinks } from "./MetricsAndErrorsQuickLinks";
import { useEndpointsTableFilters } from "dashboard/components/EndpointsTable/hooks";
import { Page, PageContent, PageHeader, PageTitle } from "dashboard/components/Page";
import { PercentilePicker } from "dashboard/components/PercentilePicker";
import { TimeRangePicker } from "dashboard/components/TimeRangePicker/TimeRangePicker";
import { Percentile } from "dashboard/utils/percentiles";
import { getTimeRangeLabel } from "dashboard/utils/time-range";
import { useTimelineSummary } from "data/queries/timeline-summary";
import { useImpression } from "hooks/use-impression";
import { useLogInteraction } from "hooks/use-log-interaction";
import { usePageIndex } from "hooks/use-page-index";
import { useSearchParamWithDefaultValue } from "hooks/use-search-param-with-default-value";
import { useSortParam } from "hooks/use-sort-param";

export const MetricsAndErrorsPage = () => {
  const theme = useTheme();

  const { serviceID: projectID, deploymentID } = useParams<{
    serviceID: string;
    deploymentID: string;
  }>();

  const logInteraction = useLogInteraction({
    projectID,
    deploymentID,
  });

  const {
    timeRange,
    setTimeRange,
    relativeEndDate,
    changeTimeRangeMode,
    refreshTimeRange,
    absoluteEndDate,
    absoluteStartDate,
  } = useTimeRangeManager({
    initialTimeRange: {
      type: "relative",
      duration: { hours: 12 },
    },
    onLogInteraction: logInteraction,
  });

  // URL-based state
  const [percentile, setPercentile] = useSearchParamWithDefaultValue<Percentile>(
    "percentile",
    "p90"
  );
  const [pageIndex] = usePageIndex();
  const { activeSortKey, activeSortDirection } = useSortParam({
    defaultSortKey: "count",
    defaultSortDirection: "desc",
  });
  const [filters] = useEndpointsTableFilters();

  const {
    data: timelineSummary,
    isLoading: isLoadingEndpoints,
    isPreviousData: isPreviousDataEndpoints,
  } = useTimelineSummary({
    projectID,
    deploymentID,
    timeRange,
    relativeEndDateMS: getTime(relativeEndDate),
    queryParams: {
      key: ["filters"],
      offset: pageIndex * ENDPOINTS_PER_PAGE,
      limit: ENDPOINTS_PER_PAGE,
      sort: `${activeSortKey === "percentile" ? percentile : activeSortKey}:${activeSortDirection}`,
      ...filters,
    },
  });

  useImpression("Metrics & Errors Page", {
    isNewPage: true,
    projectId: projectID,
    deploymentID,
  });

  if (!projectID) {
    return null;
  }

  return (
    <Page sx={{ minHeight: 600 }}>
      <PageHeader>
        <Grid container justifyContent="space-between">
          <Grid item marginBottom={1} marginRight={2}>
            <PageTitle>Metrics & Errors</PageTitle>
            {timeRange.type === "relative" && (
              <Typography variant="h6" fontWeight="normal">
                Last updated: {relativeEndDate.toLocaleTimeString()}
              </Typography>
            )}
            {timeRange.type === "absolute" && (
              <Typography variant="h6" fontWeight="normal">
                {getTimeRangeLabel(timeRange)}
              </Typography>
            )}
          </Grid>

          <Grid
            item
            sx={{
              alignItems: "flex-end",
              display: "flex",

              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              },
            }}
          >
            <PercentilePicker
              percentile={percentile}
              setPercentile={setPercentile}
              sx={{
                paddingRight: 2,

                [theme.breakpoints.down("md")]: {
                  paddingRight: 0,
                  paddingBottom: 1,
                },
              }}
            />

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                paddingLeft: 2,
                borderLeft: `1px solid ${theme.palette.divider}`,
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                  borderLeft: 0,
                  paddingLeft: 0,
                  paddingTop: 1,
                  paddingBottom: 2,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              <TimeRangePicker
                onChange={setTimeRange}
                onModeChange={changeTimeRangeMode}
                onRefresh={refreshTimeRange}
                sx={{ flexGrow: 1 }}
                value={timeRange}
                // Subtracting by just 7 days isn't quite safe, since a page could stay open for a long
                // time and have the time range become invalid. So, we subtract by an extra 12 hours
                // (which we have data for, anyway) to allow for plenty of wiggle room.
                minDateTime={sub(new Date(), { days: 7, hours: 12 }).getTime()}
                maxDateTime={Date.now()}
              />
            </Box>
          </Grid>
        </Grid>
      </PageHeader>

      <PageContent>
        <MetricsAndErrorsQuickLinks
          projectID={projectID}
          deploymentID={deploymentID}
          filterSummary={timelineSummary?.filter_summary}
          percentile={percentile}
          timeRange={timeRange}
        />

        <MetricsAndErrorsGraphs
          projectID={projectID}
          deploymentID={deploymentID}
          percentile={percentile}
          endMS={getTime(absoluteEndDate)}
          startMS={getTime(absoluteStartDate)}
          isLoadingEndpoints={isLoadingEndpoints || isPreviousDataEndpoints}
        />

        <MetricsAndErrorsEndpointsTable
          percentile={percentile}
          projectID={projectID}
          deploymentID={deploymentID}
          timelineSummary={timelineSummary}
          isLoading={isLoadingEndpoints}
          isPreviousData={isPreviousDataEndpoints}
        />
      </PageContent>
    </Page>
  );
};
