import { Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, TextField } from "@mui/material";
import type { ChatCompletionRequestMessage } from "openai";
import React, { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { QueryError } from "../QueryError";
import { AkiChatMessage } from "./AkiChatMessage";
import { AkiChatTerms } from "./AkiChatTerms";
import { getIsAkiChatTermsAcceptedInStorage } from "dashboard/utils/aki-chat";
import { AkitaFetchError } from "data/akita-fetch";
import { useAkiChat } from "data/queries/aki-chat";
import { useImpression } from "hooks/use-impression";
import { useLogInteraction } from "hooks/use-log-interaction";

type AkiChatProps = {
  apiContext?: Record<string, string | undefined>;
  initialPromptLength: number;
  messages: ChatCompletionRequestMessage[];
  setMessages: (messages: ChatCompletionRequestMessage[]) => void;
};

export const AkiChat = ({
  apiContext,
  initialPromptLength,
  messages,
  setMessages,
}: AkiChatProps) => {
  const logInteraction = useLogInteraction(apiContext);
  const [response, setResponse] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(getIsAkiChatTermsAcceptedInStorage());

  const {
    data: dataCompletions,
    isFetching,
    error,
    refetch,
  } = useAkiChat(messages, { context: apiContext, enabled: isTermsAccepted });

  const allMessages = useMemo(() => {
    if (dataCompletions?.choices[0]?.message) {
      return messages.concat(dataCompletions.choices[0].message);
    }

    return messages;
  }, [messages, dataCompletions]);

  const displayMessages = allMessages.slice(initialPromptLength);

  const onSubmitResponse = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (response.length < 1) return;

      setMessages(allMessages.concat({ role: "user", content: response }));
      setResponse("");

      logInteraction("Sent message to Aki");
    },
    [allMessages, response, setMessages, logInteraction]
  );

  const onChangeResponse = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setResponse(event.target.value);
  }, []);

  const hasMaxTokensError = (error as AkitaFetchError)?.response?.status === 400;

  useImpression("Aki Chat", apiContext);

  if (!isTermsAccepted) {
    return <AkiChatTerms setIsTermsAccepted={setIsTermsAccepted} />;
  }

  return (
    <Box>
      {displayMessages.map((message, index) => (
        <AkiChatMessage key={index} message={message} />
      ))}

      {isFetching && <AkiChatMessage isLoading />}

      {hasMaxTokensError ? (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          You&rsquo;ve reached the message limit. Please close and reopen the endpoint details to
          restart the conversation.
        </Alert>
      ) : (
        <QueryError error={error} refetch={refetch} label="We had trouble loading your messages." />
      )}

      <Box
        component="form"
        onSubmit={onSubmitResponse}
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        })}
      >
        <TextField
          onChange={onChangeResponse}
          value={response}
          fullWidth
          placeholder="Ask Aki anything"
          autoFocus
        />

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isFetching || response.length < 1}
          loading={isFetching}
          sx={(theme) => ({
            marginLeft: 1,
            height: 56,
            [theme.breakpoints.down("md")]: {
              marginLeft: 0,
              marginTop: 1,
              width: "100%",
            },
          })}
          endIcon={<Send />}
        >
          Send
        </LoadingButton>
      </Box>
    </Box>
  );
};
