import React from "react";
import { useHistory } from "react-router-dom";

type OktaErrorRedirectProps = {
  error: any;
};

export const OktaErrorRedirect = ({ error }: OktaErrorRedirectProps) => {
  const navigate = useHistory();
  const errString = error.toString();

  if (errString === "OAuthError: User is not assigned to the client application.") {
    navigate.push("/login?status=notregistered");
  } else {
    navigate.push("/login?status=error");
  }

  return <div />;
};
