export enum AkitaErrorCode {
  DeleteErr = "DELETE_ERR",
  FetchErr = "FETCH_ERR",
  PatchErr = "PATCH_ERR",
  PostErr = "POST_ERR",
  PutErr = "PUT_ERR",
  Internal = "INTERNAL_ERR",
}

export type AkitaError = {
  // Akita-specific error code, like AWS error codes.
  code: AkitaErrorCode;

  // Underlying error.
  error: Error;

  customerMessage: string;

  // Request ID to the backend that caused this error, if present.
  requestID?: string;

  // HTTP status code, if present.
  status?: number;

  // HTTP status, if present.
  statusText?: string;
};

export function akitaError(
  code: AkitaErrorCode,
  error: Error,
  customerMessage: string
): AkitaError {
  return { code, error, customerMessage };
}

export function akitaErrorOfFailure(
  failReason: any,
  errorCode: AkitaErrorCode,
  errorMsg: string
): Promise<AkitaError> {
  const customerMessage = `We had trouble ${errorMsg}.  Please try again soon!`;

  if (failReason instanceof Error) {
    const akErr = akitaError(errorCode, failReason, customerMessage);
    return Promise.resolve(akErr);
  } else if (failReason instanceof Response) {
    const akErr = akitaError(errorCode, new Error("non-2xx response code"), customerMessage);

    akErr.status = failReason.status;
    akErr.statusText = failReason.statusText;

    const contentType = failReason.headers.get("content-type");

    // Use includes to guard against bad backend sending content-type twice,
    // resulting in "application/json, application/json"
    if (contentType && contentType.includes("application/json")) {
      return failReason.json().then((errorBody) => {
        if ("request_id" in errorBody && errorBody.request_id !== "") {
          akErr.requestID = errorBody.request_id;
        }
        if ("message" in errorBody && errorBody.message !== "") {
          akErr.error = new Error(errorBody.message);
        }
        return akErr;
      });
    }
    return Promise.resolve(akErr);
  }

  const akErr = akitaError(errorCode, new Error(`fetch failed: ${failReason}`), customerMessage);
  return Promise.resolve(akErr);
}
