import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { AkitaUserInfo } from "../types/akita_api_types";
import { UserInfo } from "../types/okta_types";
import { useAnalyticsContext } from "./use-analytics-context";

/**
 * useUserIdentifier identifies the current user in integrations such as Segment and LaunchDarkly.
 *
 * @param userInfo
 * @param akitaUserInfo
 */
export const useUserIdentifier = (userInfo?: UserInfo, akitaUserInfo?: AkitaUserInfo) => {
  const { analytics } = useAnalyticsContext();

  const launchDarklyClient = useLDClient();

  useEffect(() => {
    if (analytics && userInfo && userInfo.email && akitaUserInfo) {
      const traits = {
        akitaUserID: akitaUserInfo.id,
        name: akitaUserInfo.name,
        email: userInfo.email,
        organizationID: akitaUserInfo.organization_id,
      };

      void analytics.identify(userInfo.email, traits);
    }
  }, [akitaUserInfo, analytics, userInfo]);

  useEffect(() => {
    if (launchDarklyClient && userInfo && userInfo.email && akitaUserInfo) {
      const user = {
        key: akitaUserInfo.id,
        name: akitaUserInfo.name,
        firstName: userInfo.given_name,
        lastName: userInfo.family_name,
        email: userInfo.email,
        custom: {
          organizationID: akitaUserInfo.organization_id,
        },
      };
      void launchDarklyClient.identify(user);
    }
  }, [launchDarklyClient, userInfo, akitaUserInfo]);
};
