import { DialogContent, DialogContentProps } from "@mui/material";
import React, { ReactNode } from "react";
import { mergeSxProps } from "dashboard/utils/sx";

type AkiDialogContentProps = Omit<DialogContentProps, "children"> & {
  children: ReactNode;
};

export const AkiDialogContent = ({ sx, children, ...rest }: AkiDialogContentProps) => (
  <DialogContent
    sx={mergeSxProps(
      { padding: 2, borderTop: (theme) => `1px solid ${theme.palette.divider}` },
      sx
    )}
    {...rest}
  >
    {children}
  </DialogContent>
);
