import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UseSearchParamOptions } from "./use-search-param";
import { getQueryLocation } from "dashboard/utils/routes";

/**
 * Hook that reads and writes an array from the given search parameter key in a URL, while preserving
 * other search parameters.
 *
 * Optionally takes a placeholder value, which will be ignored if present in the query string, and
 * set in the query string when this key's value is cleared.
 */
export const useSearchParamArray = <T extends string[] = string[]>(
  key: string,
  placeholder?: string
): [
  value: T,
  setValue: (newValue: T | null, options?: UseSearchParamOptions) => void,
  isPlaceholder: boolean
] => {
  const location = useLocation();
  const history = useHistory();

  const [value, isPlaceholder] = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    const paramsArray = searchParams.getAll(key);
    const isOnlyPlaceholderInArray = paramsArray.length === 1 && paramsArray[0] === placeholder;
    const filteredParamsArray = paramsArray.filter((v) => v !== placeholder) as T;

    return [filteredParamsArray, isOnlyPlaceholderInArray];
  }, [location.search, key, placeholder]);

  const setValue = useCallback(
    (newValue: T | null, { replace }: UseSearchParamOptions = {}) => {
      const newLocation = getQueryLocation(
        // We need to access the fresh location object from history.location instead of the return
        // value of useLocation here, so that multiple search params can be changed in a single event
        // handler without overwriting each other.
        history.location,
        key,
        isEmpty(newValue) ? placeholder ?? null : newValue
      );

      if (replace) {
        history.replace(newLocation);
      } else {
        history.push(newLocation);
      }
    },
    [history, key, placeholder]
  );

  return useMemo(() => [value, setValue, isPlaceholder], [value, setValue, isPlaceholder]);
};
