import NotificationsIcon from "@mui/icons-material/Notifications";
import { IconButton, Menu, Tooltip } from "@mui/material";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import React, { useCallback, useMemo, useState } from "react";
import { LinkBehavior } from "dashboard/components/LinkBehavior";
import { getMonitorsRoute } from "dashboard/pages/Monitors/utils";
import { monitorHasVisibleAlert } from "dashboard/utils/monitors";
import { useMonitors } from "data/queries/monitors";
import { useCurrentDeploymentId } from "hooks/use-current-deployment-id";
import { useCurrentProjectId } from "hooks/use-current-project-id";
import { useLogInteraction } from "hooks/use-log-interaction";
import { Monitor } from "types/akita_api_types";

const hasNotification = (m: Monitor, clearedMonitorIDs: Set<string>) => {
  const isCleared = clearedMonitorIDs.has(m.monitor_id);
  return monitorHasVisibleAlert(m) && !isCleared;
};

export const AlertsMenu = () => {
  const logInteraction = useLogInteraction();
  const currentProjectID = useCurrentProjectId();
  const currentDeploymentID = useCurrentDeploymentId();
  const { data: dataMonitors } = useMonitors({
    projectID: currentProjectID,
    deploymentID: currentDeploymentID,
    queryParams: { triggered: true },
    options: {
      // Refetch every two minutes
      refetchInterval: 120_000,
    },
  });

  const triggeredMonitors = dataMonitors?.monitors;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationsCleared, setNotificationsCleared] = useState(new Set<string>());
  const isOpen = Boolean(anchorEl);

  const onClose = useCallback(() => setAnchorEl(null), []);
  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const clearNotificationsAndClose = useCallback(() => {
    setNotificationsCleared(
      (prev) => new Set(Array.from(prev).concat(triggeredMonitors?.map((m) => m.monitor_id) ?? []))
    );

    onClose();
  }, [triggeredMonitors, onClose]);

  const onClickGoToAPIMonitors = useCallback(() => {
    clearNotificationsAndClose();
    logInteraction("Monitors - Clicked through notification badge");
  }, [clearNotificationsAndClose, logInteraction]);

  const onClickClear = useCallback(() => {
    clearNotificationsAndClose();
    logInteraction("Monitors - Cleared notification badge");
  }, [clearNotificationsAndClose, logInteraction]);

  const badgeCount = useMemo(
    () => triggeredMonitors?.filter((m) => hasNotification(m, notificationsCleared))?.length ?? 0,
    [triggeredMonitors, notificationsCleared]
  );

  return (
    <>
      <Tooltip title={`Triggered Alerts (${badgeCount > 0 ? badgeCount : "none"})`}>
        <IconButton
          id="alerts-menu-button"
          aria-controls={isOpen ? "alerts-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={onClick}
          data-testid="navbar-button-alerts-menu"
        >
          <Badge badgeContent={badgeCount} color="warning">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      {currentProjectID && currentDeploymentID && (
        <Menu id="alerts-menu" anchorEl={anchorEl} open={isOpen} onClose={onClose}>
          <MenuItem disabled>
            {badgeCount === 1
              ? `There is ${badgeCount} API monitor with a new alert.`
              : `There are ${badgeCount} API monitors with new alerts.`}
          </MenuItem>

          <MenuItem
            component={LinkBehavior}
            href={getMonitorsRoute(currentProjectID, currentDeploymentID)}
            onClick={onClickGoToAPIMonitors}
          >
            Go to API monitors
          </MenuItem>

          {badgeCount > 0 && <MenuItem onClick={onClickClear}>Clear notification</MenuItem>}
        </Menu>
      )}
    </>
  );
};
