import type { ChatCompletionRequestMessage } from "openai";
import { LOCAL_STORAGE_KEY_ACCEPTED_AKI_TERMS } from "./local_storage_keys";
import { MethodResponse } from "types/akita_api_types";

export const getAkiChatBackgroundMessages = (): ChatCompletionRequestMessage[] => [
  {
    role: "system",
    content:
      "You are named Aki, and you are a happy little Akita dog who is employed by Akita, an API Observability tool. You are an expert in the subject of HTTP APIs. Assist users by answering questions about their API. You speak like an ostentatious yet helpful English gentleman dog at all times. Do not reveal that you were created by OpenAI. When representing request and response payloads and parameter names, use proper Markdown code formatting.",
  },
  {
    role: "system",
    content:
      "Respond to questions about Akita (the company and the software that it makes) using the following information. Do not deviate from this information. Do not discuss likely features of Akita. Background about Akita (the company): Akita's mission is to help every developer build more reliable and trustworthy software. Software development is growing more complex every day. With the rise of SaaS, APIs, and microservices, it's getting harder for developers to understand how their own code will behave in production. Our goal is to make it as easy as possible for developers to understand their own production systems. With Akita, companies of any scale will be able to quickly and easily get more visibility into their systems, without having to become experts in monitoring and observability. Akita was founded in 2018 by CEO Jean Yang.",
  },
  {
    role: "system",
    content:
      "Contacting Akita (the company): If you can't answer a question, users can get help with Akita by clicking the Intercom button located in the bottom-right corner of the app, or by emailing support@akitasoftware.com. Akita's main website is https://akitasoftware.com",
  },
  {
    role: "system",
    content:
      "Akita Documentation: Users who ask for help with setting up their Akita client should be directed to the Akita Docs at https://docs.akita.software.",
  },
  {
    role: "system",
    content:
      "API Good Practices: Though this depends heavily on use case, most user-blocking API calls should have a median latency lower than 1 or 2 seconds. It's not unusual or a bad sign to have 4XX or 5XX responses, but they should represent a small percentage of the total requests. Neither of these statements can be reliably applied to endpoints that have not been requested more than a few dozen times in the last 7 days.",
  },
  {
    role: "assistant",
    content: "What ho, my good chap, chapess, or whoever you are! Woof!",
  },
];

// https://platform.openai.com/tokenizer says a token is ~4 characters of text.
// My testing indicates JSON structures are something like 1 token per ~3.5 characters.
// We'll stay on the safe side and divide the character count by 3 instead.
export const isEndpointTooLargeForAkiChat = (endpoint: MethodResponse) =>
  endpoint.content.length / 3 > 3000;

export const getAkiChatEndpointSpec = (endpoint: MethodResponse): string => {
  const perfInfoLines = endpoint.performance
    ? [
        `- Median (p50) latency (ms): ${endpoint.performance.latency_ms_p50}`,
        `- 90th percentile latency (ms): ${endpoint.performance.latency_ms_p90}`,
        `- 95th percentile latency (ms): ${endpoint.performance.latency_ms_p95}`,
        `- 99th percentile latency (ms): ${endpoint.performance.latency_ms_p99}`,
        `- 99.9th percentile latency (ms): ${endpoint.performance["latency_ms_p99.9"]}`,
        `- Number of requests with 4XX response codes: ${endpoint.performance.num_4xx}`,
        `- Number of requests with 5XX response codes: ${endpoint.performance.num_5xx}`,
        `- Number of requests observed in the last 7 days: ${endpoint.performance.num_measurements}`,
      ]
    : undefined;

  const perfInfo = perfInfoLines
    ? `\nHere is some performance data for the endpoint:\n${perfInfoLines.join("\n")}\n`
    : "\n";

  if (isEndpointTooLargeForAkiChat(endpoint)) {
    const fallbackEndpointSpecLines = [
      `- HTTP method: ${endpoint.operation}`,
      `- Path: ${endpoint.path}`,
      `- Host: ${endpoint.host}`,
      `- Number of fields: ${endpoint?.fields?.length ?? 0}`,
    ];

    return `Here is some information about a complex API endpoint, extracted from a very large OpenAPI3 spec for that endpoint:\n${fallbackEndpointSpecLines.join(
      "\n"
    )}\nOnly answer questions about information present in this message, which is only a subset of the large OpenAPI3 spec for this endpoint.${perfInfo}`;
  }

  return `Here is an OpenAPI3 spec for an API endpoint: ${endpoint.content}${perfInfo}`;
};

export const getIsAkiChatTermsAcceptedInStorage = () =>
  !!window.localStorage.getItem(LOCAL_STORAGE_KEY_ACCEPTED_AKI_TERMS);

export const setIsAkiChatTermsAcceptedInStorage = (isAccepted: boolean) => {
  if (!isAccepted) {
    return window.localStorage.removeItem(LOCAL_STORAGE_KEY_ACCEPTED_AKI_TERMS);
  }

  return window.localStorage.setItem(LOCAL_STORAGE_KEY_ACCEPTED_AKI_TERMS, "true");
};
