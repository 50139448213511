import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { CSSProperties, ReactNode } from "react";
import aki from "assets/img/aki.png";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  icon: {
    maxWidth: 100,
    margin: "0 auto",
    display: "block",
  },
  title: {
    marginTop: 20,
    fontSize: 28,
    fontWeight: 700,
  },
  subTitle: {
    marginTop: 8,
    fontSize: 14,
  },
}));

interface EmptyProps {
  title: ReactNode;
  subTitle?: ReactNode;
  height?: CSSProperties["height"];
}

export const Empty = ({ title, subTitle, height }: EmptyProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ height }} id="empty-component">
      <img className={classes.icon} src={aki} />
      <Typography variant="h1">{title}</Typography>
      {subTitle}
    </div>
  );
};
