import { Alert, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import React from "react";
import { ApiKeysTable } from "./ApiKeysTable";
import { NewApiKey } from "./NewApiKey";
import { useAPIKeys } from "data/queries/api-keys";
import { useImpression } from "hooks/use-impression";

export const APIKeys = () => {
  const { data: apiKeys, isLoading, isError } = useAPIKeys();

  useImpression("Settings - API Keys Tab", { apiKeysCount: apiKeys?.length });

  return (
    <div>
      {isLoading && <LinearProgress />}
      <Box padding={3}>
        <Typography variant="h2" gutterBottom>
          API Keys
        </Typography>

        <Box marginBottom={2}>
          <NewApiKey />
        </Box>

        {isError && (
          <Alert severity="error">
            Encountered an error while trying to load your API Keys. Please try again.
          </Alert>
        )}
        {!isLoading && <ApiKeysTable apiKeys={apiKeys ?? []} />}
      </Box>
    </div>
  );
};
