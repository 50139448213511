import {
  Alert,
  Button,
  Link,
  Menu,
  MenuItem,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useMemo } from "react";
import { OrganizationDetails } from "types/akita_api_types";

interface InternalOrgProjectsTableProps {
  sx?: SxProps<Theme>;
  organization: OrganizationDetails;
}

interface ServiceLinkMenuProps {
  text: string;
  pathFormat: string;
  deployments: string[];
}

const ServiceLinkMenu = ({ text, pathFormat, deployments }: ServiceLinkMenuProps) => {
  if (deployments.length == 1) {
    return (
      <Button component={Link} href={pathFormat.replace("{deployment}", deployments[0])}>
        {text}
      </Button>
    );
  }
  return (
    <PopupState variant="popover" popupId={text}>
      {(popupState) => (
        <React.Fragment>
          <Button {...bindTrigger(popupState)}>{text}</Button>
          <Menu {...bindMenu(popupState)}>
            {deployments.map((deployment) => (
              <MenuItem
                key={deployment}
                component={Link}
                href={pathFormat.replace("{deployment}", deployment)}
              >
                {deployment}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export const InternalOrgProjectsTable = ({ sx, organization }: InternalOrgProjectsTableProps) => {
  const projects = organization.services;
  const inferredNameParamSuffix = organization.inferred_name
    ? "?organization_inferred_name=" + encodeURIComponent(organization.inferred_name)
    : "";

  const rows = useMemo(
    () =>
      projects.map((project) => {
        const createdAtDate = new Date(project.created_at);

        let deployments: string[] = ["default"];
        if (project.deployments.length > 0) {
          deployments = project.deployments;
        }

        return (
          <TableRow hover key={project.id}>
            <TableCell>
              <Typography component="span" fontWeight="bold" variant="body1">
                {project.name}
              </Typography>
              <br />
              <Typography component="span" variant="body2">
                {project.id}
              </Typography>
            </TableCell>
            <TableCell>{format(createdAtDate, "PPpp")}</TableCell>
            <TableCell align="right">
              <ServiceLinkMenu
                text="Model"
                pathFormat={`/service/${project.id}/deployment/{deployment}/model${inferredNameParamSuffix}`}
                deployments={deployments}
              />
              <br />
              <ServiceLinkMenu
                text="Metrics"
                pathFormat={`/service/${project.id}/deployment/{deployment}/monitoring${inferredNameParamSuffix}`}
                deployments={deployments}
              />
            </TableCell>
          </TableRow>
        );
      }),
    [projects, inferredNameParamSuffix]
  );

  if (projects.length === 0)
    return (
      <Alert sx={{ marginTop: 2 }} severity="info">
        No Projects
      </Alert>
    );

  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name (ID)</TableCell>
            <TableCell>Created</TableCell>
            <TableCell align="right">Links</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};
