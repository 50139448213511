import { TextField, TextFieldProps } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  add,
  getTime,
  isAfter,
  isBefore,
  isValid as isValidDate,
  max,
  min,
  parse,
  sub,
} from "date-fns";
import React, { useCallback, useMemo } from "react";
import { TimeRangeAbsolute } from "dashboard/utils/time-range";

const INPUT_FORMAT = "P hh:mm a";

const safeParseDateString = (str: string) => {
  try {
    return parse(str, INPUT_FORMAT, new Date());
  } catch {
    return null;
  }
};

const checkIfValidDate = (
  value: Date | number | null,
  minDate: Date | number,
  maxDate: Date | number
): value is number | Date =>
  !!value && isValidDate(value) && !isBefore(value, minDate) && !isAfter(value, maxDate);

type AbsoluteDateTimePickerProps = {
  maxDateTime: number;
  minDateTime: number;
  onChange: (newValue: TimeRangeAbsolute) => void;
  side: "start" | "end";
  size?: "small" | "medium";
  value: TimeRangeAbsolute;
};

export const AbsoluteDateTimePicker = ({
  maxDateTime,
  minDateTime,
  onChange,
  side,
  size = "medium",
  value,
}: AbsoluteDateTimePickerProps) => {
  const minDateTimeForSide = useMemo(
    () =>
      side === "start"
        ? minDateTime
        : getTime(max([add(value.interval.start, { minutes: 15 }), minDateTime])),
    [side, minDateTime, value.interval.start]
  );

  const maxDateTimeForSide = useMemo(
    () =>
      side === "start"
        ? getTime(min([sub(value.interval.end, { minutes: 15 }), maxDateTime]))
        : maxDateTime,
    [side, maxDateTime, value.interval.end]
  );

  const onChangeDateTime = useCallback(
    (newValue: number | Date | null) => {
      // Only call onChange if the date is valid for the current interval value.
      if (checkIfValidDate(newValue, minDateTimeForSide, maxDateTimeForSide)) {
        onChange({ ...value, interval: { ...value.interval, [side]: getTime(newValue) } });
      }
    },
    [maxDateTimeForSide, minDateTimeForSide, onChange, value, side]
  );

  const renderInput = useCallback(
    (props: TextFieldProps) => {
      // The DateTimePicker component only performs validation on (and displays an error state for)
      // the `value` prop that we pass it. It does not perform validation on its internal text field
      // value, which is a plain string.
      //
      // Because we don't call `onChange` for invalid dates, and we prevent the user from clicking on
      // invalid dates and times in the UI, the `value` prop we pass in will only rarely be invalid.
      // However, because the internal text field value is unvalidated, the user can type an invalid
      // datetime into the input and would have no indication that something is wrong, aside from, for
      // example, a timeline not updating.
      //
      // To avoid this, we can attempt to parse the string here whenever the input is re-rendered,
      // and check if it is valid. If it's not, we'll tell the TextField to display an error state.
      const parsedValue = safeParseDateString(props.inputProps?.value);
      const isValueValid = checkIfValidDate(parsedValue, minDateTimeForSide, maxDateTimeForSide);

      return (
        <TextField
          {...props}
          size="small"
          variant="outlined"
          error={props.error || !isValueValid}
          inputProps={{
            ...props.inputProps,
            style: {
              ...props.inputProps?.style,
              textTransform: "uppercase",
              // It's difficult to theme these MUI-x components, so we'll manually do this to conform
              // to the same behavior as Buttons and Selects.
              ...(size === "small" && { height: 23, padding: "6.5px 14px", fontSize: 14 }),
            },
          }}
        />
      );
    },
    [minDateTimeForSide, maxDateTimeForSide, size]
  );

  return (
    <DateTimePicker
      label={side === "start" ? "Start" : "End"}
      inputFormat={INPUT_FORMAT}
      minDateTime={minDateTimeForSide}
      maxDateTime={maxDateTimeForSide}
      renderInput={renderInput}
      value={value.interval[side]}
      onChange={onChangeDateTime}
    />
  );
};
