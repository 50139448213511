export const MONITOR_ROWS_PER_PAGE = 20;

export type MonitorsPageTab = "enabled" | "recent";

export const getMonitorsRoute = (projectID: string, deploymentID: string) =>
  `/service/${projectID}/deployment/${deploymentID}/performance-alerts`;

export interface SlackInstallationState {
  isInstalled: boolean;
  configurationURL: string | undefined;
}

export const defaultSlackInstallationState = (): SlackInstallationState => ({
  isInstalled: false,
  configurationURL: undefined,
});
