import { SxProps, TableHead, TableHeadProps, TableRow, Theme } from "@mui/material";
import React from "react";
import { mergeSxProps } from "dashboard/utils/sx";

type AkiTableHeadProps = TableHeadProps & {
  sxRow?: SxProps<Theme>;
};

/**
 * Most Table Headers contain only the child `TableRow`, so this component wraps its children in
 * both a `TableHead` and a `TableRow` component. Also applies a overlay background color to
 * differentiate the header from the rest of the table.
 */
export const AkiTableHead = ({ children, sx, sxRow, ...rest }: AkiTableHeadProps) => (
  <TableHead
    sx={mergeSxProps({ backgroundColor: (theme) => theme.palette.overlay.min }, sx)}
    {...rest}
  >
    <TableRow sx={sxRow}>{children}</TableRow>
  </TableHead>
);
