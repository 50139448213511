import { Dialog, DialogProps } from "@mui/material";
import React, { ReactNode, useMemo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { DialogContext } from "contexts/dialog-context";
import { mergeSxProps } from "dashboard/utils/sx";

const ALIGN_PROP_TO_FlEX_ALIGN_VALUE = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
};

const ALIGN_PROP_TO_VERTICAL_ALIGN_VALUE = {
  top: "top",
  center: "middle",
  bottom: "bottom",
};

export type AkiDialogProps = Omit<DialogProps, "open" | "children" | "onClose"> & {
  children: ReactNode;
  onClose: () => void;
  align?: "top" | "center" | "bottom";
};

export const AkiDialog = ({ children, onClose, align, sx, ...rest }: AkiDialogProps) => {
  // Get a unique ID that stays the same for the lifetime of the component.
  const { current: titleID } = useRef(uuidv4());

  const contextValue = useMemo(() => ({ titleID, onClose }), [onClose, titleID]);

  return (
    <Dialog
      open
      aria-labelledby={titleID}
      onClose={onClose}
      sx={mergeSxProps(
        {
          // Annoyingly, MUI uses two different display modes depending on the value of the `scroll`
          // prop. This first one is for the "paper" mode (the default).
          "& .MuiDialog-container": {
            alignItems: align ? ALIGN_PROP_TO_FlEX_ALIGN_VALUE[align] : undefined,
          },
          // And this second one is for the "body" mode.
          "& .MuiDialog-scrollBody > *": {
            verticalAlign: align ? ALIGN_PROP_TO_VERTICAL_ALIGN_VALUE[align] : undefined,
          },
        },
        sx
      )}
      {...rest}
    >
      <DialogContext.Provider value={contextValue}>{children}</DialogContext.Provider>
    </Dialog>
  );
};
