import { datadogRum } from "@datadog/browser-rum";
import { useOktaAuth } from "@okta/okta-react";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useUserIdentifier } from "../../hooks/use-user-identifier";
import { AuthContext } from "contexts/auth-context";
import { AkitaUserInfo } from "types/akita_api_types";
import { UserInfo } from "types/okta_types";

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { authState, oktaAuth } = useOktaAuth();

  // Akita backend user state
  const [akitaUserInfo, setAkitaUserInfo] = useState<AkitaUserInfo | undefined>();

  // Okta user info
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>();

  useUserIdentifier(userInfo, akitaUserInfo);

  useEffect(() => {
    const fetchData = async () => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUserInfo(undefined);
        setAkitaUserInfo(undefined);
      } else {
        const info = await oktaAuth.getUser();
        if (info) {
          setUserInfo(info);

          // and in Datadog RUM
          datadogRum.setUser({
            email: info.email,
            name: info.name,
          });
        }
      }
    };
    void fetchData();
  }, [authState, authState?.isAuthenticated, oktaAuth, oktaAuth.token]);

  // Redirect to login on session timeout.  Using `authService.login()`
  // causes Okta to redirect back to this page on successful login.
  useEffect(() => {
    if (!authState || !authState.isAuthenticated || !authState.accessToken) {
      void oktaAuth.signInWithRedirect();
    }
  }, [authState, authState?.isAuthenticated, oktaAuth]);

  // Once the Akita user info is set, add the user's organizationId to all events.
  useEffect(() => {
    if (!akitaUserInfo) return;

    datadogRum.setUserProperty("id", akitaUserInfo.id);
    datadogRum.setUserProperty("organizationID", akitaUserInfo.organization_id);
  }, [akitaUserInfo]);

  const value = useMemo(
    () => ({
      akitaUserInfo,
      authService: oktaAuth,
      authState: authState ?? undefined,
      isValidUser: !!(userInfo && akitaUserInfo),
      setAkitaUserInfo,
      userInfo,
    }),
    [akitaUserInfo, oktaAuth, authState, setAkitaUserInfo, userInfo]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
