import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";
import React, { useEffect, useMemo, useState } from "react";
import { AnalyticsContext } from "contexts/analytics-context";

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}: AnalyticsProviderProps) => {
  const writeKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;
  const isDebugEnabled = process.env.REACT_APP_SEGMENT_DEBUG === "true";

  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);

  // TODO: Get rid of wrapper object
  const value = useMemo(() => ({ analytics }), [analytics]);

  useEffect(() => {
    const loadAnalytics = async () => {
      if (!writeKey || analytics) return;

      const [response] = await AnalyticsBrowser.load({ writeKey });

      response.debug(isDebugEnabled);

      setAnalytics(response);
    };

    void loadAnalytics();
  }, [writeKey, analytics, isDebugEnabled]);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};
