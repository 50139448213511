// Akita-specific tags.  See here for the canonical list:
import { Tags } from "types/akita_api_types";

// https://github.com/akitasoftware/akita-libs/blob/59953f825619e94088a07d92b29c3e3e6147f497/tags/reserved_tags.go
export enum ReservedTags {
  // Identifies the source of a trace or spec. See `Source` for values.
  XAkitaSource = "x-akita-source",

  // The original filesystem path of an uploaded trace.
  XAkitaTraceLocalPath = "x-akita-trace-local-path",

  // Identifies the CI framework from which a trace or spec was obtained (e.g.,
  // CircleCI, Travis).
  XAkitaCI = "x-akita-ci",

  // Each model derived from a PR (or MR) is automatically diffed against a
  // baseline spec. This tag identifies the AKID for that baseline spec.
  // Attached to specs.
  XAkitaComparedWith = "x-akita-compared-with",

  // Each model derived from a PR (or MR) is automatically diffed against a
  // baseline spec. This tag identifies the number of differences that were
  // found in this diff.
  XAkitaNumDifferences = "x-akita-num-differences",

  // The contents of the CIRCLE_BUILD_URL environment variable. Attached to
  // traces and specs derived from a CircleCI job.
  XAkitaCircleCIBuildURL = "x-akita-circleci-build-url",

  // The contents of the TRAVIS_BUILD_WEB_URL environment variable. Attached to
  // traces and specs derived from a Travis job.
  XAkitaTravisBuildWebURL = "x-akita-travis-build-web-url",

  // The contents of the TRAVIS_JOB_WEB_URL environment variable. Attached to
  // traces and specs derived from a Travis job.
  XAkitaTravisJobWebURL = "x-akita-travis-job-web-url",

  // Identifies the git branch from which the trace or spec was derived.
  // Attached to traces or specs obtained from CI.
  XAkitaGitBranch = "x-akita-git-branch",

  // Identifies the git commit hash from which the trace or spec was derived.
  // Attached to traces or specs obtained from CI.
  XAkitaGitCommit = "x-akita-git-commit",

  // A link to the git repository. Attached to traces or specs obtained from a
  // pull/merge request.
  // DEPRECATED.
  // XAkitaGitRepoURL = "x-akita-git-repo-url",

  // Identifies the GitHub PR number associated with the pull request. Attached
  // to traces or specs obtained from a GitHub pull request.
  XAkitaGitHubPR = "x-akita-github-pr",

  // A link to the GitHub pull request. Attached to traces or specs obtained
  // from a GitHub pull request.
  // DEPRECATED.
  // XAkitaGitHubPRURL = "x-akita-github-pr-url",

  // Identifies the GitHub repository for which the pull request was made.
  // Attached to traces or specs obtained from a GitHub pull request.
  XAkitaGitHubRepo = "x-akita-github-repo",

  XAkitaGitLabProject = "x-akita-gitlab-project",
  XAkitaGitLabMRIID = "x-akita-gitlab-mr-iid",

  // Which deployment environment
  XAkitaDeployment = "x-akita-deployment",

  // A comma-separated list of interfaces on which packets were captured.
  XAkitaDumpInterfacesFlag = "x-akita-dump-interfaces-flag",

  // The packet filter given on the command line to capture packets.
  XAkitaDumpFilterFlag = "x-akita-dump-filter-flag",

  // Version tag used for big models
  XAkitaBigModelTagPrefix = "x-akita-big-model:",

  // CLI metadata
  XAkitaCLIVersion = "x-akita-cli-version",
  XAkitaHARPath = "x-akita-har-path",
  XAkitaBPFFilter = "x-akita-learn-bpf-filter-flag",
  XAkitaPortFlag = "x-akita-learn-port-flag",
}

// Returns the tag's value or the empty string if tag is not present.
export const getTagAsString = (tags: Tags | undefined, tag: string) => {
  const tagVal = tags && tags[tag];
  if (tagVal) {
    if (Array.isArray(tagVal)) {
      return tagVal.join(", ");
    }
    return tagVal.toString();
  }
  return "";
};

const isBigModelVersion = (versionTag: string) =>
  versionTag.startsWith(ReservedTags.XAkitaBigModelTagPrefix);

// Finds any big model version and returns the deployment name, e.g., "deployment:staging".
// Returns null if no matching tag.
export const getBigModelDeployment = (version_tags: string[] | undefined) => {
  if (!version_tags) {
    return null;
  }
  for (const v of version_tags) {
    if (isBigModelVersion(v)) {
      return v.substring(ReservedTags.XAkitaBigModelTagPrefix.length);
    }
  }
  return null;
};

export const filterBigModelTag = (version_tags: string[] | undefined): string[] => {
  if (!version_tags) {
    return [];
  }
  return version_tags.filter((v) => !isBigModelVersion(v));
};
