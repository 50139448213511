import queryString from "query-string";

type QueryParams = Map<string, Map<string, boolean>>;

export const queryStringToMap = (qs: queryString.ParsedQuery<string>): QueryParams => {
  const result = new Map<string, Map<string, boolean>>();
  for (const [key, value] of Object.entries(qs)) {
    if (value === null) {
      continue;
    }

    let m = result.get(key);
    if (!m) {
      m = new Map<string, boolean>();
      result.set(key, m);
    }

    let values = value;
    if (!Array.isArray(value)) {
      values = [String(value)];
    }

    if (values) {
      for (const v of values) {
        if (v) {
          m.set(v, true);
        }
      }
    }
  }
  return result;
};

export const mapToQueryString = (m: QueryParams) => {
  const result: queryString.ParsedQuery<string> = {};
  m.forEach((m2: Map<string, boolean>, key: string) => {
    const values: Array<string> = [];
    m2.forEach((enabled, term) => {
      if (enabled) {
        values.push(term);
      }
    });
    result[key] = values;
  });
  return result;
};

// Writes src to dst.  Maps in dst are updated rather than replaced.
export const updateQueryParams = (dst: QueryParams, src: QueryParams) => {
  dst.forEach((dstParamMap, k) => {
    const srcParamMap = src.get(k);

    // Set parameters from src.
    if (srcParamMap) {
      srcParamMap.forEach((v, k) => {
        dstParamMap.set(k, v);
      });
    }

    // Unset (but don't delete) parameters not in src.
    for (k of dstParamMap.keys()) {
      if (srcParamMap && srcParamMap.has(k)) {
        continue;
      }
      dstParamMap.set(k, false);
    }
  });

  // Add maps in src to dst if not present.
  src.forEach((srcParamMap, k) => {
    if (!dst.has(k)) {
      dst.set(k, srcParamMap);
    }
  });
};
