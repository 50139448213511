import { SxProps, Theme, useTheme } from "@mui/material";
import React from "react";
import { Pill } from "../Pill";
import { mergeSxProps } from "dashboard/utils/sx";

export const getResponseCodeColor = (theme: Theme, responseCode: string | number | undefined) => {
  const firstChar = responseCode?.toString().slice(0, 1);

  switch (firstChar) {
    case "1":
      return theme.palette.success.light;
    case "2":
      return theme.palette.success.main;
    case "3":
      return theme.palette.success.dark;
    case "4":
      return theme.palette.warning.main;
    case "5":
      return theme.palette.error.main;
    default:
      return theme.palette.foreground;
  }
};

export const ResponseCodeEntity = ({
  code,
  sx,
}: {
  code?: string | number;
  sx?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  const color = getResponseCodeColor(theme, code);

  return (
    <Pill
      variant="outlined"
      sx={mergeSxProps({ color, border: `1px solid ${color}`, minWidth: "38px" }, sx)}
      size="small"
    >
      {code}
    </Pill>
  );
};
