import { useMemo } from "react";
import { UseSearchParamOptions, useSearchParam } from "./use-search-param";

export type SortDirection = "asc" | "desc";
export type SortParam = `${string}:${SortDirection}`;

type UseSortParamArgs = {
  defaultSortKey?: string;
  defaultSortDirection?: SortDirection;
  prefix?: string;
};

export const useSortParam = ({
  defaultSortKey = "count",
  defaultSortDirection = "desc",
  prefix = "",
}: UseSortParamArgs = {}): {
  sort: SortParam;
  setSort: (sortID: SortParam | null, options?: UseSearchParamOptions) => void;
  activeSortKey: string;
  activeSortDirection: SortDirection;
} => {
  const defaultParam: SortParam = `${defaultSortKey}:${defaultSortDirection}`;
  const [sort, setSort] = useSearchParam<SortParam>(
    `${prefix ? `${prefix}-` : ""}sort`,
    defaultParam
  );

  const [activeSortKey = defaultSortKey, activeSortDirection = defaultSortDirection] = sort
    ? (sort.split(":") as [string, SortDirection])
    : [];

  return useMemo(
    () => ({
      sort: sort ?? defaultParam,
      setSort,
      activeSortKey,
      activeSortDirection,
    }),
    [sort, setSort, activeSortKey, activeSortDirection, defaultParam]
  );
};
