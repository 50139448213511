import { Add } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { LinkBehavior } from "./LinkBehavior";
import { getQueryLocation } from "dashboard/utils/routes";
import { useCurrentDeploymentId } from "hooks/use-current-deployment-id";
import { useCurrentProjectId } from "hooks/use-current-project-id";
import { useLogInteraction } from "hooks/use-log-interaction";

type CreateMonitorButtonProps = { includeContext?: boolean } & Omit<
  ButtonProps<typeof LinkBehavior>,
  "to" | "href"
>;

export const CreateMonitorButton = ({ includeContext, ...rest }: CreateMonitorButtonProps) => {
  const projectID = useCurrentProjectId();
  const deploymentID = useCurrentDeploymentId();
  const logInteraction = useLogInteraction();
  const location = useLocation();

  return (
    <Button
      startIcon={<Add />}
      component={LinkBehavior}
      onClick={() => logInteraction("Clicked to open New Monitor modal")}
      color="secondary"
      variant="outlined"
      to={getQueryLocation(
        location,
        "modal",
        `/project/${projectID}/deployment/${deploymentID}/monitors/new`
      )}
      {...rest}
    >
      {includeContext ? "Create Monitor" : "Create"}
    </Button>
  );
};
