import { ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Grow, List, ListItemButton, Typography } from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { FixedSizeList } from "react-window";
import {
  SeeMorePseudoFilterOption,
  WindowedEndpointsTableFilterOption,
} from "./EndpointsTableFilterOption";
import {
  DEFAULT_OPEN_FILTER_KEYS,
  FilterDefinition,
  SEE_MORE_ENABLED_FILTER_KEYS,
  SEE_MORE_FILTER_LIST_SIZE,
} from "./formatting";
import { useLogInteraction } from "hooks/use-log-interaction";
import { useSearchParamArray } from "hooks/use-search-param-array";

const ITEM_HEIGHT = 32;

const sxHeaderButton = {
  alignItems: "center",
  color: "inherit",
  display: "flex",
  fontFamily: "inherit",
  fontSize: "inherit",
  fontWeight: "inherit",
  justifyContent: "space-between",
  padding: 2,
  paddingBottom: 0.5,
  paddingTop: 0.5,
  textAlign: "left",
  width: "100%",
};

type EndpointsTableFilterOptionsListProps = {
  filterDefinition: FilterDefinition;
  isLast?: boolean;
  onOptionToggled?: (optionKey: string) => void;
};

export const EndpointsTableFilterOptionsList = memo(
  ({ filterDefinition, isLast, onOptionToggled }: EndpointsTableFilterOptionsListProps) => {
    const { key: filterKey, displayKey, options: knownOptions } = filterDefinition;
    const [selectedOptions, setSelectedOptions] = useSearchParamArray(
      `filter-${filterKey}`,
      filterKey === "endpoint_categories" || filterKey === "http_methods" ? "all" : undefined
    );

    const [isExpanded, setIsExpanded] = useState(
      // Default to expanded if this key is in the default open list, or if there's an option already selected
      // in this list upon mount. Otherwise, default to collapsed to keep things less overwhelming.
      DEFAULT_OPEN_FILTER_KEYS.indexOf(filterKey) !== -1 || selectedOptions.length > 0
    );

    const [shouldIncludeSeeMoreOption, setShouldIncludeShowMoreOption] = useState(
      SEE_MORE_ENABLED_FILTER_KEYS.indexOf(filterKey) !== -1 &&
        !!knownOptions &&
        knownOptions.length > SEE_MORE_FILTER_LIST_SIZE
    );

    const logInteraction = useLogInteraction({
      filterKey,
      displayKey,
      optionsCount: knownOptions?.length,
    });

    const options = useMemo(() => {
      const allOptions = knownOptions ? [...knownOptions] : [];

      // If there's an optionKey present in the array of selected filters, and it's NOT present in
      // the array of known filter options provided by the backend, add it to the list with a count
      // of 0, so it's clear to the user what filters are currently applied.
      selectedOptions.forEach((selectedKey) => {
        if (!allOptions.some((knownKey) => knownKey.optionKey === selectedKey)) {
          allOptions.push({ optionKey: selectedKey, count: 0 });
        }
      });

      if (shouldIncludeSeeMoreOption) {
        return allOptions.slice(0, SEE_MORE_FILTER_LIST_SIZE);
      }

      return allOptions;
    }, [selectedOptions, knownOptions, shouldIncludeSeeMoreOption]);

    const toggleOptionChecked = useCallback(
      (optionKey: string) => {
        const updatedValues = selectedOptions.includes(optionKey)
          ? selectedOptions.filter((o) => o !== optionKey)
          : selectedOptions.concat(optionKey);

        setSelectedOptions(updatedValues);
        onOptionToggled && onOptionToggled(optionKey);
        logInteraction("Filtered endpoints", { selectedOptions: updatedValues });
      },
      [onOptionToggled, selectedOptions, setSelectedOptions, logInteraction]
    );

    const itemData = useMemo(
      () => (options ? { options, toggleOptionChecked, selectedOptions, filterKey } : undefined),
      [filterKey, options, selectedOptions, toggleOptionChecked]
    );

    // Get the total height of all of the list items in pixels, or 400px, whichever is less.
    // This has the effect of setting a max height for a list of 400px, after which it will scroll.
    const listHeight = Math.min(ITEM_HEIGHT * (options?.length ?? 0), 400);

    return (
      <Box>
        <Typography variant="h6">
          <ListItemButton
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-controls={`filter-${filterKey}-content`}
            id={`filter-${filterKey}-label`}
            sx={sxHeaderButton}
          >
            <Box>
              {displayKey}
              <TransitionGroup component={null}>
                {selectedOptions.length > 0 && (
                  <Grow timeout={200}>
                    <Box component="span" sx={{ color: (theme) => theme.palette.secondary.main }}>
                      {` (${selectedOptions.length})`}
                    </Box>
                  </Grow>
                )}
              </TransitionGroup>
            </Box>
            <ExpandMore
              sx={{
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: (theme) =>
                  theme.transitions.create("transform", {
                    duration: theme.transitions.duration.short,
                  }),
              }}
            />
          </ListItemButton>
        </Typography>

        <Collapse
          in={isExpanded}
          id={`filter-${filterKey}-content`}
          aria-labelledby={`filter-${filterKey}-label`}
          role="region"
        >
          {options && options.length > 0 ? (
            <List
              dense
              sx={[
                {
                  // Add 8 pixels to account for the bottom padding.
                  // Additionally account for the extra space required by the "see more" option
                  // if it's displayed
                  height: listHeight + 8 + (shouldIncludeSeeMoreOption ? 28 : 0),
                  padding: 0,
                  margin: 0,
                  paddingBottom: 1,
                },
                !isLast && {
                  marginBottom: 1,
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                },
              ]}
            >
              {isExpanded && (
                <>
                  <FixedSizeList
                    height={listHeight}
                    width="100%"
                    itemSize={ITEM_HEIGHT}
                    itemCount={options.length}
                    itemData={itemData}
                  >
                    {WindowedEndpointsTableFilterOption}
                  </FixedSizeList>
                  {shouldIncludeSeeMoreOption && (
                    <SeeMorePseudoFilterOption
                      onClick={() => setShouldIncludeShowMoreOption(false)}
                    />
                  )}
                </>
              )}
            </List>
          ) : (
            <Typography
              sx={{
                paddingLeft: 2,
              }}
            >
              None
            </Typography>
          )}
        </Collapse>
      </Box>
    );
  }
);
EndpointsTableFilterOptionsList.displayName = "EndpointsTableFilterOptionsList";
