import { ChevronLeft } from "@mui/icons-material";
import { Alert, IconButton, LinearProgress } from "@mui/material";
import React, { useMemo } from "react";
import { InternalOrgDetails } from "./InternalOrgDetails";
import { Page, PageContent, PageHeader, PageTitle } from "dashboard/components/Page";
import { useInternalOrgs } from "data/queries/internal-orgs";

interface InternalOrgPageProps {
  organizationId: string;
}

// XXX(jo): this seems to be a linter bug
// eslint-disable-next-line no-shadow
enum OrgPageContent {
  IsLoading,
  IsError,
  IsOrgDetails,
}

export const InternalOrgPage = ({ organizationId }: InternalOrgPageProps) => {
  const result = useInternalOrgs();

  const [content, organization] = useMemo(() => {
    if (result.isLoading) {
      return [OrgPageContent.IsLoading, null];
    }

    if (result.isError) {
      return [OrgPageContent.IsError, null];
    }

    if (result?.data) {
      return [
        OrgPageContent.IsOrgDetails,
        result?.data?.organizations.find((org) => org.organization_id === organizationId),
      ];
    }
    throw new Error("unknown state of result");
  }, [result, organizationId]);

  const orgNameCopy = `${organization?.inferred_name || "Organization"} (${organizationId})`;

  return (
    <Page>
      <PageHeader
        sx={{ display: "flex", alignItems: "center", justifyContent: "flexStart", padding: 2 }}
      >
        <IconButton
          href="/internal/orgs"
          aria-label="Back to all Organizations"
          sx={{ marginRight: 1 }}
        >
          <ChevronLeft />
        </IconButton>
        <PageTitle documentTitle={orgNameCopy}>{orgNameCopy}</PageTitle>
      </PageHeader>

      <PageContent>
        {content === OrgPageContent.IsLoading && <LinearProgress />}
        {content === OrgPageContent.IsError && (
          <Alert severity="error">Something went wrong.</Alert>
        )}
        {content === OrgPageContent.IsOrgDetails && (
          <InternalOrgDetails
            organization={result?.data?.organizations.find(
              (org) => org.organization_id === organizationId
            )}
          />
        )}
      </PageContent>
    </Page>
  );
};
