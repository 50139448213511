import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

export enum AuthStatus {
  IDLE,
  AUTHORIZING,
  AUTHORIZED,
  FAILED,
  CANCELED,
}

interface OAuth2Props {
  authorizeURL: string;
  clientID: string;
  redirectURI: string;
  scope: string[];
}

interface AuthState {
  status: AuthStatus;
  error: string | undefined;
  code: string | undefined;
}

const defaultAuthState: AuthState = {
  status: AuthStatus.IDLE,
  error: undefined,
  code: undefined,
};

const failedAuthState = (error: string): AuthState => ({
  status: AuthStatus.FAILED,
  error,
  code: undefined,
});

const grantedAuthState = (code: string): AuthState => ({
  status: AuthStatus.AUTHORIZED,
  code,
  error: undefined,
});

const OAUTH_STATE_KEY = "react-use-oauth2-state-key";

// Generates state to mitigate CSRF attacks
// See: https://medium.com/@dazcyril/generating-cryptographic-random-state-in-javascript-in-the-browser-c538b3daae50
const generateState = () => {
  const validChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x: number) => validChars.codePointAt(x % validChars.length)!);
  return String.fromCharCode.apply(null, [...array]);
};

// Persists the oauth state key to session storage so that it can be used by popup
const saveState = (state: string) => {
  sessionStorage.setItem(OAUTH_STATE_KEY, state);
};

// Removes the oauth state key from session storage
const removeState = () => {
  sessionStorage.removeItem(OAUTH_STATE_KEY);
};

const popupCenter = (url: string, w: number, h: number, title?: string) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth || document.documentElement.clientWidth || screen.width;
  const height = window.innerHeight || document.documentElement.clientHeight || screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  newWindow?.focus();
  return newWindow ?? undefined;
};

const openPopup = (url: string): Window | undefined => {
  const width = 400;
  const height = 600;

  return popupCenter(url, width, height);
};

const closePopup = (popupRef: MutableRefObject<Window | undefined>) => {
  popupRef.current?.close();
};

const cleanup = (
  popupRef: MutableRefObject<Window | undefined>,
  intervalRef: MutableRefObject<any>
) => {
  clearInterval(intervalRef.current);
  closePopup(popupRef);
  removeState();
};

const enhanceAuthorizeURL = (
  authorizeURL: string,
  clientID: string,
  redirectURI: string,
  scope: string[],
  state: string
) =>
  `${authorizeURL}?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}&scope=${scope}&state=${state}`;

// Checks that the state parameter matches the one that was initially sent to the request
const verifyState = (receivedState?: string): boolean => {
  const state = sessionStorage.getItem(OAUTH_STATE_KEY);
  return state === receivedState;
};

// Maps URL parameters to an object
const queryToObject = (
  query: string | string[][] | Record<string, string> | URLSearchParams | undefined
): { [p: string]: string } => {
  const params = new URLSearchParams(query);
  return Object.fromEntries(params.entries());
};

// Hook to handle OAuth2 authorization code flow
export const useOAuth2 = ({ authorizeURL, clientID, redirectURI, scope }: OAuth2Props) => {
  const intervalRef = useRef<NodeJS.Timer | undefined>(undefined);
  const popupRef = useRef<Window | undefined>(undefined);

  const [{ status, code, error }, setAuthState] = useState<AuthState>(defaultAuthState);

  useEffect(
    () => () => {
      cleanup(popupRef, intervalRef);
    },
    []
  );

  const getAuth = useCallback(() => {
    setAuthState({ status: AuthStatus.AUTHORIZING, error: undefined, code: undefined });

    // Generate and save oauth state
    const state = generateState();
    saveState(state);

    // Open popup
    popupRef.current = openPopup(
      enhanceAuthorizeURL(authorizeURL, clientID, redirectURI, scope, state)
    );

    intervalRef.current = setInterval(() => {
      // Check if the popup has been closed by the user
      if (!popupRef.current || popupRef.current.closed) {
        setAuthState((prevState) => ({ ...prevState, status: AuthStatus.CANCELED }));
        return;
      }

      let payload: { [p: string]: string } | undefined;
      try {
        payload = queryToObject(popupRef.current.location.search);
      } catch (e) {
        // Ignore errors from accessing the popup location before it has been redirected
        payload = undefined;
      }

      if (payload?.code) {
        if (verifyState(payload.state)) {
          setAuthState(grantedAuthState(payload.code));
        } else {
          setAuthState(failedAuthState("Invalid state"));
        }
      } else if (payload?.error) {
        setAuthState(failedAuthState(payload.error));
      }
    }, 250);
  }, [authorizeURL, clientID, redirectURI, scope]);

  return { getAuth, status, code, error };
};
