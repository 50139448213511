import {
  AccessTime,
  Check,
  ErrorOutline,
  ExpandMore,
  InfoOutlined,
  Storage as StorageIcon,
  WarningAmber,
} from "@mui/icons-material";
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format, isBefore, subHours } from "date-fns";
import React, { useMemo } from "react";
import { DataAlert } from "../DataAlert";
import { ProjectIssueSeverity } from "../ProjectIssueAlertsList/ProjectIssueAlert";

const SeverityIcon = ({ severity }: { severity: ProjectIssueSeverity | undefined }) => {
  if (severity === "error") return <ErrorOutline />;
  if (severity === "warning") return <WarningAmber />;
  if (severity === "info") return <InfoOutlined />;

  return null;
};

type ProjectBarStatusProps = {
  hasIssues: boolean;
  highestIssueSeverity: ProjectIssueSeverity | undefined;
  activeClients?: number;
  isCollapsedIssues: boolean;
  lastObservedDate: Date | undefined;
  toggleCollapsedIssues: () => void;
};

export const ProjectBarStatus = ({
  hasIssues,
  highestIssueSeverity,
  activeClients,
  lastObservedDate,
  isCollapsedIssues,
  toggleCollapsedIssues,
}: ProjectBarStatusProps) => {
  const theme = useTheme();
  const isVeryNarrowViewport = useMediaQuery(theme.breakpoints.down("sm"));

  const lastObservedContent = useMemo(() => {
    if (!lastObservedDate) {
      return isVeryNarrowViewport ? "No data" : "No data received";
    }

    // If we don't need to save horizontal space, use the full localized date e.g. "03/09/2023, 5:39 PM"
    if (!isVeryNarrowViewport) {
      return format(lastObservedDate, "Pp");
    }

    // If the date is more than 24 hours ago, show just the date to save space.
    if (isBefore(lastObservedDate, subHours(new Date(), 24))) {
      return format(lastObservedDate, "P");
    }

    // If the date falls within the last 24 hours, show just the time to save space.
    return format(lastObservedDate, "p");
  }, [lastObservedDate, isVeryNarrowViewport]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        [theme.breakpoints.down("md")]: { alignItems: "flex-start" },
      }}
    >
      <Typography variant="h6" textAlign="right">
        Status
      </Typography>

      <Stack direction="row" spacing={1}>
        <DataAlert icon={<StorageIcon />} title="Clients running">
          {activeClients}
        </DataAlert>

        <DataAlert
          icon={<AccessTime />}
          // Include the full date and time here, in case we've had to shorten it for narrow viewports
          title={`Last received data: ${
            lastObservedDate ? format(lastObservedDate, "Pp") : "never"
          }`}
        >
          {lastObservedContent}
        </DataAlert>

        <Button
          onClick={toggleCollapsedIssues}
          color={hasIssues ? highestIssueSeverity : "success"}
          variant="outlined"
          size="small"
          sx={{
            minWidth: hasIssues ? "unset" : 36,
            padding: hasIssues ? 1 : 0,
          }}
        >
          {hasIssues ? (
            <>
              <Box
                sx={{
                  marginRight: 0.5,
                  [theme.breakpoints.down("sm")]: { display: "none" },
                }}
                component="span"
              >
                {isCollapsedIssues ? "Show Issues" : "Hide Issues"}
              </Box>

              <SeverityIcon severity={highestIssueSeverity} />

              <ExpandMore
                aria-label={isCollapsedIssues ? "Show Issues" : "Hide Issues"}
                sx={{
                  transform: isCollapsedIssues ? "rotate(0deg)" : "rotate(180deg)",
                  transition: theme.transitions.create("transform", {
                    duration: theme.transitions.duration.short,
                  }),
                  [theme.breakpoints.up("sm")]: { display: "none" },
                }}
              />
            </>
          ) : (
            <Check aria-label="Okay" />
          )}
        </Button>
      </Stack>
    </Box>
  );
};
