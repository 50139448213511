import { Autocomplete, AutocompleteProps, SxProps, TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { uniq } from "lodash";
import { matchSorter } from "match-sorter";
import React, { useCallback, useMemo } from "react";
import { useMonitors } from "data/queries/monitors";
import { useSearchParamArray } from "hooks/use-search-param-array";

type MonitorsAutocompleteProps = {
  deploymentID: string;
  projectID: string;
  sx?: SxProps<Theme>;
};

const filterOptions: AutocompleteProps<string, true, undefined, true>["filterOptions"] = (
  options,
  { inputValue }
) => matchSorter(options, inputValue);

const renderInput: AutocompleteProps<string, true, undefined, true>["renderInput"] = (params) => (
  <TextField
    // Only show the placeholder text if there's no items selected
    placeholder={!params.InputProps.startAdornment ? "Search for monitors" : undefined}
    aria-label="Search for monitors"
    // Annoyingly, this is the only way I know of to override this default appearance.
    sx={{ fieldset: { borderColor: "divider" } }}
    {...params}
  />
);

export const MonitorsAutocomplete = ({
  deploymentID,
  projectID,
  sx,
}: MonitorsAutocompleteProps) => {
  const [values, setValues] = useSearchParamArray("filter-required_endpoints");
  const { data, isLoading } = useMonitors({
    projectID,
    deploymentID,
    queryParams: { state: "ACTIVE" },
  });

  const options = useMemo(() => {
    const monitors = data?.monitors ?? [];
    const paths = monitors.map((monitor) => monitor.path_template);

    // Ensure the list doesn't have any duplicates
    return uniq(paths);
  }, [data]);

  const onChange = useCallback(
    (_event, newValues: string[]) => {
      setValues(newValues);
    },
    [setValues]
  );

  return (
    <Autocomplete
      autoHighlight
      disabled={isLoading}
      filterOptions={filterOptions}
      freeSolo
      multiple
      onChange={onChange}
      options={options}
      renderInput={renderInput}
      sx={sx}
      value={values}
    />
  );
};
