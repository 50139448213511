import { useQuery } from "@tanstack/react-query";
import { TimeRange, getTimeRangeQueryValue } from "dashboard/utils/time-range";
import { akitaFetch, retry } from "data/akita-fetch";
import { GetTimelineSummary } from "types/akita_api_types";

const getTimelineSummary = (
  projectID: string,
  deploymentID: string,
  queryParams: Record<string, any>
) =>
  akitaFetch<GetTimelineSummary>(`/services/${projectID}/timeline/${deploymentID}/summary`, {
    queryParams,
  });

type UseTimelineSummaryArgs = {
  deploymentID?: string;
  options?: { enabled?: boolean; keepPreviousData?: boolean; refetchOnWindowFocus?: boolean };
  projectID?: string;
  queryParams: Record<string, any>;
  relativeEndDateMS: number;
  timeRange: TimeRange;
};

export const useTimelineSummary = ({
  deploymentID,
  options = {},
  projectID,
  queryParams,
  relativeEndDateMS,
  timeRange,
}: UseTimelineSummaryArgs) =>
  useQuery(
    // relativeEndDateMS is included here to ensure we don't show stale data when we're requesting a
    // relative time range from the API, as there's no end timestamp included in a relative time
    // range object (and the API doesn't accept one).
    ["timeline-summary", projectID, deploymentID, queryParams, timeRange, relativeEndDateMS],
    () =>
      getTimelineSummary(projectID!, deploymentID!, {
        ...queryParams,
        ...getTimeRangeQueryValue(timeRange),
      }),
    {
      retry,
      enabled: !!projectID && !!deploymentID,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
