import { summarizeFieldDataFormats } from "dashboard/utils/data_formats";
import { PathParameterPositions, getParameterPositions } from "dashboard/utils/path-parameters";
import { prettify } from "dashboard/utils/strings";
import { MethodResponse, MethodResponseField } from "types/akita_api_types";

type BaseFormattedEndpointField = MethodResponseField & {
  display: {
    contentType?: string;
    dataFormat: string;
    fieldName: string;
    originalFieldName: string;
    position?: number;
    responseCode?: string;
  };
};

export type FormattedEndpointField = BaseFormattedEndpointField & {
  uniqueKey: string;
};

const getUniqueKeyForField = (field: BaseFormattedEndpointField) =>
  [
    field.location.direction,
    field.location.location,
    field.display.contentType,
    field.display.dataFormat,
    field.display.responseCode,
    field.display.fieldName,
    field.display.position,
  ].join("-");

const formatField = (
  field: MethodResponseField,
  pathParamPositions: PathParameterPositions
): FormattedEndpointField => {
  let fieldName = field.location.path.join(".") || "N/A";

  if (field.location.location === "AUTH") {
    fieldName = "Authorization";
  }

  const formattedField: BaseFormattedEndpointField = {
    ...field,
    display: {
      contentType: field.location.content_type ? prettify(field.location.content_type) : undefined,
      dataFormat: summarizeFieldDataFormats(field),
      fieldName,
      originalFieldName: fieldName,
      responseCode: field.location.response_code,
      // @pr TODO: Replace with a server-provided value, when available.
      position: pathParamPositions[fieldName],
    },
  };

  return { ...formattedField, uniqueKey: getUniqueKeyForField(formattedField) };
};

interface FormattedFields {
  params: { path: FormattedEndpointField[]; query: FormattedEndpointField[] };
  headers: { request: FormattedEndpointField[]; response: FormattedEndpointField[] };
  body: { request: FormattedEndpointField[]; response: FormattedEndpointField[] };
}

const HEADER_LOCATIONS = ["HEADER", "AUTH", "COOKIE"];

export const formatFields = (fields?: MethodResponseField[], endpointPath?: string) => {
  if (!endpointPath || !fields) return undefined;

  const pathParamPositions = getParameterPositions(endpointPath);

  return fields.reduce(
    (result: FormattedFields, field) => {
      const formattedField = formatField(field, pathParamPositions);

      if (formattedField.location.direction === "REQUEST") {
        if (HEADER_LOCATIONS.includes(formattedField.location.location)) {
          result.headers.request.push(formattedField);
        } else if (formattedField.location.location === "PATH") {
          result.params.path.push(formattedField);
        } else if (formattedField.location.location === "QUERY") {
          result.params.query.push(formattedField);
        } else {
          result.body.request.push(formattedField);
        }
      } else {
        if (HEADER_LOCATIONS.includes(formattedField.location.location)) {
          result.headers.response.push(formattedField);
        } else {
          result.body.response.push(formattedField);
        }
      }

      return result;
    },
    {
      params: { path: [], query: [] },
      headers: { request: [], response: [] },
      body: { request: [], response: [] },
    }
  );
};

const HTTP_METHODS_WITH_BODIES = ["PUT", "POST", "PATCH"];
export const endpointCanHaveBody = (endpoint: MethodResponse) =>
  HTTP_METHODS_WITH_BODIES.includes(endpoint.operation);
