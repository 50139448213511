import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useCallback } from "react";
import { TimeRangeRelative, getTimeRangeLabel } from "dashboard/utils/time-range";

const DEFAULT_RELATIVE_OPTIONS = [1, 3, 6, 12, 24, 7 * 24];

type TimeRangePickerRelativeProps = {
  onChange: (newValue: TimeRangeRelative) => void;
  options?: number[];
  value: TimeRangeRelative;
};

export const TimeRangePickerRelative = ({
  onChange,
  options = DEFAULT_RELATIVE_OPTIONS,
  value,
}: TimeRangePickerRelativeProps) => {
  const onTimeRangeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const newHours = parseInt(event?.target?.value, 10);
      if (!newHours) return;

      onChange({ type: "relative", duration: { hours: newHours } });
    },
    [onChange]
  );

  return (
    <FormControl>
      <Select
        onChange={onTimeRangeChange}
        value={value.duration.hours?.toString()}
        // minWidth and size are set to match the DateTimePicker components in TimeRangePickerAbsolute
        size="small"
        sx={{ minWidth: 244 }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {getTimeRangeLabel({ type: "relative", duration: { hours: option } })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
