import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { getTimelineColorByIndex } from "dashboard/utils/timelines";
import { useAllSearchParams } from "hooks/use-all-search-params";
import { useSearchParamArray } from "hooks/use-search-param-array";

export const useEndpointsTableFilters = () =>
  useAllSearchParams("filter-", {
    "filter-endpoint_categories": "all",
    "filter-http_methods": "all",
  });

export const useEndpointsTableSelections = () => useSearchParamArray("selected-endpoints", "none");

export const useSelectedEndpointColors = () => {
  const theme = useTheme();
  const [selectedEndpoints] = useEndpointsTableSelections();

  return useMemo(
    () =>
      selectedEndpoints.reduce((result: Record<string, string>, uniqueID, index) => {
        result[uniqueID] = getTimelineColorByIndex(theme, index);
        return result;
      }, {}),
    [selectedEndpoints, theme]
  );
};
