import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import React from "react";
import { CustomTable } from "dashboard/components/Table";
import { prettifyTimeDelta } from "dashboard/utils/dates";
import { ActiveMiddlewareDaemon } from "types/akita_api_types";

type ActiveDaemonTableProps = {
  daemons: ActiveMiddlewareDaemon[];
};

const healthyColor = "#21762A";
const healthyStyle = {
  color: "#21762A",
};

const unhealthyColor = "#ED5500";
const unhealthyStyle = {
  color: "#ED5500",
};

// TODO: move this check to the backend
const getDaemonHealthElement = (lastHeartbeat: string, lastPollRequest: string) => {
  const now = new Date();
  const d = new Date(lastHeartbeat);
  const poll = new Date(lastPollRequest);
  const heartbeatDelta = now.getTime() - d.getTime();
  const pollDelta = now.getTime() - poll.getTime();

  // Mark as unready if no heartbeat.
  // The daemon will be removed from the list by the backend if it got a response but didn't
  // make a new request. We should do any additional liveness filtering there, probably.
  if (heartbeatDelta >= 60 * 1000) {
    return [
      <span style={unhealthyStyle} key="unhealthy">
        <ErrorIcon htmlColor={unhealthyColor} /> unhealthy
      </span>,
      <span key="heartbeat">{prettifyTimeDelta(d, heartbeatDelta)}</span>,
      <span key="timedelta">{prettifyTimeDelta(poll, pollDelta)}</span>,
    ];
  } else {
    return [
      <span style={healthyStyle} key="ready">
        <CheckCircleIcon htmlColor={healthyColor} /> ready
      </span>,
      <span key="heartbeat">{prettifyTimeDelta(d, heartbeatDelta)}</span>,
      <span key="timedelta">{prettifyTimeDelta(poll, pollDelta)}</span>,
    ];
  }
};

export function ActiveDaemonTable(props: ActiveDaemonTableProps) {
  const daemons = props.daemons;

  if (!daemons || daemons.length == 0) {
    return <Typography variant="body2">No daemon heartbeats received in the past hour.</Typography>;
  }

  const daemonTableData = daemons.map((d) =>
    [<span key={d.daemon_name}>{d.daemon_name}</span>].concat(
      getDaemonHealthElement(d.last_heartbeat, d.last_poll),
      [<span key="blank"></span>]
    )
  );

  return (
    <div>
      <CustomTable
        tableHead={["Active Daemons", "Status", "Last heartbeat", "Last poll request", ""]}
        tableData={daemonTableData}
      />
    </div>
  );
}
