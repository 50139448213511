import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getQueryLocation } from "dashboard/utils/routes";

export type UseSearchParamOptions = { replace?: boolean };

/**
 * Hook that reads and writes from the given search parameter key in a URL, while preserving other
 * search parameters.
 *
 * Optionally takes a placeholder value, which will be ignored if present in the query string, and
 * set in the query string when this key's value is cleared.
 */
export const useSearchParam = <T extends string = string>(
  key: string,
  placeholder?: string
): [
  value: T | null,
  setValue: (newValue: T | null, options?: UseSearchParamOptions) => void,
  isPlaceholder: boolean
] => {
  const location = useLocation();
  const history = useHistory();

  const [value, isPlaceholder] = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const rawValue = searchParams.get(key);

    if (rawValue === placeholder) return [null, true];

    return [(rawValue as T) ?? null, false];
  }, [location.search, key, placeholder]);

  const setValue = useCallback(
    (newValue: T | null, { replace }: UseSearchParamOptions = {}) => {
      // We need to access the fresh location object from history.location instead of the return
      // value of useLocation here, so that multiple search params can be changed in a single event
      // handler without overwriting each other.
      const newLocation = getQueryLocation(history.location, key, newValue ?? placeholder ?? null);

      if (replace) {
        history.replace(newLocation);
      } else {
        history.push(newLocation);
      }
    },
    [history, key, placeholder]
  );

  return useMemo(() => [value, setValue, isPlaceholder], [value, setValue, isPlaceholder]);
};
