import { Box, Typography, useTheme } from "@mui/material";
import { zip } from "lodash";
import React, { useCallback, useMemo } from "react";
import { MetricsAndErrorsGraphEmptyState } from "./MetricsAndErrorsGraphEmptyState";
import { AkiGridContainer } from "dashboard/components/AkiGrid";
import { AkiTile } from "dashboard/components/AkiTile/AkiTile";
import { AkiTileHeader } from "dashboard/components/AkiTile/AkiTileHeader";
import { AkiTimelineGroup } from "dashboard/components/AkiTimelineGroup/AkiTimelineGroup";
import {
  useEndpointsTableSelections,
  useSelectedEndpointColors,
} from "dashboard/components/EndpointsTable/hooks";
import { EndpointEntity } from "dashboard/components/entities/EndpointEntity";
import { decodeEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import {
  Percentile,
  percentileToTimelineAggregateValue,
  percentileToTimelineValue,
} from "dashboard/utils/percentiles";
import {
  DisplayTimeline,
  DisplayTimelineValue,
  akitaTimelineToDisplayTimeline,
} from "dashboard/utils/timelines";
import { useTimelineQueries } from "data/queries/use-timeline-query";
import { TimelineValue } from "types/akita_api_types";

const AKI_TIMELINE_GROUP_HEIGHT = 240;

type MetricsAndErrorsGraphsProps = {
  deploymentID?: string;
  endMS: number;
  isLoadingEndpoints: boolean;
  percentile: Percentile;
  projectID?: string;
  startMS: number;
};

export const MetricsAndErrorsGraphs = ({
  deploymentID,
  endMS,
  isLoadingEndpoints,
  percentile,
  projectID,
  startMS,
}: MetricsAndErrorsGraphsProps) => {
  const theme = useTheme();
  const [selectedEndpoints] = useEndpointsTableSelections();
  const selectedEndpointColors = useSelectedEndpointColors();
  const decodedEndpointIDs = useMemo(
    () => selectedEndpoints.map((id) => decodeEndpointUniqueID(id)),
    [selectedEndpoints]
  );

  const {
    isLoadingAll,
    isLoadingAny,
    isPreviousDataAll,
    isPreviousDataAny,
    results: resultsTimelines,
    updateKey: updateKeyTimelines,
  } = useTimelineQueries(
    projectID,
    deploymentID,
    decodedEndpointIDs.map((decodedID) => ({
      params: {
        endMS,
        startMS,
        aggregate: ["count", percentileToTimelineAggregateValue(percentile)],
        path: decodedID.path,
        host: decodedID.host,
        method: decodedID.operation,
        responseCode: decodedID.responseCode,
        key: ["host", "path", "method", "code"],
      },
      options: { refetchOnWindowFocus: false, keepPreviousData: true },
    }))
  );

  const { timelinesCount, timelinesPercentile } = useMemo(() => {
    const timelinesAndIDs = zip(
      resultsTimelines.flatMap(({ data }) => data?.timelines),
      selectedEndpoints
    );

    const displayTimelinesCount = timelinesAndIDs
      .map(([timeline, uniqueID]) =>
        timeline && uniqueID
          ? akitaTimelineToDisplayTimeline(timeline, TimelineValue.Event_Count, {
              title: uniqueID,
              color: selectedEndpointColors[uniqueID],
            })
          : undefined
      )
      .filter((t): t is DisplayTimeline => !!t);

    const displayTimelinesPercentile = timelinesAndIDs
      .map(([timeline, uniqueID]) =>
        timeline && uniqueID
          ? akitaTimelineToDisplayTimeline(timeline, percentileToTimelineValue(percentile), {
              title: uniqueID,
              color: selectedEndpointColors[uniqueID],
            })
          : undefined
      )
      .filter((t): t is DisplayTimeline => !!t);

    return {
      timelinesCount: displayTimelinesCount.length > 0 ? displayTimelinesCount : undefined,
      timelinesPercentile:
        displayTimelinesPercentile.length > 0 ? displayTimelinesPercentile : undefined,
    };

    // Intentionally disabling this lint rule, as useTimelineQueries always returns a new results
    // array whether or not the data objects have actually been updated. We don't want to re-compute
    // this data on every render, so we'll use updateKey as a dependency (see the hook for more details).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateKeyTimelines, theme, percentile, selectedEndpointColors]);

  const getTooltipContentCount = useCallback(
    (activePoints: DisplayTimelineValue[]) =>
      activePoints.map((datum) => (
        <Box key={datum.title}>
          <EndpointEntity
            endpoint={decodeEndpointUniqueID(datum.title!)}
            sx={{ marginBottom: 0.5 }}
            includeResponseCode
          />
          <Typography component="div">
            Count: <strong>{datum.y?.toLocaleString()}</strong>
          </Typography>
        </Box>
      )),
    []
  );

  const getTooltipContentPercentile = useCallback(
    (activePoints: DisplayTimelineValue[]) =>
      activePoints.map((datum) => (
        <Box key={datum.title}>
          <EndpointEntity
            endpoint={decodeEndpointUniqueID(datum.title!)}
            sx={{ marginBottom: 0.5 }}
            includeResponseCode
          />
          <Typography component="div">
            Duration ({percentile}):{" "}
            <strong>{datum.y !== null ? `${datum.y.toLocaleString()}ms` : "No Data"}</strong>
          </Typography>
        </Box>
      )),
    [percentile]
  );

  const hasDataForCount = timelinesCount && timelinesCount.length > 0;
  const isLoadingOrHasDataCount =
    isLoadingEndpoints || isLoadingAny || isPreviousDataAny || hasDataForCount;

  const hasDataForPercentile = timelinesPercentile && timelinesPercentile.length > 0;
  const isLoadingOrHasDataPercentile =
    isLoadingEndpoints || isLoadingAny || isPreviousDataAny || hasDataForPercentile;

  return (
    <AkiGridContainer sx={{ paddingBottom: 2 }}>
      <AkiTile spanC={3} sx={{ padding: 0 }}>
        <AkiTileHeader>
          <Typography variant="h5">Request Count</Typography>
        </AkiTileHeader>

        {isLoadingOrHasDataCount ? (
          <AkiTimelineGroup
            containerHeight={AKI_TIMELINE_GROUP_HEIGHT}
            endMS={endMS}
            getTooltipContent={getTooltipContentCount}
            isDataPartial={isLoadingAll || isPreviousDataAll}
            isDataStale={isLoadingAny || isPreviousDataAny}
            startMS={startMS}
            timelines={timelinesCount}
          />
        ) : (
          <MetricsAndErrorsGraphEmptyState
            height={AKI_TIMELINE_GROUP_HEIGHT}
            hasEndpointsSelected={selectedEndpoints.length > 0}
          />
        )}
      </AkiTile>

      <AkiTile spanC={3} sx={{ padding: 0 }}>
        <AkiTileHeader>
          <Typography variant="h5">Duration ({percentile})</Typography>
        </AkiTileHeader>

        {isLoadingOrHasDataPercentile ? (
          <AkiTimelineGroup
            containerHeight={AKI_TIMELINE_GROUP_HEIGHT}
            endMS={endMS}
            getTooltipContent={getTooltipContentPercentile}
            isDataPartial={isLoadingAll || isPreviousDataAll}
            isDataStale={isLoadingAny || isPreviousDataAny}
            startMS={startMS}
            timelines={timelinesPercentile}
            unitsY="ms"
          />
        ) : (
          <MetricsAndErrorsGraphEmptyState
            height={AKI_TIMELINE_GROUP_HEIGHT}
            hasEndpointsSelected={selectedEndpoints.length > 0}
          />
        )}
      </AkiTile>
    </AkiGridContainer>
  );
};
