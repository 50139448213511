import { LinearProgress } from "@mui/material";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { NotFound } from "./NotFound";
import { useEndpoint } from "data/queries/endpoint";
import { useLatestModelSummaryForProject } from "data/queries/models";

export const EndpointRedirect = ({
  deploymentID,
  serviceID,
}: {
  deploymentID: string;
  serviceID: string;
}) => {
  const location = useLocation();

  const params = new URLSearchParams(location.search) as any;

  const { data: modelSummary, isLoading: isLoadingModel } = useLatestModelSummaryForProject({
    projectID: serviceID,
    deploymentID,
  });

  const modelID = modelSummary?.id;

  const { data: endpoint, isLoading: isLoadingEndpoint } = useEndpoint(
    serviceID,
    modelID,
    undefined,
    {
      host: decodeURIComponent(params.get("host")),
      operation: decodeURIComponent(params.get("operation")),
      path: decodeURIComponent(params.get("path")),
    }
  );

  if (endpoint?.method_id && modelID) {
    return <Redirect to={`/service/${serviceID}/spec/${modelID}/endpoint/${endpoint.method_id}`} />;
  }

  if (isLoadingEndpoint || isLoadingModel) {
    return <LinearProgress />;
  }

  return <NotFound />;
};
