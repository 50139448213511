import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { matchPath, useHistory } from "react-router-dom";

export const DiagnosticsTabs = ({ projectID }: { projectID?: string }) => {
  const navigate = useHistory();
  const matched = matchPath<{ serviceID: string }>(location.pathname, {
    path: "/service/:serviceID/diagnostics/*",
  });

  const path = matched && matched.params ? (matched?.params as any)["0"] : "traces";

  return (
    <Tabs value={path} indicatorColor="primary">
      <Tab
        label="API Model History"
        value="all-models"
        onClick={() => {
          navigate.push(`/service/${projectID}/diagnostics/all-models`);
        }}
      />
      <Tab
        label="Daemons"
        value="daemons"
        onClick={() => {
          navigate.push(`/service/${projectID}/diagnostics/daemons`);
        }}
      />
      <Tab
        label="Traffic"
        value="traces"
        onClick={() => {
          navigate.push(`/service/${projectID}/diagnostics/traces`);
        }}
      />
      <Tab
        label="Traffic Filters"
        value="filters"
        onClick={() => {
          navigate.push(`/service/${projectID}/diagnostics/filters`);
        }}
      />
      <Tab
        label="Host Labels"
        value="custom-labels"
        onClick={() => {
          navigate.push(`/service/${projectID}/diagnostics/custom-labels`);
        }}
      />
    </Tabs>
  );
};
