import { Box, styled } from "@mui/material";

export const AkiTileHeader = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.overlay.min,
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1),
}));
