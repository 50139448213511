import { ChevronRight } from "@mui/icons-material";
import { Box, IconButton, Skeleton, Typography, alpha } from "@mui/material";
import React, { ReactNode, useMemo } from "react";
import { AkiTile } from "./AkiTile";
import { AkiGridItemProps } from "dashboard/components/AkiGrid";
import { Sparkline } from "dashboard/components/Sparkline";
import { TrendPill } from "dashboard/components/TrendPill";
import { abbreviateNumber } from "dashboard/utils/numbers";
import { mergeSxProps } from "dashboard/utils/sx";
import {
  DisplayTimelineValue,
  getSumTimelineValues,
  getTrendInfo,
} from "dashboard/utils/timelines";

type AkiSparkTileProps = Omit<AkiGridItemProps, "title"> & {
  aggregator?: (data?: DisplayTimelineValue[]) => number | undefined;
  data?: DisplayTimelineValue[];
  isEmptyState?: boolean;
  isLoading?: boolean;
  linkHref?: string;
  linkLabel: string;
  shouldRoundAggregation?: boolean;
  title: ReactNode;
  trendIsUpGood?: boolean;
  trendUncertainty?: number;
  units: ReactNode;
};

export const AkiSparkTile = ({
  aggregator = getSumTimelineValues,
  data,
  isEmptyState,
  isLoading,
  linkHref,
  linkLabel,
  shouldRoundAggregation,
  sx,
  title,
  trendIsUpGood,
  trendUncertainty,
  units,
  ...rest
}: AkiSparkTileProps) => {
  const { trend, direction, implication } = useMemo(
    () =>
      getTrendInfo(data, {
        uncertainty: trendUncertainty,
        isUpGood: trendIsUpGood,
      }),
    [data, trendUncertainty, trendIsUpGood]
  );

  const aggregation = useMemo(() => aggregator(data) ?? 0, [aggregator, data]);

  return (
    <AkiTile
      spanC={2}
      sx={mergeSxProps(
        { display: "flex", flexDirection: "column", padding: 0, overflow: "hidden" },
        sx
      )}
      {...rest}
    >
      <Box paddingLeft={2} paddingRight={2} paddingTop={2} position="relative">
        <Typography variant="h5" marginBottom={1}>
          {title}
        </Typography>

        <Box display="flex" alignItems="center">
          <Typography component="span" variant="body2" marginRight={1}>
            Last 7 days
          </Typography>
          <TrendPill
            isLoading={!isEmptyState && isLoading}
            trend={trend}
            direction={direction}
            implication={isEmptyState ? "uncertain" : implication}
          />
        </Box>

        {!!linkHref && (
          <IconButton
            href={linkHref}
            sx={{
              position: "absolute",
              top: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
              border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            aria-label={linkLabel}
          >
            <ChevronRight />
          </IconButton>
        )}
      </Box>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backdropFilter: "blur(3px)",
            backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.5),
            borderRadius: (theme) => theme.spacing(0.5),
            bottom: (theme) => theme.spacing(1),
            display: "flex",
            flexDirection: "column",
            padding: 1,
            position: "absolute",
            right: (theme) => theme.spacing(1),
            textAlign: "right",
            alignItems: "flex-end",
            zIndex: 2,
          }}
        >
          <Typography component="span" variant="body2">
            {units}
          </Typography>

          {!isEmptyState && isLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: (theme) => theme.spacing(0.5),
              }}
              height={50}
              width={100}
            />
          ) : (
            <Typography component="span" fontSize={48} fontWeight="bold" lineHeight={1}>
              {abbreviateNumber(
                shouldRoundAggregation ? Math.round(aggregation) : aggregation,
                shouldRoundAggregation && aggregation < 1000 ? 0 : 1
              )}
            </Typography>
          )}
        </Box>

        {data && data.length > 0 ? (
          <Sparkline data={data} implication={isEmptyState ? "uncertain" : implication} />
        ) : (
          // Spacer that matches the aspect ratio (2:1) of the Sparkline component, to prevent jumping
          <Box role="presentation" sx={{ content: '" "', paddingTop: "50%" }} />
        )}
      </Box>
    </AkiTile>
  );
};
