import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Divider, Stack, Typography } from "@mui/material";
import { subHours } from "date-fns";
import { capitalize } from "lodash";
import React, { useCallback, useRef } from "react";
import { AkiGridContainer } from "../AkiGrid";
import { AkiTile } from "../AkiTile/AkiTile";
import { AkiTileHeader } from "../AkiTile/AkiTileHeader";
import { MonitorComparatorSelect } from "./MonitorComparatorSelect";
import { MonitorMinEventCountInput } from "./MonitorMinEventCountInput";
import { MonitorSignalSelect } from "./MonitorSignalSelect";
import { MonitorThresholdInput } from "./MonitorThresholdInput";
import { EndpointAutocomplete } from "dashboard/components/EndpointAutocomplete/EndpointAutocomplete";
import { MonitorTimeline } from "dashboard/components/MonitorTimeline";
import { EndpointEntity } from "dashboard/components/entities/EndpointEntity";
import { DecodedEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import { getMonitorSignalLabel, parseMonitorThreshold } from "dashboard/utils/monitors";
import { MonitorComparator, MonitorSignalKind } from "types/akita_api_types";
import { BasicEndpoint } from "types/basic-endpoint";

type EditMonitorFormProps = {
  canSave: boolean;
  comparator: MonitorComparator;
  deploymentID: string;
  endpoint?: BasicEndpoint | DecodedEndpointUniqueID;
  endpointID?: string;
  isSaving: boolean;
  minEventCountStr: string;
  onCancel: () => void;
  onSave: () => void;
  projectID: string;
  saveButtonLabel?: string;
  setComparator: (comparator: MonitorComparator) => void;
  setEndpointID?: (endpointID: string | undefined) => void;
  setMinEventCountStr: (minEventCountStr: string) => void;
  setSignal: (signal: MonitorSignalKind) => void;
  setThresholdStr: (thresholdStr: string) => void;
  signal: MonitorSignalKind;
  thresholdStr: string;
};

export const EditMonitorForm = ({
  canSave,
  comparator,
  deploymentID,
  endpoint,
  endpointID,
  isSaving,
  minEventCountStr,
  onCancel,
  onSave,
  projectID,
  saveButtonLabel,
  setComparator,
  setEndpointID,
  setMinEventCountStr,
  setSignal,
  setThresholdStr,
  signal,
  thresholdStr,
}: EditMonitorFormProps) => {
  const { current: endMS } = useRef(Date.now());
  const startMS = subHours(endMS, 6).getTime();

  const threshold = parseMonitorThreshold(signal, thresholdStr);

  const onClickChangeEndpoint = useCallback(() => {
    if (!setEndpointID) return;

    setEndpointID(undefined);
  }, [setEndpointID]);

  return (
    <Box>
      <Box marginBottom={2}>
        <Typography variant="h3" fontWeight="bold" marginBottom={2}>
          Endpoint
        </Typography>

        {endpoint ? (
          <Stack direction={{ sm: "row", xs: "column" }} spacing={1}>
            <EndpointEntity endpoint={endpoint} />
            {setEndpointID && (
              <Button size="xsmall" variant="outlined" onClick={onClickChangeEndpoint}>
                Change
              </Button>
            )}
          </Stack>
        ) : (
          <EndpointAutocomplete
            projectID={projectID}
            deploymentID={deploymentID}
            endpointID={endpointID}
            setEndpointID={setEndpointID!}
          />
        )}
      </Box>

      {!endpoint && <Alert severity="info">Select an endpoint that you’d like to monitor.</Alert>}

      {endpoint && (
        <Box>
          <Typography variant="h3" fontWeight="bold" marginBottom={2}>
            will trigger an alert when
          </Typography>

          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={1}
            alignItems={{ md: "center" }}
            marginBottom={2}
          >
            <MonitorSignalSelect signal={signal} onChange={setSignal} />

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h3" fontWeight="bold" component="span">
                is
              </Typography>
              <MonitorComparatorSelect
                comparator={comparator}
                onChange={setComparator}
                sx={{ flexGrow: 1 }}
              />
            </Stack>

            <MonitorThresholdInput
              thresholdStr={thresholdStr}
              onChange={setThresholdStr}
              signal={signal}
            />
          </Stack>

          <Typography variant="h3" fontWeight="bold" marginBottom={1}>
            during the last 5 minutes.
          </Typography>

          <Divider />

          <AkiGridContainer paddingTop={2}>
            <AkiTile hasHeader spanC={4}>
              <AkiTileHeader>
                <Typography variant="h5" component="h2">
                  {capitalize(getMonitorSignalLabel(signal))} – Last 6 Hours
                </Typography>
              </AkiTileHeader>

              <MonitorTimeline
                deploymentID={deploymentID}
                endMS={endMS}
                endpoint={endpoint}
                projectID={projectID}
                signal={signal}
                startMS={startMS}
                threshold={threshold}
              />
            </AkiTile>

            <AkiTile hasHeader spanC={2}>
              <AkiTileHeader>
                <Typography variant="h5" component="h2">
                  Optional Settings
                </Typography>
              </AkiTileHeader>

              <Box padding={1}>
                <Typography variant="h5" component="h3" fontWeight="bold">
                  Minimum Number of Events
                </Typography>

                <Stack spacing={1}>
                  <Typography variant="body2">
                    No alert will be triggered if the number of matching events in the last 5
                    minutes is less than this.
                  </Typography>
                  <MonitorMinEventCountInput
                    minEventCountStr={minEventCountStr}
                    onChange={setMinEventCountStr}
                  />
                </Stack>
              </Box>
            </AkiTile>
          </AkiGridContainer>
        </Box>
      )}

      <Stack direction="row-reverse" spacing={1} marginTop={2}>
        <LoadingButton variant="contained" disabled={!canSave} loading={isSaving} onClick={onSave}>
          {saveButtonLabel || "Save"}
        </LoadingButton>

        <Button onClick={onCancel} variant="outlined" color="inherit">
          Cancel
        </Button>
      </Stack>
    </Box>
  );
};
