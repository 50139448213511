import { CheckCircle, Pending, RadioButtonUnchecked } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material";
import React from "react";
import { TrafficCheckStatus } from "./types";

interface TrafficCheckIconProps {
  status: TrafficCheckStatus;
  sx?: SxProps<Theme>;
}

export const TrafficCheckIcon = ({ status, sx = {} }: TrafficCheckIconProps) => {
  switch (status) {
    case "done":
      return <CheckCircle aria-label="complete" color="success" sx={sx} />;
    case "pending":
      return <Pending aria-label="in progress" color="warning" sx={sx} />;
    case "incomplete":
    default:
      return <RadioButtonUnchecked aria-label="not started" sx={sx} />;
  }
};
