import { Box, Typography, useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { VictoryLine, VictoryScatter } from "victory";
import { AkiTimelineGroup } from "dashboard/components/AkiTimelineGroup/AkiTimelineGroup";
import { ChartAnnotationMarker } from "dashboard/components/ChartAnnotationMarker";
import { DecodedEndpointUniqueID } from "dashboard/utils/endpoint-ids";
import { getMonitorHistoryTransitionAnnotations } from "dashboard/utils/monitor-history";
import {
  getMonitorSignalThresholdScale,
  getMonitorSignalUnits,
  getMonitorThresholdStrForDisplay,
  monitorSignalToAggregation,
} from "dashboard/utils/monitors";
import {
  DisplayTimelineValue,
  akitaTimelineToDisplayTimeline,
  timelineAggregationToTimelineValue,
} from "dashboard/utils/timelines";
import { useMonitorHistory } from "data/queries/monitors";
import { useTimelineQuery } from "data/queries/use-timeline-query";
import { MonitorSignalKind } from "types/akita_api_types";
import { BasicEndpoint } from "types/basic-endpoint";

type MonitorTimelineProps = {
  deploymentID: string;
  endMS: number;
  endpoint: DecodedEndpointUniqueID | BasicEndpoint;
  monitorID?: string;
  projectID: string;
  signal: MonitorSignalKind;
  startMS: number;
  threshold: number;
};

export const MonitorTimeline = ({
  deploymentID,
  endMS,
  monitorID,
  signal,
  threshold,
  endpoint,
  projectID,
  startMS,
}: MonitorTimelineProps) => {
  const theme = useTheme();

  const timelineAggregation = monitorSignalToAggregation(signal);
  const timelineValue = timelineAggregationToTimelineValue(timelineAggregation);

  const { data: dataHistory } = useMonitorHistory(projectID, deploymentID, monitorID);

  const {
    data: dataTimelines,
    isPreviousData: isPreviousDataTimelines,
    isLoading: isLoadingTimelines,
  } = useTimelineQuery(
    projectID,
    deploymentID,
    {
      endMS,
      startMS,
      bucket: "5m",
      aggregate: [timelineAggregation],
      path: endpoint.path,
      host: endpoint.host,
      method: endpoint.operation,
      key: ["host", "path", "method"],
    },
    { keepPreviousData: true }
  );

  const timelines = useMemo(() => {
    if (!dataTimelines?.timelines?.length) return undefined;

    return dataTimelines.timelines.map((apiTimeline) =>
      akitaTimelineToDisplayTimeline(apiTimeline, timelineValue, {
        color: theme.palette.foreground,
        title: "newMonitor",
      })
    );
  }, [dataTimelines?.timelines, theme.palette.foreground, timelineValue]);

  const getTooltipContent = useCallback(
    (activePoints: DisplayTimelineValue[]) =>
      activePoints.map((datum) => {
        if (datum.isAnnotation) {
          return (
            <Box key={datum.title}>
              Monitor status changed: <strong>{datum.title}</strong>
            </Box>
          );
        }

        return (
          <Box key={datum.title}>
            {datum.y !== null ? (
              <strong>
                {getMonitorThresholdStrForDisplay(signal, datum.y)}
                {getMonitorSignalUnits(signal)}
              </strong>
            ) : (
              "No Data"
            )}
          </Box>
        );
      }),
    [signal]
  );

  const thresholdMarkerData = useMemo(() => {
    if (!timelines || !Number.isFinite(threshold)) return undefined;

    return [
      { x: timelines[0].values[0].x, y: threshold },
      { x: timelines[0].values[timelines[0].values.length - 1].x, y: threshold },
    ];
  }, [threshold, timelines]);

  const alertAnnotationsData = useMemo(() => {
    if (!dataHistory) return undefined;

    return getMonitorHistoryTransitionAnnotations(dataHistory, theme, startMS);
  }, [dataHistory, theme, startMS]);

  if (!isLoadingTimelines && !isPreviousDataTimelines && (!timelines || timelines.length < 1)) {
    return (
      <Box
        sx={{
          width: "100%",
          height: 300,
          alignItems: "center",
          display: "flex",
          padding: 1,
        }}
      >
        <Typography width="100%" textAlign="center">
          No data for the last six hours.
        </Typography>
      </Box>
    );
  }

  return (
    <AkiTimelineGroup
      timelines={timelines}
      unitsY={getMonitorSignalUnits(signal)}
      voronoiBlacklist={["thresholdMarker"]}
      getTooltipContent={getTooltipContent}
      scaleY={getMonitorSignalThresholdScale(signal)}
      containerHeight={300}
      isDataStale={isPreviousDataTimelines}
    >
      {alertAnnotationsData && (
        <VictoryScatter
          name="alertAnnotations"
          data={alertAnnotationsData.values}
          dataComponent={<ChartAnnotationMarker />}
        />
      )}

      {thresholdMarkerData && (
        <VictoryLine
          name="thresholdMarker"
          style={{
            data: {
              strokeWidth: "3px",
              stroke: theme.palette.secondary.main,
              strokeDasharray: "10",
            },
          }}
          data={thresholdMarkerData}
        />
      )}
    </AkiTimelineGroup>
  );
};
