// This file ascribes types to the Akita backend API.

import { FieldProperties } from "dashboard/pages/SpecDiff/types";
import { FieldLocation } from "dashboard/utils/field_location";

export type EndpointGroupAttributes = {
  method?: string;
  host?: string;
  path_template?: string;
  response_code?: number;
};

// https://github.com/akitasoftware/akita-libs/blob/238b07b431fa948dd291de2e743c4fa3cf5734ba/api_schema/schema.go#L337
// Possible values for the "aggregate" query param of timeline queries.
export type TimelineAggregation =
  | "count" // count of events within bucket
  | "count_4xx" // count of HTTP events with a 4XX status code
  | "count_5xx" // count of HTTP events with a 5XX status code
  | "rate" // rate in events per minute
  | "max" // max of latency and RTT
  | "min" // min of latency and RTT
  | "mean" // arithmetic mean of latency and RTT
  | "median" // median value of latency and RTT
  | "90p" // 90th percentile latency and RTT
  | "95p" // 95th percentile latency and RTT
  | "99p" // 99th percentile latency and RTT
  | "99.9p" // 99.9th percentile latency and RTT
  | "fraction_4xx" // percentage of responses that are 4XX
  | "fraction_5xx"; // percentage of responses that are 5XX

// https://github.com/akitasoftware/akita-libs/blob/238b07b431fa948dd291de2e743c4fa3cf5734ba/api_schema/schema.go#L308
// @pr TODO: Rename these values to be more idiomatic; the Event_* are only here from an earlier
// copy-and-paste from a different (and incorrect) set of Go consts.
export enum TimelineValue {
  Event_Count = "count", // count of events within bucket
  Event_Rate = "rate", // rate in events per minute
  Event_Latency = "latency", // processing latency in milliseconds
  Event_Latency_Max = "latency_max", // maximum latency
  Event_Latency_Min = "latency_min", // minimum latency
  Event_Latency_Mean = "latency_mean", // arithmetic mean latency
  Event_Latency_Median = "latency_median", // median (50th percentile) latency
  Event_Latency_90p = "latency_90p", // 90th percentile latency
  Event_Latency_95p = "latency_95p", // 95th percentile latency
  Event_Latency_99p = "latency_99p", // 99th percentile latency
  Event_Latency_99_9p = "latency_99.9p", // 99.9th percentile latency
  Event_RTT = "rtt", // estimated network round-trip time, in milliseconds
  Event_RTT_Max = "rtt_max", // maximum rtt
  Event_RTT_Min = "rtt_min", // minimum rtt
  Event_RTT_Mean = "rtt_mean", // arithmetic mean rtt
  Event_RTT_Median = "rtt_median", // median (50th percentile) rtt
  Event_RTT_90p = "rtt_90p", // 90th percentile rtt
  Event_RTT_95p = "rtt_95p", // 95th percentile rtt
  Event_RTT_99p = "rtt_99p", // 99th percentile rtt
  Event_RTT_99_9p = "rtt_99.9p", // 99.9th percentile rtt
  Event_Num_4xx = "num_4xx", // Number of calls that resulted in a 4XX response.
  Event_Num_5xx = "num_5xx", // Number of calls that resulted in a 5XX response.
  Event_Fraction_4xx = "fraction_4xx", // percentage of responses that are 4XX
  Event_Fraction_5xx = "fraction_5xx", // percentage of responses that are 5XX
}

// @pr TODO: Remove this once the consuming code is gone, or updated to use the actual data structure.
export type TimelineValuesMap = Map<TimelineValue, number | null>;

// This more accurately represents the real structure of a timeline event.value object than
// TimelineValuesMap, and is therefore safer to use.
export type TimelineValues = Partial<Record<TimelineValue, number>>;

// See github.com/akitasoftware/akita-libs/api_schema/schema.go.
export type TimelineEvent<T = TimelineValuesMap> = {
  time: string;
  values: T;
};

// See github.com/akitasoftware/akita-libs/api_schema/schema.go.
export type Timeline<T = TimelineValues> = {
  group_attrs: EndpointGroupAttributes;
  events: TimelineEvent<T>[];
};

export type GenerateApiKeyResponse = {
  /** API Key ID */
  resource_id: string;

  /** API Key Secret */
  api_key: string;
};

export type ApiKeySummary = {
  id: string;
  created_at: string;
};

export type CreateServiceResponse = {
  resource_id: string;
};

export type GetApiKeysResponse = ApiKeySummary[];

export type LearnSessionTag = {
  key: string;
  value: string;
};

export type MethodMetadataResponse = {
  host: string;
  operation: string;
  path: string;
  performance?: MethodPerformanceResponse;
  method_id: string;
};

export type GetMethodsMetadata = {
  metadata?: MethodMetadataResponse[];
  summary?: SpecContentSummary;
  total_count: number;
};

export type TimelineSummaryEndpoint = {
  count: number;
  host: string;
  latency_90p: number;
  latency_95p: number;
  latency_99p: number;
  "latency_99.9p": number;
  latency_median: number;
  operation: string;
  path: string;
  response_code: number;
};

export type GetTimelineSummary = {
  filter_summary?: TimelineSummaryFilters;
  timeline_summary?: TimelineSummaryEndpoint[];
  total_count: number;
};

export type LearnSessionSummary = {
  id: string;
  name?: string;
  creation_time: string;
  tags: LearnSessionTag[];

  // List of API specs built from this learn session.
  api_spec_ids?: string[];

  // Number of witnesses in this learn session.
  stats?: {
    num_witnesses: number;
  };
};

export type GetLearnSessionsResponse = {
  sessions?: LearnSessionSummary[];
};

export type GetSpecLearnSessionsResponse = {
  sessions: string[];
};

export type ServiceSummary = {
  id: string;
  name: string;
  deployments: string[];
  deployment_infos: DeploymentInfo[];
};

export type DeploymentInfo = {
  first_observed: string; // "2022-03-15T00:26:00Z";
  last_observed: string; // "2022-03-15T00:52:00Z";
  name: string;
};

export type GetServicesResponse = ServiceSummary[];

// TODO: SpecStats should be renamed to WitnessStats
export type SpecStats = {
  num_witnesses: number;
  num_witnesses_by_data_type: Record<string, number>;
  num_witnesses_by_arg: Record<string, number>;
  num_witnesses_by_resp: Record<string, number>;
  num_witnesses_by_status_code: Record<string, number>;
};

export type GetSpecStatsResponse = {
  inbound_stats: SpecStats;
  outbound_stats: Record<string, SpecStats>;
};

export type Tags = {
  [key: string]: string | string[];
};

export type SpecSummary = {
  id: string;
  name?: string;
  creation_time: string;
  edit_time: string;
  state: string;

  tags?: Tags;
  tags_set?: Tags;
  version_tags?: string[];

  // Number of endpoints and trace events
  num_endpoints?: number;

  // Start and end time of events in model
  trace_start_time?: string;
  trace_end_time?: string;
};

export type GetSpecsResponse = {
  specs?: SpecSummary[];
  last_page: boolean;
};

export type GetMethodResponse = {
  method?: MethodResponse;
};

export type SpecContentSummary = {
  endpoint_categories: Record<string, number>;
  authentications: Record<string, number>;
  data_formats: Record<string, number>;
  data_kinds: Record<string, number>;
  hosts: Record<string, number>;
  http_methods: Record<string, number>;
  directions: Record<string, number>;
  data_types: Record<string, number>;
  params: Record<string, number>;
  paths: Record<string, number>;
  properties: Record<string, number>;
  response_codes: Record<string, number>;
};

export type TimelineSummaryFilters = {
  endpoint_categories: Record<string, number>;
  hosts: Record<string, number>;
  http_methods: Record<string, number>;
  paths: Record<string, number>;
  response_codes: Record<string, number>;
};

export type GetSpecResponse = {
  content: string;
  learn_session_ids: string[];
  name: string;
  summary: SpecContentSummary;
  state: string;
  tags: Tags;
};

export type GetSpecMetadataResponse = {
  name: string;
  state: string;
  tags: Tags;
  tags_set?: Tags;
};

export type GetSpecMethodsResponse = {
  methods?: MethodResponse[];
  total_method_count: number;
  method_count: number;
  name: string;
  summary: SpecContentSummary;
  state: string;
  tags: Tags;
};

export type MethodResponse = {
  content: string;
  host: string;
  operation: string;
  path: string;
  fields?: MethodResponseField[];
  performance?: MethodPerformanceResponse;
  method_id: string;
};

export type MethodResponseField = {
  location: FieldLocation;
  properties: FieldProperties;
};

export type MethodPerformanceResponse = {
  num_measurements: number;
  latency_ms_p50: number;
  latency_ms_p90: number;
  latency_ms_p95: number;
  latency_ms_p99: number;
  "latency_ms_p99.9": number;
  num_4xx: number;
  num_5xx: number;
};

export type GetSpecSummaryResponse = {
  name: string;
  summary: SpecContentSummary;
  state: string;
  tags: Tags;
};

export type UploadSpecRequest = {
  name: string;

  // TODO(kku): Currently storing spec content in JSON payload. Use
  // multipart/form-data upload once front service can support that.
  content: string;
};

export type UploadSpecResponse = {
  id: string;
};

export type GetServiceStatsResponse = {
  num_api_endpoints: number;
  num_api_specs: number;
  service_witness_stats: GetSpecStatsResponse;
  last_learn_time?: string;
};

export type SpecVersion = {
  name: string;
  api_spec_id: string;
  service_id: string;
  creation_time: string;
};

export type GetSpecVersionsResponse = {
  versions: SpecVersion[];
};

export type TrafficShapeCount = {
  // String-encoded OpenAPI3 spec
  shape: string;
  count: number;
};

export type TrafficOperation = {
  host: string;
  port: number;
  operation: string;
  path: string;
  response_code: number;
  num_requests: number;
  num_requests_by_shape: TrafficShapeCount[];
};

export type GetOutboundTrafficResponse = {
  traffic: TrafficOperation[];
};

export type PostServiceAddressResponse = {
  resource_id: string;
};

export type AkitaUserInfo = {
  id: string;
  created_at: string;
  name: string;
  tos_accepted_at: string;
  organization_id: string;
};

export type GetTeamResponse = AkitaUserInfo[];

export type OrganizationInvite = {
  id: string;
  invitee_email: string;
  invitee_identity_id: string;
  inviter_email: string;
  inviter_name: string;
  organization_id: string;
  created_at: string;
};

export type GetInvitesResponse = OrganizationInvite[];

export type EndpointLocation = {
  rest_op: string;
  server: string;
  rest_path: string;
};

export type CorrelationParameter = {
  inbound_parameter: string;
  outbound_parameter: string;
};

export type Correlation = {
  id: string;
  learn_session_id: string;
  active_learning_version: string;
  inbound_endpoint: EndpointLocation;
  outbound_endpoint: EndpointLocation;
  parameters: CorrelationParameter[];
  created_at: string;
};

export type GetCorrelation = {
  correlations: Correlation[];
};

export type Feature = {
  name: string;
  enabled: boolean;
};

export type GetFeaturesResponse = {
  features: Feature[];
};

export type ScheduledTraceSession = {
  trace_id: string;
  trace_name: string;
  start_time: string;
  end_time: string;
  sampling_rate: number;
  filter_third_party_trackers: boolean;
};

export type ActiveMiddlewareDaemon = {
  daemon_name: string;
  last_heartbeat: string;
  last_poll: string;
  last_response: string;
};

export type ActiveMiddlewareTrace = {
  trace_id: string;
  trace_name: string;
};

export type GetMiddlewareResponse = {
  active_trace_sessions: ActiveMiddlewareTrace[];
  daemons: ActiveMiddlewareDaemon[];
  scheduled_trace_sessions: ScheduledTraceSession[];
};

export type DaemonInfo = {
  daemon_name: string;
  last_heartbeat: string;
};

export type GetDaemonsResponse = DaemonInfo[];

// See https://github.com/akitasoftware/superstar/blob/1f44e86d44e674f16d4dba9205e268cdd4dda5e0/services/kings_cross/rest/timeline/timeline.go#L58
export type GetTimelineOptions = {
  // Start and end of report
  // Should be epoch times on the wire so we don't have to mess with
  // time/date parsing at the API level.
  // Defaults to (now-1 week, now)
  start: number;
  end: number;

  // Bucket size, leave as default value to report every call as a separate timeline event.
  // Must be parseable by Golang's time.Duration, e.g. "2h53m".
  bucket: string;
};

export type GetTimelineResponse = {
  actual_start_time: string;
  actual_end_time: string;
  next_start_time?: string;
  timelines?: Timeline<TimelineValues>[];
};

export type GetTimelineDataSourcesResponse = {
  tags: { [tag: string]: string[] };
};

export type GetDeploymentsResponse = {
  deployments: DeploymentInfo[];
};

export type MergeSpecsResponse = {
  id: string;
};

export type EndpointRemapRule = {
  action: {
    change_host_name: string | null;
    label_source_service: string | null;
    omit_request: null | string;
  };
  condition: {
    host_name_ends_with: string | null;
    host_name_matches: string | null;
    http_path_ends_with: string | null;
    http_path_starts_with: string | null;
    request_has_port: string | null;
    source_ip_address: string | null;
  };
  id: string | null;
};

export type EndpointRemapRulesResponse = {
  full_rules: EndpointRemapRule[];
  user_rules: EndpointRemapRule[];
};

export type PacketCounts = {
  interface: string;
  src_port: number;
  dst_port: number;

  tcp_packets: number;
  http_requests: number;
  http_responses: number;
  tls_hello: number;
  unparsed: number;
};

export type PacketCountSummary = {
  version: string;
  total: PacketCounts;
  top_by_port: { [key: number]: PacketCounts };
  top_by_interface: { [key: string]: PacketCounts };

  // XXX(cns): Only counts HTTP requests and TLS handshakes.
  top_by_host: { [key: string]: PacketCounts };
};

// These error types are defined in
// https://github.com/akitasoftware/akita-libs/blob/4d0a4a501d88d026ed1e7541454ef0dbd4d02a50/api_schema/schema.go#L482
export enum ApidumpError {
  PCAPPermission = "PCAP permission failure",
  PCAPInterfaceNotImplemented = "PCAP interface not implemented",
  PCAPInterfaceOther = "Other PCAP interface failure",
  InvalidFilters = "Invalid filters",
  TraceCreation = "Trace creation failure",
  Other = "Other error",
}

// These error types are defined in
// https://github.com/akitasoftware/superstar/blob/2ffa9f0d258d8b515a51aedd1e571d86de57879d/services/kings_cross/rest/common.go#L351
export enum InferredError {
  Waiting = "Waiting for telemetry",
  Missing = "No telemetry",
  Empty = "Empty capture",
  EmptyDockerDesktop = "Empty capture on Docker Desktop",
  TLS = "Only TLS traffic",
  TLS_v0 = "Probably TLS traffic",
  Unparsable = "Unparsable traffic",
}

export type ClientErrorReport = {
  Version: string;
  apidump_error?: ApidumpError;
  apidump_error_text?: string;
  inferred_capture_error?: InferredError;
};

export type AgentResourceUsage = {
  observed_duration_in_seconds: number;
  observed_starting_at: string;
  peak: AgentResourceUsageData;
  recent: AgentResourceUsageData;
};

export type AgentResourceUsageData = {
  cpus_used: number;
  relative_cpu: number;
  vm_hwm: number;
};

export type ClientPacketCaptureStats = {
  client_id: string;
  deployment: string;
  edit_time: string;
  observed_starting_at: string;
  observed_duration_in_seconds: number;
  packet_count_summary?: PacketCountSummary;
  error?: ClientErrorReport;
  agent_resource_usage?: AgentResourceUsage;
};

export type GetClientPacketCaptureStatsResponse = {
  stats: ClientPacketCaptureStats[];
};

export type GetClientTelemetryCountRunningResponse = {
  count: number;
};

export type ServiceUsageStats = {
  service_id: string;
  witness_count: number;
};

export type GetUsageStatsResponse = {
  organization_id: string;
  total_witness_count: number;
  projected_witness_count?: number;
  service_usage_stats: ServiceUsageStats[];
  observation_interval_start: string;
  observation_interval_end: string;
  min_observation_time?: string;
  max_observation_time?: string;
  has_projected_stats: boolean;
};

export type OrganizationUserDetails = {
  id: string;
  name: string;
  email: string;
  created_at: string;
};

export type OrganizationServiceDetails = {
  id: string;
  name: string;
  deployments: string[];
  created_at: string;
};

export type OrganizationDetails = {
  organization_id: string;
  inferred_name: string;
  users: OrganizationUserDetails[];
  services: OrganizationServiceDetails[];
};

export type GetAdminDashboardsOrganizationsResponse = {
  organizations: OrganizationDetails[];
};

// Monitor signal kinds are defined in the superstar repository:
// https://github.com/akitasoftware/superstar/blob/082eed03ca4f64d84305ebdca0d620e514e76705/services/kings_cross/rest/common.go#L349
export enum MonitorSignalKind {
  COUNT = "COUNT",
  COUNT_4XX = "COUNT_4XX",
  COUNT_5XX = "COUNT_5XX",
  RATE_4XX = "RATE_4XX",
  RATE_5XX = "RATE_5XX",
  LATENCY_MAX = "LATENCY_MAX",
  LATENCY_MIN = "LATENCY_MIN",
  LATENCY_MEAN = "LATENCY_MEAN",
  LATENCY_MEDIAN = "LATENCY_MEDIAN",
  LATENCY_90P = "LATENCY_90P",
  LATENCY_95P = "LATENCY_95P",
  LATENCY_99P = "LATENCY_99P",
}

// https://github.com/akitasoftware/superstar/blob/4cf06fc5bbcc8ba585676d5aff467577aeff69bf/services/kings_cross/rest/common.go#L401
export enum MonitorComparator {
  GT = "GT",
  GTE = "GTE",
  LT = "LT",
  LTE = "LTE",
  EQ = "EQ",
}

export type MonitorState = "ACTIVE" | "PROPOSED" | "SUPPRESSED";

type MonitorTrigger = {
  signal: MonitorSignalKind;
  comparator: MonitorComparator;
  threshold: number;
  min_event_count: number;
};

type MonitorUserStatus = {
  hidden?: boolean;
  muted_until?: string;
  muted_forever?: boolean;
};

export type MonitorUpdate = {
  monitor_id: string;
} & MonitorTrigger &
  MonitorUserStatus;

export type Monitor = {
  monitor_id: string;

  http_method: string;
  host: string;
  path_template: string;

  in_alert_state: boolean;
  observed_at: string | null;

  state: MonitorState;
  // Populated when the state is "PROPOSED"
  proposed_reason?: string;
} & MonitorTrigger &
  MonitorUserStatus;

export type MonitorToCreate = {
  http_method: string;
  host: string;
  path_template: string;
} & MonitorTrigger &
  MonitorUserStatus;

export type GetMonitorsQueryParams = {
  limit?: number;
  offset?: number;
  state?: MonitorState | MonitorState[];
  triggered?: boolean;
  required_endpoints?: string[];
};

export type GetMonitorsResponse = {
  monitors: Monitor[];
  next_offset: number;
  total_count: number;
};

export type MonitorHistoryItem = {
  in_alert_state: boolean;
  num_buckets_with_data: number;
  observed_at: string;

  // An undefined value means no signal could be computed, because there was
  // no observed traffic at this time.  For example, we can't compute latency
  // without traffic.
  signal_value?: number;
};

export type GetMonitorHistoryResponse = {
  history: MonitorHistoryItem[] | null;
  next_offset?: number;
};

export type GetRecentlyAlertedMonitorsQueryParams = {
  limit?: number;
  offset?: number;
};

export type RecentlyAlertedMonitor = {
  alert_end_time: string | null;
  alert_start_time: string | null;
  comparator: MonitorComparator;
  host: string;
  http_method: string;
  min_event_count: number;
  monitor_id: string;
  path_template: string;
  signal: MonitorSignalKind;
  state: MonitorState;
  threshold: number;
};

export type GetRecentlyAlertedMonitorsResponse = {
  monitors: RecentlyAlertedMonitor[];
  next_offset?: number;
  total_count: number;
};

export type AlertHistoryEvent = {
  comparator: MonitorComparator;
  event_time: string;
  min_event_count: number;
  threshold: number;
};

export type AlertHistoryEventPair = {
  end_event: AlertHistoryEvent | null;
  start_event: AlertHistoryEvent;
};

export type GetAlertHistoryQueryParams = {
  limit?: number;
  offset?: number;
};

export type GetAlertHistoryResponse = {
  alerts: AlertHistoryEventPair[];
};

export type SlackInstallationDetailsResponse = {
  organization_id: string;
  is_installed: boolean;
  enterprise_name?: string;
  team_name?: string;
  incoming_webhook?: {
    channel_name: string;
    configuration_url: string;
  };
  scopes: Set<string>;
};

export type ActivationURLResponse = {
  user_email: string;

  // Empty if error message is present.
  activation_link: string;

  response_code: number;
  error_message: string;
};

export type ReactivateUsersResponse = {
  activation_urls: ActivationURLResponse[];
};

export type PublicReactivateUsersResponse = Record<string, never>;

export type SpecEdit =
  | {
      hide_endpoint: {
        method: string;
        host: string;
        path_template: string;
      };
    }
  | {
      unhide_endpoint: {
        method: string;
        host: string;
        path_template: string;
      };
    }
  | {
      rename_path_parameters: {
        host: string;
        path_template_prefix: string;
        new_name: string;
      }[];
    }
  | CollapsePathsEditRequest;

export type CollapsePathsEditRequest = {
  // The method, host, and path_templates of the endpoints to collapse.
  combine_paths: {
    include_endpoints: {
      method: string;
      host: string;
      path_template: string;
    }[];
    // All editable path parameters of the endpoints to collapse mapped to their
    // new names and exceptional values.
    path_parameters: Record<
      string,
      {
        name: string;
        exceptional_values: string[];
      }
    >;
  };
};

export type CheckCollapseEndpointsRequest = {
  method: string;
  host: string;
  path_template: string;
}[];

export type CheckCollapseEndpointsResponse = {
  inferred_parameters: Record<
    string,
    {
      observed_values: Record<
        string,
        {
          exceptional_value: boolean;
        }
      >;
    }
  >;
};
