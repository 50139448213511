import { SxProps, Theme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LastSelectedProjectAndDeploymentKey } from "dashboard/utils/local_storage_keys";
import { useCurrentDeploymentId } from "hooks/use-current-deployment-id";
import { useCurrentProjectId } from "hooks/use-current-project-id";
import { GetServicesResponse } from "types/akita_api_types";
import { ProjectAndDeployment } from "types/client_side_util_types";

type ProjectSelectorProps = {
  projects?: GetServicesResponse;
  projectsAreLoading: boolean;
  sx?: SxProps<Theme>;
};

export const ProjectSelector = ({ projects, projectsAreLoading, sx }: ProjectSelectorProps) => {
  const currentProjectId = useCurrentProjectId();
  const currentDeploymentId = useCurrentDeploymentId();
  const navigate = useHistory();
  const location = useLocation();

  const navigateToProject = (event: any) => {
    const newProjectId = event.target.value;
    const deployments = projects?.find((project) => project.id === newProjectId)?.deployments;

    if (!currentProjectId) {
      return console.error("No project ID when navigating to project");
    }

    let newURL = location.pathname.replace(currentProjectId, newProjectId);

    let newDeploymentId: string | undefined;
    if (currentDeploymentId && !deployments?.includes(currentDeploymentId)) {
      newDeploymentId = deployments?.includes("default") ? "default" : deployments?.[0];

      if (newDeploymentId) {
        newURL = newURL.replace(currentDeploymentId, newDeploymentId);
      }
    }

    const deploymentIdToStore = newDeploymentId || currentDeploymentId;
    if (deploymentIdToStore) {
      const projectAndDeployment: ProjectAndDeployment = {
        serviceId: newProjectId,
        deploymentName: deploymentIdToStore,
      };
      window.localStorage.setItem(
        LastSelectedProjectAndDeploymentKey,
        JSON.stringify(projectAndDeployment)
      );
    }

    navigate.push(newURL);
  };

  if (projectsAreLoading) {
    return null;
  }

  return (
    <FormControl sx={sx}>
      <Typography variant="h6" id="project-selector-label">
        Project
      </Typography>
      <Select
        labelId="project-selector-label"
        name="project-selector"
        onChange={navigateToProject}
        size="small"
        value={currentProjectId ?? ""}
      >
        {(projects || []).map((p) => (
          <MenuItem key={p.id} value={p.id}>
            {p.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
