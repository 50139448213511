import { Link, LinkProps } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { mergeSxProps } from "dashboard/utils/sx";

type NavbarLinkProps = LinkProps & {
  href: string;
  shouldAlignToLogo?: boolean;
};

export const NavbarLink = ({ shouldAlignToLogo = true, sx, href, ...rest }: NavbarLinkProps) => {
  const location = useLocation();
  const isActive =
    // Don't match an empty or root ("/") pathname, since that would result in all links showing as active.
    location?.pathname &&
    location?.pathname !== "/" &&
    (href.includes(location?.pathname) || location?.pathname?.includes(href));

  return (
    <Link
      sx={mergeSxProps(
        (theme) => ({
          alignItems: "center",
          color: "#FFFFFF",
          display: "flex",
          textDecoration: "none",
          transition: "opacity linear 200ms, border linear 200ms",
          borderBottom: "2px solid",
          borderColor: isActive ? "#FFFFFF" : "transparent",
          position: "relative",
          top: shouldAlignToLogo ? 3 : 0,
          "&:hover": { opacity: 0.7 },

          [theme.breakpoints.down("md")]: {
            color: theme.palette.foreground,
            paddingLeft: 1,
            fontSize: 18,
            borderBottom: 0,
            borderLeft: "2px solid",
            borderColor: isActive ? theme.palette.foreground : "transparent",
          },
        }),
        sx
      )}
      variant="body2"
      href={href}
      {...rest}
    />
  );
};
