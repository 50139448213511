import { TabPanel } from "@mui/lab";
import { Box, LinearProgress } from "@mui/material";
import React, { Suspense, lazy, useCallback, useMemo } from "react";
import { EndpointFieldsTable } from "../EndpointFieldsTable/EndpointFieldsTable";
import { QueryError } from "../QueryError";
import { SpecEditSuccessDialog } from "../SpecEditSuccessDialog";
import { EndpointDetailsAkiChat, useEndpointDetailsAkiChat } from "./EndpointDetailsAkiChat";
import { EndpointDetailsTabPanelSettings } from "./EndpointDetailsTabPanelSettings";
import { FormattedEndpointField, endpointCanHaveBody, formatFields } from "./formatting";
import { getPathTemplatePrefixForParameter } from "dashboard/utils/path-parameters";
import { usePostEditSpecs } from "data/queries/edit-specs";
import { EndpointParams, useEndpoint } from "data/queries/endpoint";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";
import { useLogInteraction } from "hooks/use-log-interaction";

const EndpointDetailsAsRedoc = lazy(() => import("dashboard/components/EndpointDetailsAsRedoc"));

type EndpointDetailsContentProps = {
  projectID: string;
  modelID?: string;
  endpointID?: string;
  endpointParams?: EndpointParams;
  onEdit: () => void;
};

export const EndpointDetailsContent = ({
  projectID,
  modelID,
  endpointID,
  endpointParams,
  onEdit,
}: EndpointDetailsContentProps) => {
  const isEnabledAskAki = useIsFlagEnabled(FeatureFlag.AskAkiEnabled);

  const {
    data: dataEndpoint,
    isLoading: isLoadingEndpoint,
    error: errorEndpoint,
    refetch: refetchEndpoint,
  } = useEndpoint(projectID, modelID, endpointID, endpointParams);

  const {
    error: errorEditSpecs,
    isLoading: isSavingEditSpecs,
    isSuccess: isSuccessEdit,
    mutateAsync: postEditSpecs,
  } = usePostEditSpecs();

  const [akiChatMessages, setAkiChatMessages, initialAkiChatPromptLength] =
    useEndpointDetailsAkiChat(dataEndpoint);

  const logInteraction = useLogInteraction({ projectID, modelID, endpointID });

  const onEditStartPathParams = useCallback(
    () => logInteraction("Started renaming path parameters"),
    [logInteraction]
  );

  const onSavePathParams = useCallback(
    (pathParameterFields: FormattedEndpointField[]) => {
      if (!dataEndpoint || !modelID) return;

      const renames = pathParameterFields.map((field) => ({
        host: dataEndpoint.host,
        path_template_prefix: getPathTemplatePrefixForParameter(
          dataEndpoint.path,
          field.display.position!
        ),
        new_name: field.display.fieldName,
      }));

      void postEditSpecs({
        projectID,
        modelID,
        edits: [{ rename_path_parameters: renames }],
      }).then(() => {
        logInteraction("Renamed path parameters", { fieldsCount: pathParameterFields.length });
      });
    },
    [dataEndpoint, modelID, postEditSpecs, projectID, logInteraction]
  );

  const formattedFields = useMemo(
    () => formatFields(dataEndpoint?.fields, dataEndpoint?.path),
    [dataEndpoint?.fields, dataEndpoint?.path]
  );

  const canHaveBody = dataEndpoint ? endpointCanHaveBody(dataEndpoint) : false;

  return (
    <Box>
      {isSuccessEdit && <SpecEditSuccessDialog onClose={onEdit} />}

      <QueryError
        error={errorEndpoint}
        label="We had trouble loading your endpoint."
        refetch={refetchEndpoint}
      />

      <TabPanel sx={{ padding: 0 }} value="params">
        <EndpointFieldsTable
          fields={formattedFields?.params?.path}
          isEditable
          isLoading={isLoadingEndpoint}
          isEditSaving={isSavingEditSpecs}
          onEditSave={onSavePathParams}
          onEditStart={onEditStartPathParams}
          sx={{ marginBottom: 2 }}
          title="Path Parameters"
          includePosition
          editButtonLabel="Rename"
          editError={
            <QueryError
              error={errorEditSpecs}
              label="We had trouble updating your path parameters."
              sx={{ flexGrow: 1, margin: 0 }}
            />
          }
        />

        <EndpointFieldsTable
          title="Query Parameters"
          fields={formattedFields?.params?.query}
          isLoading={isLoadingEndpoint}
        />
      </TabPanel>

      <TabPanel sx={{ padding: 0 }} value="headers">
        <EndpointFieldsTable
          title="Request Headers"
          fields={formattedFields?.headers?.request}
          isLoading={isLoadingEndpoint}
          sx={{ marginBottom: 2 }}
        />

        <EndpointFieldsTable
          title="Response Headers"
          fields={formattedFields?.headers?.response}
          isLoading={isLoadingEndpoint}
          includeResponseCode
        />
      </TabPanel>

      <TabPanel sx={{ padding: 0 }} value="body">
        {canHaveBody && (
          <EndpointFieldsTable
            title="Request Body"
            fields={formattedFields?.body?.request}
            isLoading={isLoadingEndpoint}
            sx={{ marginBottom: 2 }}
            includeContentType
          />
        )}

        <EndpointFieldsTable
          title="Response Body"
          fields={formattedFields?.body?.response}
          isLoading={isLoadingEndpoint}
          includeResponseCode
          includeContentType
        />
      </TabPanel>

      {isEnabledAskAki && (
        <TabPanel sx={{ padding: 0 }} value="aki">
          <EndpointDetailsAkiChat
            endpoint={dataEndpoint}
            initialPromptLength={initialAkiChatPromptLength}
            messages={akiChatMessages}
            setMessages={setAkiChatMessages}
            endpointContext={{
              endpointID,
              ...endpointParams,
            }}
          />
        </TabPanel>
      )}

      <TabPanel sx={{ padding: 0 }} value="openapi3">
        <Suspense fallback={<LinearProgress />}>
          <EndpointDetailsAsRedoc isLoading={isLoadingEndpoint} method={dataEndpoint} />
        </Suspense>
      </TabPanel>

      <EndpointDetailsTabPanelSettings
        modelID={modelID}
        projectID={projectID}
        endpoint={dataEndpoint}
      />
    </Box>
  );
};
