import { useMemo } from "react";
import { UseSearchParamOptions, useSearchParam } from "./use-search-param";

/**
 * Hook that reads and writes from the given search parameter key in a URL, while preserving other
 * search parameters.
 *
 * Also takes a default value, which will be returned when there is no value set in the URL.
 */
export const useSearchParamWithDefaultValue = <T extends string = string>(
  key: string,
  defaultValue: T
): [value: T, setValue: (newValue: T, options?: UseSearchParamOptions) => void] => {
  const result = useSearchParam<T>(key);

  return useMemo(
    () => [
      result[0] ?? defaultValue,
      result[1] as (newValue: T, options?: UseSearchParamOptions) => void,
    ],
    [result, defaultValue]
  );
};
