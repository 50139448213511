import { Button } from "@mui/material";
import { Analytics } from "@segment/analytics-next";
import React, { useEffect, useState } from "react";
import { trackEvent } from "../../../analytics/event-utils";
import { AuthStatus, useOAuth2 } from "../../../auth/use-oauth2";
import {
  postSlackInstallation,
  useSlackInstallation,
} from "../../../data/queries/slack-installation";
import { useAnalyticsContext } from "../../../hooks/use-analytics-context";
import { SlackInstallationState, defaultSlackInstallationState } from "./utils";

interface AddSlackButtonProps {
  setSlackInstallation: React.Dispatch<React.SetStateAction<SlackInstallationState>>;
  analytics: Analytics | undefined;
  scope: string[];
}

const AddSlackButton = ({ setSlackInstallation, analytics, scope }: AddSlackButtonProps) => {
  const host = window.location.host;
  let clientID = process.env.REACT_APP_SLACK_CLIENT_ID;
  if (!clientID) {
    clientID = "467201431381.3945109262820";
  }

  const { getAuth, status, code, error } = useOAuth2({
    authorizeURL: "https://slack.com/oauth/v2/authorize",
    clientID,
    redirectURI: `https://${host}/slack/callback`,
    scope,
  });

  useEffect(() => {
    const slackInstallationFailedEvent = (errMsg: string) => {
      trackEvent("Slack Installation: Failed", analytics, {
        error: errMsg,
      });
    };

    const handleOauthFlow = (oauthCode: string) => {
      void postSlackInstallation(oauthCode)
        .then((response) => {
          if (response.is_installed) {
            trackEvent("Slack Installation: Complete", analytics);
          } else {
            slackInstallationFailedEvent("Backend failed to complete app installation");
          }
          setSlackInstallation({
            isInstalled: response.is_installed,
            configurationURL: response.incoming_webhook?.configuration_url,
          });
        })
        .catch((err) => {
          slackInstallationFailedEvent(`Failed to complete backend OAuth flow: ${err}`);
        });
    };

    if (status == AuthStatus.AUTHORIZED && code) {
      handleOauthFlow(code);
    } else if (status === AuthStatus.FAILED) {
      slackInstallationFailedEvent(error || "Unknown failure during Front-End OAuth flow");
    } else if (status === AuthStatus.CANCELED) {
      trackEvent("Slack Installation: Auth Popup Closed", analytics);
    }
  }, [status, code, error, analytics, setSlackInstallation]);

  return (
    <Button
      onClick={() => getAuth()}
      disabled={status === AuthStatus.AUTHORIZING}
      sx={{ padding: 0 }}
      size="small"
    >
      <img
        alt="Add to Slack"
        height="36"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </Button>
  );
};

interface SlackConfigurationButtonProps {
  configurationURL: string;
}

const SlackConfigurationButton = ({ configurationURL }: SlackConfigurationButtonProps) => (
  <Button
    href={configurationURL}
    target="_blank"
    rel="noreferrer"
    size="small"
    color="inherit"
    variant="outlined"
  >
    Configure Slack
  </Button>
);

export const SlackIntegration = () => {
  const [slackInstallation, setSlackInstallation] = useState<SlackInstallationState>(
    defaultSlackInstallationState
  );

  const { analytics } = useAnalyticsContext();

  const { data: slackInstallationData, isLoading: isLoadingSlackInstallation } =
    useSlackInstallation();

  useEffect(() => {
    if (!isLoadingSlackInstallation && slackInstallationData) {
      setSlackInstallation({
        isInstalled: slackInstallationData.is_installed,
        configurationURL: slackInstallationData.incoming_webhook?.configuration_url,
      });
    }
  }, [isLoadingSlackInstallation, slackInstallationData]);

  // Avoid flashing the "add" button when this is already installed, but we're still loading.
  if (isLoadingSlackInstallation) return null;

  return slackInstallation.isInstalled ? (
    <SlackConfigurationButton configurationURL={slackInstallation.configurationURL!} />
  ) : (
    <AddSlackButton
      setSlackInstallation={setSlackInstallation}
      analytics={analytics}
      scope={["incoming-webhook"]}
    />
  );
};
