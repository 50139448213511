import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useMemo } from "react";
import { InternalOrgsTableRow } from "./InternalOrgsTableRow";
import { OrganizationDetails } from "types/akita_api_types";

interface InternalOrgsTableProps {
  organizations?: OrganizationDetails[];
}

export const InternalOrgsTable = ({ organizations = [] }: InternalOrgsTableProps) => {
  const rows = useMemo(
    () =>
      organizations.map((organization) => (
        <InternalOrgsTableRow key={organization.organization_id} organization={organization} />
      )),
    [organizations]
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Inferred Name</TableCell>
            <TableCell align="right">Projects</TableCell>
            <TableCell align="right">Users</TableCell>
            <TableCell align="right">Details</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};
