import { Box, Divider, List, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { trackEvent } from "../../../analytics/event-utils";
import { useAnalyticsContext } from "../../../hooks/use-analytics-context";
import { StepGetAPIKey } from "./StepGetAPIKey";
import { StepSelectProject } from "./StepSelectProject";
import { StepStartClient } from "./StepStartClient";
import { StepTrafficCheck } from "./StepTrafficCheck";
import { OnboardingStep } from "dashboard/components/OnboardingStep/OnboardingStep";
import { Page, PageContent, PageHeader, PageTitle } from "dashboard/components/Page";
import { DockerDesktopOriginFlagKey } from "dashboard/utils/local_storage_keys";
import { useGenerateAPIKey } from "data/queries/api-keys";
import { useProjects } from "data/queries/projects";
import { useImpression } from "hooks/use-impression";
import { GenerateApiKeyResponse, ServiceSummary } from "types/akita_api_types";

interface OnboardingPageProps {
  projectID?: string;
}

// If we have a recent localstorage entry indicating that the user clicked the sign up
// link from the Docker Extension, assume that's their intended way of setting up Akita
const isDockerExtensionInstallHeuristic = () => {
  const maybeDockerDesktopOriginData = window.localStorage.getItem(DockerDesktopOriginFlagKey);
  return (
    !!maybeDockerDesktopOriginData &&
    new Date(
      Date.parse(JSON.parse(maybeDockerDesktopOriginData).docker_desktop_origin_flag_set_at)
    ) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
};

export const OnboardingPage = ({ projectID }: OnboardingPageProps) => {
  const { analytics } = useAnalyticsContext();
  const { data: projects } = useProjects();
  const defaultProject = projects?.find((p) => p.id === projectID);

  // STATE
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [project, setProject] = useState<ServiceSummary | undefined>(defaultProject);
  const [apiKey, setApiKey] = useState<GenerateApiKeyResponse | undefined>(undefined);

  // FETCHING
  const generateAPIKey = useGenerateAPIKey({ onSuccess: setApiKey });

  // EFFECTS
  // Old-style event, retained for compatibility with existing dashboards.
  useEffect(() => {
    trackEvent("Started Onboarding", analytics, {
      source: "superstar-frontend",
      initialprojectID: projectID,
      isV2: true,
    });
  }, [analytics, projectID]);

  useImpression("Onboarding Page", { initialprojectID: projectID });

  const [isDockerDesktopInstall, setIsDockerDesktopInstall] = useState(
    isDockerExtensionInstallHeuristic()
  );

  return (
    <Page>
      <PageHeader>
        <PageTitle>{`Setting up Akita${
          isDockerDesktopInstall ? " via Docker Desktop" : ""
        }`}</PageTitle>
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 1,
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "flex-start",
            },
          })}
        >
          <Typography>
            Welcome to Akita. See how your API is being used in as little as 10 minutes!
          </Typography>
          <ToggleButtonGroup
            color="primary"
            size="small"
            value={isDockerDesktopInstall}
            disabled={currentStepIndex > 2}
            exclusive
            onChange={(_, v) => {
              if (v === null) return;
              setIsDockerDesktopInstall(v);
            }}
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                marginTop: 1,
              },
            })}
          >
            <ToggleButton value={false}>Standard&nbsp;setup</ToggleButton>
            <ToggleButton value={true}>Docker&nbsp;Desktop&nbsp;setup</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Divider />
      </PageHeader>

      <PageContent>
        <List component="ol" disablePadding>
          <OnboardingStep
            currentStepIndex={currentStepIndex}
            setCurrentStepIndex={setCurrentStepIndex}
            stepIndex={0}
            title="Create a project"
            isClickable
          >
            <StepSelectProject project={project} setProject={setProject} />
          </OnboardingStep>

          <OnboardingStep
            currentStepIndex={currentStepIndex}
            setCurrentStepIndex={setCurrentStepIndex}
            isClickable={!!project}
            stepIndex={1}
            title="Get an API key"
          >
            <StepGetAPIKey
              fetchNewApiKey={generateAPIKey.mutate}
              apiKeyId={apiKey?.resource_id}
              apiKeySecret={apiKey?.api_key}
              isLoading={generateAPIKey.isLoading}
              isError={generateAPIKey.isError}
              isDockerDesktopInstall={isDockerDesktopInstall}
            />
          </OnboardingStep>

          <OnboardingStep
            currentStepIndex={currentStepIndex}
            setCurrentStepIndex={setCurrentStepIndex}
            isClickable={!!project && !!apiKey}
            stepIndex={2}
            title={
              isDockerDesktopInstall
                ? "Finish logging in to Docker Desktop"
                : "Install and run the Akita client"
            }
          >
            <StepStartClient
              isDockerDesktopInstall={isDockerDesktopInstall}
              apiKeyId={apiKey?.resource_id}
              apiKeySecret={apiKey?.api_key}
              projectName={project?.name}
            />
          </OnboardingStep>

          {!isDockerDesktopInstall && (
            <OnboardingStep
              currentStepIndex={currentStepIndex}
              setCurrentStepIndex={setCurrentStepIndex}
              isClickable={!!project && !!apiKey}
              stepIndex={3}
              title="Send some traffic"
            >
              <StepTrafficCheck projectID={project?.id} />
            </OnboardingStep>
          )}
        </List>
      </PageContent>
    </Page>
  );
};
