import { TrendingDown, TrendingFlat, TrendingUp } from "@mui/icons-material";
import { Box, Skeleton, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { mergeSxProps } from "dashboard/utils/sx";
import { TrendDirection, TrendImplication } from "dashboard/utils/timelines";

interface TrendPillProps {
  /** A percentage, represented as a decimal fraction (i.e. 50% is 0.5). */
  trend?: number;

  /** Indicates whether the trend is up, down, or essentially flat. */
  direction?: TrendDirection;

  /** Determines what color the pill should be. Red for "bad", green for "good",
   * gray for "uncertain". */
  implication?: TrendImplication;

  sx?: SxProps<Theme>;
  isLoading?: boolean;
}

export const TrendPill = ({ trend, direction, implication, isLoading, sx }: TrendPillProps) => {
  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: (theme) => theme.spacing(0.5),
        }}
        height={24}
        width={70}
      />
    );
  }

  if (Number.isNaN(trend)) return null;

  let icon = <TrendingFlat fontSize="small" aria-label="flat trend" />;

  if (direction === "up") {
    icon = <TrendingUp fontSize="small" aria-label="upwards trend" />;
  }

  if (direction === "down") {
    icon = <TrendingDown fontSize="small" aria-label="downwards trend" />;
  }

  let trendStr = `${((trend ?? 0) * 100).toFixed(1)}%`;

  if (trend === Infinity) {
    trendStr = "∞%";
  }

  if (trend === -Infinity) {
    trendStr = "-∞%";
  }

  return (
    <Box
      sx={mergeSxProps(
        {
          padding: "2px 4px",
          color: "#FFFFFF",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: (theme) => theme.spacing(0.5),
          backgroundColor: (theme) => {
            switch (implication) {
              case "good":
                return theme.palette.success.main;
              case "bad":
                return theme.palette.error.main;
              default:
                return theme.palette.grey[600];
            }
          },
        },
        sx
      )}
    >
      {!!direction && icon}

      <Typography component="span" variant="body2" fontSize="12px" paddingLeft={0.5}>
        {trendStr}
      </Typography>
    </Box>
  );
};
