import { noop } from "lodash";
import { createContext } from "react";

interface OnboardingStepContextValue {
  nextStep: () => void;
  prevStep: () => void;
  markCompleted: () => void;
  trackOnboardingEvent: (
    eventName: string,
    properties?: Record<string, string | number | boolean | undefined>
  ) => void;
  stepIndex?: number;
}

export const OnboardingStepContext = createContext<OnboardingStepContextValue>({
  nextStep: noop,
  prevStep: noop,
  markCompleted: noop,
  trackOnboardingEvent: noop,
  stepIndex: undefined,
});
