import { TabPanel } from "@mui/lab";
import { Divider, Link, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { LinkBehavior } from "../LinkBehavior";
import { EndpointDetailsCategoryManagement } from "./EndpointDetailsCategoryManagement";
import { getQueryLocation } from "dashboard/utils/routes";
import { FeatureFlag } from "feature_flags/feature-flags";
import { useIsFlagEnabled } from "hooks/use-is-flag-enabled";
import { MethodResponse } from "types/akita_api_types";

type EndpointDetailsTabPanelSettingsProps = {
  endpoint?: MethodResponse;
  modelID?: string;
  projectID: string;
};

export const EndpointDetailsTabPanelSettings = ({
  endpoint,
  modelID,
  projectID,
}: EndpointDetailsTabPanelSettingsProps) => {
  const location = useLocation();
  const isEnabledModelPageEditMode = useIsFlagEnabled(FeatureFlag.ModelPageEditModeEnabled);

  const settingsLocation = getQueryLocation(
    location,
    "modal",
    `/project/${projectID}/model/${modelID}/settings`
  );

  return (
    <TabPanel sx={{ padding: 0 }} value="settings">
      {isEnabledModelPageEditMode && (
        <>
          <Typography variant="h3" marginBottom={2}>
            Change category
          </Typography>

          <EndpointDetailsCategoryManagement
            modelID={modelID}
            projectID={projectID}
            endpoint={endpoint}
          />

          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        </>
      )}
      <Typography variant="h3" marginBottom={1}>
        Fix this endpoint
      </Typography>

      <List disablePadding>
        <ListItem sx={{ padding: 0 }}>
          <ListItemText
            primary={
              <Link color="text.primary" href={`/service/${projectID}/diagnostics/custom-labels`}>
                The hostname is incorrect.
              </Link>
            }
            secondary="e.g. 10.0.0.1 should be example.com"
          />
        </ListItem>

        <ListItem sx={{ padding: 0 }}>
          <ListItemText
            primary={
              <Link component={LinkBehavior} color="text.primary" to={settingsLocation}>
                Path inference is too aggressive.
              </Link>
            }
            secondary="e.g. /{arg3} should be /foo and /bar"
          />
        </ListItem>

        <ListItem sx={{ padding: 0 }}>
          <ListItemText
            primary={
              <Link component={LinkBehavior} color="text.primary" to={settingsLocation}>
                Path inference is not aggressive enough.
              </Link>
            }
            secondary="e.g. /foo and /bar should be collapsed to {arg3}"
          />
        </ListItem>
      </List>
    </TabPanel>
  );
};
