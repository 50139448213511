import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { LinearProgress, Typography } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { ErrorWidget } from "dashboard/components/ErrorWidget";
import { CustomTable } from "dashboard/components/Table";
import { prettifyTimeDelta } from "dashboard/utils/dates";
import { useGetFetch } from "hooks/use-get-fetch";
import { GetDaemonsResponse } from "types/akita_api_types";

const dangerColor = "#ED5500";
const successColor = "#21762A";

export const DaemonList = () => {
  const { authState } = useOktaAuth();

  // Fetch daemon heartbeats
  const daemonURL = `${process.env.REACT_APP_API_URL}/v1/daemon/active`;
  const [daemons, isDaemonLoading, daemonError, daemonRefresh] = useGetFetch<GetDaemonsResponse>(
    daemonURL,
    undefined,
    false,
    "fetching the daemon status"
  );

  // Refresh list every 30 seconds
  React.useEffect(() => {
    const id = setInterval(() => {
      daemonRefresh();
    }, 30 * 1000);
    return () => clearInterval(id);
  }, [authState?.isAuthenticated]);

  if (isDaemonLoading) {
    return (
      <div>
        <ErrorWidget errorSources={[daemonError]} errorContext="daemon" />
        <LinearProgress />
      </div>
    );
  }

  if (!daemons || daemons.length == 0) {
    return <Typography variant="body2">No daemon heartbeats received in the past hour.</Typography>;
  }

  const healthyStyle = {
    color: successColor,
  };

  const unhealthyStyle = {
    color: dangerColor,
  };

  const daemonHealth = (lastHeartbeat: string) => {
    const now = new Date();
    const d = new Date(lastHeartbeat);
    const msDelta = now.getTime() - d.getTime();
    if (msDelta <= 60 * 1000) {
      return [
        <span style={healthyStyle} key="healthy">
          <CheckCircleIcon htmlColor={successColor} /> healthy
        </span>,
        <span key="time">{prettifyTimeDelta(d, msDelta)}</span>,
      ];
    } else {
      return [
        <span style={unhealthyStyle} key="unhealthy">
          <ErrorIcon htmlColor={dangerColor} /> unhealthy
        </span>,
        <span key="time">{prettifyTimeDelta(d, msDelta)}</span>,
      ];
    }
  };

  const daemonTableData = daemons.map((d) =>
    [<span key={d.daemon_name}>{d.daemon_name}</span>].concat(daemonHealth(d.last_heartbeat), [
      <span key="blank"></span>,
    ])
  );

  return (
    <div>
      <CustomTable
        tableHead={["Active Daemons", "Status", "Last heartbeat", ""]}
        tableData={daemonTableData}
      />
    </div>
  );
};
