import { Skeleton, SkeletonProps } from "@mui/material";
import React, { useRef } from "react";
import { randNumberInRange } from "dashboard/utils/numbers";
import { mergeSxProps } from "dashboard/utils/sx";

type SkeletonTextProps = Omit<SkeletonProps, "variant"> & {
  maxWidth?: number;
  minWidth?: number;
  randomWidth?: boolean;
  randomWidthInPixels?: boolean;
};

export const SkeletonText = ({
  maxWidth = 100,
  minWidth = 50,
  randomWidth = true,
  sx,
  randomWidthInPixels = false,
  ...rest
}: SkeletonTextProps) => {
  // Store this as a ref so the random width never changes after mount.
  const widthRef = useRef(randNumberInRange(minWidth, maxWidth));

  const width = randomWidth ? `${widthRef.current}${randomWidthInPixels ? "px" : "%"}` : "100%";

  return (
    <Skeleton
      variant="text"
      width={width}
      sx={mergeSxProps({ display: "inline-block" }, sx)}
      {...rest}
    />
  );
};
