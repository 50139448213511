import { datadogRum } from "@datadog/browser-rum";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePageTracker } from "../hooks/use-page-tracker";
import { Navbar } from "./components/Navbar/Navbar";
import { ModalRouter } from "./routers/ModalRouter";
import { CheckUser } from "dashboard/components/CheckUser";
import { Footer } from "dashboard/components/Footer";
import { PrimaryRouter } from "dashboard/routers/Router";
import { useProjects } from "data/queries/projects";
import { useAuth } from "hooks/use-auth";
import { useSession } from "hooks/use-session";

/**
 * Initializes the DataDog library
 */
const initializeMetrics = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const isProd = apiURL?.startsWith("https://api.akita.software");

  // const isLocal = process.env.REACT_APP_NETLIFY !== "true";

  // This is supplied by Netlify, so it'll be undefined (or "") when running locally.
  const version = process.env.REACT_APP_COMMIT_REF || "dev";

  let datadogApplicationID = "f498e9b2-cfbe-4ff3-8fab-74fc5f7053c4"; // dev application ID
  let datadogClientToken = "pub91831cc19b3b4c64e39eb8ddaea519d6"; // dev client token

  if (isProd) {
    datadogApplicationID = "5b269a29-caa8-4e0d-9796-fdfd7b2c2a3d"; // prod application ID
    datadogClientToken = "pub905bfdd410241db4dce10e82834a50d7"; // prod application ID
  }

  datadogRum.init({
    applicationId: datadogApplicationID,
    clientToken: datadogClientToken,
    site: "datadoghq.com",
    service: "superstar-frontend",
    env: isProd ? "prod" : "staging",
    sampleRate: 100,
    trackInteractions: true,
    trackViewsManually: true,
    version,
  });
};

export const Layout = () => {
  // Set up Metric Integrations.
  // THIS MUST HAPPEN BEFORE ANYTHING ELSE IN THIS COMPONENT!
  useEffect(() => {
    initializeMetrics();
  }, []);

  useSession();

  const location = useLocation();

  usePageTracker();

  const { authState, authService, setAkitaUserInfo, isValidUser } = useAuth();
  const { isLoading } = useProjects();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100vh",
        }}
      >
        <Navbar />

        <Box sx={{ padding: 2, paddingTop: 9 }}>
          {authState?.isAuthenticated && authState?.accessToken?.accessToken && (
            <CheckUser
              authState={authState}
              authService={authService}
              location={location}
              onSuccess={setAkitaUserInfo}
            />
          )}

          {isValidUser && !isLoading && <PrimaryRouter />}
        </Box>

        <Footer />
      </Box>

      {isValidUser && !isLoading && <ModalRouter />}
    </>
  );
};
