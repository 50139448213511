import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { uniq } from "lodash";
import { matchSorter } from "match-sorter";
import React, { useCallback, useMemo } from "react";
import { useLogInteraction } from "hooks/use-log-interaction";
import { useSearchParamArray } from "hooks/use-search-param-array";
import { SpecContentSummary, TimelineSummaryFilters } from "types/akita_api_types";

const filterOptions: AutocompleteProps<string, true, undefined, true>["filterOptions"] = (
  options,
  { inputValue }
) => matchSorter(options, inputValue);

const renderInput: AutocompleteProps<string, true, undefined, true>["renderInput"] = (params) => (
  <TextField
    // Only show the placeholder text if there's no items selected
    placeholder={!params.InputProps.startAdornment ? "Search for endpoints" : undefined}
    aria-label="Search for endpoints"
    // Annoyingly, this is the only way I know of to override this default appearance.
    sx={{ fieldset: { borderColor: "divider" } }}
    {...params}
  />
);

type EndpointsTableAutocompleteProps = {
  summary?: SpecContentSummary | TimelineSummaryFilters;
  isLoading?: boolean;
};

export const EndpointsTableAutocomplete = ({
  summary,
  isLoading,
}: EndpointsTableAutocompleteProps) => {
  const [values, setValues] = useSearchParamArray("filter-required_endpoints");
  const logInteraction = useLogInteraction({
    pathsCount: summary?.paths ? Object.keys(summary.paths).length : undefined,
    hostsCount: summary?.hosts ? Object.keys(summary.hosts).length : undefined,
  });

  const options = useMemo(() => {
    const result: string[] = [];

    if (summary?.paths) {
      const pathOptions = Object.keys(summary.paths);

      result.push(...pathOptions);
    }

    if (summary?.hosts) {
      const hostOptions = Object.keys(summary.hosts);

      result.push(...hostOptions);
    }

    // Ensure the list doesn't have any duplicates
    return uniq(result);
  }, [summary]);

  const onChange = useCallback(
    (_event, newValues: string[]) => {
      logInteraction("Searched for endpoints", { searchCount: newValues.length });
      setValues(newValues);
    },
    [setValues, logInteraction]
  );

  return (
    <Autocomplete
      autoHighlight
      disabled={isLoading}
      filterOptions={filterOptions}
      freeSolo
      multiple
      onChange={onChange}
      options={options}
      renderInput={renderInput}
      sx={{ marginBottom: 2 }}
      value={values}
    />
  );
};
