import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AcceptInviteModal } from "./AcceptInviteModal";
import { InvitesTable } from "./InvitesTable";
import { acceptInvite } from "./backend";
import { QueryError } from "dashboard/components/QueryError";
import { useAPIKeys } from "data/queries/api-keys";
import { useProjects } from "data/queries/projects";
import { useUserInvites } from "data/queries/user-invites";
import { useAuth } from "hooks/use-auth";
import { useImpression } from "hooks/use-impression";
import { OrganizationInvite } from "types/akita_api_types";

export const Invites = () => {
  const location = useLocation();
  const { userInfo, authState } = useAuth();
  const [invite, setInvite] = useState<OrganizationInvite | null>(null);
  const [isDialogVisible, showDialog] = useState(false);
  const [alert, setAlert] = useState(<div />);
  const { data: apiKeys, isLoading: isLoadingAPIKeys, isError: isErrorAPIKeys } = useAPIKeys();
  const { data: projects, isLoading: isLoadingProjects, isError: isErrorProjects } = useProjects();

  // Get invite ID from query params
  const { organization_invite_id } = queryString.parse(location.search);

  // Get invitations from backend
  const {
    data: invites,
    isLoading: isLoadingInvites,
    error: errorInvites,
    refetch: refetchInvites,
  } = useUserInvites();

  // If the user has services or API keys (or if we're not sure yet), warn that accepting an
  // invitation will remove access to their services and revoke their API keys
  const shouldShowWarning =
    isErrorAPIKeys ||
    isErrorProjects ||
    isLoadingAPIKeys ||
    isLoadingProjects ||
    !!projects?.length ||
    !!apiKeys?.length;

  useEffect(() => {
    if (invites === null || isLoadingInvites || !organization_invite_id) return;

    for (const inv of invites ? invites : []) {
      if (inv.id === organization_invite_id) {
        setInvite(inv);
        showDialog(true);
        return;
      }
    }

    // Set alert if we can't find the invite
    setAlert(
      <Alert severity="error">
        We couldn&rsquo;t find your invitation! Here are a few things to check.
        <ul>
          <li style={{ marginBottom: "12px" }}>
            You&rsquo;re logged in as <b>{userInfo?.email}</b>. Was that the email address your
            invite was sent to?
          </li>
          <li>
            If you got here by copy/pasting a link from an invitation email, check and make sure you
            got it all.
          </li>
        </ul>
        Otherwise, drop us a line for a little <a href="mailto:support@akitasoftware.com">help</a>.
      </Alert>
    );
  }, [invites, isLoadingInvites, organization_invite_id, userInfo?.email]);

  const setAcceptDialog = (invite: OrganizationInvite) => {
    const accessToken = authState?.accessToken?.accessToken;

    if (!shouldShowWarning && accessToken) {
      acceptInvite(accessToken, invite.id).then(
        () => {
          // Reload the window at the landing page.
          (window.location as any) = "/";
        },
        (akErrPromise) => {
          akErrPromise.then(() => {
            setAlert(
              <Alert severity="error">
                Sorry! We had trouble accepting your invitation from {invite.inviter_name}. Please
                try again soon.
              </Alert>
            );
          });
        }
      );
    } else {
      setInvite(invite);
      showDialog(true);
    }
  };

  useImpression("Settings - Invites Tab", { invitesCount: invites?.length });

  const isLoading = isLoadingInvites || isLoadingProjects || isLoadingAPIKeys;

  return (
    <div>
      {isLoading && <LinearProgress />}
      <Box padding={3}>
        {!isLoading && (
          <div>
            {invite && (
              <AcceptInviteModal
                invite={invite}
                open={isDialogVisible}
                close={() => showDialog(false)}
                showWarning={shouldShowWarning}
                setAlert={setAlert}
              />
            )}
            {alert}
            <Typography variant="h2" gutterBottom>
              Your invitations to new teams
            </Typography>

            <QueryError
              label="We had trouble loading your invitations."
              error={errorInvites}
              refetch={refetchInvites}
            />

            {!!invites && invites.length > 0 && (
              <InvitesTable
                invites={invites}
                setAcceptInviteDialog={setAcceptDialog}
                refreshInvites={refetchInvites}
              />
            )}

            {!!invites && invites.length < 1 && (
              <Typography>No outstanding invitations.</Typography>
            )}
          </div>
        )}
      </Box>
    </div>
  );
};
